/* eslint-disable react-native/no-inline-styles */
import React from 'react';

import moment from 'moment';
import { FlatList } from 'native-base';

import { StatementBalanceItem } from './StatementBalanceItem';
import { Card, Text, View } from './Themed';
import Colors from '../constants/Colors';
import Constants from '../constants/Constants';
import AppStyles from '../constants/Styles';
import { StatementTransaction } from '../commonTypes';
import Layout from '../constants/Layout';

export type StatementBalanceWidgetProps = {
  transactionItem: StatementTransaction;
};
export function StatementBalanceWidget({ transactionItem }: StatementBalanceWidgetProps) {
  if (transactionItem.isBalance) {
    return (
      <Card
        style={{
          marginBottom: 10,
          marginRight: 10,
          borderColor: Colors.primary,
          borderWidth: 2,
        }}
      >
        <View
          style={{
            backgroundColor: 'transparent',
          }}
        >
          <Text
            style={{
              fontSize: Constants.TextSize.max,
              color: Colors.primary,
            }}
          >
            {transactionItem.accountNumber}
          </Text>
          <Text style={AppStyles.textSubTitle}>
            {moment(transactionItem.valueDate?.toDate()).format('ddd, DD MMM YYYY')}
          </Text>
        </View>
        <FlatList
          style={{ maxHeight: Layout.window.height / 2 }}
          data={transactionItem.balances}
          renderItem={({ item, index }) => {
            return (
              <StatementBalanceItem
                balance={item}
                currency={transactionItem.currency}
                balKey={index.toString()}
              />
            );
          }}
        />
      </Card>
    );
  }
  return null;
}
