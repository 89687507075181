import React, { FC } from 'react';

import { AddIcon } from '@chakra-ui/icons';
import { ButtonProps, Button as ChakraButton } from '@chakra-ui/react';

export type CButtonProps = {
  label: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  variant?: 'Default' | 'Create' | 'Edit' | 'Delete' | 'Cancel' | 'Approve';
  onPress: () => void;
} & ButtonProps;
const Button: FC<CButtonProps> = ({
  label,
  isLoading,
  isDisabled,
  onPress,
  variant = 'Default',
  ...otherProps
}) => {
  let icon = undefined;
  let colorScheme = 'blue';
  switch (variant) {
    case 'Create':
      icon = <AddIcon />;
      colorScheme = 'green';
      break;
    case 'Edit':
      colorScheme = 'orange';
      break;
    case 'Delete':
      colorScheme = 'red';
      break;
    case 'Cancel':
      colorScheme = 'gray';
      break;
    case 'Approve':
      colorScheme = 'green';
      break;
    default:
      break;
  }

  return (
    <ChakraButton
      mr={2}
      colorScheme={colorScheme}
      onClick={onPress}
      leftIcon={icon}
      isDisabled={isDisabled}
      isLoading={isLoading}
      {...otherProps}
    >
      {label}
    </ChakraButton>
  );
};

export default Button;
