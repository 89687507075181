import { uuidv4 } from '@firebase/util';
import * as DocumentPicker from 'expo-document-picker';
import { Timestamp, WhereFilterOp, where } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import isEmpty from 'lodash/isEmpty';

import pick from 'lodash/pick';
import moment from 'moment';
import * as XLSX from 'xlsx';

import rdiff from 'recursive-diff';
import Colors from '../constants/Colors';
import Constants, { DateFormat } from '../constants/Constants';
import {
  Action,
  ApprovalWorkflowRequest,
  BalanceRecord,
  DateName,
  RequestAction,
  UploadedFile,
  User,
  UserGroup,
  Visibility,
} from '../commonTypes';
import { isEqual, pickBy } from 'lodash';

export async function _uploadFileAsync(uri: string) {
  // Why are we using XMLHttpRequest? See:
  // https://github.com/expo/expo/issues/2402#issuecomment-443726662

  const blob = await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      resolve(xhr.response);
    };
    xhr.onerror = function (e) {
      console.log(e);
      reject(new TypeError('Network request failed'));
    };
    xhr.responseType = 'blob';
    xhr.open('GET', uri, true);
    xhr.send(null);
  });

  const name = Math.round(+new Date() / 1000);

  const fileRef = ref(getStorage(), `${uuidv4()}`);
  const result = await uploadBytes(fileRef, blob);
  const downloadUrl = await getDownloadURL(fileRef);
  return {
    downloadURL: downloadUrl,
    filepath: fileRef.fullPath.toString(),
  } as UploadedFile;
}

export const _pickDocument = async (statusCallback: any) => {
  const result = await DocumentPicker.getDocumentAsync({ multiple: false });
  statusCallback(true);
  if (result?.assets?.[0]?.uri) {
    const uploadedFile: UploadedFile = await _uploadFileAsync(result?.assets?.[0]?.uri);
    return uploadedFile;
  }
  return null;
};

export const _getCodeDescr = ({
  code,
  familyCode,
  subFamilyCode,
  descrOnly,
}: {
  code?: string;
  descrOnly?: boolean;
  familyCode?: string;
  subFamilyCode?: string;
}) => {
  if (!code) {
    return '';
  }
  const combinedCode = code + (familyCode || '') + (subFamilyCode || '');
  if (descrOnly) {
    return Constants.BankCodes[combinedCode]?.descr || '';
  }
  return Constants.BankCodes[combinedCode]?.descr
    ? combinedCode + ' : ' + Constants.BankCodes[combinedCode]?.descr
    : combinedCode;
};

export const _getOpeningBalances = (balances: BalanceRecord[]) => {
  return balances.filter((balance) =>
    [...Constants.openingLedger, ...Constants.openingAvailable].includes(balance.code),
  );
};

export const _getOpeningLedgerBalance = (balances?: BalanceRecord[]) => {
  return (
    balances?.filter((balance) => Constants.openingLedger.includes(balance.code))[0]?.amount || 0
  );
};
export const _getOpeningAvailableBalance = (balances?: BalanceRecord[]) => {
  return (
    balances?.filter((balance) => Constants.openingAvailable.includes(balance.code))[0]?.amount || 0
  );
};
export const _getClosingAvailableBalance = (balances?: BalanceRecord[]) => {
  return (
    balances?.filter((balance) => Constants.closingAvailable.includes(balance.code))[0]?.amount || 0
  );
};

export const _getClosingBalances = (balances: BalanceRecord[]) => {
  return balances.filter((balance) => Constants.closingBalances.includes(balance.code));
};

export const _getOtherBalances = (balances: BalanceRecord[]) => {
  return balances.filter(
    (balance) =>
      !Constants.closingBalances.includes(balance.code) &&
      !Constants.openingBalances.includes(balance.code),
  );
};
export const _getFormattedCurrency = (
  amount?: number,
  currency?: string,
  amountOnly?: boolean,
  shorten?: 'M' | 'B',
) => {
  if (shorten && shorten === 'B' && amount && amount > 1000000000) {
    return (
      (currency && !amountOnly ? currency + ' ' : '') +
      parseFloat((amount / 1000000000).toFixed(2)).toLocaleString('en-US') +
      'B'
    );
  }
  if (shorten && amount && amount > 1000000) {
    return (
      (currency && !amountOnly ? currency + ' ' : '') +
      parseFloat((amount / 1000000).toFixed(2)).toLocaleString('en-US') +
      'M'
    );
  }
  return (
    (currency && !amountOnly ? currency + ' ' : '') +
    (parseFloat((amount || 0).toFixed(2)).toLocaleString('en-US') || '')
  );
};

export const _getCrDbIndicator = (code?: string) => {
  return code ? Constants.BankCodes[code]?.type : 'NA';
};

export const _getConvertedAmount = (currency?: string, amount: number, currencyRates: any) => {
  if (amount && amount !== 0 && currency) {
    return amount / (currencyRates[currency] || 1);
  }
  return amount;
};

export const _downloadExcel = (data: any, headers: any, fileName?: string) => {
  data = data.map((item) => pick(item, Object.keys(headers)));
  const worksheet = XLSX.utils.json_to_sheet(data, { header: ['accountNumber'] });
  const workbook = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  XLSX.utils.sheet_add_aoa(worksheet, [Object.values(headers)], { origin: 'A1' });

  //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
  //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
  XLSX.writeFile(workbook, `CashExact_${fileName || 'downloaded'}.xlsx`);
};

export const _getColorFromCrDrIndicator = (CdtDbtInd?: string) => {
  if (CdtDbtInd === 'C') {
    return Colors.green;
  }
  if (CdtDbtInd === 'D') {
    return Colors.red;
  }
  return Colors.black;
};

export const _addAlpha = (color: string, opacity: number) => {
  // coerce values so ti is between 0 and 1.
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
};

export const _filterToWhereClause = (filter: any) => {
  if (isEmpty(filter)) {
    return [];
  }
  console.warn('filter', filter);

  return Object.entries(filter)?.flatMap(([k, v]) => {
    let operator: WhereFilterOp = '==';
    if (Array.isArray(v)) {
      operator = 'in';
    }
    console.warn(moment(v as Date).toDate());
    if (_isValidDate(v)) {
      return [
        where(k, '>=', moment(v as Date).toDate()),
        where(
          k,
          '<',
          moment(v as Date)
            .add(1, 'days')
            .toDate(),
        ),
      ];
    }
    return where(k, operator, v);
  });
};

export const _isValidDate = (date: any) => {
  return date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date);
};

export const _textFieldIsInvalid = (value?: string) => {
  return !value || value.toString().trim() === '';
};

export const _emailFieldIsInvalid = (value?: string) => {
  return (
    !value ||
    value.trim() === '' ||
    !String(value)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
  );
};

export const _array_move = (arr: any[], old_index: number, new_index: number) => {
  if (new_index >= arr.length) {
    let k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
};

export const _sort = (arr?: any[], key = 'name') => {
  return arr?.sort((a, b) => {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  });
};

export const _getDayFromCode = (dayNumber: number) => {
  return moment().day(dayNumber).format('dddd');
};

export const _getDaysFromCodes = (dayNumbers?: number[]) => {
  if (!dayNumbers) {
    return undefined;
  }
  let days = '';
  dayNumbers.map((dayNumber) => {
    days += _getDayFromCode(dayNumber) + ', ';
  });
  return days.slice(0, -2);
};

export const _searchList = (list?: any[], text?: string) => {
  const searchArray = text?.split(' ');
  return list?.filter((item) =>
    searchArray?.every((searchWord) =>
      JSON.stringify(Object.values(item))
        .toLocaleLowerCase()
        .includes(searchWord.toLocaleLowerCase()),
    ),
  );
};

export const _getDateFromDateName = (dateName?: DateName) => {
  switch (dateName) {
    case DateName.TODAY:
      return moment().startOf('day');
    case DateName.YESTERDAY:
      return moment().subtract(1, 'days').startOf('day');
    case DateName.TOMORROW:
      return moment().add(1, 'days').startOf('day');
    default:
      return moment().startOf('day');
  }
};

// Filter a list based on the access of the user
// The list should include the fields visibility and userGroups in it
export const _filterListByAccess = (
  currentUserUid: string,
  userGroupsOfUser?: string[],
  list?: any[],
) => {
  if (!list || list.length === 0) {
    return [];
  }
  return list.filter((item) => {
    if (item.visibility === Visibility.Public) {
      return true;
    }
    if (item.visibility === Visibility.Private) {
      return item.createdBy === currentUserUid;
    }
    if (item.visibility === Visibility.UserGroup) {
      return userGroupsOfUser?.some((uug) => item.userGroups.includes(uug));
    }
    return true;
  });
};

export const _getMergedPermissions = (userGroups: UserGroup[]) => {
  const mergedPermission: any = {};
  userGroups.map((userGroup) => {
    if (userGroup.permissions) {
      Object.entries(userGroup.permissions).map(([key, value]) => {
        if (!mergedPermission[key]) {
          mergedPermission[key] = {};
        }
        mergedPermission[key].view = mergedPermission[key].view || value.view;
        mergedPermission[key].edit = mergedPermission[key].edit || value.edit;
        mergedPermission[key].approve = mergedPermission[key].approve || value.approve;
        mergedPermission[key].delete = mergedPermission[key].delete || value.delete;
        mergedPermission[key].create = mergedPermission[key].create || value.create;
      });
    }
  });
  return mergedPermission;
};

export const _getChanges = (
  before: any,
  after: any,
  ignoreFields?: string[],
  arrayFields?: string[],
) => {
  let diff = rdiff.getDiff(before, after);
  console.warn('diff', diff);
  if (ignoreFields) {
    diff = diff.filter((d: rdiff.rdiffResult) => !ignoreFields.includes(d.path?.[0].toString()));
    arrayFields?.map((field) => {
      diff.push({
        op: 'update',
        path: [field],
        val: after[field] ? after[field].join(', ') : 'Empty',
      });
      return diff;
    });
  }

  return diff;
};

export const _getChangedFields = (before: any, after: any) => {
  return pickBy(after, (v, k) => !isEqual(before[k], v));
};

export const _getUsersByUserGroups = (userGroups: string[], users?: User[]) => {
  return (
    users
      ?.filter((user) => user.userGroups?.some((userGroup) => userGroups.includes(userGroup)))
      ?.map((user) => {
        return {
          uid: user.uid,
          name: user.firstName,
        };
      }) || []
  );
};

export const _getTimeStampString = (timestamp?: Timestamp) => {
  if (!timestamp) {
    return '';
  }
  if (timestamp instanceof Timestamp) {
    return moment(timestamp.toDate()).format(DateFormat.DEFAULT);
  }
  if (timestamp?._seconds) {
    return moment(timestamp?._seconds * 1000).format(DateFormat.DEFAULT);
  }
  return moment().format(DateFormat.DEFAULT);
};

export const _getApprovalWorkflowRequestAction = (request?: ApprovalWorkflowRequest) => {
  let action: { label: RequestAction; color: string } = {
    label: RequestAction.Update,
    color: 'orange',
  };
  const before = request?.before;
  const after = request?.after;
  if (!before && after) {
    action = { label: RequestAction.Create, color: 'green' };
  }
  return action;
};

export const _getLogActionColor = (action?: Action) => {
  let color = 'orange';
  switch (action) {
    case Action.CREATE:
      color = 'green';
      break;
    case Action.UPDATE:
      color = 'orange';
      break;
    case Action.DELETE:
      color = 'red';
      break;
    case Action.APPROVE:
      color = 'green';
      break;
    case Action.REJECT:
      color = 'red';
      break;
    default:
      color = 'orange';
      break;
  }
  return color;
};

export const _getItemNameFromLogs = (item: any) => {
  return item?.after?.sequenceNumber
    ? '#' + item?.after?.sequenceNumber
    : item?.before?.name ||
        item?.before?.accountName ||
        item?.before?.template ||
        item?.after?.template ||
        item?.after?.name ||
        item?.after?.firstName ||
        item?.key;
};
