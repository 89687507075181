import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions';

import { Badge, useToast } from '@chakra-ui/react';
import { isEmpty, omit } from 'lodash';
import { TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../App';
import {
  ApprovalWorkflowSource,
  InputMode,
  LogType,
  StaticDataStore,
  Status,
  User,
} from '../../commonTypes';
import AppStyles from '../../constants/Styles';
import { _emailFieldIsInvalid, _getChangedFields, _textFieldIsInvalid } from '../../utils/helper';
import ActivityHistory from '../ActivityHistory';
import ReviewAndApproveModal from '../ApprovalWorkflow/ReviewAndApproveModal';
import ButtonGroup from '../ButtonGroup';
import CInput from '../CInput';
import StatusTag from '../StatusTag';
import { View } from '../Themed';
import UserGroupInput from './UserGroupInput';

export type UserDetailsProps = {
  user: User;
  setIsVisible?: Dispatch<SetStateAction<boolean>>;
  onUserUpdated?: (updatedUser: User) => any;
  mode?: InputMode;
  onNext?: () => void;
  onItemActioned?: () => void;
};

export default function UserDetails({
  user,
  setIsVisible,
  onUserUpdated,
  mode = InputMode.CREATE,
  onNext,
  onItemActioned,
}: UserDetailsProps) {
  const [isSaving, setIsSaving] = useState(false);
  const [password, setPassword] = useState('');
  const [reEnterdPassword, setReEnterdPassword] = useState('');
  const [selectedUser, setSelectedUser] = useState<User>(user);
  const { staticData } = useSelector((store: StaticDataStore) => store);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [canApprove, setCanApprove] = useState(false);
  const isWorkflowEnabled =
    staticData?.orgInfo?.Workflow?.[ApprovalWorkflowSource.USERS]?.status ||
    staticData?.orgInfo?.Workflow?.[ApprovalWorkflowSource.SECURITY]?.status;
  const isReadOnly = [InputMode.VIEW, InputMode.APPROVE].includes(mode);
  const canEdit = staticData.accessibleScreens?.UsersScreen?.edit || false;
  useEffect(() => {
    setSelectedUser(user);
  }, [user]);

  const { db } = useContext(AppContext);
  const toast = useToast();
  const { profileData } = useSelector((store: StaticDataStore) => store);

  const onSave = useCallback(
    async (isDeactive?: boolean) => {
      if (isSaving || !profileData?.orgId) {
        return;
      }
      setIsSaving(true);
      if (_emailFieldIsInvalid(selectedUser?.email)) {
        setIsSaving(false);

        return toast({
          title: 'Invalid email',
          description: 'Please enter a valid email',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
      if (
        _textFieldIsInvalid(selectedUser?.firstName) ||
        _textFieldIsInvalid(selectedUser?.lastName)
      ) {
        setIsSaving(false);

        return toast({
          title: 'Missing mandatory fields',
          description: 'Please fill all the mandatory fields',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
      if (
        mode === InputMode.CREATE &&
        (_textFieldIsInvalid(password) || _textFieldIsInvalid(reEnterdPassword))
      ) {
        setIsSaving(false);
        return toast({
          title: 'Missing mandatory fields',
          description: 'Please fill all the mandatory fields',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }

      if (password !== reEnterdPassword) {
        setIsSaving(false);
        return toast({
          title: 'Passwords do not match',
          description: 'Password and Re-entered password do not match',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
      if (mode === InputMode.CREATE) {
        const functions = getFunctions();
        if (__DEV__) {
          connectFunctionsEmulator(functions, 'localhost', 5001);
        }
        const createUser = httpsCallable(functions, 'createUser');
        createUser({
          email: selectedUser?.email,
          password: password,
          displayName: selectedUser?.firstName + ' ' + selectedUser?.lastName,
          firstName: selectedUser?.firstName,
          lastName: selectedUser?.lastName,
          orgId: profileData.orgId,
          userGroups: selectedUser?.userGroups || [],
          status: isWorkflowEnabled ? Status.PENDING_APPROVAL : Status.ACTIVE,
        }).then((result) => {
          // Read result of the Cloud Function.
          /** @type {any} */
          // console.log(result.data);
          setIsSaving(false);
          if (result?.data?.state) {
            onUserUpdated?.({
              ...selectedUser,
              status: isWorkflowEnabled ? Status.PENDING_APPROVAL : Status.ACTIVE,
              uid: result?.data?.uid,
            });
            setIsVisible?.(false);
          }

          return toast({
            title: result?.data?.message,
            status: result?.data?.state ? 'success' : 'error',
            duration: 5000,
            isClosable: true,
          });
        });
      } else {
        let userDoc = {
          ...selectedUser,
          status: isDeactive ? Status.DEACTIVATED : Status.ACTIVE,
          displayName: selectedUser?.firstName + ' ' + selectedUser?.lastName,
        };
        const changedFields = _getChangedFields(user, selectedUser);
        const updateRequest = {
          ...changedFields,
          requestedBy: profileData.uid,
          requestId: uuidv4(),
          lastUpdatedAt: serverTimestamp(),
        };
        if (isEmpty(changedFields)) {
          setIsVisible?.(false);
          setIsSaving(false);
          return;
        }
        if (isWorkflowEnabled) {
          userDoc = {
            ...user,
            updateRequest: updateRequest,
          };
        }
        await updateDoc(
          doc(db, 'Organizations', profileData.orgId, 'Users', selectedUser?.uid),
          isWorkflowEnabled
            ? { updateRequest: updateRequest }
            : {
                ...omit(userDoc, ['uid', 'email', 'lastLogin', 'createdBy', 'createdDtTm']),
              },
        )
          .then(() => {
            setIsSaving(false);
            onUserUpdated?.(userDoc);
            setIsVisible?.(false);
            return toast({
              title: isWorkflowEnabled ? 'User update requested. Pending approval' : 'User Updated',
              status: isWorkflowEnabled ? 'warning' : 'success',
              duration: 5000,
              isClosable: true,
            });
          })
          .catch((error) => {
            setIsSaving(false);
            console.error('Something went wrong');
          });
      }
    },
    [
      db,
      isSaving,
      mode,
      onUserUpdated,
      password,
      profileData.orgId,
      reEnterdPassword,
      user,
      selectedUser,
      setIsVisible,
      toast,
    ],
  );

  const onTextChange = useCallback((field: keyof User, value: string) => {
    setSelectedUser((existingValue) => {
      return { ...existingValue, [field]: value } as User;
    });
  }, []);

  if (!selectedUser) {
    return null;
  }

  return (
    <>
      {isWorkflowEnabled && (
        <ReviewAndApproveModal
          onClose={() => {
            setShowApprovalModal(false);
          }}
          pendingApprovalItem={selectedUser}
          source={ApprovalWorkflowSource.USERS}
          id={selectedUser?.uid}
          onApprovalDataLoaded={(approvalData) => {
            setCanApprove(approvalData.canApprove);
          }}
          onApprovalOrRejectonCompleted={(approved) => {
            onUserUpdated?.(
              omit(
                {
                  ...selectedUser,
                  status: approved
                    ? Status.ACTIVE
                    : selectedUser?.updateRequest
                    ? selectedUser.status
                    : Status.DEACTIVATED,
                },
                ['updateRequest'],
              ),
            );
            setIsVisible?.(false);
          }}
          onSomeActionDone={onItemActioned}
          show={showApprovalModal}
        />
      )}
      <View pointerEvents={canEdit && !isReadOnly ? 'auto' : 'none'}>
        <View style={AppStyles.flexRowCenterSpaceBetween}>
          <CInput
            label="User Email"
            fieldKey="email"
            fieldValue={selectedUser.email}
            onTextChange={onTextChange}
            isRequired
            disabled={mode === InputMode.EDIT}
          />
          <View>
            <StatusTag label={selectedUser.status || Status.ACTIVE} isReadOnly />
            {selectedUser.updateRequest && (
              <TouchableOpacity
                onPress={() => {
                  setShowApprovalModal(true);
                }}
              >
                <Badge variant="solid" colorScheme="orange">
                  Pending Updates
                </Badge>
              </TouchableOpacity>
            )}
          </View>
        </View>
        <View style={AppStyles.flexRowCenter}>
          <View style={AppStyles.flex1}>
            <CInput
              label="First Name"
              fieldKey="firstName"
              fieldValue={selectedUser.firstName}
              onTextChange={onTextChange}
              isRequired
            />
          </View>
          <View style={AppStyles.flex1}>
            <CInput
              label="Last Name"
              fieldKey="lastName"
              fieldValue={selectedUser.lastName}
              onTextChange={onTextChange}
              isRequired
            />
          </View>
        </View>
        {mode === InputMode.CREATE && (
          <View style={AppStyles.flexRowCenter}>
            <View style={AppStyles.flex1}>
              <CInput
                label="Password"
                fieldKey="passsword"
                fieldValue={password}
                onTextChange={(field, value) => setPassword(value)}
                isSecured={true}
                isRequired
              />
            </View>
            <View style={AppStyles.flex1}>
              <CInput
                label="Re-enter Password"
                fieldKey="repasssword"
                isSecured={true}
                fieldValue={reEnterdPassword}
                onTextChange={(field, value) => setReEnterdPassword(value)}
                isRequired
              />
            </View>
          </View>
        )}
        <UserGroupInput
          values={selectedUser.userGroups}
          onSelect={(v) =>
            setSelectedUser((existingValue) => ({
              ...existingValue,
              userGroups: v.map((x) => x),
            }))
          }
          isMultiSelect={true}
        />
      </View>
      {mode !== InputMode.CREATE && (
        <ActivityHistory recordKey={selectedUser.uid} recordType={LogType.User} />
      )}
      <ButtonGroup
        mode={mode}
        onSave={onSave}
        isSaving={isSaving}
        canApprove={canApprove}
        setShowApprovalModal={setShowApprovalModal}
        setIsVisible={setIsVisible}
        onNext={onNext}
      />
    </>
  );
}
