import React, { FC } from 'react';

import { useSelector } from 'react-redux';

import { Select } from 'chakra-react-select';

import { FormControl, FormControlProps, FormLabel } from '@chakra-ui/react';

import { StaticDataStore } from '../commonTypes';
import CToolTip from './CToolTip';

export type CountryInputProps = {
  title?: string;
  onSelect: (values: string[]) => void;
  value?: string;
  values?: string[];
  isRequired?: boolean;
  isMultiSelect?: boolean;
  isReadOnly?: boolean;
  hasError?: boolean;
  formProps?: FormControlProps;
};
const CountryInput: FC<CountryInputProps> = ({
  onSelect,
  value,
  isRequired,
  isMultiSelect,
  values,
  title,
  isReadOnly,
  hasError,
  formProps,
}) => {
  const { staticData } = useSelector((store: StaticDataStore) => store);

  return (
    <FormControl isRequired={isRequired} width={300} isDisabled={hasError} {...formProps}>
      <FormLabel>
        {title || isMultiSelect ? 'Countries' : 'Country'}
        {value && !staticData.countries?.find((country) => country.code === value) && (
          <CToolTip
            isWarningIcon
            label="This country is not setup. Please request the Admin to set this up to use it elsewhere"
          />
        )}
      </FormLabel>
      <Select
        isReadOnly={isReadOnly}
        isMulti={isMultiSelect}
        onChange={(selected) => {
          isMultiSelect ? onSelect(selected?.map((val) => val.value)) : onSelect([selected?.value]);
        }}
        closeMenuOnSelect={!isMultiSelect}
        value={
          isMultiSelect
            ? values?.map((val) => {
                return { value: val, label: val };
              })
            : value
            ? [{ value, label: value }]
            : []
        }
        name="Country"
        options={staticData.countries?.map((country) => {
          return { value: country.code, label: country.code + ' - ' + country.name };
        })}
        placeholder="Select Country"
        size="md"
        menuPosition="fixed"
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
        }}
        chakraStyles={{
          menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
        }}
        menuPortalTarget={document.body}
      />
    </FormControl>
  );
};

export default CountryInput;
