/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useContext, useState } from 'react';

import { StyleSheet, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';

import {
  DocumentData,
  QueryDocumentSnapshot,
  collection,
  getDocs,
  orderBy,
  query,
} from 'firebase/firestore';
import {
  ActiveStatus,
  InputMode,
  Job,
  JobStatus,
  PaymentStatus,
  StaticDataStore,
} from '../commonTypes';
import { CFlatList } from '../components/CFlatList';
import { FilterBar } from '../components/FilterBar';
import { Text, View } from '../components/Themed';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import AppStyles from '../constants/Styles';
import { RootTabScreenProps } from '../types';
import { _filterToWhereClause, _searchList } from '../utils/helper';

import { Card, Indicator, useToast } from '@chakra-ui/react';
import { useFocusEffect } from '@react-navigation/native';
import { isEmpty, set } from 'lodash';
import { AppContext } from '../App';
import Button from '../components/Button';
import JobDetailsModal from '../components/Jobs/JobDetailsModal';
import StatusTag from '../components/StatusTag';
import IconText from '../components/IconText';
import { JobStatusColors } from '../constants/Constants';

export default function JobScreen({ navigation }: RootTabScreenProps<'JobScreen'>) {
  const [jobs, setJobs] = useState<Job[]>([]);
  const [selectedJob, setSelectedJob] = useState<Job>();
  const [isLoading, setIsLoading] = useState(true);
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [filteredPayments, setFilteredPayments] = useState<Job[] | undefined>();
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [filter, setFilter] = useState({});
  const [mode, setMode] = useState(InputMode.EDIT);
  const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot<DocumentData> | null>();
  const toast = useToast();
  const { profileData, staticData } = useSelector((store: StaticDataStore) => store);

  const canEdit = staticData.accessibleScreens?.JobScreen?.edit || false;

  const { db } = useContext(AppContext);

  const fetchJobs = useCallback(
    async (filterValues: any) => {
      console.log('Fetching Jobs');
      if (!profileData?.orgId) {
        return;
      }
      setIsLoading(true);
      setLastVisible(null);
      const constraints = _filterToWhereClause(filterValues);
      const q = query(
        collection(db, 'Organizations', profileData?.orgId, 'Jobs'),
        ...constraints,
        orderBy('lastRun', 'desc'),
      );
      const querySnapshot = await getDocs(q);
      const _jobs: Job[] = [];
      querySnapshot.forEach((doc) => {
        _jobs.push({ ...doc.data(), id: doc.id } as Job);
      });

      setJobs(_jobs);
      if (querySnapshot.empty) {
        setLastVisible(null);
      } else {
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      }
      setIsLoading(false);
    },
    [db, profileData?.orgId, staticData?.bankAccounts],
  );

  // const loadMoreJobs = useCallback(
  //   async (filterValues: any) => {
  //     if (lastVisible) {
  //       setIsLoadingMore(true);
  //       const constraints = _filterToWhereClause(filterValues);
  //       const q = query(
  //         collection(db, 'Organizations', profileData?.orgId, 'Jobs'),
  //         ...constraints,
  //         orderBy('lastRun', 'desc'),
  //       );
  //       const querySnapshot = await getDocs(q);
  //       const _jobs: Job[] = [];
  //       querySnapshot.forEach((doc) => {
  //         _jobs.push({ ...doc.data(), id: doc.id } as Job);
  //       });
  //       if (querySnapshot.empty) {
  //         setLastVisible(null);
  //       } else {
  //         setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
  //       }
  //       setJobs([...jobs, ..._jobs]);
  //       setIsLoadingMore(false);
  //     }
  //   },
  //   [db, lastVisible, jobs, profileData?.orgId],
  // );

  useFocusEffect(
    useCallback(() => {
      setIsLoading(true);
      fetchJobs(filter);
    }, [fetchJobs, filter]),
  );

  const onSearchOrFilter = useCallback(
    (searchValue: string) => {
      if (searchValue && searchValue !== '') {
        setFilteredPayments(_searchList(jobs, searchValue));
      } else {
        setFilteredPayments(undefined);
      }
    },
    [jobs],
  );

  const onCreateNewJob = useCallback(() => {
    setMode(InputMode.CREATE);
    setSelectedJob({
      name: '',
      status: ActiveStatus.Active,
      schedule: {
        frequency: 'Adhoc',
        occurrenceDailyType: 'Every',
        occurrenceDailyEveryUnit: 'Mins',
      },
    } as Job);
    setIsDetailVisible(true);
  }, [profileData.uid, staticData?.categories]);

  return (
    <View style={AppStyles.container}>
      <JobDetailsModal
        isVisible={isDetailVisible}
        setIsVisible={setIsDetailVisible}
        job={selectedJob}
        onJobUpdated={() => {
          setIsLoading(true);
          fetchJobs(filter);
        }}
        mode={mode}
      />
      <View style={[AppStyles.flexRowCenterSpaceBetween, AppStyles.marginTop]}>
        {(jobs && jobs.length > 0) || !isEmpty(filter) ? (
          <FilterBar
            onSearch={onSearchOrFilter}
            searchPlaceholder={'Search Jobs'}
            filter={filter}
            setFilter={setFilter}
            filterParams={[
              {
                title: 'Status',
                type: 'MultiSelect',
                field: 'lastRunStatus',
                options: [
                  { label: JobStatus.Scheduled, value: JobStatus.Scheduled },
                  { label: JobStatus.Running, value: JobStatus.Running },
                  { label: JobStatus.Failed, value: JobStatus.Failed },
                  { label: JobStatus.Completed, value: JobStatus.Completed },
                ],
              },
            ]}
          />
        ) : (
          <View />
        )}
        {staticData.accessibleScreens?.JobScreen?.create ? (
          <Button label="Create Job" variant="Create" onPress={onCreateNewJob} />
        ) : (
          <View />
        )}
      </View>

      <View
        style={{
          flexDirection: 'row',
          height: Layout.window.height - 150,
          justifyContent: 'space-between',
        }}
      >
        <View style={{ width: Layout.window.width - 60 }}>
          <CFlatList
            emptyMessage={!isEmpty(filter) ? 'No results found' : 'No Jobs yet'}
            emptyCTA={'Create Job'}
            emptyAction={onCreateNewJob}
            // subAction={
            //   <Text style={AppStyles.textSubTitle}>
            //     Importing bank statements will automatically create bank accounts if they do not
            //     exists
            //   </Text>
            // }
            data={filteredPayments || jobs}
            isLoading={isLoading}
            isLoadingMore={isLoadingMore}
            // loadMore={() => loadMoreJobs(filter)}
            renderItem={({ item }: { item: Job }) => (
              <TouchableOpacity
                onPress={() => {
                  setMode(InputMode.EDIT);
                  setSelectedJob(item);
                  setIsDetailVisible(true);
                }}
              >
                <Card style={AppStyles.card} mr={2}>
                  <View style={[AppStyles.flexRowCenter, AppStyles.padding]}>
                    <View style={{ flex: 2 }}>
                      <Text style={AppStyles.textRowTitle}>{item.name}</Text>
                      <Text style={AppStyles.textSubTitle}>{item.description}</Text>
                    </View>
                    <View>
                      <StatusTag label={item?.status} />
                      <IconText
                        icon={
                          <View style={AppStyles.flexRowCenter}>
                            <Text style={AppStyles.textSubTitle}>Last run</Text>
                            <View
                              style={{
                                width: 10,
                                height: 10,
                                borderRadius: 10,
                                marginLeft: 10,
                                backgroundColor: item?.lastRunStatus
                                  ? JobStatusColors[item?.lastRunStatus]
                                  : Colors.grey,
                              }}
                            />
                          </View>
                        }
                        value={item?.lastRun ? item?.lastRun.toDate().toDateString() : 'Never'}
                        showEmpty={true}
                        textColor={Colors.black}
                      />
                    </View>
                  </View>
                </Card>
              </TouchableOpacity>
            )}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  bankAccountCard: {
    marginBottom: 10,
    marginHorizontal: 10,
    borderWidth: 2,
    flex: 1,
    borderRadius: 5,
    padding: 0,
  },
  triangle: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderTopWidth: 20,
    borderRightWidth: 0,
    borderBottomWidth: 20,
    borderLeftWidth: 15,
    borderTopColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: 'transparent',
    borderLeftColor: Colors.primary,
    position: 'absolute',
    left: 0,
    top: 35,
  },
});
