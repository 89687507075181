/* eslint-disable react-native/no-inline-styles */
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { FontAwesome5, Ionicons, MaterialIcons } from '@expo/vector-icons';
import debounce from 'lodash/debounce';
import { Icon, Input, Popover } from 'native-base';
import { InterfacePopoverProps } from 'native-base/lib/typescript/components/composites/Popover/types';
import { FlatList, TextStyle, TouchableOpacity, ViewStyle } from 'react-native';

import { Card, Text, View } from './Themed';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import AppStyles from '../constants/Styles';
import { _addAlpha, _searchList } from '../utils/helper';
import { FormControl, FormLabel } from '@chakra-ui/react';

export type MultiSelectInputProps<T> = {
  title: string;
  placeholder?: string;
  data?: T[];
  dataKey?: string;
  onSelect: Dispatch<SetStateAction<T | undefined>>;
  selectedValues?: T[];
  label?: string;
  renderItem?: any;
  isSingleSelect?: boolean;
  isReadOnly?: boolean;
  style?: ViewStyle;
  selectedValueStyle?: TextStyle;
  isRequired?: boolean;
  onAddNew?: () => void;
  includeAll?: boolean; // All option on the Top
  hideTitle?: boolean;
  hasError?: boolean;
};
export function MultiSelectInput({
  data = [],
  dataKey,
  title,
  placeholder,
  onSelect,
  selectedValues = [],
  label = 'label',
  renderItem,
  isSingleSelect,
  isReadOnly,
  style,
  selectedValueStyle,
  isRequired,
  onAddNew,
  includeAll,
  hideTitle,
  hasError,
}: MultiSelectInputProps<any>) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState<(typeof data)[]>();

  const onSearchOrFilter = useCallback(
    (searchValue: string) => {
      if (searchValue && searchValue !== '') {
        setFilteredData(_searchList(data, searchValue));
      } else {
        setFilteredData(undefined);
      }
    },
    [data],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedQuery = useCallback(
    debounce((q) => onSearchOrFilter(q), 500),
    [onSearchOrFilter],
  );
  const onChange = (value: string) => {
    setSearchText(value);
    delayedQuery(value);
  };

  const renderTrigger = useCallback(
    (triggerProps: InterfacePopoverProps) => {
      return (
        <TouchableOpacity
          style={[
            {
              marginTop: 6,
              flexDirection: 'row',
              justifyContent: 'space-between',
              borderColor: hasError ? Colors.red : Colors.light_grey,
              borderWidth: hasError ? 2 : 1,
              borderRadius: 5,
              padding: 5,
              flexWrap: 'wrap',
            },
            style,
          ]}
          {...triggerProps}
          disabled={isReadOnly}
          onPress={() => setIsOpen(true)}
        >
          {selectedValues && selectedValues.length > 0 ? (
            <View style={[AppStyles.flexRow, { flex: 1, flexWrap: 'wrap' }]}>
              {selectedValues.map((value, index) => {
                return (
                  <View
                    key={index.toString()}
                    style={[
                      { justifyContent: 'center' },
                      !isSingleSelect && {
                        borderColor: _addAlpha(Colors.primary, 0.1),
                        backgroundColor: _addAlpha(Colors.primary, 0.1),
                        paddingHorizontal: 10,
                        alignItems: 'center',
                        marginRight: 10,
                        marginTop: 5,
                        borderRadius: 5,
                      },
                    ]}
                  >
                    <Text style={[selectedValueStyle]}>
                      {value?.[label] ||
                        (value ? JSON.stringify(value) : '*You do not have access*')}
                    </Text>
                  </View>
                );
              })}
            </View>
          ) : (
            <View style={{ justifyContent: 'center' }}>
              <Text style={{ color: Colors.light_grey }}>
                {isReadOnly ? '*' : placeholder || 'Select ' + title + '...'}
              </Text>
            </View>
          )}
          {!isReadOnly && <Icon as={<MaterialIcons name="arrow-drop-down" />} size={5} ml="2" />}
        </TouchableOpacity>
      );
    },
    [isReadOnly, isSingleSelect, label, selectedValues, style, title],
  );

  return (
    <View style={{ zIndex: 9999 }}>
      {/* {!hideTitle && (
        <Text style={[AppStyles.textInputLabel, { marginTop: 10 }]}>
          {title}
          {isRequired && <Text style={{ color: Colors.red }}>*</Text>}
        </Text>
      )} */}
      <FormControl isRequired={isRequired}>
        {!hideTitle && <FormLabel>{title}</FormLabel>}
        <Popover isOpen={isOpen} trigger={renderTrigger} onClose={() => setIsOpen(!isOpen)}>
          <Popover.Content w="l">
            <Popover.Arrow />
            <Popover.Header>
              <View style={AppStyles.flexRowCenter}>
                {(data?.length || 0) > 0 ? (
                  <Input
                    InputLeftElement={<Icon as={<MaterialIcons name="search" />} size={5} ml="2" />}
                    InputRightElement={
                      searchText ? (
                        <Icon
                          as={<Ionicons name="close-circle" />}
                          size={5}
                          ml="4"
                          color="muted.400"
                          style={{ marginRight: 10 }}
                          onPress={() => onChange('')}
                        />
                      ) : undefined
                    }
                    value={searchText}
                    size="md"
                    variant="rounded"
                    placeholder={'Search here'}
                    w="l"
                    onChangeText={onChange}
                  />
                ) : (
                  <Text>No Options available</Text>
                )}
                {onAddNew && (
                  <TouchableOpacity onPress={onAddNew} style={AppStyles.marginLeft}>
                    <FontAwesome5 name={'plus'} size={20} color={Colors.primary} />
                  </TouchableOpacity>
                )}
              </View>
            </Popover.Header>
            <Popover.Body>
              <FlatList
                style={{
                  height: (data?.length || 0) > 0 ? Layout.window.height / 2 : 0,
                  maxHeight: 250,
                }}
                data={
                  filteredData ||
                  (includeAll
                    ? [{ [dataKey || 'id']: 'All', name: 'All ' + title }, ...data]
                    : data)
                }
                renderItem={({ item }) => {
                  const selected = selectedValues.findIndex(
                    (value) => value[dataKey || 'id'] === item[dataKey || 'id'],
                  );
                  return (
                    <TouchableOpacity
                      onPress={() => {
                        if (isSingleSelect || (item[dataKey || 'id'] === 'All' && includeAll)) {
                          onSelect([item]);
                          return setIsOpen(false);
                        }
                        const _selectedValues = selectedValues.filter(
                          (value) => value[dataKey || 'id'] !== 'All',
                        );
                        if (selected > -1) {
                          onSelect(
                            _selectedValues.filter(
                              (value) => value[dataKey || 'id'] !== item[dataKey || 'id'],
                            ),
                          );
                        } else {
                          onSelect([..._selectedValues, item]);
                        }
                      }}
                    >
                      <Card style={{ marginHorizontal: 0 }}>
                        {renderItem ? (
                          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            {renderItem(item)}
                            {selected > -1 ? (
                              <FontAwesome5 name={'check'} size={12} color={Colors.primary} />
                            ) : (
                              <View style={{ width: 12 }} />
                            )}
                          </View>
                        ) : (
                          <>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                              <Text>{item.accountNumber}</Text>
                              <View style={AppStyles.flexRowCenter}>
                                <Text style={{ marginHorizontal: 5 }}>{item.currency}</Text>
                                {selected > -1 ? (
                                  <FontAwesome5 name={'check'} size={12} color={Colors.primary} />
                                ) : (
                                  <View style={{ width: 12 }} />
                                )}
                              </View>
                            </View>
                            <Text style={AppStyles.textSubTitle}>{item.accountName}</Text>
                          </>
                        )}
                      </Card>
                    </TouchableOpacity>
                  );
                }}
              />
            </Popover.Body>
          </Popover.Content>
        </Popover>
      </FormControl>
    </View>
  );
}
