/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { addDoc, collection, doc, getDocs, orderBy, query, updateDoc } from 'firebase/firestore';
import moment from 'moment';

import { StyleSheet, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';

import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import Button from '../components/Button';

import { AppContext } from '../App';
import { Holiday, StaticDataStore } from '../commonTypes';
import { CFlatList } from '../components/CFlatList';
import CInput from '../components/CInput';
import CountryInput from '../components/CountryInput';
import CurrencyInput from '../components/CurrencyInput';
import { DateInput } from '../components/DateInput';
import { FilterBar } from '../components/FilterBar';
import { Card, Text, View } from '../components/Themed';
import Layout from '../constants/Layout';
import AppStyles from '../constants/Styles';
import { RootTabScreenProps } from '../types';
import { _searchList, _sort, _textFieldIsInvalid } from '../utils/helper';

export default function HolidayCalendarScreen({
  navigation,
}: RootTabScreenProps<'HolidayCalendarScreen'>) {
  const [isLoading, setIsLoading] = useState(false);
  const [isImportHolidays, setIsImportHolidays] = useState(false);
  const [selectedHoliday, setSelectedHoliday] = useState<Holiday>();
  const [selectedIndex, setSelectedIndex] = useState<number>();

  const [holidays, setHolidays] = useState<Holiday[]>([]);
  const [filteredHolidays, setFilteredHolidays] = useState<Holiday[] | undefined>();
  const { db } = useContext(AppContext);
  const toast = useToast();
  const { profileData } = useSelector((store: StaticDataStore) => store);
  const [isSaving, setIsSaving] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const fetchHolidays = useCallback(async () => {
    console.log('Fetching Banks');
    setIsLoading(true);
    if (!profileData?.orgId) {
      return;
    }
    const q = query(
      collection(db, 'Organizations', profileData?.orgId, 'Holidays'),
      orderBy('date'),
    );
    const querySnapshot = await getDocs(q);
    const _holidays: Holiday[] = [];
    querySnapshot.forEach((holidayDoc) => {
      _holidays.push({
        ...holidayDoc.data(),
        id: holidayDoc.id,
        date: holidayDoc.data().date.toDate(),
      } as Holiday);
    });
    setHolidays(_holidays);
    setIsLoading(false);
  }, [db, profileData?.orgId]);

  useEffect(() => {
    fetchHolidays();
  }, [fetchHolidays]);

  const onSearchOrFilter = useCallback(
    (searchValue: string) => {
      if (searchValue && searchValue !== '') {
        setFilteredHolidays(_searchList(holidays, searchValue));
      } else {
        setFilteredHolidays(undefined);
      }
    },
    [holidays],
  );

  const onSave = useCallback(async () => {
    if (!profileData?.orgId) {
      return;
    }
    setIsSaving(true);
    if (_textFieldIsInvalid(selectedHoliday?.name)) {
      setIsSaving(false);

      return toast({
        title: 'Invalid holiday name',
        description: 'Please enter a valid name',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    if (!selectedHoliday?.date) {
      setIsSaving(false);

      return toast({
        title: 'Invalid holiday date',
        description: 'Please enter a valid date',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }

    if (selectedIndex !== undefined && selectedHoliday?.name) {
      // Update existing currency
      if (!holidays) {
        setIsSaving(false);
        return;
      }
      const index = holidays?.findIndex((holiday) => holiday.id === selectedHoliday?.id);
      const updatedHolidayList = holidays;
      if (index < 0 || !updatedHolidayList) {
        return;
      }
      updatedHolidayList[index] = selectedHoliday;

      await updateDoc(
        doc(db, 'Organizations', profileData.orgId, 'Holidays', selectedHoliday.id || ''),
        selectedHoliday,
      );

      const _holidays = filteredHolidays || holidays;
      _holidays[selectedIndex] = selectedHoliday;
      if (filteredHolidays) {
        setFilteredHolidays(_holidays);
        const _AllAccounts = holidays;
        _AllAccounts[_AllAccounts.findIndex((bankAcc) => bankAcc.id === selectedHoliday.id)] =
          selectedHoliday;
        setHolidays(_AllAccounts);
      } else {
        setHolidays(_holidays);
      }
      setIsSaving(false);
      onClose();
    } else {
      // Create new Holiday

      const newHoliday = await addDoc(
        collection(db, 'Organizations', profileData.orgId, 'Holidays'),
        selectedHoliday,
      );
      setHolidays((currentValue) => [{ ...selectedHoliday, id: newHoliday.id }, ...currentValue]);
      if (filteredHolidays) {
        setFilteredHolidays((currentValue) => [
          { ...selectedHoliday, id: newHoliday.id },
          ...(currentValue || []),
        ]);
      }
    }
    setIsSaving(false);
    onClose;

    return toast({
      title: 'Success',
      description: `Holiday ${selectedHoliday?.name ? 'Updated' : 'Created'}`,
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  }, [profileData.orgId, selectedHoliday, selectedIndex, toast, holidays, db, filteredHolidays]);

  const onTextChange = useCallback((field: keyof Holiday, value: string) => {
    setSelectedHoliday((existingValue) => {
      return { ...existingValue, [field]: value } as Holiday;
    });
  }, []);

  const renderFlyOut = useCallback(() => {
    if (selectedHoliday) {
      return (
        <>
          <CInput
            label="Holiday Name"
            fieldValue={selectedHoliday.name}
            onTextChange={onTextChange}
            isRequired
            fieldKey="name"
          />
          <DateInput
            title={'Holiday Date'}
            onSelect={(value) =>
              setSelectedHoliday((existingValue) => {
                return { ...existingValue, date: value.toDate() } as Holiday;
              })
            }
            value={selectedHoliday.date ? moment(selectedHoliday.date) : undefined}
          />
          <CountryInput
            onSelect={(v) =>
              setSelectedHoliday((existingValue) => {
                return { ...existingValue, countries: v.map((x) => x) } as Holiday;
              })
            }
            values={selectedHoliday?.countries}
            isMultiSelect
          />

          <CurrencyInput
            onSelect={(v) =>
              setSelectedHoliday((existingValue) => {
                return { ...existingValue, currencies: v.map((x) => x) } as Holiday;
              })
            }
            values={selectedHoliday?.currencies}
            isMultiSelect
          />
        </>
      );
    }
    return <></>;
  }, [onTextChange, selectedHoliday]);

  return (
    <View style={AppStyles.container}>
      <Drawer isOpen={isOpen} placement="right" size={'sm'} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{`${selectedHoliday?.id ? 'Update' : 'Create'} Holiday`}</DrawerHeader>

          <DrawerBody>{renderFlyOut()}</DrawerBody>

          <DrawerFooter>
            <Button label="Cancel" variant="Cancel" onPress={onClose} isDisabled={isSaving} />
            <Button label="Save" onPress={onSave} isLoading={isSaving} />
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <View style={[AppStyles.flexRowCenterSpaceBetween, AppStyles.marginTop]}>
        {holidays && holidays.length > 0 ? (
          <FilterBar onSearch={onSearchOrFilter} searchPlaceholder={'Search Name or Date'} />
        ) : (
          <View />
        )}
        <View style={AppStyles.flexRowCenter}>
          <Button
            label="Create Holiday"
            variant={'Create'}
            onPress={() => {
              setSelectedHoliday({
                name: '',
                date: undefined,
              } as Holiday);
              onOpen();
            }}
          />
          {/* <MoreMenu>
            <Menu.Item
              onPress={() => {
                fetchDefaultConfigHolidays();
                setIsImportHolidays(true);
                setOpen(true);
              }}
            >
              Import Holidays
            </Menu.Item>
          </MoreMenu> */}
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row',
          height: Layout.window.height - 150,
          justifyContent: 'space-between',
        }}
      >
        <View style={AppStyles.width60}>
          <CFlatList
            style={AppStyles.width60}
            emptyMessage={filteredHolidays === undefined ? 'No Holidays yet' : 'No results found'}
            keyField="code"
            data={_sort(filteredHolidays || holidays, 'date') as Holiday[]}
            isLoading={isLoading}
            renderItem={({ item, index }: { item: Holiday; index: number }) => (
              <TouchableOpacity
                onPress={() => {
                  setIsImportHolidays(false);
                  setSelectedHoliday(item);
                  setSelectedIndex(index);
                  onOpen();
                }}
                key={item.id}
              >
                <Card style={styles.holidayCard}>
                  <View style={AppStyles.flexRowCenterSpaceBetween}>
                    <Text style={AppStyles.textRowTitle}>
                      {moment(item.date).format('DD MMM YYYY')}
                    </Text>
                    <Text style={AppStyles.textSubTitle}>{item.name}</Text>
                  </View>
                </Card>
              </TouchableOpacity>
            )}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  holidayCard: {
    marginBottom: 10,
    marginHorizontal: 10,
    borderWidth: 2,
  },
});
