import { Platform } from 'react-native';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import ReduxThunk from 'redux-thunk';

import profileReducer from '../reducers/profileReducer';
import staticDataReducer from '../reducers/staticDataReducer';
import userInterfaceReducer from '../reducers/userInterfaceReducer';
const rootReducer = combineReducers({
  profileData: profileReducer,
  staticData: staticDataReducer,
  userInterface: userInterfaceReducer,
});

const configureStore = () => {
  if (__DEV__ && Platform.OS !== 'web') {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    return createStore(rootReducer, composeEnhancers(applyMiddleware(ReduxThunk)));
  }
  return createStore(rootReducer);
};
export default configureStore;
