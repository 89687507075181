import React, { FC, useCallback } from 'react';

import startCase from 'lodash/startCase';
import { FormControl, IInputProps } from 'native-base';
import { ViewStyle } from 'react-native';
import rdiff from 'recursive-diff';

import Colors from '../constants/Colors';
import Constants from '../constants/Constants';
import AppStyles from '../constants/Styles';
import { Text } from './Themed';
import { StaticDataStore } from '../commonTypes';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

export type CChangesSummaryItemProps = {
  item: rdiff.rdiffResult;
  style?: ViewStyle;
  width?: number;
  before?: any;
};
const CChangesSummaryItem: FC<CChangesSummaryItemProps & IInputProps> = ({
  item,
  before,
  style,
  width = 300,
  ...props
}) => {
  const { staticData } = useSelector((store: StaticDataStore) => store);

  const renderValue = useCallback(() => {
    let requestedValue = item.val;
    let beforeValue =
      typeof before[item.path[0]] === 'object'
        ? JSON.stringify(before[item.path[item.path.length - 1]] || get(before, item.path))
        : before[item.path[0]];
    if (Array.isArray(before[item.path[0]]) && item.path[0] !== 'accountGroups') {
      beforeValue = before[item.path[0]].join(', ');
    }
    if (item.path[0] === 'permissions') {
      return JSON.stringify(item.val);
    }
    if (item.path[0] === 'accountGroups') {
      if (item.op === 'delete') {
        requestedValue =
          (staticData.accountGroups?.find((ag) => ag.id === before[item.path[0]]?.[item.path[1]])
            ?.name || item.val) + ' - Removed';
      } else {
        requestedValue =
          (staticData.accountGroups?.find((ag) => ag.id === item.val || ag.id === item.val?.[0])
            ?.name || item.val) +
          ' - ' +
          startCase(item.op);
      }
      beforeValue =
        before[item.path[0]]
          ?.map((val: string) => {
            return staticData.accountGroups?.find((ag) => ag.id === val)?.name || val;
          })
          ?.join(', ') || 'Empty';
    }
    return (
      <>
        <Text style={{ fontSize: Constants.TextSize.xxl }}>{requestedValue}</Text>
        {before[item.path[0]] && (
          <Text style={{ color: Colors.light_grey, fontSize: Constants.TextSize.small }}>
            {'Was: '}
            <Text style={{ color: Colors.grey, fontSize: Constants.TextSize.small }}>
              {beforeValue || 'Empty'}
            </Text>
          </Text>
        )}
      </>
    );
  }, [item, before, staticData.accountGroups]);
  if (!item.path) {
    return null;
  }
  return (
    <FormControl width={width} style={[AppStyles.marginLeft25, AppStyles.marginTop, style]}>
      <Text style={AppStyles.textSubTitle}>
        {Array.isArray(before[item.path[0]])
          ? startCase(item.path[0].toString())
          : startCase(item.path[item.path.length - 1].toString())}
      </Text>
      {renderValue()}
      {/* <FormControl.HelperText>{JSON.stringify(item)}</FormControl.HelperText> */}
    </FormControl>
  );
};

export default CChangesSummaryItem;
