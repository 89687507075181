import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react';

import { doc, getDoc } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions';
import { useSelector } from 'react-redux';

import {
  Badge,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import { Input } from 'native-base';
import { AppContext } from '../../App';
import {
  ApprovalTimelineItem,
  ApprovalTimelineItemStatus,
  ApprovalWorkflowRequest,
  ApprovalWorkflowSource,
  StaticDataStore,
} from '../../commonTypes';
import Colors from '../../constants/Colors';
import AppStyles from '../../constants/Styles';
import useUsers from '../../hooks/useUsers';
import {
  _getApprovalWorkflowRequestAction,
  _getChanges,
  _getItemNameFromLogs,
} from '../../utils/helper';
import CChangesSummaryItem from '../CChangesSummaryItem';
import { CFlatList } from '../CFlatList';
import { Button, Text, View } from '../Themed';
import ApprovalTimeline from './ApprovalTimeline';

export type ReviewAndApproveModalProps = {
  pendingApprovalItem?: any;
  onClose: () => void;
  excludeFields?: string[];
  arrayFields?: string[];
  source: ApprovalWorkflowSource;
  id?: string; // Passed for Create
  show?: boolean;
  onSomeActionDone?: (approved?: boolean) => void;
  onApprovalOrRejectonCompleted?: (approved?: boolean) => void;
  onApprovalDataLoaded?: (data: any) => void;
  approvalRequestData?: ApprovalWorkflowRequest; // if already fetched, pass it here
  isFullScreen?: boolean;
  onNext?: () => void;
};
const ReviewAndApproveModal: FC<ReviewAndApproveModalProps> = ({
  pendingApprovalItem,
  onClose,
  excludeFields,
  source,
  arrayFields,
  id,
  show,
  onApprovalOrRejectonCompleted,
  onSomeActionDone,
  onApprovalDataLoaded,
  approvalRequestData,
  isFullScreen,
  onNext,
}) => {
  const { profileData } = useSelector((store: StaticDataStore) => store);

  const [isApproving, setIsApproving] = React.useState(false);
  const [isRejecting, setIsRejecting] = React.useState(false);
  const [canApprove, setCanApprove] = React.useState(false);
  const [approvalRequest, setApprovalRequest] = React.useState(approvalRequestData);
  const [message, setMessage] = React.useState('');
  const { db } = useContext(AppContext);
  const toast = useToast();
  const { _getUsersFromUid } = useUsers();

  const _changes: rdiff.rdiffResult[] | undefined = useMemo(() => {
    if (!pendingApprovalItem || !pendingApprovalItem.updateRequest) {
      return undefined;
    }
    const defaultExcludeFileds = [
      'lastUpdatedBy',
      'updateRequest',
      'requestedBy',
      'lastUpdatedAt',
      'requestId',
      'lastApprovalId',
      'lastApprovalAction',
      'lastApprovalComments',
      'approvedBy',
      'rejectedBy',
    ];
    const aditionalArrayFields = [];
    if (source === ApprovalWorkflowSource.USERS) {
      defaultExcludeFileds.push(...['uid', 'userGroups']);
      aditionalArrayFields.push('userGroups');
    }

    return _getChanges(
      pendingApprovalItem,
      pendingApprovalItem.updateRequest || [],
      [...(excludeFields || []), ...defaultExcludeFileds],
      [...(arrayFields || []), ...aditionalArrayFields],
    ).filter((x) => pendingApprovalItem.updateRequest[x.path?.[0]] !== undefined);
  }, [excludeFields, pendingApprovalItem]);

  const getApprovalTimeline = useCallback(() => {
    console.warn(
      'getApprovalTimeline',
      pendingApprovalItem?.updateRequest?.requestId || source + id,
    );
    getDoc(
      doc(
        db,
        'Organizations',
        profileData.orgId,
        'Approvals',
        pendingApprovalItem?.updateRequest?.requestId || source + id,
      ),
    ).then((reqeustDoc) => {
      if (reqeustDoc.exists()) {
        const requestData = reqeustDoc.data() as ApprovalWorkflowRequest;
        console.warn('timelineData', requestData);
        setApprovalRequest(requestData);

        const approvalData = {
          canApprove: false,
          approvers: [],
          approvedAt: requestData.timeline?.[requestData.timeline.length - 1].timestamp,
        };
        requestData.timeline
          ?.filter(
            (x: ApprovalTimelineItem) =>
              x.status === ApprovalTimelineItemStatus.PENDING ||
              x.status === ApprovalTimelineItemStatus.IN_PROGRESS,
          )
          .every((x: ApprovalTimelineItem) => {
            if (x.approvers.some((approver) => approver.uid === profileData?.uid)) {
              setCanApprove(true);
              approvalData.canApprove = true;
              return false;
            }
            return true;
          });
        onApprovalDataLoaded?.(approvalData);
      }
    });
  }, [db, pendingApprovalItem?.updateRequest?.requestId, profileData.orgId, profileData?.uid]);

  useEffect(() => {
    if (approvalRequestData) {
      setApprovalRequest(approvalRequestData);
    }
  }, [approvalRequestData?.id]);

  useEffect(() => {
    if (profileData?.orgId && (pendingApprovalItem || id) && !approvalRequestData) {
      getApprovalTimeline();
    } else if (approvalRequestData) {
      if (approvalRequestData.approvers?.includes(profileData?.uid)) {
        setCanApprove(true);
      }
    }
  }, [getApprovalTimeline, pendingApprovalItem, id, profileData?.orgId, approvalRequestData]);

  const onAction = useCallback(
    (action: 'Approved' | 'Rejected') => {
      if (action === 'Approved') {
        setIsApproving(true);
      }
      if (action === 'Rejected') {
        setIsRejecting(true);
      }
      const functions = getFunctions();
      if (__DEV__) {
        connectFunctionsEmulator(functions, 'localhost', 5001);
      }
      const approve = httpsCallable(functions, 'approve');
      approve({
        orgId: profileData.orgId,
        approvalId:
          pendingApprovalItem?.updateRequest?.requestId || approvalRequestData?.id || source + id,
        action: action,
        message: message,
      }).then((result: { data: { state: boolean; timeline: ApprovalTimelineItem[] } }) => {
        console.warn(result);
        if (result?.data?.state) {
          setApprovalRequest((currentValue) => ({
            ...currentValue,
            timeline: result?.data?.timeline || currentValue.timeline,
          }));
          if (action === 'Rejected') {
            onApprovalOrRejectonCompleted?.();
          }
          onSomeActionDone?.(action === 'Approved');
          if (
            action === 'Approved' &&
            result?.data?.timeline?.[result?.data?.timeline?.length - 1]?.status ===
              ApprovalTimelineItemStatus.COMPLETED
          ) {
            console.warn('onApprovalOrRejectonCompleted', true);
            onApprovalOrRejectonCompleted?.(true);
          }
          const approvalData = {
            canApprove: false,
            approvers: [],
            approvedAt: result?.data?.timeline?.[result?.data?.timeline.length - 1].timestamp,
          };
          result?.data?.timeline
            ?.filter(
              (x: ApprovalTimelineItem) =>
                x.status === ApprovalTimelineItemStatus.PENDING ||
                x.status === ApprovalTimelineItemStatus.IN_PROGRESS,
            )
            .every((x: ApprovalTimelineItem) => {
              if (x.approvers.some((approver) => approver.uid === profileData?.uid)) {
                setCanApprove(true);
                approvalData.canApprove = true;
                return false;
              }
              return true;
            });
          onApprovalDataLoaded?.(approvalData);
        }

        setIsApproving(false);
        setIsRejecting(false);
        setCanApprove(false);
        onNext ? onNext() : onClose();

        toast({
          title: action,
          description: `Request ${action} successfully!`,
          status: action === 'Approved' ? 'success' : 'error',
          duration: 5000,
          isClosable: true,
        });
      });
    },
    [pendingApprovalItem?.updateRequest?.requestId, profileData.orgId, toast, message],
  );

  const action = _getApprovalWorkflowRequestAction(approvalRequest);

  if (show) {
    return (
      <Modal isOpen={true} onClose={onClose} size={isFullScreen ? 'full' : 'auto'}>
        <ModalOverlay bg="none" backdropFilter="auto" backdropInvert="30%" backdropBlur="2px" />
        <ModalContent maxWidth={isFullScreen ? '95%' : '60%'}>
          <ModalCloseButton />
          <ModalHeader>
            {approvalRequest?.type}
            <Text style={AppStyles.marginLeft}>{`Request by - ${_getUsersFromUid(
              pendingApprovalItem?.updateRequest?.requestedBy ||
                approvalRequest?.timeline[0].approvers[0].uid,
            )}`}</Text>
            <Badge
              ml={2}
              colorScheme={action?.color}
              variant="solid"
              style={{ alignSelf: 'flex-end' }}
              fontSize={'0.6em'}
            >
              {action?.label}
            </Badge>
          </ModalHeader>
          <ModalBody>
            <Text style={AppStyles.textRowTitle}>{_getItemNameFromLogs(approvalRequest)}</Text>
            <View style={[AppStyles.flex1, AppStyles.padding]}>
              {_changes && _changes.length && (
                <CFlatList
                  emptyMessage=" "
                  isLoading={false}
                  numColumns={2}
                  data={_changes || []}
                  renderItem={({ item }) => {
                    return <CChangesSummaryItem item={item} before={pendingApprovalItem} />;
                  }}
                />
              )}

              {/* {renderApprovalTimeline()} */}
              <ApprovalTimeline
                id={id}
                source={source}
                pendingApprovalItem={pendingApprovalItem}
                onApprovalDataLoaded={onApprovalDataLoaded}
                timeline={approvalRequest?.timeline}
              />
            </View>
            {canApprove && (
              <View
                style={[
                  AppStyles.marginTop,
                  { alignSelf: 'flex-end', position: 'absolute', bottom: 20, right: 20 },
                ]}
              >
                <Input
                  maxLength={1000}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  style={{ borderColor: Colors.light_grey }}
                  multiline
                  placeholder="Message to Requester"
                  autoComplete="off"
                  width={'100%'}
                />
                <View style={[AppStyles.flexRowCenter, AppStyles.marginTop]}>
                  <Button
                    label={'Reject'}
                    onPress={() => onAction('Rejected')}
                    icon={'remove'}
                    loading={isRejecting}
                    disabled={isApproving}
                    mr={2}
                    colorScheme={'red'}
                  />
                  <Button
                    label={'Approve'}
                    loading={isApproving}
                    disabled={isRejecting}
                    onPress={() => onAction('Approved')}
                    icon={'check'}
                    colorScheme="green"
                  />
                  <Button
                    label={'Next'}
                    loading={isApproving || isRejecting}
                    disabled={isApproving || isRejecting}
                    onPress={onNext}
                    icon={'arrow-right'}
                    colorScheme="blue"
                    ml={2}
                    hidden={!onNext}
                  />
                </View>
              </View>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }
  return null;
};

export default ReviewAndApproveModal;
