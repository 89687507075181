import React, { FC, useState } from 'react';

import moment from 'moment';
import { useSelector } from 'react-redux';

import Colors from '../../constants/Colors';
import { StaticDataStore } from '../../commonTypes';
import CardBadge from '../CardBadge';
import CurrencyRateSummaryModal from '../CurrencyRateSummaryModal';

const RatesWidget: FC = () => {
  const { staticData } = useSelector((store: StaticDataStore) => store);
  const [isVisible, setIsVisible] = useState(false);
  const daysAgo = moment().diff(moment(staticData.currencyRates?.date), 'days');

  return (
    <>
      <CurrencyRateSummaryModal isVisible={isVisible} setIsVisible={setIsVisible} />
      <CardBadge
        label="Currency Rates"
        value={((Object.keys(staticData.currencyRates || {})?.length || 1) - 1)?.toString() || '0'}
        additionalInfo={'Updated ' + (daysAgo ? daysAgo.toString() + ' day(s) ago' : 'Today')}
        additionalInfoStyle={{ color: Colors.grey }}
        onPress={() => setIsVisible(true)}
      />
    </>
  );
};

export default RatesWidget;
