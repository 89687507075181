import React, { useCallback, useMemo } from 'react';

import { ColDef } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { Skeleton, VStack } from 'native-base';
import { StyleProp, ViewStyle } from 'react-native';

import Layout from '../constants/Layout';

type CTable<T> = {
  isLoading: boolean;
  loadMore?: () => void;
  isLoadingMore?: boolean;
  contentLoader?: React.ReactElement;
  emptyMessage?: string;
  emptyCTA?: string;
  emptyAction?: () => void;
  subAction?: React.ReactElement;
  style?: StyleProp<ViewStyle>;
  cellClickedListener?: () => void;
  tableData?: any[];
  columnDefs: ColDef[];
};

export function CTable({
  isLoading,
  loadMore,
  isLoadingMore,
  contentLoader,
  emptyMessage,
  emptyCTA,
  emptyAction,
  subAction,
  style,
  cellClickedListener,
  tableData = [],
  columnDefs,
}: CTable<any>) {
  const loader = useCallback(
    (index: number) => {
      return (
        contentLoader || (
          <VStack
            key={index.toString()}
            w="99%"
            height={100}
            marginTop={2}
            marginLeft={2}
            padding={5}
            borderWidth="1"
            space={8}
            overflow="hidden"
            rounded="md"
            _dark={{
              borderColor: 'coolGray.500',
            }}
            _light={{
              borderColor: 'coolGray.200',
            }}
          >
            <Skeleton.Text px="4" />
          </VStack>
        )
      );
    },
    [contentLoader],
  );

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
    }),
    [],
  );
  // if (!isLoading && data?.length === 0) {
  //   return (
  //     <View style={AppStyles.flexCenter}>
  //       <Text style={AppStyles.textRowTitle}>{emptyMessage || 'No data found!'}</Text>
  //       {emptyCTA && emptyAction && <Button label={emptyCTA} onPress={emptyAction} />}
  //       {subAction || null}
  //     </View>
  //   );
  // }

  return (
    <>
      <div className="ag-theme-alpine" style={{ maxWidth: Layout.window.width * 0.8, height: 400 }}>
        <AgGridReact
          rowData={tableData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          rowSelection="single" // Options - allows click selection of rows
          onCellClicked={cellClickedListener} // Optional - registering for Grid Event
          alwaysShowHorizontalScroll={true}
          rowDragManaged={true}
        />
      </div>
    </>
  );
}
