import React, { FC, useCallback } from 'react';

import { InputMode } from '../commonTypes';
import AppStyles from '../constants/Styles';
import { Button, View } from './Themed';

export type ButtonGroupProps = {
  mode: InputMode;
  onSave: () => void;
  onNext?: () => void;
  canApprove?: boolean;
  isSaving?: boolean;
  setShowApprovalModal: (value: boolean) => void;
  setIsVisible?: (value: boolean) => void;
};
const ButtonGroup: FC<ButtonGroupProps> = ({
  mode,
  onSave,
  onNext,
  canApprove,
  isSaving,
  setShowApprovalModal,
  setIsVisible,
}) => {
  return (
    <View
      style={[
        AppStyles.flexRowCenterSpaceAround,
        { position: 'absolute', bottom: 10, right: 10, alignSelf: 'flex-end' },
      ]}
    >
      {mode !== InputMode.APPROVE && (
        <Button
          label={mode !== InputMode.CREATE ? 'Go Back' : 'Cancel'}
          variant="outline"
          disabled={isSaving}
          onPress={() => setIsVisible?.(false)}
          mr={2}
        />
      )}
      {[InputMode.CREATE, InputMode.EDIT].includes(mode) && (
        <Button
          label={mode === InputMode.CREATE ? 'Create' : 'Save Changes'}
          loading={isSaving}
          onPress={() => {
            onSave();
          }}
          colorScheme={mode === InputMode.CREATE ? 'blue' : 'orange'}
        />
      )}
      {canApprove && (
        <View style={[AppStyles.flexRowCenter, { alignSelf: 'flex-end' }]}>
          <Button
            onPress={() => setShowApprovalModal(true)}
            label="Reject"
            ml={2}
            colorScheme={'red'}
          />
          <Button
            label="Approve"
            ml={2}
            colorScheme={'green'}
            onPress={() => setShowApprovalModal(true)}
          />
          <Button
            label={'Next'}
            onPress={onNext}
            icon={'arrow-right'}
            colorScheme="blue"
            ml={2}
            hidden={!onNext}
          />
        </View>
      )}
    </View>
  );
};

export default ButtonGroup;
