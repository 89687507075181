/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useContext, useState } from 'react';

import { StyleSheet, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';

import {
  DocumentData,
  QueryDocumentSnapshot,
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
} from 'firebase/firestore';
import { ActivityHistory, LogType, StaticDataStore } from '../commonTypes';
import { CFlatList } from '../components/CFlatList';
import { FilterBar } from '../components/FilterBar';
import { Card, Text, View } from '../components/Themed';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import AppStyles from '../constants/Styles';
import { RootTabScreenProps } from '../types';
import {
  _filterToWhereClause,
  _getItemNameFromLogs,
  _getLogActionColor,
  _getTimeStampString,
  _searchList,
} from '../utils/helper';

import { Badge } from '@chakra-ui/react';
import { useFocusEffect } from '@react-navigation/native';
import { isEmpty } from 'lodash';
import { AppContext } from '../App';
import ActivityHistoryChangesModal from '../components/ActivityHistoryChangesModal';
import useUsers from '../hooks/useUsers';

export default function LogsScreen({ navigation }: RootTabScreenProps<'LogsScreen'>) {
  const [logs, setLogs] = useState<ActivityHistory[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState<ActivityHistory>();
  const [filteredLogs, setFilteredLogs] = useState<ActivityHistory[] | undefined>();
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [filter, setFilter] = useState({});
  const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot<DocumentData> | null>();

  const { profileData, staticData } = useSelector((store: StaticDataStore) => store);
  const { _getUsersFromUid } = useUsers();

  const { db } = useContext(AppContext);

  const fetchLogs = useCallback(
    async (filterValues: any) => {
      console.log('Fetching Logs - LogsScreen');
      if (!profileData?.orgId) {
        return;
      }
      setIsLoading(true);
      setLastVisible(null);
      const constraints = _filterToWhereClause(filterValues);
      console.warn('constraints', constraints);
      const q = query(
        collection(db, 'Organizations', profileData?.orgId, 'Logs'),
        ...constraints,
        orderBy('timestamp', 'desc'),
        limit(20),
      );
      const querySnapshot = await getDocs(q);
      const _logs: ActivityHistory[] = [];
      querySnapshot.forEach((doc) => {
        _logs.push({ ...doc.data(), id: doc.id } as ActivityHistory);
      });

      setLogs(_logs);
      if (querySnapshot.empty) {
        setLastVisible(null);
      } else {
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      }
      setIsLoading(false);
    },
    [db, profileData?.orgId, staticData?.bankAccounts],
  );

  const loadMoreLogs = useCallback(
    async (filterValues: any) => {
      if (lastVisible && !isLoadingMore) {
        setIsLoadingMore(true);
        const constraints = _filterToWhereClause(filterValues);
        const q = query(
          collection(db, 'Organizations', profileData?.orgId, 'Logs'),
          ...constraints,
          orderBy('timestamp', 'desc'),
          startAfter(lastVisible),
          limit(20),
        );
        const querySnapshot = await getDocs(q);
        const _logs: ActivityHistory[] = [];
        querySnapshot.forEach((doc) => {
          _logs.push({ ...doc.data(), id: doc.id } as ActivityHistory);
        });
        if (querySnapshot.empty) {
          setLastVisible(null);
        } else {
          setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        }
        setLogs((cv) => [...cv, ..._logs]);
        setIsLoadingMore(false);
      }
    },
    [db, lastVisible, profileData?.orgId, isLoadingMore],
  );

  useFocusEffect(
    useCallback(() => {
      setIsLoading(true);
      fetchLogs(filter);
    }, [fetchLogs, filter]),
  );

  // useEffect(() => {
  //   fetchLogs();
  //   setIsLoading(false);
  // }, [fetchLogs]);

  const onSearchOrFilter = useCallback(
    (searchValue: string) => {
      if (searchValue && searchValue !== '') {
        setFilteredLogs(_searchList(logs, searchValue));
      } else {
        setFilteredLogs(undefined);
      }
    },
    [logs],
  );

  return (
    <View style={AppStyles.container}>
      {selectedItem && (
        <ActivityHistoryChangesModal
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
      )}
      <View style={[AppStyles.flexRowCenterSpaceBetween, AppStyles.marginTop]}>
        {(logs && logs.length > 0) || !isEmpty(filter) ? (
          <FilterBar
            onSearch={onSearchOrFilter}
            searchPlaceholder={'Search Loaded Logs'}
            filter={filter}
            setFilter={setFilter}
            filterParams={[
              {
                title: 'Date',
                type: 'DateRange',
                field: 'timestamp',
              },
              {
                title: 'Type',
                type: 'MultiSelect',
                field: 'type',
                minWidth: 200,
                options: [
                  { label: LogType.AccountGroup, value: LogType.AccountGroup },
                  { label: LogType.Bank, value: LogType.Bank },
                  { label: LogType.BankAccount, value: LogType.BankAccount },
                  { label: LogType.BankStatement, value: LogType.BankStatement },
                  { label: LogType.BankTransactionRule, value: LogType.BankTransactionRule },
                  { label: LogType.CashPosition, value: LogType.CashPosition },
                  { label: LogType.Category, value: LogType.Category },
                  { label: LogType.Country, value: LogType.Country },
                  { label: LogType.Currency, value: LogType.Currency },
                  { label: LogType.Entity, value: LogType.Entity },
                  { label: LogType.HolidayCalendar, value: LogType.HolidayCalendar },
                  { label: LogType.Jobs, value: LogType.Jobs },
                  { label: LogType.PaymentTemplates, value: LogType.PaymentTemplates },
                  { label: LogType.Payments, value: LogType.Payments },
                  { label: LogType.Reports, value: LogType.Reports },
                  { label: LogType.SFTPCredentials, value: LogType.SFTPCredentials },
                  { label: LogType.User, value: LogType.User },
                  { label: LogType.UserGroup, value: LogType.UserGroup },
                ],
              },
              {
                title: 'User',
                type: 'MultiSelect',
                field: 'user',
                minWidth: 200,
                options:
                  staticData?.users?.map((user) => {
                    return { label: user.displayName || user.firstName, value: user.uid };
                  }) || [],
              },
            ]}
          />
        ) : (
          <View />
        )}
      </View>

      <View
        style={{
          flexDirection: 'row',
          height: Layout.window.height - 150,
          justifyContent: 'space-between',
        }}
      >
        <View style={{ width: Layout.window.width - 60 }}>
          <CFlatList
            emptyMessage={!isEmpty(filter) ? 'No results found' : 'No Logs yet'}
            data={filteredLogs || logs}
            isLoading={isLoading}
            isLoadingMore={isLoadingMore}
            loadMore={() => loadMoreLogs(filter)}
            renderItem={({ item }: { item: ActivityHistory }) => (
              <TouchableOpacity
                onPress={() => {
                  setSelectedItem(item);
                }}
              >
                <Card>
                  <View style={AppStyles.flexRowCenterSpaceBetween}>
                    <Text style={AppStyles.textRowTitle}>{item.type} </Text>
                    <Badge
                      colorScheme={_getLogActionColor(item.action)}
                      variant={'solid'}
                      style={{ alignSelf: 'flex-end' }}
                    >
                      {item.action}
                    </Badge>
                  </View>
                  <View style={AppStyles.flexRowCenterSpaceBetween}>
                    <Text style={AppStyles.textSubTitle}>{_getItemNameFromLogs(item)}</Text>
                    <View style={{ alignItems: 'flex-end' }}>
                      <Text style={AppStyles.textTiny}>
                        {'By '}
                        <Text style={AppStyles.textPrimary}>{_getUsersFromUid(item.user)}</Text>
                      </Text>
                      <Text style={AppStyles.textTiny}>
                        {'On '}
                        <Text style={AppStyles.textPrimary}>
                          {_getTimeStampString(item.timestamp)}
                        </Text>
                      </Text>
                    </View>
                  </View>
                </Card>
              </TouchableOpacity>
            )}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  bankAccountCard: {
    marginBottom: 10,
    marginHorizontal: 10,
    borderWidth: 2,
    flex: 1,
    borderRadius: 5,
    padding: 0,
  },
  triangle: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderTopWidth: 20,
    borderRightWidth: 0,
    borderBottomWidth: 20,
    borderLeftWidth: 15,
    borderTopColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: 'transparent',
    borderLeftColor: Colors.primary,
    position: 'absolute',
    left: 0,
    top: 35,
  },
});
