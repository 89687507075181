import React, { Dispatch, FC, SetStateAction } from 'react';

import { ApprovalWorkflow, ApprovalWorkflowStep, Operator } from '../../commonTypes';
import StepItemContainer from './StepItemContainer';

export type DefaultStepItemProps = {
  step: ApprovalWorkflowStep;
  index: number;
  setSelectedWorkflow: Dispatch<SetStateAction<ApprovalWorkflow | undefined>>;
};
const DefaultStepItem: FC<DefaultStepItemProps> = ({ step, index, setSelectedWorkflow }) => {
  const fieldProperties = {
    accountGroups: {
      label: 'Account Groups',
      condition: [Operator.IN, Operator.NOT_IN],
      valueFiled: 'Account Groups',
    },
  };

  return (
    <StepItemContainer
      step={step}
      index={index}
      setSelectedWorkflow={setSelectedWorkflow}
      fieldProperties={fieldProperties}
    />
  );
};

export default DefaultStepItem;
