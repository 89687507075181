import React, { FC, useCallback, useContext, useEffect } from 'react';

import { FontAwesome } from '@expo/vector-icons';
import { doc, getDoc } from 'firebase/firestore';
import { Skeleton, VStack } from 'native-base';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';

import { AppContext } from '../../App';
import {
  ApprovalTimelineItem,
  ApprovalTimelineItemStatus,
  ApprovalWorkflowSource,
  StaticDataStore,
} from '../../commonTypes';
import Colors from '../../constants/Colors';
import AppStyles from '../../constants/Styles';
import { _getTimeStampString } from '../../utils/helper';
import { CFlatList } from '../CFlatList';
import { Text, View } from '../Themed';

export type ApprovalTimelineProps = {
  pendingApprovalItem?: any;
  source?: ApprovalWorkflowSource;
  id?: string; // Passed for Create
  onApprovalDataLoaded?: (data: any) => void;
  timeline?: ApprovalTimelineItem[];
};
const ApprovalTimeline: FC<ApprovalTimelineProps> = ({
  pendingApprovalItem,
  source,
  id,
  onApprovalDataLoaded,
  timeline,
}) => {
  const { profileData } = useSelector((store: StaticDataStore) => store);
  const [expandedIndex, setExpandedIndex] = React.useState<number | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState(true);
  const [approvalTimeline, setApprovalTimeline] = React.useState<
    ApprovalTimelineItem[] | undefined
  >(timeline);
  const { db } = useContext(AppContext);

  const getApprovalTimeline = useCallback(() => {
    setIsLoading(true);
    getDoc(
      doc(
        db,
        'Organizations',
        profileData.orgId,
        'Approvals',
        pendingApprovalItem?.updateRequest?.requestId || (source || '') + id,
      ),
    )
      .then((timelineDoc) => {
        if (timelineDoc.exists()) {
          const timelineData = timelineDoc.data();
          console.warn('timelineData', timelineData);
          setApprovalTimeline(timelineData?.timeline);
          setIsLoading(false);
          setExpandedIndex(
            timelineData.timeline?.findIndex(
              (x: ApprovalTimelineItem) =>
                x.status === ApprovalTimelineItemStatus.PENDING ||
                x.status === ApprovalTimelineItemStatus.IN_PROGRESS,
            ),
          );

          const approvalData = {
            canApprove: false,
            approvers: [],
            approvedAt: timelineData.timeline?.[timelineData.timeline.length - 1].timestamp,
          };
          timelineData.timeline
            ?.filter(
              (x: ApprovalTimelineItem) =>
                x.status === ApprovalTimelineItemStatus.PENDING ||
                x.status === ApprovalTimelineItemStatus.IN_PROGRESS,
            )
            .every((x: ApprovalTimelineItem) => {
              if (x.approvers.some((approver) => approver.uid === profileData?.uid)) {
                approvalData.canApprove = true;
                return false;
              }
              return true;
            });
          // console.warn('approvalData', approvalData);
          onApprovalDataLoaded?.(approvalData);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error getting document:', error);
      });
  }, [db, pendingApprovalItem?.updateRequest?.requestId, profileData?.orgId, profileData?.uid]);

  useEffect(() => {
    if (profileData?.orgId && (pendingApprovalItem?.updateRequest || id) && !timeline) {
      getApprovalTimeline();
    }
    if (timeline) {
      setApprovalTimeline(timeline);
      setExpandedIndex(
        timeline?.findIndex(
          (x: ApprovalTimelineItem) =>
            x.status === ApprovalTimelineItemStatus.PENDING ||
            x.status === ApprovalTimelineItemStatus.IN_PROGRESS,
        ),
      );
      setIsLoading(false);
    }
  }, [getApprovalTimeline, pendingApprovalItem?.updateRequest, id, profileData?.orgId, timeline]);

  const getColorFromAction = useCallback(
    (
      action:
        | 'Skipped'
        | 'Approved'
        | 'Pending'
        | 'Not Started'
        | 'Rejected'
        | 'Submitted'
        | 'In Progress'
        | 'Completed',
    ) => {
      switch (action) {
        case 'Approved':
        case 'Submitted':
        case 'Completed':
          return Colors.green;
        case 'In Progress':
        case 'Pending':
          return Colors.orange;
        case 'Rejected':
          return Colors.red;
        default:
          return Colors.grey;
      }
    },
    [],
  );

  const approvalTimelineNames = useCallback(
    (item: ApprovalTimelineItem, index: number) => {
      if (item.title === 'Submitted') {
        return <Text>{`Requested by ${item.approvers[0].name}`}</Text>;
      }
      if (item.approvers.length <= 1) {
        return <Text>{item.title}</Text>;
      }

      return (
        <View style={AppStyles.flexRowCenter}>
          <Text>{item.title}</Text>
          <FontAwesome
            name={expandedIndex === index ? 'chevron-up' : 'chevron-down'}
            size={12}
            color={Colors.primary}
            style={AppStyles.marginLeft}
          />
        </View>
      );
    },
    [expandedIndex],
  );

  return (
    <View>
      <Text style={[AppStyles.textFormSectionHeader, AppStyles.marginLeft25]}>
        Approval Timeline
      </Text>
      <View style={[AppStyles.flexRowCenterSpaceBetween, AppStyles.marginTop]}>
        <CFlatList
          data={approvalTimeline || []}
          isLoading={isLoading}
          contentLoader={
            <VStack
              w="40%"
              marginTop={2}
              marginLeft={2}
              padding={2}
              borderWidth="1"
              overflow="hidden"
              rounded="md"
              _dark={{
                borderColor: 'coolGray.500',
              }}
              _light={{
                borderColor: 'coolGray.200',
              }}
            >
              <Skeleton.Text px="1" lines={1} />
            </VStack>
          }
          renderItem={({ item, index }: { item: ApprovalTimelineItem; index: number }) => (
            <View
              style={[
                AppStyles.flexRowCenter,
                AppStyles.marginLeft25,
                AppStyles.paddingTop,
                styles.verticalLine,
              ]}
            >
              <View style={styles.verticalLine}>
                <Text style={AppStyles.textSecondary}>{_getTimeStampString(item.timestamp)}</Text>
              </View>
              <View style={AppStyles.flexRow}>
                <View
                  style={[
                    styles.roundIndicator,
                    { backgroundColor: getColorFromAction(item.status) },
                  ]}
                />
                <TouchableOpacity
                  onPress={() => {
                    setExpandedIndex(expandedIndex === index ? undefined : index);
                  }}
                  style={{ marginTop: -3 }}
                >
                  {approvalTimelineNames(item, index)}
                  {expandedIndex === index && (
                    <View style={[AppStyles.marginLeft25, AppStyles.marginTop]}>
                      {item.approvers.map((approver) => {
                        return (
                          <View style={AppStyles.flexRowCenter} key={approver.uid}>
                            <View
                              style={[
                                styles.roundIndicator,
                                { backgroundColor: getColorFromAction(approver.state) },
                              ]}
                            />
                            <Text>{approver.name}</Text>
                            <Text style={[AppStyles.textSecondary, AppStyles.marginLeft]}>
                              {JSON.stringify(approver.comments)}
                            </Text>
                          </View>
                        );
                      })}
                    </View>
                  )}
                </TouchableOpacity>
              </View>
            </View>
          )}
          emptyMessage="No activity history found"
          hideEmptyImage
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  verticalLine: {
    width: 100,
    alignItems: 'flex-end',
    paddingRight: 10,
    borderRightWidth: 3,
    borderRightColor: Colors.lightest_grey,
    paddingVertical: 10,
  },
  roundIndicator: {
    width: 10,
    height: 10,
    borderRadius: 10,
    marginRight: 5,
    marginLeft: -7,
  },
});

export default ApprovalTimeline;
