import React, { useCallback, useState } from 'react';

import { MaterialIcons } from '@expo/vector-icons';
import { Icon, Popover, Radio } from 'native-base';
import { InterfacePopoverProps } from 'native-base/lib/typescript/components/composites/Popover/types';
import { StyleSheet, TouchableOpacity } from 'react-native';

import { FilterParam } from './FilterBar';
import { Text } from './Themed';
import Colors from '../constants/Colors';
import AppStyles from '../constants/Styles';
import { _addAlpha } from '../utils/helper';

export function FilterSelect({
  title,
  options,
  selectedValue,
  onSelect,
}: FilterParam & { onSelect: (val: string | null) => void }) {
  const [isOpen, setIsOpen] = useState(false);

  const renderTrigger = useCallback(
    (triggerProps: InterfacePopoverProps) => {
      return (
        <TouchableOpacity
          style={[
            styles.paramContainer,
            {
              borderColor: selectedValue ? _addAlpha(Colors.primary, 0.1) : Colors.light_grey,
              backgroundColor: selectedValue ? _addAlpha(Colors.primary, 0.1) : undefined,
            },
          ]}
          {...triggerProps}
          onPress={() => setIsOpen(true)}
        >
          {selectedValue ? (
            <Text style={{ color: Colors.grey }}>
              {title + ': '}
              <Text style={AppStyles.textSecondary}>
                {options.find((option) => option.value === selectedValue)?.label}
              </Text>{' '}
            </Text>
          ) : (
            <Text>{title}</Text>
          )}

          {selectedValue ? (
            <TouchableOpacity onPress={() => onSelect(null)}>
              <Icon as={<MaterialIcons name="close" />} size={5} ml="2" />
            </TouchableOpacity>
          ) : (
            <Icon as={<MaterialIcons name="arrow-drop-down" />} size={5} ml="2" />
          )}
        </TouchableOpacity>
      );
    },
    [onSelect, options, selectedValue, title],
  );

  return (
    <Popover trigger={renderTrigger} isOpen={isOpen} onClose={() => setIsOpen(!isOpen)}>
      <Popover.Content accessibilityLabel="Delete Customerd" w="56">
        <Popover.Arrow />

        <Popover.Body>
          <Radio.Group
            name="myRadioGroup"
            accessibilityLabel="favorite number"
            value={selectedValue}
            onChange={(nextValue) => {
              setIsOpen(false);
              onSelect(nextValue);
            }}
          >
            {options.map((option) => {
              return (
                <Radio value={option.value} my={1} key={option.value} size={'sm'}>
                  {option.label}
                </Radio>
              );
            })}
          </Radio.Group>
        </Popover.Body>
      </Popover.Content>
    </Popover>
  );
}

const styles = StyleSheet.create({
  paramContainer: {
    marginLeft: 20,
    flexDirection: 'row',
    borderColor: Colors.grey,
    borderWidth: 1,
    borderRadius: 10,
    padding: 5,
    alignItems: 'center',
  },
});
