import React, { Dispatch, FC, SetStateAction } from 'react';

import { FontAwesome5 } from '@expo/vector-icons';
import { Radio } from 'native-base';
import { TouchableOpacity } from 'react-native';
import { ApprovalWorkflow, ApprovalWorkflowStep, Operator, PaymentType } from '../../commonTypes';
import Colors from '../../constants/Colors';
import AppStyles from '../../constants/Styles';
import { Card, Text, View } from '../Themed';
import UserGroupInput from '../Users/UserGroupInput';
import StepCriteria from './StepCriteria';

export type PaymentStepItemProps = {
  step: ApprovalWorkflowStep;
  index: number;
  setSelectedWorkflow: Dispatch<SetStateAction<ApprovalWorkflow | undefined>>;
};
const PaymentStepItem: FC<PaymentStepItemProps> = ({ step, index, setSelectedWorkflow }) => {
  const fieldProperties = {
    'fromAccountDetails.accountGroups': {
      label: 'Account Groups',
      condition: [Operator.IN, Operator.NOT_IN],
      valueFiled: 'Account Groups',
    },
    paymentAmount: {
      label: 'Amount',
      condition: [Operator.GREATER_THAN, Operator.LESS_THAN, Operator.BETWEEN],
      valueFiled: 'Amount',
    },
    type: {
      label: 'Type',
      condition: [Operator.EQUALS],
      valueFiled: 'Dropdown',
      options: [
        { value: PaymentType.External, label: PaymentType.External },
        { value: PaymentType.Internal, label: PaymentType.Internal },
      ],
    },
    category: {
      label: 'Category',
      condition: [Operator.IN, Operator.NOT_IN],
      valueFiled: 'Category',
    },
    template: {
      label: 'Template',
      condition: [Operator.EXISTS],
      valueFiled: 'Boolean',
    },
  };

  const isFallback = index === 0;

  return (
    <Card style={{ marginBottom: 20 }}>
      <View style={AppStyles.flexRow}>
        <View style={[{ flex: 2 }]}>
          <View style={AppStyles.flexRowCenterSpaceBetween}>
            <Text style={AppStyles.textRowTitle}>
              {isFallback ? 'Fallback Rule' : `Rule ${index}`}
            </Text>
            {isFallback ? (
              <View />
            ) : (
              <TouchableOpacity
                style={[AppStyles.flexRowCenter, AppStyles.marginRight, AppStyles.padding]}
                onPress={() => {
                  setSelectedWorkflow((currentValue) => {
                    const steps = currentValue.steps || [];
                    steps.splice(index, 1);
                    return { ...currentValue, steps: steps };
                  });
                }}
              >
                <FontAwesome5 name="trash" size={12} color={Colors.red} />
                <Text style={{ fontSize: 12, marginLeft: 5, color: Colors.red }}>Delete rule</Text>
              </TouchableOpacity>
            )}
          </View>
          <Text
            style={[
              AppStyles.textSubTitle,
              AppStyles.marginTop,
              { color: Colors.black, fontWeight: 'bold' },
            ]}
          >
            Approvers
          </Text>
          <View style={[AppStyles.marginTop]}>
            <Radio.Group
              style={AppStyles.marginRight}
              name="myRadioGroup"
              value={step.allOrAny}
              onChange={(nextValue) => {
                setSelectedWorkflow((currentValue) => {
                  const steps = currentValue.steps || [];
                  steps[index].allOrAny = nextValue;
                  return { ...currentValue, steps: steps };
                });
              }}
            >
              <Radio value="Any" my="0.5">
                Any Users of
              </Radio>
              <Radio value="All" my="0.5">
                All Users of
              </Radio>
            </Radio.Group>

            <View style={[AppStyles.marginLeft25, AppStyles.marginTop]}>
              <UserGroupInput
                onSelect={(values) => {
                  setSelectedWorkflow((currentValue) => {
                    const steps = currentValue.steps || [];
                    steps[index].approverUserGroups = values.map((value) => value) || [];
                    return { ...currentValue, steps: steps };
                  });
                }}
                values={step.approverUserGroups}
                isMultiSelect
              />
            </View>
          </View>
        </View>
        <StepCriteria
          fieldProperties={fieldProperties}
          step={step}
          index={index}
          setSelectedWorkflow={setSelectedWorkflow}
        />
      </View>
    </Card>
  );
};

export default PaymentStepItem;
