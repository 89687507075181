import React, { useCallback, useContext, useEffect, useState } from 'react';

import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { ScrollView, StyleSheet, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';
import omit from 'lodash/omit';
import { useToast } from '@chakra-ui/react';
import { AppContext } from '../App';
import { SFTPConfiguration, StaticDataStore } from '../commonTypes';
import Button from '../components/Button';
import { CFlatList } from '../components/CFlatList';
import CInput from '../components/CInput';
import { FilterBar } from '../components/FilterBar';
import { Card, Text, View } from '../components/Themed';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import AppStyles from '../constants/Styles';
import { RootTabScreenProps } from '../types';
import { _searchList, _textFieldIsInvalid } from '../utils/helper';

export default function SFTPConfigurationScreen({
  navigation,
}: RootTabScreenProps<'SFTPConfigurationScreen'>) {
  const [sftpConfigurations, setSftpConfigurations] = useState<SFTPConfiguration[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedSftpConfiguration, setSelectedSftpConfiguration] = useState<SFTPConfiguration>();
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [editEnabled, setEditEnabled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [filteredSftpConfigurations, setFilteredSftpConfigurations] = useState<
    SFTPConfiguration[] | undefined
  >();
  const { db } = useContext(AppContext);
  const toast = useToast();
  const { profileData } = useSelector((store: StaticDataStore) => store);

  const fetchSFTPConfigurations = useCallback(async () => {
    console.log('Fetching SFTP Configurations');
    const q = query(
      collection(db, 'Organizations', profileData.orgId, 'SFTPCredentials'),
      orderBy('name'),
    );
    const querySnapshot = await getDocs(q);
    const _sftpConfigurations: SFTPConfiguration[] = [];
    querySnapshot.forEach((doc) => {
      _sftpConfigurations.push({ ...doc.data(), id: doc.id } as SFTPConfiguration);
    });
    setSftpConfigurations(_sftpConfigurations);
    setIsLoading(false);
  }, [db, profileData.orgId]);

  useEffect(() => {
    fetchSFTPConfigurations();
  }, [fetchSFTPConfigurations]);

  const onSearchOrFilter = useCallback(
    (searchValue: string) => {
      if (searchValue && searchValue !== '') {
        setFilteredSftpConfigurations(_searchList(sftpConfigurations, searchValue));
      } else {
        setFilteredSftpConfigurations(undefined);
      }
    },
    [sftpConfigurations],
  );

  const onSave = useCallback(async () => {
    if (_textFieldIsInvalid(selectedSftpConfiguration?.name)) {
      toast({
        title: 'Invalid Name for the SFTP Profile',
        description: 'Please enter a valid name',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (_textFieldIsInvalid(selectedSftpConfiguration?.host)) {
      toast({
        title: 'Invalid Hose',
        description: 'Please enter a valid Host name',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (_textFieldIsInvalid(selectedSftpConfiguration?.username)) {
      toast({
        title: 'Enter a valid User Name',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (_textFieldIsInvalid(selectedSftpConfiguration?.password)) {
      toast({
        title: 'Invalid password',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    setIsSaving(true);

    if (selectedIndex !== undefined && selectedSftpConfiguration?.id) {
      updateDoc(
        doc(
          db,
          'Organizations',
          profileData.orgId,
          'SFTPCredentials',
          selectedSftpConfiguration.name,
        ),
        { ...selectedSftpConfiguration, lastUpdatedBy: profileData?.uid },
      )
        .then(() => {
          const _sftpConfigurations = filteredSftpConfigurations || sftpConfigurations || [];
          _sftpConfigurations[selectedIndex] = selectedSftpConfiguration;
          if (filteredSftpConfigurations) {
            setFilteredSftpConfigurations(_sftpConfigurations);
            const __AllsftpConfigurations = sftpConfigurations;
            __AllsftpConfigurations[
              __AllsftpConfigurations.findIndex(
                (sftpConfig) => sftpConfig.name === selectedSftpConfiguration.id,
              )
            ] = selectedSftpConfiguration;
            setSftpConfigurations(__AllsftpConfigurations);
          } else {
            setSftpConfigurations(_sftpConfigurations);
          }
          setEditEnabled(false);
          toast({
            title: 'SFTP Profile Updated',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          setIsSaving(false);
        })
        .catch((e) => {
          console.warn(e);
          toast({
            title: 'Error updating SFTP Profile',
            description: e.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          setIsSaving(false);
          return;
        });
    } else {
      if (!selectedSftpConfiguration) {
        return;
      }
      await getDoc(
        doc(
          db,
          'Organizations',
          profileData.orgId,
          'SFTPCredentials',
          selectedSftpConfiguration.name,
        ),
      )
        .then((docSnap) => {
          if (docSnap.exists()) {
            toast({
              title: 'SFTP Profile with this name already exists',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
            setIsSaving(false);
            return;
          }
        })
        .catch((e) => {
          console.warn(e);
          toast({
            title: 'Error creating SFTP Profile',
            description: e.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          setIsSaving(false);
          return;
        });
      setDoc(
        doc(
          db,
          'Organizations',
          profileData.orgId,
          'SFTPCredentials',
          selectedSftpConfiguration.name,
        ),
        {
          ...selectedSftpConfiguration,
          createdBy: profileData.uid,
          createdDtTm: serverTimestamp(),
        } as SFTPConfiguration,
      )
        .then(() => {
          setSftpConfigurations((currentValue) => [
            { ...selectedSftpConfiguration, id: selectedSftpConfiguration.name },
            ...currentValue,
          ]);
          if (filteredSftpConfigurations) {
            setFilteredSftpConfigurations((currentValue) => [
              { ...selectedSftpConfiguration, id: newSFTPConfiguration.id },
              ...(currentValue || []),
            ]);
          }
          toast({
            title: 'SFTP Profile Created',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          setEditEnabled(false);
          setIsSaving(false);
        })
        .catch((e) => {
          console.warn(e);
          toast({
            title: 'Error creating SFTP Profile',
            description: e.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          setIsSaving(false);
          return;
        });
    }
  }, [
    sftpConfigurations,
    db,
    filteredSftpConfigurations,
    profileData.orgId,
    profileData.uid,
    selectedSftpConfiguration,
    selectedIndex,
    toast,
  ]);

  const onTextChange = useCallback((field: keyof SFTPConfiguration, value: string) => {
    setSelectedSftpConfiguration((existingValue) => {
      return { ...existingValue, [field]: value } as SFTPConfiguration;
    });
  }, []);

  return (
    <View style={AppStyles.container}>
      <View style={AppStyles.flexRowCenterSpaceBetween}>
        {sftpConfigurations && sftpConfigurations.length > 0 ? (
          <FilterBar
            onSearch={onSearchOrFilter}
            searchPlaceholder={'Search by Name or Description'}
          />
        ) : (
          <View />
        )}
        <Button
          mt={2}
          label="Create SFTP Profile"
          variant={'Create'}
          onPress={() => {
            setSelectedSftpConfiguration({
              name: '',
              description: '',
              username: '',
              password: '',
              host: '',
            } as SFTPConfiguration);
            setSelectedIndex(undefined);
            setEditEnabled(true);
          }}
        />
      </View>

      <View
        style={{
          flexDirection: 'row',
          height: Layout.window.height - 150,
        }}
      >
        <View style={{ width: Layout.window.width * 0.3 }}>
          <CFlatList
            emptyMessage={
              filteredSftpConfigurations === undefined ? 'No SFTP Profiles yet' : 'No results found'
            }
            data={filteredSftpConfigurations || sftpConfigurations}
            isLoading={isLoading}
            renderItem={({ item, index }: { item: SFTPConfiguration; index: number }) => (
              <TouchableOpacity
                onPress={() => {
                  setSelectedSftpConfiguration(item);
                  setSelectedIndex(index);
                  setEditEnabled(false);
                }}
                key={item.id}
              >
                <Card
                  style={[
                    styles.bankAccountCard,
                    selectedSftpConfiguration?.id === item.id && { borderColor: Colors.primary },
                  ]}
                >
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View>
                      <Text style={AppStyles.textRowTitle}>{item.name}</Text>
                      <Text style={AppStyles.textSubTitle}>{item.description}</Text>
                    </View>
                    {/* <StatusTag isReadOnly label={item.status} /> */}
                  </View>
                </Card>
              </TouchableOpacity>
            )}
          />
        </View>
        {selectedSftpConfiguration && (
          <View style={AppStyles.width60}>
            <View style={[AppStyles.flexRowCenterSpaceBetween, { marginHorizontal: 10 }]}>
              <Text style={[AppStyles.textRowTitle, { marginBottom: 10 }]}>
                {selectedSftpConfiguration.name || 'SFTP Profile'}
              </Text>
            </View>
            <ScrollView
              style={{
                maxHeight: Layout.window.height - 50,
                marginRight: 10,
                borderWidth: 2,
                borderColor: Colors.primary,
                borderRadius: 10,
              }}
            >
              <View style={{ flex: 1, padding: 10, paddingBottom: 100 }}>
                <>
                  <View style={[AppStyles.flexRowCenterSpaceBetween]}>
                    <CInput
                      label="Name"
                      fieldValue={selectedSftpConfiguration.name}
                      fieldKey="name"
                      onTextChange={onTextChange}
                      isReadOnly={!editEnabled}
                      isRequired
                    />
                    {/* <StatusTag isReadOnly label={selectedSftpConfiguration.status} /> */}
                  </View>
                  <CInput
                    label="Source Path"
                    fieldValue={selectedSftpConfiguration.path}
                    fieldKey="path"
                    onTextChange={onTextChange}
                    isReadOnly={!editEnabled}
                    isRequired
                  />
                  <CInput
                    label="Description"
                    fieldValue={selectedSftpConfiguration.description}
                    fieldKey="description"
                    onTextChange={onTextChange}
                    isReadOnly={!editEnabled}
                  />
                  <View style={AppStyles.flexRow}>
                    <CInput
                      label="Host Name"
                      fieldValue={selectedSftpConfiguration.host}
                      fieldKey="host"
                      onTextChange={onTextChange}
                      isReadOnly={!editEnabled}
                      isRequired
                    />
                    <CInput
                      label="Port"
                      fieldValue={selectedSftpConfiguration.port}
                      fieldKey="port"
                      onTextChange={onTextChange}
                      isReadOnly={!editEnabled}
                      isRequired
                      ml={2}
                    />
                  </View>
                  <View style={AppStyles.flexRow}>
                    <CInput
                      label="User Name"
                      fieldValue={selectedSftpConfiguration.username}
                      fieldKey="username"
                      onTextChange={onTextChange}
                      isReadOnly={!editEnabled}
                      isRequired
                    />
                    <CInput
                      ml={2}
                      label="Password"
                      fieldValue={selectedSftpConfiguration.password}
                      fieldKey="password"
                      onTextChange={onTextChange}
                      isReadOnly={!editEnabled}
                      isRequired
                      inputProps={{
                        type: showPassword ? 'text' : 'password',
                        pr: '4.5rem',
                        autoComplete: 'off',
                      }}
                      rightElement={
                        <Button
                          h="1.75rem"
                          size="sm"
                          label={showPassword ? 'Hide' : 'Show'}
                          onPress={() => setShowPassword((cv) => !cv)}
                        />
                      }
                      rightElementProps={{ width: '4.5rem' }}
                    />
                  </View>

                  <CInput
                    label="Destination Path"
                    fieldValue={selectedSftpConfiguration.destinationPath}
                    fieldKey="destinationPath"
                    onTextChange={onTextChange}
                    isReadOnly={!editEnabled}
                  />
                  <CInput
                    label="File Mask"
                    fieldValue={selectedSftpConfiguration.fileMask}
                    fieldKey="fileMask"
                    onTextChange={onTextChange}
                    isReadOnly={!editEnabled}
                  />
                </>
              </View>
            </ScrollView>

            <View style={[AppStyles.flexRowCenter, AppStyles.floatingBottonRight]}>
              <Button
                label="Cancel"
                onPress={() => {
                  setEditEnabled(false);
                  if (selectedIndex === undefined) {
                    setSelectedSftpConfiguration(undefined);
                  }
                }}
                isDisabled={isLoading}
                hidden={!editEnabled}
                variant="Cancel"
              />
              <Button
                label="Copy"
                variant={'Default'}
                onPress={() => {
                  setSelectedSftpConfiguration({
                    ...omit(selectedSftpConfiguration, ['id']),
                    name: `${selectedSftpConfiguration.name} Copy`,
                  });
                  setSelectedIndex(undefined);
                  setEditEnabled(true);
                }}
                hidden={editEnabled}
              />
              <Button
                label="Edit"
                variant={'Edit'}
                onPress={() => {
                  setEditEnabled(true);
                }}
                hidden={editEnabled}
              />
              <Button
                label={selectedSftpConfiguration?.id ? 'Save Changes' : 'Create SFTP Profile'}
                hidden={!editEnabled}
                onPress={() => onSave()}
                isLoading={isSaving}
              />
            </View>
          </View>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  bankAccountCard: {
    marginBottom: 10,
    marginHorizontal: 10,
    borderWidth: 2,
  },
});
