import React, { FC } from 'react';

import { Checkbox } from 'native-base';
import { ViewStyle } from 'react-native';

export type CCheckBoxProps = {
  label: string;
  onChange: (value: boolean) => void;
  disabled?: boolean;
  style?: ViewStyle;
  value?: boolean;
};
const CCheckBox: FC<CCheckBoxProps> = ({ label, onChange, style, value }) => {
  return (
    <Checkbox
      colorScheme="green"
      isChecked={!!value}
      value={label}
      onChange={onChange}
      style={style}
    >
      {label}
    </Checkbox>
  );
};

export default CCheckBox;
