/* eslint-disable react-native/no-inline-styles */
import React, { FC, useContext } from 'react';

import { useDisclosure } from '@chakra-ui/react';
import { FontAwesome, Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { LinearGradient } from 'expo-linear-gradient';
import { getAuth } from 'firebase/auth';
import { collection, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { Animated, TouchableOpacity } from 'react-native';
import { Searchbar } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import { AppContext } from '../App';
import { SHOW_SMART_MENU, UPDATE_NOTIF_COUNT, UPDATE_PROFILE } from '../actions/ActionConstatnts';
import { StaticDataStore } from '../commonTypes';
import { Text, View } from '../components/Themed';
import Colors from '../constants/Colors';
import Constants from '../constants/Constants';
import AppStyles from '../constants/Styles';
import NotifMenu from './NotifMenu';

export type props = {
  title?: string;
};
const HeaderBar: FC<props> = ({ title }) => {
  const { profileData, staticData } = useSelector((store: StaticDataStore) => store);
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { db } = useContext(AppContext);
  const notificationCount = useSelector(
    (store: StaticDataStore) => store.userInterface.notificationCount,
  );
  const [ringAnimation] = React.useState(new Animated.Value(0));

  React.useEffect(() => {
    if (profileData?.uid && profileData?.orgId) {
      console.warn('Fetching Notif Count');
      let q = query(
        collection(db, 'Organizations', profileData?.orgId, 'Notifications'),
        where('user', '==', profileData?.uid),
        where('read', '==', false),
        where('hide', '==', false),
        orderBy('priority', 'asc'),
        orderBy('timestamp', 'desc'),
        limit(1),
      );
      const unsubscribe = onSnapshot(q, (notifSnapshot) => {
        console.warn('Notif Count', notifSnapshot.docs.length);
        if (notifSnapshot.docs.length && notifSnapshot.docs[0].data().priority === 1) {
          Animated.loop(
            Animated.sequence([
              Animated.timing(ringAnimation, {
                toValue: -1, // so i add the delay here
                duration: 100,
                delay: 800,
                useNativeDriver: false,
              }),
              Animated.timing(ringAnimation, {
                toValue: 1,
                duration: 100,
                useNativeDriver: false,
              }),
              Animated.timing(ringAnimation, {
                toValue: -1,
                duration: 100,
                useNativeDriver: false,
              }),
              Animated.timing(ringAnimation, {
                toValue: 1,
                duration: 100,
                useNativeDriver: false,
              }),
              Animated.timing(ringAnimation, {
                toValue: 0,
                duration: 100,
                useNativeDriver: false,
              }),
            ]),
          ).start();
        } else {
          Animated.timing(ringAnimation, {
            toValue: 0,
            duration: 100,
            useNativeDriver: false,
          }).start();
        }
        dispatch({
          payload: notifSnapshot.docs.length,
          type: UPDATE_NOTIF_COUNT,
        });
      });

      return () => unsubscribe();
    }
  }, [profileData?.uid, profileData?.orgId, db]);

  const rotation = ringAnimation.interpolate({
    inputRange: [-1, 1], // left side to right side
    outputRange: ['-10deg', '10deg'], // before that we have to check now it's perfect
  });
  return (
    <LinearGradient
      // Background Linear Gradient
      colors={[Colors.secondary, Colors.primary]}
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 0 }}
      style={{
        paddingLeft: 50,
        height: 50,
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 10,
        flexDirection: 'row',
      }}
    >
      <Text
        style={{
          fontSize: Constants.TextSize.max,
          color: Colors.white,
          marginLeft: 50,
        }}
      >
        {title || staticData?.orgInfo?.Name || 'Cash Xact'}
      </Text>
      {profileData?.uid && (
        <View style={AppStyles.flexRowCenter}>
          <Searchbar
            style={{ width: 200, height: 30, marginRight: 10 }}
            placeholder="Ctrl + M"
            placeholderTextColor={Colors.transBlack}
            maxFontSizeMultiplier={10}
            onFocus={() => {
              dispatch({
                payload: true,
                type: SHOW_SMART_MENU,
              });
            }}
          />
          <TouchableOpacity style={AppStyles.marginHorizontal} onPress={() => onOpen()}>
            <Animated.View style={{ transform: [{ rotate: rotation }] }}>
              <FontAwesome name="bell" size={20} color={Colors.white} />
            </Animated.View>
            {notificationCount ? (
              <View
                style={{
                  position: 'absolute',
                  width: 10,
                  height: 10,
                  borderRadius: 10,
                  backgroundColor: Colors.red,
                  bottom: 10,
                  right: 10,
                }}
              />
            ) : null}
          </TouchableOpacity>
          <Text style={[AppStyles.textSecondary, AppStyles.marginRight, { color: Colors.white }]}>
            {profileData?.firstName}
          </Text>
          <TouchableOpacity
            onPress={() => {
              getAuth().signOut();
              dispatch({
                payload: { uid: null, orgId: null },
                type: UPDATE_PROFILE,
              });
              navigation.navigate('LoginScreen');
            }}
          >
            <Ionicons name="log-out" size={20} color={Colors.white} />
          </TouchableOpacity>
        </View>
      )}
      {profileData?.uid && <NotifMenu isOpen={isOpen} onOpen={onOpen} onClose={onClose} />}
    </LinearGradient>
  );
};

export default HeaderBar;
