/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react-native/no-inline-styles */
/**
 * Learn more about Light and Dark modes:
 * https://docs.expo.io/guides/color-schemes/
 */

import React, { useState } from 'react';
import { Box, ButtonProps, Button as DefaultButton } from '@chakra-ui/react';
import { FontAwesome } from '@expo/vector-icons';
import { Alert, HStack, Menu, Pressable, VStack, Text as NBText } from 'native-base';
import {
  ActivityIndicator as DefaultActivityIndicator,
  Text as DefaultText,
  View as DefaultView,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';
import {
  CardProps,
  Card as DefaultCard,
  TextInput as DefaultTextInput,
  TextInputProps,
  Button as PaperButton,
  ButtonProps as PaperButtonProps,
} from 'react-native-paper';

import Colors from '../constants/Colors';
import Constants from '../constants/Constants';
import AppStyles from '../constants/Styles';
import useColorScheme from '../hooks/useColorScheme';
import { MenuItem } from '../types';
import { ColorScheme } from 'chakra-react-select';

export function useThemeColor(
  props: { light?: string; dark?: string },
  colorName: keyof typeof Colors.light & keyof typeof Colors.dark,
) {
  const theme = useColorScheme();
  const colorFromProps = props[theme];

  if (colorFromProps) {
    return colorFromProps;
  }
  return Colors[theme][colorName];
}

type ThemeProps = {
  lightColor?: string;
  darkColor?: string;
};

export type TextProps = ThemeProps & DefaultText['props'];
export type ViewProps = ThemeProps & DefaultView['props'];

export function Text(props: TextProps) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  const color = useThemeColor({ light: lightColor, dark: darkColor }, 'text');

  return <DefaultText style={[{ color }, style]} {...otherProps} />;
}

export function View(props: ViewProps) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  // const backgroundColor = useThemeColor(
  //   { light: lightColor, dark: darkColor },
  //   "background"
  // );
  const backgroundColor = 'transparant';

  return <DefaultView style={[{ backgroundColor }, style]} {...otherProps} />;
}

export function TextInput(
  props: Partial<TextInputProps> & {
    onChangeText: React.Dispatch<React.SetStateAction<string>>;
  },
) {
  const { style, onChangeText, ...otherProps } = props;

  return (
    <DefaultTextInput
      mode="outlined"
      style={[{ marginTop: 10 }, style]}
      onChangeText={(text) => onChangeText(text)}
      {...otherProps}
    />
  );
}

export function Button(
  props: {
    label: string;
    icon?: string | React.ReactElement;
    color?: ColorScheme;
    size?: number;
    isFloating?: boolean;
    loading?: boolean;
    onPress: () => void;
    hide?: boolean;
    disabled?: boolean;
    variant?: 'solid' | 'outline' | 'ghost' | 'link';
  } & ButtonProps,
) {
  const {
    onPress,
    label,
    icon,
    loading,
    color = 'blue',
    variant,
    isFloating,
    hide = false,
    disabled,
    ...remainingProps
  } = props;

  if (hide) {
    return null;
  }
  return (
    <Box position={isFloating ? 'fixed' : undefined} bottom={10} right={10}>
      <DefaultButton
        colorScheme={color}
        onClick={onPress}
        isLoading={loading}
        variant={variant}
        isDisabled={disabled}
        {...remainingProps}
      >
        {label}
      </DefaultButton>
    </Box>
  );
}

export function SmallCta(
  props: Partial<PaperButtonProps> & {
    label: string;
    icon?: string;
    buttonColor?: string;
    visible?: boolean;
  },
) {
  const {
    visible = true,
    style,
    onPress,
    label,
    icon,
    loading,
    buttonColor,
    ...otherProps
  } = props;

  if (!visible) {
    return null;
  }
  return (
    <PaperButton
      buttonColor={buttonColor || Colors.secondary}
      labelStyle={{ fontSize: Constants.TextSize.large }}
      style={[
        {
          borderRadius: 10,
          margin: 5,
          alignSelf: 'center',
          shadowColor: Colors.secondary,
          shadowRadius: 5,
          shadowOpacity: 0.5,
          shadowOffset: { width: -2, height: 4 },
          elevation: 5,
          flexDirection: 'row',
        },
        style,
      ]}
      onPress={!loading && onPress}
      mode="contained"
      {...otherProps}
    >
      {icon && (
        <FontAwesome
          name={icon}
          size={Constants.TextSize.large}
          color={Colors.white}
          style={{ marginRight: 10 }}
        />
      )}
      {label}
      {loading && (
        <DefaultActivityIndicator
          color={Colors.white}
          size={'small'}
          style={AppStyles.marginLeft}
        />
      )}
    </PaperButton>
  );
}

export function MoreMenu(props: { children: React.ReactElement }) {
  return (
    <Menu
      style={{ paddingHorizontal: 10 }}
      w="190"
      trigger={(triggerProps) => {
        return (
          <Pressable
            accessibilityLabel="More options menu"
            {...triggerProps}
            style={{
              borderRadius: 10,
              margin: 10,
              alignSelf: 'center',
              shadowColor: Colors.secondary,
              shadowRadius: 5,
              shadowOpacity: 0.5,
              shadowOffset: { width: -2, height: 4 },
              elevation: 5,
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: Colors.green,
              paddingVertical: 10,
              paddingHorizontal: 10,
              height: '100%',
            }}
          >
            <FontAwesome name="ellipsis-v" size={Constants.TextSize.xxl} color={Colors.white} />
          </Pressable>
        );
      }}
    >
      {props.children}
    </Menu>
  );
}

export function CTA(props: {
  label: string;
  icon?: string | React.ReactElement;
  buttonColor?: string;
  onPress: () => void;
  style?: ViewStyle;
}) {
  const { onPress, label, icon, buttonColor, style } = props;

  return (
    <TouchableOpacity
      style={[
        {
          borderRadius: 10,
          margin: 5,
          alignSelf: 'center',
          elevation: 5,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        },
        style,
      ]}
      onPress={onPress}
    >
      {icon && React.isValidElement(icon) ? (
        <View style={{ marginRight: 5 }}>{icon}</View>
      ) : (
        <FontAwesome
          name={icon}
          size={Constants.TextSize.large}
          color={buttonColor || Colors.secondary}
          style={{ marginRight: 5 }}
        />
      )}
      <Text style={{ color: buttonColor || Colors.secondary }}>{label}</Text>
    </TouchableOpacity>
  );
}
export function CTAFilled(props: {
  label: string;
  icon?: string | React.ReactElement;
  buttonColor?: string;
  onPress: () => void;
  style?: ViewStyle;
}) {
  const { onPress, label, icon, buttonColor, style } = props;

  return (
    <TouchableOpacity
      style={[
        {
          borderRadius: 10,
          alignSelf: 'center',
          marginTop: 5,
          marginHorizontal: 5,
          elevation: 5,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: buttonColor || Colors.secondary,
          paddingHorizontal: 10,
          paddingVertical: 5,
        },
        style,
      ]}
      onPress={onPress}
    >
      {icon && React.isValidElement(icon) ? (
        <View style={{ marginRight: 5 }}>{icon}</View>
      ) : (
        <FontAwesome
          name={icon}
          size={Constants.TextSize.large}
          color={Colors.white}
          style={{ marginRight: 5 }}
        />
      )}
      <Text style={{ color: Colors.white }}>{label}</Text>
    </TouchableOpacity>
  );
}

export function Card(props: Partial<CardProps>) {
  const { style, ...otherProps } = props;
  return (
    <DefaultCard
      style={[
        {
          marginTop: 5,
          marginHorizontal: 10,
          padding: 10,
          backgroundColor: Colors.white,
          borderColor: Colors.white,
          overflow: 'hidden',
        },
        style,
      ]}
      {...otherProps}
    />
  );
}

export const MenuItemComponent: React.FC<{
  menuItem: MenuItem;
  index: number;
  onPress: () => void;
  onSubItemPress: (target: string) => void;
  expanded?: boolean;
}> = ({ menuItem, index, onPress, onSubItemPress, expanded }) => {
  const [hoveredItem, setHoveredItem] = useState<string>();
  return (
    <View
      key={index}
      style={{ backgroundColor: '#FFFFFF33', marginBottom: 5, padding: 5, borderRadius: 10 }}
    >
      <TouchableOpacity
        style={{
          justifyContent: 'center',
        }}
        onPress={() => {
          onPress();
        }}
      >
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <View
              style={{
                width: 25,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <FontAwesome name={menuItem.icon} color={Colors.white} size={20} />
            </View>
            {expanded && (
              <Text
                style={{
                  marginHorizontal: 10,
                  color: Colors.white,
                  fontSize: Constants.TextSize.xl,
                }}
              >
                {menuItem.title}
              </Text>
            )}
          </View>
          {expanded && menuItem.subMenu && (
            <FontAwesome
              name={menuItem.expanded ? 'angle-up' : 'angle-down'}
              size={20}
              color={Colors.white}
            />
          )}
        </View>
      </TouchableOpacity>
      {expanded && menuItem.expanded && (
        <View>
          {menuItem.subMenu.map((subItem, subIndex) => {
            return (
              <Pressable
                key={subIndex}
                style={[
                  AppStyles.flexRowCenter,
                  { paddingLeft: 50, paddingRight: 10, paddingVertical: 5 },
                ]}
                onPress={() => {
                  onSubItemPress(subItem.target);
                }}
                onHoverIn={() => {
                  setHoveredItem(subItem.title);
                }}
                onHoverOut={() => {
                  setHoveredItem(undefined);
                }}
              >
                <Text
                  style={{
                    color: Colors.white,
                    fontWeight: hoveredItem === subItem.title ? 'bold' : 'normal',
                  }}
                >
                  {subItem.title}
                </Text>
                {hoveredItem === subItem.title && (
                  <TouchableOpacity
                    onPress={() =>
                      window.open(
                        `${window.location.origin}/login?redirect=${subItem.target}`,
                        '_blank',
                      )
                    }
                  >
                    <FontAwesome
                      name="external-link"
                      color={Colors.white}
                      size={15}
                      style={{ marginLeft: 5 }}
                    />
                  </TouchableOpacity>
                )}
              </Pressable>
            );
          })}
        </View>
      )}
    </View>
  );
};

export const ToastAlert: React.FC<{
  id: string;
  status?: string;
  variant: string;
  title: string;
  description?: string;
  isClosable?: boolean;
}> = ({ id, status, variant, title, description, isClosable, ...rest }) => {
  return (
    <Alert
      zIndex={10000}
      maxWidth="100%"
      alignSelf="center"
      flexDirection="row"
      status={status ? status : 'info'}
      variant={variant}
      {...rest}
    >
      <VStack space={1} flexShrink={1} w="100%">
        <HStack flexShrink={1} alignItems="center" justifyContent="space-between">
          <HStack space={2} flexShrink={1} alignItems="center">
            <Alert.Icon />
            <NBText
              fontSize="md"
              fontWeight="medium"
              flexShrink={1}
              color={variant === 'solid' ? 'lightText' : variant !== 'outline' ? 'darkText' : null}
            >
              {title}
            </NBText>
          </HStack>
          {/* {isClosable ? (
            <IconButton
              variant="unstyled"
              icon={<CloseIcon size="3" />}
              _icon={{
                color: variant === 'solid' ? 'lightText' : 'darkText',
              }}
              onPress={() => toast.close(id)}
            />
          ) : null} */}
        </HStack>
        <NBText
          px="6"
          color={variant === 'solid' ? 'lightText' : variant !== 'outline' ? 'darkText' : null}
        >
          {description}
        </NBText>
      </VStack>
    </Alert>
  );
};
