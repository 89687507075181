import React, { FC } from 'react';

import { LockIcon, QuestionIcon, WarningIcon } from '@chakra-ui/icons';
import { Tooltip } from '@chakra-ui/react';
import Colors from '../constants/Colors';

export type CToolTipProps = {
  label: string;
  iconColor?: string;
  isLockIcon?: boolean;
  isWarningIcon?: boolean;
};
const CToolTip: FC<CToolTipProps> = ({ label, iconColor, isLockIcon, isWarningIcon }) => {
  return (
    <Tooltip textAlign={'center'} label={label} fontSize="small" placement="top">
      {isLockIcon ? (
        <LockIcon ml={1} mb={1} fontSize={12} color={Colors.darkOrange} />
      ) : isWarningIcon ? (
        <WarningIcon ml={1} mb={1} fontSize={12} color={Colors.darkOrange} />
      ) : (
        <QuestionIcon ml={1} mb={1} fontSize={12} color={iconColor || Colors.secondary} />
      )}
    </Tooltip>
  );
};

export default CToolTip;
