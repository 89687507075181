import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import omit from 'lodash/omit';

import { useDispatch, useSelector } from 'react-redux';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
} from '@chakra-ui/react';
import { FontAwesome5 } from '@expo/vector-icons';
import { TouchableOpacity } from 'react-native';
import { AppContext } from '../../App';
import {
  ActiveStatus,
  InputMode,
  Job,
  JobSchedule,
  SFTPConfiguration,
  StaticDataStore,
  Task,
  TaskType,
} from '../../commonTypes';
import AppStyles from '../../constants/Styles';
import { Timezones } from '../../constants/timezones';
import { _textFieldIsInvalid } from '../../utils/helper';
import CDropdown from '../CDropdown';
import CInput from '../CInput';
import { CModal } from '../CModal';
import CRadio from '../CRadio';
import StatusTag from '../StatusTag';
import { Text, View } from '../Themed';
import { MultiSelectInput } from '../MultiSelectInput';
import DaysOfWeekPicker from '../DaysOfWeekPicker';

export type JobDetailsModalProps = {
  job?: Job;
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  onJobUpdated?: (updatedJob: Job) => any;
  mode?: InputMode;
};

export default function JobDetailsModal({
  job,
  isVisible,
  setIsVisible,
  onJobUpdated,
  mode = InputMode.CREATE,
}: JobDetailsModalProps) {
  const [isSaving, setIsSaving] = useState(false);
  const [selectedJob, setSelectedJob] = useState<Job | undefined>(job);
  const { db } = useContext(AppContext);
  const toast = useToast();
  const { profileData, staticData } = useSelector((store: StaticDataStore) => store);
  const canEdit = staticData.accessibleScreens?.JobScreen?.edit || false;
  const [expandedIndex, setExpandedIndex] = useState<number>(-1);
  const [sftpConfigurations, setSftpConfigurations] = useState<SFTPConfiguration[]>([]);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedJob(job);
  }, [job]);

  const fetchSFTPConfigurations = useCallback(async () => {
    console.log('Fetching SFTP Configurations');
    const q = query(
      collection(db, 'Organizations', profileData.orgId, 'SFTPCredentials'),
      orderBy('name'),
    );
    const querySnapshot = await getDocs(q);
    const _sftpConfigurations: SFTPConfiguration[] = [];
    querySnapshot.forEach((doc) => {
      _sftpConfigurations.push({ ...doc.data(), id: doc.id } as SFTPConfiguration);
    });
    setSftpConfigurations(_sftpConfigurations);
  }, [db, profileData.orgId]);

  useEffect(() => {
    fetchSFTPConfigurations();
  }, [fetchSFTPConfigurations, isVisible]);

  const onSave = useCallback(
    async (status = ActiveStatus.Active) => {
      if (isSaving || !profileData?.orgId) {
        return;
      }

      setIsSaving(true);
      if (_textFieldIsInvalid(selectedJob?.name)) {
        setIsSaving(false);
        setTabIndex(0);
        return toast({
          title: 'Invalid name',
          description: 'Please enter a valid job name',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
      if (_textFieldIsInvalid(selectedJob?.description)) {
        setIsSaving(false);
        setTabIndex(0);
        return toast({
          title: 'Invalid description',
          description: 'Please enter a valid job description',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
      if (selectedJob?.tasks?.some((task) => _textFieldIsInvalid(task.name))) {
        setIsSaving(false);
        setTabIndex(0);
        return toast({
          title: 'Invalid task name',
          description: 'Please enter a valid task name',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }

      if (selectedJob?.tasks?.some((task) => _textFieldIsInvalid(task.type))) {
        setIsSaving(false);
        setTabIndex(0);
        return toast({
          title: 'Invalid task type',
          description: 'Please enter a valid task type',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }

      if (
        selectedJob?.tasks?.some(
          (task) => task.type === TaskType.SFTP && (!task.sftpConfig || !task.sftpDirection),
        )
      ) {
        setIsSaving(false);
        setTabIndex(0);
        return toast({
          title: 'Invalid SFTP Configuration',
          description: 'Please select a valid SFTP Configuration',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }

      if (
        selectedJob?.tasks?.some(
          (task) =>
            (task.type === TaskType.Archive || task.type === TaskType.Import) &&
            _textFieldIsInvalid(task.sourceFolder),
        )
      ) {
        setIsSaving(false);
        setTabIndex(0);
        return toast({
          title: 'Invalid Source Folder',
          description: 'Please enter a valid source folder',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }

      if (
        selectedJob?.tasks?.some(
          (task) => task.type === TaskType.Export && _textFieldIsInvalid(task.destinationFolder),
        )
      ) {
        setIsSaving(false);
        setTabIndex(0);
        return toast({
          title: 'Invalid Destination Folder',
          description: 'Please enter a valid destination folder',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }

      if (
        !selectedJob?.schedule ||
        !selectedJob?.schedule?.timeZone ||
        !selectedJob?.schedule?.frequency ||
        (selectedJob?.schedule?.frequency === 'Daily' && !selectedJob?.schedule?.daysOfWeek) ||
        (selectedJob?.schedule?.occurrenceDailyType === 'Every' &&
          (!selectedJob?.schedule?.occurrenceDailyEvery ||
            !selectedJob?.schedule?.occurrenceDailyEveryUnit ||
            !selectedJob?.schedule?.occurrencyDailyFromTime ||
            !selectedJob?.schedule?.occurrencyDailyToTime))
      ) {
        setIsSaving(false);
        setTabIndex(1);
        return toast({
          title: 'Invalid Schedule',
          description: 'Please enter a valid Schedule',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }

      if (
        selectedJob?.schedule?.occurrenceDailyType === 'Once' &&
        !selectedJob?.schedule?.onceTime
      ) {
        setIsSaving(false);
        setTabIndex(1);
        return toast({
          title: 'Invalid Schedule',
          description: 'Please enter a valid Schedule',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
      Object.keys(selectedJob).forEach(
        (key) => selectedJob[key] === undefined && delete selectedJob[key],
      );

      const jobDoc = {
        ...selectedJob,
        status,
      };
      if (mode === InputMode.CREATE) {
        //Create new Job
        const docSnap = await getDoc(
          doc(db, 'Organizations', profileData.orgId, 'Banks', selectedJob.name),
        );

        if (docSnap.exists()) {
          toast({
            title: 'Job already exists',
            description: 'A Job with the same name already exists. Please enter a different name.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          setIsSaving(false);
          return;
        }
        await setDoc(doc(db, 'Organizations', profileData.orgId, 'Jobs', selectedJob.name), {
          ...jobDoc,
          createdDtTm: serverTimestamp(),
          createdBy: profileData?.uid,
          lastRun: null,
          lastRunId: null,
          lastRunStatus: null,
        } as Job);
        setIsSaving(false);
        setIsVisible(false);
        onJobUpdated?.(jobDoc as Job);

        toast({
          title: 'Job Created',
          description: 'Job created successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else if (selectedJob && selectedJob.id) {
        await updateDoc(doc(db, 'Organizations', profileData.orgId, 'Jobs', selectedJob.id), {
          ...omit(jobDoc, ['createdBy', 'createdDtTm', 'lastRun', 'lastRunId', 'lastRunStatus']),
          lastUpdatedBy: profileData?.uid,
        })
          .then(() => {
            toast({
              title: 'Job Updated',
              description: 'Job updated successfully',
              status: 'success',
              duration: 5000,
              isClosable: true,
            });
            onJobUpdated?.(jobDoc as Job);
            setIsVisible(false);
          })
          .catch((e) => {
            return toast({
              title: 'Something went wrong',
              description: 'Please try again later',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
          });
        setIsSaving(false);
      } else {
        setIsSaving(false);
        return toast({
          title: 'Something went wrong',
          description: 'Please try again later',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    },
    [
      db,
      dispatch,
      isSaving,
      mode,
      onJobUpdated,
      profileData.orgId,
      profileData?.uid,
      selectedJob,
      setIsVisible,
      staticData.ourBanks,
      toast,
    ],
  );

  const onTextChange = useCallback((field: keyof Job, value: string) => {
    setSelectedJob((existingValue) => {
      return { ...existingValue, [field]: value } as Job;
    });
  }, []);

  const onTaskTextChange = useCallback((field: keyof Task, value: string, index: number) => {
    setSelectedJob((existingValue) => {
      const tasks = existingValue?.tasks || [];
      const task = existingValue?.tasks?.[index] || ({} as Task);
      task[field] = value;
      tasks[index] = task;
      return { ...existingValue, tasks } as Job;
    });
  }, []);

  const onScheduleChange = useCallback((field: keyof JobSchedule, value: string) => {
    setSelectedJob((existingValue) => {
      return { ...existingValue, schedule: { ...existingValue?.schedule, [field]: value } } as Job;
    });
  }, []);

  const renderTask = useCallback(
    (task: Task, index: number) => {
      return (
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                <Text style={{ fontWeight: 'bold' }}>
                  {`Step ${index + 1}:  `}
                  <Text style={{ fontWeight: 'normal' }}>{task.name}</Text>
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <View style={[AppStyles.flexRowCenterSpaceBetween, { width: '100%' }]}>
              <View style={AppStyles.flexRow}>
                <CInput
                  label="Task Name"
                  fieldKey="name"
                  fieldValue={task.name}
                  onTextChange={(field, value) => onTaskTextChange(field, value, index)}
                  isRequired
                />
                <CInput
                  label="Description"
                  fieldKey="description"
                  fieldValue={task.description}
                  onTextChange={(field, value) => onTaskTextChange(field, value, index)}
                  ml={2}
                  width={600}
                />
              </View>
              <TouchableOpacity
                onPress={() => {
                  setSelectedJob((existingValue) => {
                    return {
                      ...existingValue,
                      tasks: existingValue?.tasks?.filter((_, i) => i !== index),
                    } as Job;
                  });
                }}
              >
                <FontAwesome5 name="trash" size={15} color="red" />
              </TouchableOpacity>
            </View>
            <View style={[AppStyles.flexRowCenter, AppStyles.marginTop]}>
              <CDropdown
                placeholder="Select Task Type"
                name="Task Type"
                value={task.type}
                title="Task Type"
                onChange={(value) => onTaskTextChange('type', value, index)}
                isRequired
                options={[
                  { label: TaskType.Export, value: TaskType.Export },
                  { label: TaskType.Import, value: TaskType.Import },
                  { label: TaskType.SFTP, value: TaskType.SFTP },
                  { label: TaskType.Archive, value: TaskType.Archive },
                ]}
              />
              {task?.type === TaskType.SFTP && (
                <>
                  <CDropdown
                    placeholder="Select Direction"
                    name="SFTP Direction"
                    value={task.sftpDirection}
                    title="SFTP Direction"
                    ml={2}
                    onChange={(value) => onTaskTextChange('sftpDirection', value, index)}
                    isRequired
                    options={[
                      { label: 'Pull', value: 'Pull' },
                      { label: 'Push', value: 'Push' },
                    ]}
                  />
                  <CDropdown
                    tooltip="Can be setup in SFTP Configuration screen"
                    placeholder="Select Profile"
                    name="SFTP Profile"
                    value={task.sftpConfig}
                    title="SFTP Profile"
                    ml={2}
                    onChange={(value) => onTaskTextChange('sftpConfig', value, index)}
                    isRequired
                    options={
                      sftpConfigurations?.map((config) => {
                        return { label: config.name, value: config.id };
                      }) || []
                    }
                  />
                </>
              )}
              {(task?.type === TaskType.Archive || task?.type === TaskType.Import) && (
                <CInput
                  ml={2}
                  label="Source Folder"
                  fieldKey="sourceFolder"
                  fieldValue={task.sourceFolder}
                  onTextChange={(field, value) => onTaskTextChange(field, value, index)}
                  isRequired
                />
              )}
              {task?.type === TaskType.Export && (
                <CInput
                  ml={2}
                  label="Destination Folder"
                  fieldKey="destinationFolder"
                  fieldValue={task.destinationFolder}
                  onTextChange={(field, value) => onTaskTextChange(field, value, index)}
                  isRequired
                />
              )}
            </View>
          </AccordionPanel>
        </AccordionItem>
      );
    },
    [sftpConfigurations],
  );

  if (!isVisible || !selectedJob) {
    return null;
  }

  return (
    <CModal
      onSave={() => onSave()}
      open={isVisible}
      setOpen={(value) => setIsVisible(value)}
      title={selectedJob?.name || 'Job'}
      isSaving={isSaving}
      hideButtons={!canEdit}
      additionalButtons={
        mode === InputMode.CREATE ? undefined : (
          <Button
            ml={2}
            mr={2}
            colorScheme={
              (selectedJob?.status || ActiveStatus.Active) === ActiveStatus.Active ? 'red' : 'green'
            }
            onClick={() => {
              onSave(
                (selectedJob?.status || ActiveStatus.Active) === ActiveStatus.Active
                  ? ActiveStatus.Deativated
                  : ActiveStatus.Active,
              );
            }}
          >
            {(selectedJob?.status || ActiveStatus.Active) === ActiveStatus.Active
              ? 'Deactivate'
              : 'Activate'}
          </Button>
        )
      }
    >
      <View pointerEvents={canEdit ? 'auto' : 'none'}>
        <View style={[AppStyles.flexRowCenterSpaceBetween, { width: '100%' }]}>
          <View style={AppStyles.flexRow}>
            <CInput
              label="Job Name"
              fieldKey="name"
              fieldValue={selectedJob.name}
              onTextChange={onTextChange}
              isRequired
              disabled={mode === InputMode.EDIT}
            />
            <CInput
              label="Description"
              fieldKey="description"
              fieldValue={selectedJob.description}
              onTextChange={onTextChange}
              isRequired
              width={600}
              ml={2}
            />
          </View>
          <StatusTag label={selectedJob.status} />
        </View>
        <Tabs variant="enclosed" mt={5} index={tabIndex} onChange={setTabIndex}>
          <TabList>
            <Tab>Tasks</Tab>
            <Tab>Schedule</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Accordion
                allowToggle
                mt={5}
                index={expandedIndex}
                onChange={(expandedIndex) => setExpandedIndex(expandedIndex)}
              >
                {selectedJob.tasks?.map((task, index) => {
                  return renderTask(task, index);
                })}
              </Accordion>
              <Button
                w={200}
                onClick={() => {
                  setSelectedJob((existingValue) => {
                    return {
                      ...existingValue,
                      tasks: [...(existingValue?.tasks || []), { name: '', description: '' }],
                    } as Job;
                  });
                  setExpandedIndex(selectedJob.tasks?.length || 0);
                }}
                colorScheme="blue"
                mt={2}
              >
                Add Task
              </Button>
            </TabPanel>
            <TabPanel>
              <Stack direction={'row'} spacing={10}>
                <CDropdown
                  width={400}
                  placeholder="Time Zone"
                  name="Time Zone"
                  value={selectedJob?.schedule?.timeZone}
                  title="Time Zone"
                  onChange={(value) => onScheduleChange('timeZone', value)}
                  isRequired
                  options={Timezones.map((tz) => {
                    return { label: tz.text, value: tz.value };
                  })}
                />
                <CRadio
                  width={400}
                  name="Frequency"
                  value={selectedJob?.schedule?.frequency}
                  title="Frequency"
                  onChange={(value) => onScheduleChange('frequency', value)}
                  isRequired
                  options={[
                    { label: 'Adhoc', value: 'Adhoc' },
                    { label: 'Daily', value: 'Daily' },
                  ]}
                />
              </Stack>
              <Stack direction={'row'} spacing={10} mt={2}>
                {selectedJob?.schedule?.frequency === 'Daily' && (
                  <DaysOfWeekPicker
                    width={400}
                    values={selectedJob.schedule?.daysOfWeek}
                    onSelect={(days) => {
                      onScheduleChange('daysOfWeek', days || []);
                    }}
                    title="Days of Week"
                  />
                )}
                {selectedJob?.schedule?.frequency === 'Daily' && (
                  <FormControl isRequired>
                    <FormLabel>Time</FormLabel>
                    <Stack direction={'row'} spacing={5} alignItems={'center'}>
                      <CDropdown
                        width={120}
                        placeholder="Type"
                        name="Type"
                        value={selectedJob?.schedule?.occurrenceDailyType || 'Every'}
                        onChange={(value) => onScheduleChange('occurrenceDailyType', value)}
                        isRequired
                        options={[
                          { label: 'Once', value: 'Once' },
                          { label: 'Every', value: 'Every' },
                        ]}
                      />
                      {selectedJob?.schedule?.occurrenceDailyType === 'Once' ? (
                        <>
                          <Text>At</Text>
                          <Input
                            width={150}
                            type="time"
                            value={selectedJob?.schedule?.onceTime}
                            onChange={(e) => onScheduleChange('onceTime', e.target.value)}
                            isRequired
                          />
                        </>
                      ) : (
                        <>
                          <Input
                            width={20}
                            type="number"
                            value={selectedJob?.schedule?.occurrenceDailyEvery}
                            onChange={(e) =>
                              onScheduleChange('occurrenceDailyEvery', e.target.value)
                            }
                            isRequired
                          />
                          <CDropdown
                            width={120}
                            placeholder="Type"
                            name="occurrenceDailyEveryUnit"
                            value={selectedJob?.schedule?.occurrenceDailyEveryUnit || 'Hours'}
                            onChange={(value) =>
                              onScheduleChange('occurrenceDailyEveryUnit', value)
                            }
                            isRequired
                            options={[
                              { label: 'Hours', value: 'Hours' },
                              { label: 'Mins', value: 'Mins' },
                            ]}
                          />
                          <Text>From</Text>
                          <Input
                            width={150}
                            type="time"
                            value={selectedJob?.schedule?.occurrencyDailyFromTime}
                            onChange={(e) =>
                              onScheduleChange('occurrencyDailyFromTime', e.target.value)
                            }
                            isRequired
                          />
                          <Text>To</Text>
                          <Input
                            width={150}
                            type="time"
                            value={selectedJob?.schedule?.occurrencyDailyToTime}
                            onChange={(e) =>
                              onScheduleChange('occurrencyDailyToTime', e.target.value)
                            }
                            isRequired
                          />
                        </>
                      )}
                    </Stack>
                  </FormControl>
                )}
              </Stack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </View>
    </CModal>
  );
}
