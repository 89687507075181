import React, { useCallback } from 'react';

import { Skeleton, VStack } from 'native-base';
import { FlatList, FlatListProps, ListRenderItem, StyleProp, ViewStyle } from 'react-native';
import NoDataSvg from '../assets/images/no_data.svg';

import { Image } from 'react-native';
import Layout from '../constants/Layout';
import AppStyles from '../constants/Styles';
import { Button, Text, View } from './Themed';

type CFlatList<T> = FlatListProps<T> & {
  renderItem: ListRenderItem<T>;
  ListHeaderComponent?: React.ReactElement;
  isLoading: boolean;
  data?: T[];
  loadMore?: () => void;
  isLoadingMore?: boolean;
  contentLoader?: React.ReactElement;
  emptyMessage?: string;
  emptyImage?: React.ReactElement;
  emptyCTA?: string;
  emptyAction?: () => void;
  subAction?: React.ReactElement;
  style?: StyleProp<ViewStyle>;
  emptyStyle?: StyleProp<ViewStyle>;
  keyField?: string;
  horizontal?: boolean;
  loaderLines?: number;
  hideEmptyImage?: boolean;
};

export function CFlatList({
  renderItem,
  isLoading,
  data,
  loadMore,
  isLoadingMore,
  contentLoader,
  emptyMessage,
  emptyCTA,
  emptyAction,
  subAction,
  style,
  keyField,
  ListHeaderComponent,
  horizontal,
  emptyImage,
  emptyStyle,
  hideEmptyImage,
  loaderLines,
  ...props
}: CFlatList<any>) {
  const loader = useCallback(
    (index: number) => {
      return (
        contentLoader || (
          <View key={index.toString()}>
            <VStack
              w="98%"
              height={loaderLines ? loaderLines * 33 : 100}
              marginTop={2}
              marginLeft={2}
              padding={5}
              borderWidth="1"
              space={8}
              overflow="hidden"
              rounded="md"
              _dark={{
                borderColor: 'coolGray.500',
              }}
              _light={{
                borderColor: 'coolGray.200',
              }}
            >
              <Skeleton.Text px="1" lines={loaderLines || 3} />
            </VStack>
          </View>
        )
      );
    },
    [contentLoader, loaderLines],
  );
  const ContentLoader: ListRenderItem<any> = useCallback(
    ({ index }) => {
      return loader(index);
    },
    [loader],
  );
  if (!isLoading && (!data || data?.length === 0)) {
    return (
      <View style={[AppStyles.flexCenter, emptyStyle]}>
        {!hideEmptyImage &&
          (emptyImage || <Image source={NoDataSvg} style={{ height: 157, width: 160 }} />)}
        <Text
          style={[
            AppStyles.textSubTitle,
            AppStyles.marginBottom,
            AppStyles.marginTop,
            { textAlign: 'center' },
          ]}
        >
          {emptyMessage || 'No data found!'}
        </Text>
        {emptyCTA && emptyAction && <Button label={emptyCTA} onPress={emptyAction} />}
        {subAction || null}
      </View>
    );
  }

  return (
    <FlatList
      horizontal={horizontal}
      data={isLoading ? ([{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }] as typeof data) : data}
      keyExtractor={(item, index) => (keyField ? item?.[keyField] : item?.id || index.toString())}
      renderItem={isLoading ? ContentLoader : renderItem}
      ListFooterComponent={isLoadingMore ? loader : null}
      onEndReached={loadMore}
      refreshing={isLoadingMore || isLoading}
      style={[{ maxHeight: Layout.window.height - 100, paddingBottom: 10 }, style]}
      ListHeaderComponent={ListHeaderComponent}
      {...props}
    />
  );
}
