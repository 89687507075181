import React, { FC, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { BankAccount } from '../commonTypes';
import AppStyles from '../constants/Styles';
import { StaticDataStore } from '../comonTypes';
import { MultiSelectInput } from './MultiSelectInput';
import { Text, View } from './Themed';
import { ViewStyle } from 'react-native';
import { FormControl, FormLabel } from '@chakra-ui/react';
import { Select, chakraComponents } from 'chakra-react-select';

export type BankAccountInputProps = {
  onSelect: (values: BankAccount[]) => void;
  value?: string;
  isRequired?: boolean;
  title?: string;
  showDeactivated?: boolean;
  style?: ViewStyle;
  hasError?: boolean;
  isReadOnly?: boolean;
  hideLabel?: boolean;
  width?: number;
};
const BankAccountInput: FC<BankAccountInputProps> = ({
  onSelect,
  value,
  isRequired,
  title,
  showDeactivated,
  style,
  hasError,
  isReadOnly,
  hideLabel,
  width,
}) => {
  const { staticData } = useSelector((store: StaticDataStore) => store);

  const bankAccountDetails = useMemo(() => {
    return staticData.bankAccounts?.find((acc) => acc.id === value);
  }, [value, staticData.bankAccounts]);

  const customComponents = {
    Option: ({ children, ...props }) => (
      <chakraComponents.Option {...props}>
        <View style={{ width: '100%' }}>
          <View style={[AppStyles.flexRowCenterSpaceBetween, { width: '100%' }]}>
            <Text style={{ fontWeight: '600' }}>{props.data.account.accountNumber}</Text>
            <Text>{props.data.account.currency}</Text>
          </View>
          <View style={AppStyles.flexRowCenterSpaceBetween}>
            <Text>{props.data.account.accountName}</Text>
          </View>
        </View>
      </chakraComponents.Option>
    ),
  };

  return (
    <View style={[AppStyles.defaultWidthSmall, style]}>
      {/* <FormControl isRequired={isRequired} width={width}>
        <FormLabel hidden={hideLabel}>{'Bank Account'}</FormLabel>
        <View style={{ alignSelf: 'flex-start' }}>
          <Select
            isReadOnly={isReadOnly}
            onChange={(selected) => {
              console.warn(selected?.value);
              return onSelect([staticData.bankAccounts?.find((ag) => ag.id === selected?.value)]);
            }}
            closeMenuOnSelect
            value={
              value
                ? [
                    {
                      value,
                      label: staticData.bankAccounts?.find((ag) => ag.id === value)?.name || value,
                      account: staticData.bankAccounts?.find((ag) => ag.id === value),
                    },
                  ]
                : []
            }
            name="BankAccounts"
            options={[
              ...((showDeactivated
                ? staticData.bankAccounts
                : staticData?.bankAccounts?.filter((x) => x.status === 'Active') || []
              )?.map((ag) => {
                return { value: ag.id, label: ag.accountNumber, account: ag };
              }) || []),
            ]}
            placeholder="Select Account"
            size="sm"
            menuPosition="fixed"
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
            }}
            chakraStyles={{
              menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
            }}
            menuPortalTarget={document.body}
            components={customComponents}
          />
        </View>
      </FormControl> */}
      <MultiSelectInput
        hasError={hasError}
        isReadOnly={isReadOnly}
        title={title || 'Bank Account'}
        data={
          showDeactivated
            ? staticData.bankAccounts
            : staticData?.bankAccounts?.filter((x) => x.status === 'Active') || []
        }
        dataKey="name"
        onSelect={onSelect}
        isSingleSelect
        selectedValues={
          value
            ? [
                {
                  label: staticData.bankAccounts?.find((acc) => acc.id === value)?.accountNumber,
                  name: value,
                },
              ]
            : []
        }
        // renderItem={(item: BankAccount) => <Text>{item?.accountNumber}</Text>}
        style={AppStyles.defaultWidthSmall}
        isRequired={isRequired}
      />
      <View style={[AppStyles.flexRowCenterSpaceBetween, { marginTop: 5, marginHorizontal: 5 }]}>
        <Text style={AppStyles.textTiny}>{bankAccountDetails?.accountName}</Text>
        <Text style={AppStyles.textTiny}>{bankAccountDetails?.bank}</Text>
      </View>
    </View>
  );
};

export default BankAccountInput;
