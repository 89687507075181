import React, { FC } from 'react';

import { FormControl, FormLabel, Radio, RadioGroup, SpaceProps, Stack } from '@chakra-ui/react';
import CToolTip from './CToolTip';

export type CRadioProps = {
  options: { value: string; label: string }[];
  value?: string;
  name: string;
  onChange: (value?: string) => void;
  isRequired?: boolean;
  title?: string;
  width?: number;
  ml?: SpaceProps['ml'];
  tooltip?: string;
};
const CRadio: FC<CRadioProps> = ({
  value,
  options,
  name,
  onChange,
  isRequired,
  title,
  width,
  ml,
  tooltip,
}) => {
  return (
    <FormControl ml={ml} isRequired={isRequired} width={width}>
      {title && (
        <FormLabel>
          {title}
          {tooltip && <CToolTip label={tooltip} />}
        </FormLabel>
      )}
      <RadioGroup onChange={(value) => onChange(value)} value={value}>
        <Stack direction="row">
          {options.map((option) => (
            <Radio key={option.value} value={option.value}>
              {option.label}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
    </FormControl>
  );
};

export default CRadio;
