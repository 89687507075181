export const USER_PROFILE = 'USER_PROFILE';
export const UPDATE_PROFILE = 'USER_PROFILE';
export const UPDATE_BANK_ACCOUNTS = 'UPDATE_BANK_ACCOUNTS';
export const UPDATE_BANK_PREV_BUS_DAYS = 'UPDATE_BANK_PREV_BUS_DAYS';
export const UPDATE_ORG_INFO = 'UPDATE_ORG_INFO';
export const UPDATE_OUR_BANKS = 'UPDATE_OUR_BANKS';
export const UPDATE_ENTITIES = 'UPDATE_ENTITIES';
export const UPDATE_USERS = 'UPDATE_USERS';
export const UPDATE_USER_GROUPS = 'UPDATE_USER_GROUPS';
export const UPDATE_PERMISSIONS = 'UPDATE_PERMISSIONS';
export const UPDATE_ACCOUNT_GROUPS = 'UPDATE_ACCOUNT_GROUPS';
export const UPDATE_CURRENCIES = 'UPDATE_CURRENCIES';
export const UPDATE_CURRENCY_RATES = 'UPDATE_CURRENCY_RATES';
export const UPDATE_COUNTRIES = 'UPDATE_COUNTRIES';
export const UPDATE_CATEGORIES = 'UPDATE_CATEGORIES';
export const UPDATE_ACCESSIBLE_SCREENS = 'UPDATE_ACCESSIBLE_SCREENS';

export const SHOW_SMART_MENU = 'SHOW_SMART_MENU';
export const UPDATE_NOTIF_COUNT = 'UPDATE_NOTIF_COUNT';
export const UPDATE_HIGH_PRIO_NOTIF_COUNT = 'UPDATE_HIGH_PRIO_NOTIF_COUNT';
