/* eslint-disable max-lines */

import React, { useCallback, useContext, useEffect, useState } from 'react';

import { getApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, sendEmailVerification, User } from 'firebase/auth';
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  where,
  WriteBatch,
  writeBatch,
} from 'firebase/firestore';
import { StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import { UPDATE_PROFILE } from '../actions/ActionConstatnts';
import { AppContext } from '../App';
import { StaticDataStore } from '../commonTypes';
import { View } from '../components/Themed';
import { RootTabScreenProps } from '../types';

export default function TempUtilsScreen({ navigation }: RootTabScreenProps<'TempUtilsScreen'>) {
  const { profileData } = useSelector((store: StaticDataStore) => store);
  const [isLoading, setIsLoading] = useState(true);
  const [emailVerificationSent, setEmailVerificationSent] = useState(false);
  const app = getApp();
  const auth = getAuth(app);
  const { db } = useContext(AppContext);
  const dispatch = useDispatch();

  const onUserSignedIn = useCallback(
    (user: User) => {
      if (user.emailVerified) {
        dispatch({
          payload: { uid: user.uid, orgId: profileData.orgId },
          type: UPDATE_PROFILE,
        });
      } else {
        sendEmailVerification(user).then(() => {
          setEmailVerificationSent(true);
        });
      }
    },
    [dispatch],
  );

  useEffect(() => {
    return onAuthStateChanged(auth, (user) => {
      setIsLoading(false);

      if (user) {
        onUserSignedIn(user);
      } else {
        // User is signed out
        // ...
      }
    });
  }, [auth, onUserSignedIn]);

  const runScript = useCallback(async () => {
    const currencies = [
      {
        label: 'Albania Lek',
        value: 'Albania Lek|Lek',
        ISO: 'ALL',
        key: 'Albania Lek',
      },
      {
        label: 'Afghanistan Afghani',
        value: 'Afghanistan Afghani|؋',
        ISO: 'AFN',
        key: 'Afghanistan Afghani',
      },
      {
        label: 'Argentina Peso',
        value: 'Argentina Peso|$',
        ISO: 'ARS',
        key: 'Argentina Peso',
      },
      {
        label: 'Aruba Guilder',
        value: 'Aruba Guilder|ƒ',
        ISO: 'AWG',
        key: 'Aruba Guilder',
      },
      {
        label: 'Australia Dollar',
        value: 'Australia Dollar|$',
        ISO: 'AUD',
        key: 'Australia Dollar',
      },
      {
        label: 'Azerbaijan Manat',
        value: 'Azerbaijan Manat|₼',
        ISO: 'AZN',
        key: 'Azerbaijan Manat',
      },
      {
        label: 'Bahamas Dollar',
        value: 'Bahamas Dollar|$',
        ISO: 'BSD',
        key: 'Bahamas Dollar',
      },
      {
        label: 'Barbados Dollar',
        value: 'Barbados Dollar|$',
        ISO: 'BBD',
        key: 'Barbados Dollar',
      },
      {
        label: 'Belarus Ruble',
        value: 'Belarus Ruble|Br',
        ISO: 'BYR',
        key: 'Belarus Ruble',
      },
      {
        label: 'Belize Dollar',
        value: 'Belize Dollar|BZ$',
        ISO: 'BZD',
        key: 'Belize Dollar',
      },
      {
        label: 'Bermuda Dollar',
        value: 'Bermuda Dollar|$',
        ISO: 'BMD',
        key: 'Bermuda Dollar',
      },
      {
        label: 'Bolivia Bolíviano',
        value: 'Bolivia Bolíviano|$b',
        ISO: 'BOB',
        key: 'Bolivia Bolíviano',
      },
      {
        label: 'Bosnia and Herzegovina Convertible Mark',
        value: 'Bosnia and Herzegovina Convertible Mark|KM',
        ISO: 'BOM',
        key: 'Bosnia and Herzegovina Convertible Mark',
      },
      {
        label: 'Botswana Pula',
        value: 'Botswana Pula|P',
        ISO: 'BWP',
        key: 'Botswana Pula',
      },
      {
        label: 'Bulgaria Lev',
        value: 'Bulgaria Lev|лв',
        ISO: 'BGN',
        key: 'Bulgaria Lev',
      },
      {
        label: 'Brazil Real',
        value: 'Brazil Real|R$',
        ISO: 'BRL',
        key: 'Brazil Real',
      },
      {
        label: 'Brunei Darussalam Dollar',
        value: 'Brunei Darussalam Dollar|$',
        ISO: 'BND',
        key: 'Brunei Darussalam Dollar',
      },
      {
        label: 'Cambodia Riel',
        value: 'Cambodia Riel|៛',
        ISO: 'KHR',
        key: 'Cambodia Riel',
      },
      {
        label: 'Canada Dollar',
        value: 'Canada Dollar|$',
        ISO: 'CAD',
        key: 'Canada Dollar',
      },
      {
        label: 'Cayman Islands Dollar',
        value: 'Cayman Islands Dollar|$',
        ISO: 'KYD',
        key: 'Cayman Islands Dollar',
      },
      {
        label: 'Chile Peso',
        value: 'Chile Peso|$',
        ISO: 'CLP',
        key: 'Chile Peso',
      },
      {
        label: 'China Yuan Renminbi',
        value: 'China Yuan Renminbi|¥',
        ISO: 'CNY',
        key: 'China Yuan Renminbi',
      },
      {
        label: 'Colombia Peso',
        value: 'Colombia Peso|$',
        ISO: 'COP',
        key: 'Colombia Peso',
      },
      {
        label: 'Costa Rica Colon',
        value: 'Costa Rica Colon|₡',
        ISO: 'CRC',
        key: 'Costa Rica Colon',
      },
      {
        label: 'Croatia Kuna',
        value: 'Croatia Kuna|kn',
        ISO: 'HRK',
        key: 'Croatia Kuna',
      },
      { label: 'Cuba Peso', value: 'Cuba Peso|₱', ISO: 'CUP', key: 'Cuba Peso' },
      {
        label: 'Czech Republic Koruna',
        value: 'Czech Republic Koruna|Kč',
        ISO: 'CZK',
        key: 'Czech Republic Koruna',
      },
      {
        label: 'Denmark Krone',
        value: 'Denmark Krone|kr',
        ISO: 'DKK',
        key: 'Denmark Krone',
      },
      {
        label: 'Dominican Republic Peso',
        value: 'Dominican Republic Peso|RD$',
        ISO: 'DOP',
        key: 'Dominican Republic Peso',
      },
      {
        label: 'East Caribbean Dollar',
        value: 'East Caribbean Dollar|$',
        ISO: 'XCD',
        key: 'East Caribbean Dollar',
      },
      {
        label: 'Egypt Pound',
        value: 'Egypt Pound|£',
        ISO: 'EGP',
        key: 'Egypt Pound',
      },
      {
        label: 'El Salvador Colon',
        value: 'El Salvador Colon|$',
        ISO: 'SVC',
        key: 'El Salvador Colon',
      },
      {
        label: 'Euro Member Countries',
        value: 'Euro Member Countries|€',
        ISO: 'EUR',
        key: 'Euro Member Countries',
      },
      {
        label: 'Falkland Islands (Malvinas) Pound',
        value: 'Falkland Islands (Malvinas) Pound|£',
        ISO: 'FKP',
        key: 'Falkland Islands (Malvinas) Pound',
      },
      {
        label: 'Fiji Dollar',
        value: 'Fiji Dollar|$',
        ISO: 'FJD',
        key: 'Fiji Dollar',
      },
      {
        label: 'Ghana Cedi',
        value: 'Ghana Cedi|¢',
        ISO: 'GHS',
        key: 'Ghana Cedi',
      },
      {
        label: 'Gibraltar Pound',
        value: 'Gibraltar Pound|£',
        ISO: 'GIP',
        key: 'Gibraltar Pound',
      },
      {
        label: 'Guatemala Quetzal',
        value: 'Guatemala Quetzal|Q',
        ISO: 'GTQ',
        key: 'Guatemala Quetzal',
      },
      {
        label: 'Guernsey Pound',
        value: 'Guernsey Pound|£',
        ISO: 'XXX',
        key: 'Guernsey Pound',
      },
      {
        label: 'Guyana Dollar',
        value: 'Guyana Dollar|$',
        ISO: 'GYD',
        key: 'Guyana Dollar',
      },
      {
        label: 'Honduras Lempira',
        value: 'Honduras Lempira|L',
        ISO: 'HNL',
        key: 'Honduras Lempira',
      },
      {
        label: 'Hong Kong Dollar',
        value: 'Hong Kong Dollar|$',
        ISO: 'HKD',
        key: 'Hong Kong Dollar',
      },
      {
        label: 'Hungary Forint',
        value: 'Hungary Forint|Ft',
        ISO: 'HUF',
        key: 'Hungary Forint',
      },
      {
        label: 'Iceland Krona',
        value: 'Iceland Krona|kr',
        ISO: 'ISK',
        key: 'Iceland Krona',
      },
      {
        label: 'India Rupee',
        value: 'India Rupee|₹',
        ISO: 'INR',
        key: 'India Rupee',
      },
      {
        label: 'Indonesia Rupiah',
        value: 'Indonesia Rupiah|Rp',
        ISO: 'IDR',
        key: 'Indonesia Rupiah',
      },
      { label: 'Iran Rial', value: 'Iran Rial|﷼', ISO: 'IRR', key: 'Iran Rial' },
      {
        label: 'Isle of Man Pound',
        value: 'Isle of Man Pound|£',
        ISO: 'GBP',
        key: 'Isle of Man Pound',
      },
      {
        label: 'Israel Shekel',
        value: 'Israel Shekel|₪',
        ISO: 'ILS',
        key: 'Israel Shekel',
      },
      {
        label: 'Jamaica Dollar',
        value: 'Jamaica Dollar|J$',
        ISO: 'JMD',
        key: 'Jamaica Dollar',
      },
      { label: 'Japan Yen', value: 'Japan Yen|¥', ISO: 'JPY', key: 'Japan Yen' },
      {
        label: 'Jersey Pound',
        value: 'Jersey Pound|£',
        ISO: 'GBP',
        key: 'Jersey Pound',
      },
      {
        label: 'Kazakhstan Tenge',
        value: 'Kazakhstan Tenge|лв',
        ISO: 'KZT',
        key: 'Kazakhstan Tenge',
      },
      {
        label: 'Kenyan Shilling',
        value: 'Kenyan Shilling|/=',
        ISO: 'KES',
        key: 'Korea (North) Won',
      },
      {
        label: 'Korea (North) Won',
        value: 'Korea (North) Won|₩',
        ISO: 'KPW',
        key: 'Korea (North) Won',
      },
      {
        label: 'Korea (South) Won',
        value: 'Korea (South) Won|₩',
        ISO: 'KRW',
        key: 'Korea (South) Won',
      },
      {
        label: 'Kyrgyzstan Som',
        value: 'Kyrgyzstan Som|лв',
        ISO: 'KGS',
        key: 'Kyrgyzstan Som',
      },
      { label: 'Laos Kip', value: 'Laos Kip|₭', ISO: 'LAP', key: 'Laos Kip' },
      {
        label: 'Lebanon Pound',
        value: 'Lebanon Pound|£',
        ISO: 'LBP',
        key: 'Lebanon Pound',
      },
      {
        label: 'Liberia Dollar',
        value: 'Liberia Dollar|$',
        ISO: 'LRD',
        key: 'Liberia Dollar',
      },
      {
        label: 'Macedonia Denar',
        value: 'Macedonia Denar|ден',
        ISO: 'MKD',
        key: 'Macedonia Denar',
      },
      {
        label: 'Malaysia Ringgit',
        value: 'Malaysia Ringgit|RM',
        ISO: 'MYR',
        key: 'Malaysia Ringgit',
      },
      {
        label: 'Mauritius Rupee',
        value: 'Mauritius Rupee|₨',
        ISO: 'MUR',
        key: 'Mauritius Rupee',
      },
      {
        label: 'Mexico Peso',
        value: 'Mexico Peso|$',
        ISO: 'MXN',
        key: 'Mexico Peso',
      },
      {
        label: 'Mongolia Tughrik',
        value: 'Mongolia Tughrik|₮',
        ISO: 'MNT',
        key: 'Mongolia Tughrik',
      },
      {
        label: 'Mozambique Metical',
        value: 'Mozambique Metical|MT',
        ISO: 'MZN',
        key: 'Mozambique Metical',
      },
      {
        label: 'Namibia Dollar',
        value: 'Namibia Dollar|$',
        ISO: 'NAD',
        key: 'Namibia Dollar',
      },
      {
        label: 'Nepal Rupee',
        value: 'Nepal Rupee|₨',
        ISO: 'NPR',
        key: 'Nepal Rupee',
      },
      {
        label: 'Netherlands Antilles Guilder',
        value: 'Netherlands Antilles Guilder|ƒ',
        ISO: 'XXX',
        key: 'Netherlands Antilles Guilder',
      },
      {
        label: 'New Zealand Dollar',
        value: 'New Zealand Dollar|$',
        ISO: 'NZD',
        key: 'New Zealand Dollar',
      },
      {
        label: 'Nicaragua Cordoba',
        value: 'Nicaragua Cordoba|C$',
        ISO: 'NIO',
        key: 'Nicaragua Cordoba',
      },
      {
        label: 'Nigeria Naira',
        value: 'Nigeria Naira|₦',
        ISO: 'NGN',
        key: 'Nigeria Naira',
      },
      {
        label: 'Norway Krone',
        value: 'Norway Krone|kr',
        ISO: 'NOK',
        key: 'Norway Krone',
      },
      { label: 'Oman Rial', value: 'Oman Rial|﷼', ISO: 'OMR', key: 'Oman Rial' },
      {
        label: 'Pakistan Rupee',
        value: 'Pakistan Rupee|₨',
        ISO: 'PKR',
        key: 'Pakistan Rupee',
      },
      {
        label: 'Panama Balboa',
        value: 'Panama Balboa|B/.',
        ISO: 'PAB',
        key: 'Panama Balboa',
      },
      {
        label: 'Paraguay Guarani',
        value: 'Paraguay Guarani|Gs',
        ISO: 'PYG',
        key: 'Paraguay Guarani',
      },
      { label: 'Peru Sol', value: 'Peru Sol|S/.', ISO: 'PEN', key: 'Peru Sol' },
      {
        label: 'Philippines Peso',
        value: 'Philippines Peso|₱',
        ISO: 'PHP',
        key: 'Philippines Peso',
      },
      {
        label: 'Poland Zloty',
        value: 'Poland Zloty|zł',
        ISO: 'PLN',
        key: 'Poland Zloty',
      },
      {
        label: 'Qatar Riyal',
        value: 'Qatar Riyal|﷼',
        ISO: 'QAR',
        key: 'Qatar Riyal',
      },
      {
        label: 'Romania Leu',
        value: 'Romania Leu|lei',
        ISO: 'RON',
        key: 'Romania Leu',
      },
      {
        label: 'Russia Ruble',
        value: 'Russia Ruble|₽',
        ISO: 'RUB',
        key: 'Russia Ruble',
      },
      {
        label: 'Saint Helena Pound',
        value: 'Saint Helena Pound|£',
        ISO: 'SHP',
        key: 'Saint Helena Pound',
      },
      {
        label: 'Saudi Arabia Riyal',
        value: 'Saudi Arabia Riyal|﷼',
        ISO: 'SAR',
        key: 'Saudi Arabia Riyal',
      },
      {
        label: 'Serbia Dinar',
        value: 'Serbia Dinar|Дин.',
        ISO: 'RSD',
        key: 'Serbia Dinar',
      },
      {
        label: 'Seychelles Rupee',
        value: 'Seychelles Rupee|₨',
        ISO: 'SCR',
        key: 'Seychelles Rupee',
      },
      {
        label: 'Singapore Dollar',
        value: 'Singapore Dollar|$',
        ISO: 'SGD',
        key: 'Singapore Dollar',
      },
      {
        label: 'Solomon Islands Dollar',
        value: 'Solomon Islands Dollar|$',
        ISO: 'SMD',
        key: 'Solomon Islands Dollar',
      },
      {
        label: 'Somalia Shilling',
        value: 'Somalia Shilling|S',
        ISO: 'SOS',
        key: 'Somalia Shilling',
      },
      {
        label: 'South Africa Rand',
        value: 'South Africa Rand|R',
        ISO: 'ZAR',
        key: 'South Africa Rand',
      },
      {
        label: 'Sri Lanka Rupee',
        value: 'Sri Lanka Rupee|₨',
        ISO: 'LKR',
        key: 'Sri Lanka Rupee',
      },
      {
        label: 'Sweden Krona',
        value: 'Sweden Krona|kr',
        ISO: 'SEK',
        key: 'Sweden Krona',
      },
      {
        label: 'Switzerland Franc',
        value: 'Switzerland Franc|CHF',
        ISO: 'CHF',
        key: 'Switzerland Franc',
      },
      {
        label: 'Suriname Dollar',
        value: 'Suriname Dollar|$',
        ISO: 'SRD',
        key: 'Suriname Dollar',
      },
      {
        label: 'Syria Pound',
        value: 'Syria Pound|£',
        ISO: 'SYP',
        key: 'Syria Pound',
      },
      {
        label: 'Taiwan New Dollar',
        value: 'Taiwan New Dollar|NT$',
        ISO: 'TWD',
        key: 'Taiwan New Dollar',
      },
      {
        label: 'Tanzanian Shilling',
        value: 'Tanzanian Shilling|TSh',
        ISO: 'TSH',
        key: 'Tanzanian Shilling',
      },
      {
        label: 'Thailand Baht',
        value: 'Thailand Baht|฿',
        ISO: 'THB',
        key: 'Thailand Baht',
      },
      {
        label: 'Trinidad and Tobago Dollar',
        value: 'Trinidad and Tobago Dollar|TT$',
        ISO: 'TTD',
        key: 'Trinidad and Tobago Dollar',
      },
      {
        label: 'Turkey Lira',
        value: 'Turkey Lira|',
        ISO: 'TRY',
        key: 'Turkey Lira',
      },
      {
        label: 'Tuvalu Dollar',
        value: 'Tuvalu Dollar|$',
        ISO: 'AUD',
        key: 'Tuvalu Dollar',
      },
      {
        label: 'Ukraine Hryvnia',
        value: 'Ukraine Hryvnia|₴',
        ISO: 'UAH',
        key: 'Ukraine Hryvnia',
      },
      {
        label: 'United Kingdom Pound',
        value: 'United Kingdom Pound|£',
        ISO: 'GBP',
        key: 'United Kingdom Pound',
      },
      {
        label: 'United States Dollar',
        value: 'United States Dollar|$',
        ISO: 'USD',
        key: 'United States Dollar',
      },
      {
        label: 'Uruguay Peso',
        value: 'Uruguay Peso|$U',
        ISO: 'UYU',
        key: 'Uruguay Peso',
      },
      {
        label: 'Uzbekistan Som',
        value: 'Uzbekistan Som|лв',
        ISO: 'UZS',
        key: 'Uzbekistan Som',
      },
      {
        label: 'Venezuela Bolívar',
        value: 'Venezuela Bolívar|Bs',
        ISO: 'VEF',
        key: 'Venezuela Bolívar',
      },
      {
        label: 'Viet Nam Dong',
        value: 'Viet Nam Dong|₫',
        ISO: 'VND',
        key: 'Viet Nam Dong',
      },
      {
        label: 'Yemen Rial',
        value: 'Yemen Rial|﷼',
        ISO: 'WER',
        key: 'Yemen Rial',
      },
      {
        label: 'Zambian kwacha',
        value: 'Zambian kwacha|ZK',
        ISO: 'ZMW',
        key: 'Zambian kwacha',
      },
      {
        label: 'Zimbabwe Dollar',
        value: 'Zimbabwe Dollar|Z$',
        ISO: 'ZWL',
        key: 'Zimbabwe Dollar',
      },
    ];

    const countries = [
      { name: 'Afghanistan', code: 'AF', nonBusinessDays: [0, 6] },
      { name: 'Aland Islands', code: 'AX', nonBusinessDays: [0, 6] },
      { name: 'Albania', code: 'AL', nonBusinessDays: [0, 6] },
      { name: 'Algeria', code: 'DZ', nonBusinessDays: [0, 6] },
      { name: 'American Samoa', code: 'AS', nonBusinessDays: [0, 6] },
      { name: 'Andorra', code: 'AD', nonBusinessDays: [0, 6] },
      { name: 'Angola', code: 'AO', nonBusinessDays: [0, 6] },
      { name: 'Anguilla', code: 'AI', nonBusinessDays: [0, 6] },
      { name: 'Antarctica', code: 'AQ', nonBusinessDays: [0, 6] },
      { name: 'Antigua and Barbuda', code: 'AG', nonBusinessDays: [0, 6] },
      { name: 'Argentina', code: 'AR', nonBusinessDays: [0, 6] },
      { name: 'Armenia', code: 'AM', nonBusinessDays: [0, 6] },
      { name: 'Aruba', code: 'AW', nonBusinessDays: [0, 6] },
      { name: 'Australia', code: 'AU', nonBusinessDays: [0, 6] },
      { name: 'Austria', code: 'AT', nonBusinessDays: [0, 6] },
      { name: 'Azerbaijan', code: 'AZ', nonBusinessDays: [0, 6] },
      { name: 'Bahamas', code: 'BS', nonBusinessDays: [0, 6] },
      { name: 'Bahrain', code: 'BH', nonBusinessDays: [0, 6] },
      { name: 'Bangladesh', code: 'BD', nonBusinessDays: [0, 6] },
      { name: 'Barbados', code: 'BB', nonBusinessDays: [0, 6] },
      { name: 'Belarus', code: 'BY', nonBusinessDays: [0, 6] },
      { name: 'Belgium', code: 'BE', nonBusinessDays: [0, 6] },
      { name: 'Belize', code: 'BZ', nonBusinessDays: [0, 6] },
      { name: 'Benin', code: 'BJ', nonBusinessDays: [0, 6] },
      { name: 'Bermuda', code: 'BM', nonBusinessDays: [0, 6] },
      { name: 'Bhutan', code: 'BT', nonBusinessDays: [0, 6] },
      { name: 'Bolivia', code: 'BO', nonBusinessDays: [0, 6] },
      { name: 'Bosnia and Herzegovina', code: 'BA', nonBusinessDays: [0, 6] },
      { name: 'Botswana', code: 'BW', nonBusinessDays: [0, 6] },
      { name: 'Bouvet Island', code: 'BV', nonBusinessDays: [0, 6] },
      { name: 'Brazil', code: 'BR', nonBusinessDays: [0, 6] },
      { name: 'British Virgin Islands', code: 'VG', nonBusinessDays: [0, 6] },
      { name: 'British Indian Ocean Territory', code: 'IO', nonBusinessDays: [0, 6] },
      { name: 'Brunei Darussalam', code: 'BN', nonBusinessDays: [0, 6] },
      { name: 'Bulgaria', code: 'BG', nonBusinessDays: [0, 6] },
      { name: 'Burkina Faso', code: 'BF', nonBusinessDays: [0, 6] },
      { name: 'Burundi', code: 'BI', nonBusinessDays: [0, 6] },
      { name: 'Cambodia', code: 'KH', nonBusinessDays: [0, 6] },
      { name: 'Cameroon', code: 'CM', nonBusinessDays: [0, 6] },
      { name: 'Canada', code: 'CA', nonBusinessDays: [0, 6] },
      { name: 'Cape Verde', code: 'CV', nonBusinessDays: [0, 6] },
      { name: 'Cayman Islands', code: 'KY', nonBusinessDays: [0, 6] },
      { name: 'Central African Republic', code: 'CF', nonBusinessDays: [0, 6] },
      { name: 'Chad', code: 'TD', nonBusinessDays: [0, 6] },
      { name: 'Chile', code: 'CL', nonBusinessDays: [0, 6] },
      { name: 'China', code: 'CN', nonBusinessDays: [0, 6] },
      { name: 'Hong Kong, SAR China', code: 'HK', nonBusinessDays: [0, 6] },
      { name: 'Macao, SAR China', code: 'MO', nonBusinessDays: [0, 6] },
      { name: 'Christmas Island', code: 'CX', nonBusinessDays: [0, 6] },
      { name: 'Cocos (Keeling) Islands', code: 'CC', nonBusinessDays: [0, 6] },
      { name: 'Colombia', code: 'CO', nonBusinessDays: [0, 6] },
      { name: 'Comoros', code: 'KM', nonBusinessDays: [0, 6] },
      { name: 'Congo (Brazzaville)', code: 'CG', nonBusinessDays: [0, 6] },
      { name: 'Congo, (Kinshasa)', code: 'CD', nonBusinessDays: [0, 6] },
      { name: 'Cook Islands', code: 'CK', nonBusinessDays: [0, 6] },
      { name: 'Costa Rica', code: 'CR', nonBusinessDays: [0, 6] },
      { name: "Côte d'Ivoire", code: 'CI', nonBusinessDays: [0, 6] },
      { name: 'Croatia', code: 'HR', nonBusinessDays: [0, 6] },
      { name: 'Cuba', code: 'CU', nonBusinessDays: [0, 6] },
      { name: 'Cyprus', code: 'CY', nonBusinessDays: [0, 6] },
      { name: 'Czech Republic', code: 'CZ', nonBusinessDays: [0, 6] },
      { name: 'Denmark', code: 'DK', nonBusinessDays: [0, 6] },
      { name: 'Djibouti', code: 'DJ', nonBusinessDays: [0, 6] },
      { name: 'Dominica', code: 'DM', nonBusinessDays: [0, 6] },
      { name: 'Dominican Republic', code: 'DO', nonBusinessDays: [0, 6] },
      { name: 'Ecuador', code: 'EC', nonBusinessDays: [0, 6] },
      { name: 'Egypt', code: 'EG', nonBusinessDays: [0, 6] },
      { name: 'El Salvador', code: 'SV', nonBusinessDays: [0, 6] },
      { name: 'Equatorial Guinea', code: 'GQ', nonBusinessDays: [0, 6] },
      { name: 'Eritrea', code: 'ER', nonBusinessDays: [0, 6] },
      { name: 'Estonia', code: 'EE', nonBusinessDays: [0, 6] },
      { name: 'Ethiopia', code: 'ET', nonBusinessDays: [0, 6] },
      { name: 'Falkland Islands (Malvinas)', code: 'FK', nonBusinessDays: [0, 6] },
      { name: 'Faroe Islands', code: 'FO', nonBusinessDays: [0, 6] },
      { name: 'Fiji', code: 'FJ', nonBusinessDays: [0, 6] },
      { name: 'Finland', code: 'FI', nonBusinessDays: [0, 6] },
      { name: 'France', code: 'FR', nonBusinessDays: [0, 6] },
      { name: 'French Guiana', code: 'GF', nonBusinessDays: [0, 6] },
      { name: 'French Polynesia', code: 'PF', nonBusinessDays: [0, 6] },
      { name: 'French Southern Territories', code: 'TF', nonBusinessDays: [0, 6] },
      { name: 'Gabon', code: 'GA', nonBusinessDays: [0, 6] },
      { name: 'Gambia', code: 'GM', nonBusinessDays: [0, 6] },
      { name: 'Georgia', code: 'GE', nonBusinessDays: [0, 6] },
      { name: 'Germany', code: 'DE', nonBusinessDays: [0, 6] },
      { name: 'Ghana', code: 'GH', nonBusinessDays: [0, 6] },
      { name: 'Gibraltar', code: 'GI', nonBusinessDays: [0, 6] },
      { name: 'Greece', code: 'GR', nonBusinessDays: [0, 6] },
      { name: 'Greenland', code: 'GL', nonBusinessDays: [0, 6] },
      { name: 'Grenada', code: 'GD', nonBusinessDays: [0, 6] },
      { name: 'Guadeloupe', code: 'GP', nonBusinessDays: [0, 6] },
      { name: 'Guam', code: 'GU', nonBusinessDays: [0, 6] },
      { name: 'Guatemala', code: 'GT', nonBusinessDays: [0, 6] },
      { name: 'Guernsey', code: 'GG', nonBusinessDays: [0, 6] },
      { name: 'Guinea', code: 'GN', nonBusinessDays: [0, 6] },
      { name: 'Guinea-Bissau', code: 'GW', nonBusinessDays: [0, 6] },
      { name: 'Guyana', code: 'GY', nonBusinessDays: [0, 6] },
      { name: 'Haiti', code: 'HT', nonBusinessDays: [0, 6] },
      { name: 'Heard and Mcdonald Islands', code: 'HM', nonBusinessDays: [0, 6] },
      { name: 'Holy See (Vatican City State)', code: 'VA', nonBusinessDays: [0, 6] },
      { name: 'Honduras', code: 'HN', nonBusinessDays: [0, 6] },
      { name: 'Hungary', code: 'HU', nonBusinessDays: [0, 6] },
      { name: 'Iceland', code: 'IS', nonBusinessDays: [0, 6] },
      { name: 'India', code: 'IN', nonBusinessDays: [0, 6] },
      { name: 'Indonesia', code: 'ID', nonBusinessDays: [0, 6] },
      { name: 'Iran, Islamic Republic of', code: 'IR', nonBusinessDays: [0, 6] },
      { name: 'Iraq', code: 'IQ', nonBusinessDays: [0, 6] },
      { name: 'Ireland', code: 'IE', nonBusinessDays: [0, 6] },
      { name: 'Isle of Man', code: 'IM', nonBusinessDays: [0, 6] },
      { name: 'Israel', code: 'IL', nonBusinessDays: [0, 6] },
      { name: 'Italy', code: 'IT', nonBusinessDays: [0, 6] },
      { name: 'Jamaica', code: 'JM', nonBusinessDays: [0, 6] },
      { name: 'Japan', code: 'JP', nonBusinessDays: [0, 6] },
      { name: 'Jersey', code: 'JE', nonBusinessDays: [0, 6] },
      { name: 'Jordan', code: 'JO', nonBusinessDays: [0, 6] },
      { name: 'Kazakhstan', code: 'KZ', nonBusinessDays: [0, 6] },
      { name: 'Kenya', code: 'KE', nonBusinessDays: [0, 6] },
      { name: 'Kiribati', code: 'KI', nonBusinessDays: [0, 6] },
      { name: 'Korea (North)', code: 'KP', nonBusinessDays: [0, 6] },
      { name: 'Korea (South)', code: 'KR', nonBusinessDays: [0, 6] },
      { name: 'Kuwait', code: 'KW', nonBusinessDays: [0, 6] },
      { name: 'Kyrgyzstan', code: 'KG', nonBusinessDays: [0, 6] },
      { name: 'Lao PDR', code: 'LA', nonBusinessDays: [0, 6] },
      { name: 'Latvia', code: 'LV', nonBusinessDays: [0, 6] },
      { name: 'Lebanon', code: 'LB', nonBusinessDays: [0, 6] },
      { name: 'Lesotho', code: 'LS', nonBusinessDays: [0, 6] },
      { name: 'Liberia', code: 'LR', nonBusinessDays: [0, 6] },
      { name: 'Libya', code: 'LY', nonBusinessDays: [0, 6] },
      { name: 'Liechtenstein', code: 'LI', nonBusinessDays: [0, 6] },
      { name: 'Lithuania', code: 'LT', nonBusinessDays: [0, 6] },
      { name: 'Luxembourg', code: 'LU', nonBusinessDays: [0, 6] },
      { name: 'Macedonia, Republic of', code: 'MK', nonBusinessDays: [0, 6] },
      { name: 'Madagascar', code: 'MG', nonBusinessDays: [0, 6] },
      { name: 'Malawi', code: 'MW', nonBusinessDays: [0, 6] },
      { name: 'Malaysia', code: 'MY', nonBusinessDays: [0, 6] },
      { name: 'Maldives', code: 'MV', nonBusinessDays: [0, 6] },
      { name: 'Mali', code: 'ML', nonBusinessDays: [0, 6] },
      { name: 'Malta', code: 'MT', nonBusinessDays: [0, 6] },
      { name: 'Marshall Islands', code: 'MH', nonBusinessDays: [0, 6] },
      { name: 'Martinique', code: 'MQ', nonBusinessDays: [0, 6] },
      { name: 'Mauritania', code: 'MR', nonBusinessDays: [0, 6] },
      { name: 'Mauritius', code: 'MU', nonBusinessDays: [0, 6] },
      { name: 'Mayotte', code: 'YT', nonBusinessDays: [0, 6] },
      { name: 'Mexico', code: 'MX', nonBusinessDays: [0, 6] },
      { name: 'Micronesia, Federated States of', code: 'FM', nonBusinessDays: [0, 6] },
      { name: 'Moldova', code: 'MD', nonBusinessDays: [0, 6] },
      { name: 'Monaco', code: 'MC', nonBusinessDays: [0, 6] },
      { name: 'Mongolia', code: 'MN', nonBusinessDays: [0, 6] },
      { name: 'Montenegro', code: 'ME', nonBusinessDays: [0, 6] },
      { name: 'Montserrat', code: 'MS', nonBusinessDays: [0, 6] },
      { name: 'Morocco', code: 'MA', nonBusinessDays: [0, 6] },
      { name: 'Mozambique', code: 'MZ', nonBusinessDays: [0, 6] },
      { name: 'Myanmar', code: 'MM', nonBusinessDays: [0, 6] },
      { name: 'Namibia', code: 'NA', nonBusinessDays: [0, 6] },
      { name: 'Nauru', code: 'NR', nonBusinessDays: [0, 6] },
      { name: 'Nepal', code: 'NP', nonBusinessDays: [0, 6] },
      { name: 'Netherlands', code: 'NL', nonBusinessDays: [0, 6] },
      { name: 'Netherlands Antilles', code: 'AN', nonBusinessDays: [0, 6] },
      { name: 'New Caledonia', code: 'NC', nonBusinessDays: [0, 6] },
      { name: 'New Zealand', code: 'NZ', nonBusinessDays: [0, 6] },
      { name: 'Nicaragua', code: 'NI', nonBusinessDays: [0, 6] },
      { name: 'Niger', code: 'NE', nonBusinessDays: [0, 6] },
      { name: 'Nigeria', code: 'NG', nonBusinessDays: [0, 6] },
      { name: 'Niue', code: 'NU', nonBusinessDays: [0, 6] },
      { name: 'Norfolk Island', code: 'NF', nonBusinessDays: [0, 6] },
      { name: 'Northern Mariana Islands', code: 'MP', nonBusinessDays: [0, 6] },
      { name: 'Norway', code: 'NO', nonBusinessDays: [0, 6] },
      { name: 'Oman', code: 'OM', nonBusinessDays: [0, 6] },
      { name: 'Pakistan', code: 'PK', nonBusinessDays: [0, 6] },
      { name: 'Palau', code: 'PW', nonBusinessDays: [0, 6] },
      { name: 'Palestinian Territory', code: 'PS', nonBusinessDays: [0, 6] },
      { name: 'Panama', code: 'PA', nonBusinessDays: [0, 6] },
      { name: 'Papua New Guinea', code: 'PG', nonBusinessDays: [0, 6] },
      { name: 'Paraguay', code: 'PY', nonBusinessDays: [0, 6] },
      { name: 'Peru', code: 'PE', nonBusinessDays: [0, 6] },
      { name: 'Philippines', code: 'PH', nonBusinessDays: [0, 6] },
      { name: 'Pitcairn', code: 'PN', nonBusinessDays: [0, 6] },
      { name: 'Poland', code: 'PL', nonBusinessDays: [0, 6] },
      { name: 'Portugal', code: 'PT', nonBusinessDays: [0, 6] },
      { name: 'Puerto Rico', code: 'PR', nonBusinessDays: [0, 6] },
      { name: 'Qatar', code: 'QA', nonBusinessDays: [0, 6] },
      { name: 'Réunion', code: 'RE', nonBusinessDays: [0, 6] },
      { name: 'Romania', code: 'RO', nonBusinessDays: [0, 6] },
      { name: 'Russian Federation', code: 'RU', nonBusinessDays: [0, 6] },
      { name: 'Rwanda', code: 'RW', nonBusinessDays: [0, 6] },
      { name: 'Saint-Barthélemy', code: 'BL', nonBusinessDays: [0, 6] },
      { name: 'Saint Helena', code: 'SH', nonBusinessDays: [0, 6] },
      { name: 'Saint Kitts and Nevis', code: 'KN', nonBusinessDays: [0, 6] },
      { name: 'Saint Lucia', code: 'LC', nonBusinessDays: [0, 6] },
      { name: 'Saint-Martin (French part)', code: 'MF', nonBusinessDays: [0, 6] },
      { name: 'Saint Pierre and Miquelon', code: 'PM', nonBusinessDays: [0, 6] },
      { name: 'Saint Vincent and Grenadines', code: 'VC', nonBusinessDays: [0, 6] },
      { name: 'Samoa', code: 'WS', nonBusinessDays: [0, 6] },
      { name: 'San Marino', code: 'SM', nonBusinessDays: [0, 6] },
      { name: 'Sao Tome and Principe', code: 'ST', nonBusinessDays: [0, 6] },
      { name: 'Saudi Arabia', code: 'SA', nonBusinessDays: [0, 6] },
      { name: 'Senegal', code: 'SN', nonBusinessDays: [0, 6] },
      { name: 'Serbia', code: 'RS', nonBusinessDays: [0, 6] },
      { name: 'Seychelles', code: 'SC', nonBusinessDays: [0, 6] },
      { name: 'Sierra Leone', code: 'SL', nonBusinessDays: [0, 6] },
      { name: 'Singapore', code: 'SG', nonBusinessDays: [0, 6] },
      { name: 'Slovakia', code: 'SK', nonBusinessDays: [0, 6] },
      { name: 'Slovenia', code: 'SI', nonBusinessDays: [0, 6] },
      { name: 'Solomon Islands', code: 'SB', nonBusinessDays: [0, 6] },
      { name: 'Somalia', code: 'SO', nonBusinessDays: [0, 6] },
      { name: 'South Africa', code: 'ZA', nonBusinessDays: [0, 6] },
      { name: 'South Georgia and the South Sandwich Islands', code: 'GS', nonBusinessDays: [0, 6] },
      { name: 'South Sudan', code: 'SS', nonBusinessDays: [0, 6] },
      { name: 'Spain', code: 'ES', nonBusinessDays: [0, 6] },
      { name: 'Sri Lanka', code: 'LK', nonBusinessDays: [0, 6] },
      { name: 'Sudan', code: 'SD', nonBusinessDays: [0, 6] },
      { name: 'Suriname', code: 'SR', nonBusinessDays: [0, 6] },
      { name: 'Svalbard and Jan Mayen Islands', code: 'SJ', nonBusinessDays: [0, 6] },
      { name: 'Swaziland', code: 'SZ', nonBusinessDays: [0, 6] },
      { name: 'Sweden', code: 'SE', nonBusinessDays: [0, 6] },
      { name: 'Switzerland', code: 'CH', nonBusinessDays: [0, 6] },
      { name: 'Syrian Arab Republic (Syria)', code: 'SY', nonBusinessDays: [0, 6] },
      { name: 'Taiwan, Republic of China', code: 'TW', nonBusinessDays: [0, 6] },
      { name: 'Tajikistan', code: 'TJ', nonBusinessDays: [0, 6] },
      { name: 'Tanzania, United Republic of', code: 'TZ', nonBusinessDays: [0, 6] },
      { name: 'Thailand', code: 'TH', nonBusinessDays: [0, 6] },
      { name: 'Timor-Leste', code: 'TL', nonBusinessDays: [0, 6] },
      { name: 'Togo', code: 'TG', nonBusinessDays: [0, 6] },
      { name: 'Tokelau', code: 'TK', nonBusinessDays: [0, 6] },
      { name: 'Tonga', code: 'TO', nonBusinessDays: [0, 6] },
      { name: 'Trinidad and Tobago', code: 'TT', nonBusinessDays: [0, 6] },
      { name: 'Tunisia', code: 'TN', nonBusinessDays: [0, 6] },
      { name: 'Turkey', code: 'TR', nonBusinessDays: [0, 6] },
      { name: 'Turkmenistan', code: 'TM', nonBusinessDays: [0, 6] },
      { name: 'Turks and Caicos Islands', code: 'TC', nonBusinessDays: [0, 6] },
      { name: 'Tuvalu', code: 'TV', nonBusinessDays: [0, 6] },
      { name: 'Uganda', code: 'UG', nonBusinessDays: [0, 6] },
      { name: 'Ukraine', code: 'UA', nonBusinessDays: [0, 6] },
      { name: 'United Arab Emirates', code: 'AE', nonBusinessDays: [0, 6] },
      { name: 'United Kingdom', code: 'GB', nonBusinessDays: [0, 6] },
      { name: 'United States of America', code: 'US', nonBusinessDays: [0, 6] },
      { name: 'US Minor Outlying Islands', code: 'UM', nonBusinessDays: [0, 6] },
      { name: 'Uruguay', code: 'UY', nonBusinessDays: [0, 6] },
      { name: 'Uzbekistan', code: 'UZ', nonBusinessDays: [0, 6] },
      { name: 'Vanuatu', code: 'VU', nonBusinessDays: [0, 6] },
      { name: 'Venezuela (Bolivarian Republic)', code: 'VE', nonBusinessDays: [0, 6] },
      { name: 'Viet Nam', code: 'VN', nonBusinessDays: [0, 6] },
      { name: 'Virgin Islands, US', code: 'VI', nonBusinessDays: [0, 6] },
      { name: 'Wallis and Futuna Islands', code: 'WF', nonBusinessDays: [0, 6] },
      { name: 'Western Sahara', code: 'EH', nonBusinessDays: [0, 6] },
      { name: 'Yemen', code: 'YE', nonBusinessDays: [0, 6] },
      { name: 'Zambia', code: 'ZM', nonBusinessDays: [0, 6] },
      { name: 'Zimbabwe', code: 'ZW', nonBusinessDays: [0, 6] },
    ];

    // await setDoc(doc(db, 'DefaultConfig', 'Currencies'), { Currencies: currencies });

    await setDoc(doc(db, 'DefaultConfig', 'Countries'), { Countries: countries });
    // alert('Done');
  }, [db]);

  const deleteAccountAndRelatedDetails = useCallback(
    (accountId: string) => {
      const q = query(
        collection(db, 'Organizations', profileData.orgId, 'BankStatement'),
        where('accountId', '==', accountId),
      );
      getDocs(q).then(async (querySnapshot) => {
        console.warn(querySnapshot.docs.length);

        const batchArray: WriteBatch[] = [];
        batchArray.push(writeBatch(db));
        let operationCounter = 0;
        let batchIndex = 0;

        querySnapshot.docs.map((trans) => {
          batchArray[batchIndex].delete(trans.ref);
          operationCounter++;
          if (operationCounter === 499) {
            batchArray.push(writeBatch(db));
            batchIndex++;
            operationCounter = 0;
          }
        });
        const promises = batchArray.map(
          async (batch) =>
            // eslint-disable-next-line no-return-await
            await batch.commit().catch((e) => {
              // console.log(e);
              //errorsExist = true;
              console.warn('error encountered');
              console.warn(e);
            }),
        );
        await Promise.all(promises)
          .then(() => {
            console.warn('Completed Deleting Transactions');
          })
          .catch((error) => {
            console.warn('Something went wrong');
            console.warn(error);
          });
      });

      const q2 = query(
        collection(db, 'Organizations', profileData.orgId, 'CashPositionSummary'),
        where('date', '>=', moment('2023-06-19').toDate()),
      );
      getDocs(q2).then((querySnapshot) => {
        querySnapshot.forEach((trans) => {
          console.warn('Deleting cp Summary ', trans.id);
          deleteDoc(trans.ref);
        });
      });

      deleteDoc(doc(db, 'Organizations', profileData.orgId, 'BankAccounts', accountId));
      // alert('done');
    },
    [db, profileData.orgId],
  );

  return (
    <View style={styles.container}>
      {/* <Button
        label={'Run script'}
        onPress={async () =>
          // {
          //   const snapshot = await getCountFromServer(
          //     collection(db, 'Organizations', profileData.orgId, 'BankStatement'),
          //   );
          //   console.log('count: ', snapshot.data().count);
          // }
          [
            'CZ2935000000001000503606CZK',
            'CZ0735000000001000503614CZK',
            'CZ8235000000001000503622CZK',
            'CZ3535000000001330503609EUR',
            'PL52105000861000002273650362PLN',
            'NL82INGB0008237239EUR',
            'PL16105000861000009032141724PLN',
          ].map((acc) => deleteAccountAndRelatedDetails(acc))
        }
      /> */}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
