import React, { useEffect, useState } from 'react';

import { StatusBar } from 'expo-status-bar';
import { initializeApp } from 'firebase/app';
import { NativeBaseProvider, extendTheme } from 'native-base';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Provider } from 'react-redux';
import { PrimeReactProvider } from 'primereact/api';

import { ChakraProvider } from '@chakra-ui/react';
import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import Navigation from './navigation';
import configureStore from './store/configureStore';
import { Firestore, getFirestore } from 'firebase/firestore';
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primeicons/primeicons.css';
import '@webdatarocks/webdatarocks/webdatarocks.css';

const firebaseConfig = {
  apiKey: 'AIzaSyDmkKbqY2lSHh-ipy4_-6AysY4VGUmHS8M',
  authDomain: 'cashexact.firebaseapp.com',
  projectId: 'cashexact',
  storageBucket: 'cashexact.appspot.com',
  messagingSenderId: '63547641594',
  appId: '1:63547641594:web:e8f0c0637256f9b1960343',
  measurementId: 'G-35CD8VM2X7',
};
const app = initializeApp(firebaseConfig);
const defaultDb = getFirestore(app);

export const AppContext = React.createContext<{
  defaultDb: Firestore;
  db: Firestore;
  setDb: React.Dispatch<React.SetStateAction<Firestore>>;
}>({ defaultDb, db: defaultDb, setDb: () => {} });
export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();
  const [db, setDb] = useState<Firestore>(defaultDb);

  const theme = extendTheme({
    components: {
      Toast: {
        defaultProps: {
          _overlay: { style: { zIndex: 999 } },
        },
      },
    },
  });

  const store = configureStore();

  if (!isLoadingComplete) {
    return null;
  }
  return (
    <PrimeReactProvider>
      <Provider store={store}>
        <AppContext.Provider value={{ db, defaultDb, setDb }}>
          <NativeBaseProvider theme={theme}>
            <ChakraProvider>
              <SafeAreaProvider>
                <Navigation colorScheme={colorScheme} />
                <StatusBar />
              </SafeAreaProvider>
            </ChakraProvider>
          </NativeBaseProvider>
        </AppContext.Provider>
      </Provider>
    </PrimeReactProvider>
  );
}
