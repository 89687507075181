import React from 'react';

import { View } from '../components/Themed';
import BalanceByCurrencyWidget from '../components/Widgets/BalanceByCurrencyWidget';
import BankAccountsWidget from '../components/Widgets/BankAccountsWidget';
import BankStatementWidget from '../components/Widgets/BankStatementWidget';
import BanksWidget from '../components/Widgets/BanksWidget';
import RatesWidget from '../components/Widgets/RatesWidget';
import AppStyles from '../constants/Styles';

export default function HomeScreen() {
  return (
    <View style={[AppStyles.container, { justifyContent: 'flex-start', padding: 10 }]}>
      <View style={AppStyles.flexRowCenterSpaceAround}>
        <BankAccountsWidget />
        <BanksWidget />
        <RatesWidget />
        <BankStatementWidget />
      </View>

      <BalanceByCurrencyWidget />
    </View>
  );
}
