import { useSelector } from 'react-redux';
import { StaticDataStore } from '../commonTypes';

export default function useUsers() {
  const { staticData } = useSelector((store: StaticDataStore) => store);

  const _getUsersFromUid = (uids?: string[] | string) => {
    if (!Array.isArray(uids)) {
      return staticData?.users?.find((user) => user.uid === uids)?.firstName || 'Unknown';
    }
    return [...new Set(uids)]
      .map((uid) => staticData?.users?.find((user) => user.uid === uid)?.firstName || 'Unknown')
      .join(', ');
  };

  return { _getUsersFromUid };
}
