import React, { FC } from 'react';

import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { PaymentMethodParams } from '../commonTypes';
import AppStyles from '../constants/Styles';
import { CTAFilled, Text, View } from './Themed';
import { TouchableOpacity } from 'react-native';
import { omit } from 'lodash';

export type KeyValuePairInput<T> = {
  onChange: (value: T) => void;
  value?: T;
  title: string;
  isRequired?: boolean;
  isReadOnly?: boolean;
};
const KeyValuePairInput: FC<KeyValuePairInput<any>> = ({
  title,
  onChange,
  value,
  isRequired,
  isReadOnly,
}) => {
  const keys = Object.keys(PaymentMethodParams).filter(
    (key) => !Object.keys(value || {}).includes(key),
  );

  return (
    <FormControl isRequired={isRequired}>
      <FormLabel>{title}</FormLabel>
      {Object.entries(value || { ['']: '' })?.map(([key, val]) => {
        return (
          <View style={[AppStyles.flexRowCenter, AppStyles.marginLeft25, AppStyles.marginTop]}>
            <View style={{ width: 200 }}>
              <Select
                isReadOnly={isReadOnly}
                onChange={(selected) => onChange(omit({ ...value, [selected?.value]: '' }, ''))}
                value={key && key !== '' ? [{ value: key, label: PaymentMethodParams[key] }] : []}
                name="Parameters"
                options={keys.map((key) => {
                  return { value: key, label: PaymentMethodParams[key] };
                })}
                placeholder="Select ..."
                menuPosition="fixed"
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
                }}
                chakraStyles={{
                  menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
                }}
                menuPortalTarget={document.body}
              />
            </View>
            <Text>{'  ='}</Text>
            <Input
              onChange={(e) => {
                console.warn('xxx', key);
                onChange({ ...value, [key]: e.target.value });
              }}
              ml={2}
              placeholder="Value"
              value={val}
              width={300}
              colorScheme="blue"
              borderColor={'grey'}
              isDisabled={!key}
              isReadOnly={isReadOnly}
            />
          </View>
        );
      })}

      {keys.length > 0 && value && !Object.keys(value).includes('') && !isReadOnly && (
        <CTAFilled
          label="Add Parameter"
          icon={'plus'}
          style={[AppStyles.marginTop, { marginLeft: 25, width: 150 }]}
          onPress={() => onChange({ ...value, ['']: '' })}
        />
      )}
      {Object.keys(value).length == 0 && isReadOnly && (
        <Text style={[AppStyles.marginTop, { marginLeft: 25 }]}>No Parameters</Text>
      )}
    </FormControl>
  );
};

export default KeyValuePairInput;
