import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { doc, getDoc, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { UPDATE_BANK_ACCOUNTS } from '../../actions/ActionConstatnts';
import AppStyles from '../../constants/Styles';

import { Badge, useToast } from '@chakra-ui/react';
import { TouchableOpacity } from 'react-native';
import { AppContext } from '../../App';
import {
  ApprovalWorkflowSource,
  Bank,
  BankAccount,
  Entity,
  InputMode,
  LogType,
  StaticDataStore,
  Status,
} from '../../commonTypes';
import { _getChangedFields, _getFormattedCurrency, _textFieldIsInvalid } from '../../utils/helper';
import ActivityHistory from '../ActivityHistory';
import ReviewAndApproveModal from '../ApprovalWorkflow/ReviewAndApproveModal';
import BankInput from '../BankInput';
import CInput from '../CInput';
import CurrencyInput from '../CurrencyInput';
import EntityInput from '../EntityInput';
import FieldDisplay from '../FieldDisplay';
import PaymentMethodInput from '../Payments/PaymentMethodInput';
import StatusTag from '../StatusTag';
import { Button, Text, View } from '../Themed';
import AccountGroupInput from './AccountGroupInput';
import ButtonGroup from '../ButtonGroup';

export type BankAccountDetailsProps = {
  bankAccount: BankAccount;
  setIsVisible?: Dispatch<SetStateAction<boolean>>;
  onAccountUpdated?: (updatedAccount: BankAccount) => any;
  mode?: InputMode;
  onNext?: () => void;
  onItemActioned?: () => void;
};

const BankAccountDetails: FC<BankAccountDetailsProps> = ({
  bankAccount,
  setIsVisible,
  onAccountUpdated,
  mode = InputMode.CREATE,
  onNext,
  onItemActioned,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [selectedBankAccount, setSelectedBankAccount] = useState<BankAccount>(bankAccount);
  const [canApprove, setCanApprove] = useState(false);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const isReadOnly = [InputMode.VIEW, InputMode.APPROVE].includes(mode);
  useEffect(() => {
    setSelectedBankAccount(bankAccount);
    console.warn(bankAccount);
  }, [bankAccount]);

  const { db } = useContext(AppContext);
  const toast = useToast();
  const dispatch = useDispatch();
  const { profileData, staticData } = useSelector((store: StaticDataStore) => store);

  const isWorkflowEnabled =
    staticData?.orgInfo?.Workflow?.[ApprovalWorkflowSource.BANK_ACCOUNTS]?.status ||
    staticData?.orgInfo?.Workflow?.[ApprovalWorkflowSource.STATIC_DATA]?.status;

  const onSave = useCallback(async () => {
    if (isSaving || !profileData?.orgId) {
      return;
    }
    setIsSaving(true);
    if (_textFieldIsInvalid(selectedBankAccount?.accountNumber)) {
      setIsSaving(false);

      return toast({
        title: 'Invalid account number',
        description: 'Please enter a valid account number',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    if (_textFieldIsInvalid(selectedBankAccount?.accountName)) {
      setIsSaving(false);
      return toast({
        title: 'Invalid account name',
        description: 'Please enter a account name',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    if (_textFieldIsInvalid(selectedBankAccount?.currency)) {
      setIsSaving(false);

      return toast({
        title: 'Invalid currency',
        description: 'Please enter a valid currency',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }

    if (_textFieldIsInvalid(selectedBankAccount?.entity)) {
      setIsSaving(false);

      return toast({
        title: 'Invalid entity',
        description: 'Please select a valid entity',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }

    if (_textFieldIsInvalid(selectedBankAccount?.bank)) {
      setIsSaving(false);

      return toast({
        title: 'Invalid bank',
        description: 'Please select a valid bank',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }

    const updateRequest = {
      ..._getChangedFields(bankAccount, selectedBankAccount),
      requestedBy: profileData.uid,
      requestId: uuidv4(),
      lastUpdatedAt: serverTimestamp(),
    };

    let bankAccountDoc = {
      ...selectedBankAccount,
      status:
        !selectedBankAccount.status ||
        selectedBankAccount.status === Status.NEW ||
        (!isWorkflowEnabled && selectedBankAccount.status === Status.PENDING_APPROVAL)
          ? Status.ACTIVE
          : selectedBankAccount.status,
      lastUpdatedBy: profileData.uid,
    };

    if (bankAccountDoc?.id) {
      await updateDoc(
        doc(db, 'Organizations', profileData.orgId, 'BankAccounts', bankAccountDoc.id),
        isWorkflowEnabled ? { updateRequest: updateRequest } : bankAccountDoc,
      )
        .then(() => {
          if (isWorkflowEnabled) {
            bankAccountDoc = { ...bankAccount, updateRequest };
          }
          onAccountUpdated?.(bankAccountDoc);
          const _bankAccounts = staticData.bankAccounts || [];
          _bankAccounts[
            _bankAccounts.findIndex((eBankAccount) => eBankAccount.id === bankAccountDoc.id)
          ] = bankAccountDoc;
          dispatch({
            payload: _bankAccounts,
            type: UPDATE_BANK_ACCOUNTS,
          });

          toast({
            title: isWorkflowEnabled
              ? 'Account update requested. Pending approval'
              : 'Account Updated',
            status: isWorkflowEnabled ? 'warning' : 'success',
            duration: 5000,
            isClosable: true,
          });
        })
        .catch((e) => {
          console.warn(e);
          return toast({
            title: 'Something went wrong',
            description: 'You may not have permission to perform this action',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        });

      setIsSaving(false);
      setIsVisible?.(false);
    } else if (bankAccountDoc?.accountNumber && bankAccountDoc?.currency) {
      //Create new account
      const docSnap = await getDoc(
        doc(
          db,
          'Organizations',
          profileData?.orgId,
          'BankAccounts',
          bankAccountDoc?.accountNumber + bankAccountDoc?.currency || '',
        ),
      );
      if (docSnap.exists()) {
        setIsSaving(false);
        return toast({
          title: 'Account already exists',
          description: 'An account with the same number and currency already exists',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
      await setDoc(
        doc(
          db,
          'Organizations',
          profileData?.orgId,
          'BankAccounts',
          bankAccountDoc?.accountNumber + bankAccountDoc?.currency || '',
        ),
        {
          ...selectedBankAccount,
          createdBy: profileData?.uid,
          createdDtTm: serverTimestamp(),
          status: isWorkflowEnabled ? Status.PENDING_APPROVAL : Status.ACTIVE,
        },
      );
      onAccountUpdated?.({
        ...bankAccountDoc,
        createdBy: profileData?.uid,
        createdDtTm: Date.now(),
      });
      setIsSaving(false);
      setIsVisible?.(false);
      const _bankAccounts = staticData.bankAccounts || [];
      _bankAccounts.push(bankAccountDoc);
      dispatch({
        payload: _bankAccounts,
        type: UPDATE_BANK_ACCOUNTS,
      });

      return toast({
        title: 'Account Created',
        description: isWorkflowEnabled
          ? 'Account creation request has been submitted and is pending approval'
          : 'Account Created Successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } else {
      setIsSaving(false);
      return toast({
        title: 'Something went wrong',
        description: 'Please check the details and try again',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [
    bankAccount,
    db,
    dispatch,
    isSaving,
    onAccountUpdated,
    profileData.orgId,
    profileData.uid,
    selectedBankAccount,
    setIsVisible,
    staticData.bankAccounts,
    staticData?.orgInfo?.Workflow?.BankAccounts?.status,
    toast,
  ]);

  const onTextChange = useCallback((field: keyof BankAccount, value: string) => {
    setSelectedBankAccount((existingValue) => {
      return { ...existingValue, [field]: value } as BankAccount;
    });
  }, []);

  const BackButton = useCallback(
    (isGoBack?: boolean) => {
      return (
        <Button
          label={isGoBack ? 'Go Back' : 'Cancel'}
          variant="outline"
          disabled={isSaving}
          onPress={() => setIsVisible?.(false)}
          mr={2}
        />
      );
    },
    [isSaving],
  );

  const CTA = useMemo(() => {
    return (
      <View
        style={[
          AppStyles.flexRowCenterSpaceAround,
          { position: 'absolute', bottom: 10, right: 10, alignSelf: 'flex-end' },
        ]}
      >
        {mode !== InputMode.APPROVE && BackButton(!!selectedBankAccount?.id)}
        {[InputMode.CREATE, InputMode.EDIT].includes(mode) && (
          <Button
            label={selectedBankAccount?.id ? 'Save Changes' : 'Create Account'}
            loading={isSaving}
            onPress={() => {
              onSave();
            }}
            colorScheme={selectedBankAccount?.id ? 'orange' : 'blue'}
          />
        )}
        {canApprove && (
          <View style={[AppStyles.flexRowCenter, { alignSelf: 'flex-end' }]}>
            <Button
              onPress={() => setShowApprovalModal(true)}
              label="Reject"
              mr={2}
              colorScheme={'red'}
            />
            <Button
              label="Approve"
              ml={2}
              colorScheme={'green'}
              onPress={() => setShowApprovalModal(true)}
            />
            <Button
              label={'Next'}
              onPress={onNext}
              icon={'arrow-right'}
              colorScheme="blue"
              ml={2}
              hidden={!onNext}
            />
          </View>
        )}
      </View>
    );
  }, [isSaving, onSave, canApprove]);

  if (!selectedBankAccount) {
    return null;
  }

  return (
    <>
      <View>
        {isWorkflowEnabled && (
          <ReviewAndApproveModal
            onClose={() => {
              setShowApprovalModal(false);
            }}
            pendingApprovalItem={selectedBankAccount}
            source={ApprovalWorkflowSource.BANK_ACCOUNTS}
            id={bankAccount?.id}
            onApprovalDataLoaded={(approvalData) => {
              setCanApprove(approvalData.canApprove);
              console.warn('approvalData2', approvalData);
            }}
            onApprovalOrRejectonCompleted={() => {
              setIsVisible?.(false);
            }}
            onSomeActionDone={onItemActioned}
            show={showApprovalModal}
          />
        )}
        <View style={AppStyles.flexRowCenter}>
          <View style={AppStyles.flex1} pointerEvents={isReadOnly ? 'none' : 'auto'}>
            <CInput
              label="Account Number"
              fieldKey="accountNumber"
              fieldValue={selectedBankAccount.accountNumber}
              onTextChange={onTextChange}
              isRequired
              disabled={mode === InputMode.EDIT && !!selectedBankAccount?.id}
            />
          </View>
          <View style={AppStyles.flex1} pointerEvents={isReadOnly ? 'none' : 'auto'}>
            <CInput
              label="Account Name"
              fieldKey="accountName"
              fieldValue={selectedBankAccount.accountName}
              onTextChange={onTextChange}
              isRequired
            />
          </View>
          <View style={[AppStyles.flex1, AppStyles.alignFlexEnd]}>
            <StatusTag
              label={selectedBankAccount.status}
              onPendingApprovalPress={() => setShowApprovalModal(true)}
            />
            {selectedBankAccount.updateRequest && (
              <TouchableOpacity
                onPress={() => {
                  setShowApprovalModal(true);
                }}
              >
                <Badge variant="solid" colorScheme="orange">
                  Pending Updates
                </Badge>
              </TouchableOpacity>
            )}
            <FieldDisplay
              label="System Balance"
              value={_getFormattedCurrency(
                selectedBankAccount.balance,
                selectedBankAccount.currency,
              )}
            />
          </View>
        </View>
        <View style={AppStyles.flexRowCenter} pointerEvents={isReadOnly ? 'none' : 'auto'}>
          <View style={[AppStyles.flex1, AppStyles.marginTop]}>
            <EntityInput
              onSelect={(values: Entity[]) => onTextChange('entity', values[0].name)}
              value={selectedBankAccount.entity}
              isRequired
            />
          </View>
          <View style={AppStyles.flex1}>
            <CurrencyInput
              onSelect={(values: string[]) => onTextChange('currency', values[0])}
              value={selectedBankAccount.currency}
              isReadOnly={!!selectedBankAccount?.id}
              isRequired
            />
          </View>
          <View style={AppStyles.flex1} />
        </View>
        <View style={AppStyles.flexRowCenter} pointerEvents={isReadOnly ? 'none' : 'auto'}>
          <View style={AppStyles.flex1}>
            <PaymentMethodInput
              onSelect={(values: string[]) => onTextChange('paymentMethods', values)}
              values={selectedBankAccount.paymentMethods}
              isMultiSelect
            />
          </View>
          <View style={AppStyles.flex1}>
            <AccountGroupInput
              onSelect={(values: string[]) => onTextChange('accountGroups', values)}
              values={selectedBankAccount.accountGroups}
              isMultiSelect
              includeAll={false}
            />
          </View>
          <View style={AppStyles.flex1} />
        </View>
        <View style={[AppStyles.flexRow]} pointerEvents={isReadOnly ? 'none' : 'auto'}>
          <View style={AppStyles.flex1}>
            <Text style={AppStyles.textFormSectionHeader}>Bank Details</Text>
            <BankInput
              onSelect={(values: Bank[]) => onTextChange('bank', values[0].name)}
              value={selectedBankAccount.bank}
              isRequired
            />
            <CInput
              label="Bank Code - Override"
              fieldKey="BIC"
              fieldValue={selectedBankAccount.BIC}
              onTextChange={onTextChange}
            />
          </View>

          <View style={AppStyles.flex1} />
        </View>
      </View>
      {selectedBankAccount.id && (
        <ActivityHistory recordKey={selectedBankAccount.id} recordType={LogType.BankAccount} />
      )}
      <ButtonGroup
        mode={mode}
        onSave={onSave}
        isSaving={isSaving}
        canApprove={canApprove}
        setShowApprovalModal={setShowApprovalModal}
        setIsVisible={setIsVisible}
        onNext={onNext}
      />
    </>
  );
};
export default BankAccountDetails;
