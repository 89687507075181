import React, { FC, useEffect, useMemo } from 'react';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
} from '@chakra-ui/react';

import moment from 'moment';

import { Payment, PaymentStatus, PaymentTemplateStatus } from '../../commonTypes';
import { DateFormat } from '../../constants/Constants';
import AppStyles from '../../constants/Styles';
import useUsers from '../../hooks/useUsers';
import { View } from '../Themed';
import { _getTimeStampString } from '../../utils/helper';

export type PaymentTimelineProps = {
  paymentDetails: Partial<Payment>;
  onApprovalStepClicked?: () => void;
  isTemplate?: boolean;
};

const PaymentTimeline: FC<PaymentTimelineProps> = ({
  paymentDetails = { status: PaymentStatus.DRAFT } as Payment,
  onApprovalStepClicked,
  isTemplate,
}) => {
  const { _getUsersFromUid } = useUsers();

  const steps = useMemo(() => {
    const _steps = [
      {
        title: 'Create',
        description: paymentDetails?.createdBy ? _getUsersFromUid(paymentDetails?.createdBy) : '',
        timestamp: _getTimeStampString(paymentDetails?.createdAt),
        status: PaymentStatus.PENDING_APPROVAL,
      },
      {
        title: 'Approve',
        description: paymentDetails?.approvedBy?.length
          ? _getUsersFromUid(paymentDetails?.approvedBy)
          : '',
        timestamp: paymentDetails?.approvedAt
          ? _getTimeStampString(paymentDetails?.approvedAt)
          : '',
        status: isTemplate ? PaymentTemplateStatus.ACTIVE : PaymentStatus.APPROVED,
      },
    ];
    if (isTemplate) {
      if (paymentDetails?.updateRequest) {
        _steps.push({
          title: 'Update Request',
          description: paymentDetails?.updateRequest?.requestedBy
            ? _getUsersFromUid(paymentDetails?.updateRequest?.requestedBy)
            : '',
          timestamp: paymentDetails?.updateRequest?.lastUpdatedAt
            ? moment(paymentDetails?.updateRequest?.lastUpdatedAt.toDate()).format(
                DateFormat.DEFAULT,
              )
            : '',
          status: PaymentTemplateStatus.PENDING_UPDATE,
        });

        _steps.push({
          title: 'Approve Update',
          description: '',
          timestamp: '',
          status: PaymentTemplateStatus.UPDATE_APPROVED,
        });
      }
      return _steps;
    }
    if (paymentDetails?.status !== PaymentStatus.CANCELLED) {
      _steps.push(
        {
          title: 'Release',
          description: paymentDetails?.releasedBy
            ? _getUsersFromUid(paymentDetails?.releasedBy)
            : '',
          timestamp: paymentDetails?.releasedAt
            ? moment(paymentDetails?.releasedAt.toDate()).format(DateFormat.DEFAULT)
            : '',
          status: PaymentStatus.RELEASED,
        },
        {
          title: 'Sent to bank',
          description: '',
          timestamp: paymentDetails?.sentAt
            ? moment(paymentDetails?.sentAt.toDate()).format(DateFormat.DEFAULT)
            : '',
          status: PaymentStatus.SENT,
        },
      );
    }
    if (paymentDetails?.status === PaymentStatus.REJECTED) {
      _steps.push({
        title: 'Rejected',
        description: '',
        timestamp: paymentDetails?.receivedAckDtTm
          ? moment(paymentDetails?.receivedAckDtTm.toDate()).format(DateFormat.DEFAULT)
          : '',
        status: PaymentStatus.REJECTED,
      });
    } else {
      if (paymentDetails?.status === PaymentStatus.CANCELLED) {
        _steps.push({
          title: 'Cancelled',
          description: paymentDetails?.rejectedBy
            ? _getUsersFromUid(paymentDetails?.rejectedBy)
            : '',

          timestamp: paymentDetails?.rejectedAt
            ? moment(paymentDetails?.rejectedAt.toDate()).format(DateFormat.DEFAULT)
            : '',
          status: PaymentStatus.CANCELLED,
        });
      } else {
        _steps.push(
          {
            title: 'Received by Bank',
            description: '',
            status: PaymentStatus.RECEIVED,
            timestamp: paymentDetails?.receivedAckDtTm
              ? moment(paymentDetails?.receivedAckDtTm.toDate()).format(DateFormat.DEFAULT)
              : '',
          },
          {
            title: 'Complete',
            description: '',
            status: PaymentStatus.COMPLETED,
            timestamp: paymentDetails?.completedDtTm
              ? moment(paymentDetails?.completedDtTm.toDate()).format(DateFormat.DEFAULT)
              : '',
          },
        );
      }
    }
    return _steps;
  }, [paymentDetails, isTemplate]);

  const { activeStep, setActiveStep } = useSteps({
    index: steps.findIndex((x) => x.status === paymentDetails.status) + 1,
    count: steps.length,
  });

  useEffect(() => {
    if (paymentDetails.updateRequest && isTemplate) {
      setActiveStep(steps.findIndex((x) => x.status === PaymentTemplateStatus.PENDING_UPDATE) + 1);
    } else setActiveStep(steps.findIndex((x) => x.status === paymentDetails.status) + 1);
  }, [paymentDetails.status, setActiveStep, steps]);

  return (
    <View style={[AppStyles.margin, { maxWidth: isTemplate ? '75%' : '90%' }]}>
      <Stepper
        index={activeStep}
        colorScheme={
          [PaymentStatus.REJECTED, PaymentStatus.CANCELLED].includes(
            paymentDetails?.status as PaymentStatus,
          )
            ? 'red'
            : 'blue'
        }
      >
        {steps.map((step, index) => (
          <Step
            key={index}
            onClick={() => {
              if (
                ((step.status === PaymentStatus.APPROVED ||
                  step.status === PaymentTemplateStatus.ACTIVE) &&
                  paymentDetails.status !== PaymentStatus.DRAFT &&
                  !paymentDetails.updateRequest) ||
                step.status === PaymentTemplateStatus.UPDATE_APPROVED
              ) {
                onApprovalStepClicked?.();
              }
            }}
          >
            <StepIndicator>
              <StepStatus
                complete={step.status === PaymentStatus.REJECTED ? 'X' : <StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>

            <Box flexShrink="0">
              <StepTitle>{step.title}</StepTitle>
              <StepDescription>{step.description}</StepDescription>
              <StepDescription>{step.timestamp}</StepDescription>
            </Box>

            <StepSeparator />
          </Step>
        ))}
      </Stepper>
      {paymentDetails.status === PaymentStatus.REJECTED && (
        <Alert status="error" width={'auto'} mt={2}>
          <AlertIcon />
          <AlertTitle>Payment Rejected!</AlertTitle>
          <AlertDescription>{paymentDetails.bankRejectReason}</AlertDescription>
        </Alert>
      )}
    </View>
  );
};

export default PaymentTimeline;
