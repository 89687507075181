import React, { useCallback, useContext, useEffect, useState } from 'react';

import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';

import { AppContext } from '../App';
import { Bank, InputMode, StaticDataStore } from '../commonTypes';
import BankDetails from '../components/BankAccounts/BankDetails';
import Button from '../components/Button';
import { CFlatList } from '../components/CFlatList';
import { CModal } from '../components/CModal';
import { FilterBar } from '../components/FilterBar';
import StatusTag from '../components/StatusTag';
import { Card, Text, View } from '../components/Themed';
import Colors from '../constants/Colors';
import Constants from '../constants/Constants';
import AppStyles from '../constants/Styles';
import { RootTabScreenProps } from '../types';
import { _searchList } from '../utils/helper';

export default function BankScreen({ navigation }: RootTabScreenProps<'BankScreen'>) {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedBank, setSelectedBank] = useState<Bank>();
  const [banks, setBanks] = useState<Bank[]>();
  const [inputMode, setInputMode] = useState<InputMode>(InputMode.CREATE);
  const [filteredBanks, setFilteredBanks] = useState<Bank[]>();
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [isDetailVisible, setIsDetailVisible] = useState(true);

  const { db } = useContext(AppContext);

  const { profileData, staticData } = useSelector((store: StaticDataStore) => store);

  const canEdit = staticData.accessibleScreens?.BankScreen?.edit || false;

  const fetchBanks = useCallback(async () => {
    console.log('Fetching Banks');
    setIsLoading(true);
    if (!profileData?.orgId) {
      return;
    }
    const q = query(collection(db, 'Organizations', profileData?.orgId, 'Banks'), orderBy('name'));
    const querySnapshot = await getDocs(q);
    const _banks: Bank[] = [];
    querySnapshot.forEach((doc) => {
      _banks.push(doc.data() as Bank);
    });
    console.warn(_banks);
    setBanks(_banks);
    setIsLoading(false);
  }, [db, profileData?.orgId]);

  useEffect(() => {
    fetchBanks();
  }, [fetchBanks]);

  const onCreateNewBank = useCallback(() => {
    setSelectedBank({
      name: '',
    } as Bank);
    setInputMode(InputMode.CREATE);
    setIsDetailVisible(true);
  }, []);

  const onBankUpdated = useCallback(
    (bank: Bank) => {
      if (inputMode === InputMode.CREATE) {
        setBanks((existingValue) => {
          return [...(existingValue || []), bank];
        });
      } else if (selectedIndex !== undefined) {
        //Updating existing account
        const _banks = filteredBanks || banks;
        if (!_banks) {
          return;
        }
        _banks[selectedIndex] = bank;
        if (filteredBanks) {
          setFilteredBanks(_banks);
          const _AllBanks = banks;
          if (!_AllBanks) {
            return;
          }
          _AllBanks[_AllBanks.findIndex((eBank) => eBank.name === bank.name)] = bank;
          setBanks(_AllBanks);
        } else {
          setBanks(_banks);
        }
      }
    },
    [banks, filteredBanks, inputMode, selectedIndex],
  );

  const onSearchOrFilter = useCallback(
    (searchValue: string) => {
      if (searchValue && searchValue !== '') {
        setFilteredBanks(_searchList(banks, searchValue));
      } else {
        setFilteredBanks(undefined);
      }
    },
    [banks],
  );

  return (
    <View style={AppStyles.container}>
      {selectedBank && (
        <CModal
          open={isDetailVisible}
          setOpen={(value) => setIsDetailVisible(value)}
          title={selectedBank?.name || 'Bank'}
          hideButtons
        >
          <BankDetails
            setIsVisible={setIsDetailVisible}
            bank={selectedBank}
            onBankUpdated={onBankUpdated}
            mode={canEdit ? inputMode || InputMode.EDIT : InputMode.VIEW}
          />
        </CModal>
      )}
      <View style={[AppStyles.flexRowCenterSpaceBetween, AppStyles.marginTop]}>
        {staticData.currencies && staticData.currencies.length > 0 ? (
          <FilterBar onSearch={onSearchOrFilter} searchPlaceholder={'Search Name or Code'} />
        ) : (
          <View />
        )}
        <View />
        <View style={AppStyles.flexRowCenter}>
          <Button
            label="Create Bank"
            variant="Create"
            onPress={onCreateNewBank}
            hidden={!staticData.accessibleScreens?.BankScreen?.create}
          />
        </View>
      </View>
      <View style={AppStyles.width60}>
        <CFlatList
          emptyMessage={filteredBanks === undefined ? 'No Banks yet' : 'No results found'}
          emptyCTA={'Create Bank'}
          emptyAction={onCreateNewBank}
          // subAction={
          //   <Text style={AppStyles.textSubTitle}>
          //     Importing bank statements will automatically create bank accounts if they do not exist
          //   </Text>
          // }
          data={filteredBanks || banks}
          isLoading={isLoading}
          renderItem={({ item, index }: { item: Bank; index: number }) => (
            <TouchableOpacity
              onPress={() => {
                setSelectedBank(item);
                setSelectedIndex(index);
                setInputMode(InputMode.EDIT);
                setIsDetailVisible(true);
              }}
            >
              <Card style={styles.bankCard}>
                <View style={AppStyles.flexRowCenter}>
                  <View style={{ flex: 2 }}>
                    <Text style={AppStyles.textRowTitle}>{item.name}</Text>
                    <Text style={AppStyles.textSubTitle}>{item.externalName}</Text>
                  </View>

                  <View style={AppStyles.flex1}>
                    <Text style={{ color: Colors.grey }}>Relationship</Text>
                    <Text style={{ fontSize: Constants.TextSize.xl }}>{item.relationship}</Text>
                  </View>
                  {item.BIC ? (
                    <View style={AppStyles.flex1}>
                      <Text style={{ color: Colors.grey }}>BIC Code</Text>
                      <Text style={{ fontSize: Constants.TextSize.xl }}>{item.BIC}</Text>
                    </View>
                  ) : (
                    <View />
                  )}
                  <View style={AppStyles.flex1}>
                    <StatusTag label={item.status} />
                    {item.updateRequest && <StatusTag label={'Pending Updates'} isReadOnly />}
                  </View>
                </View>
              </Card>
            </TouchableOpacity>
          )}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  bankCard: {
    marginBottom: 10,
    marginHorizontal: 10,
    borderWidth: 2,
  },
});
