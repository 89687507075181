/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react-native/no-inline-styles */
/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import * as React from 'react';

import { DarkTheme, DefaultTheme, NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { ColorSchemeName } from 'react-native';
import { useSelector } from 'react-redux';

import { StaticDataStore } from '../commonTypes';
import SmartMenu from '../components/SmartMenu';
import AccountGroupsScreen from '../screens/AccountGroupsScreen';
import ApplyBankTransactionRulesScreen from '../screens/ApplyBankTransactionRulesScreen';
import ApprovalWorkflowScreen from '../screens/ApprovalWorkflowScreen';
import BankAccountScreen from '../screens/BankAccountScreen';
import BankScreen from '../screens/BankScreen';
import BankStatementScreen from '../screens/BankStatementScreen';
import BankTransactionRulesScreen from '../screens/BankTransactionRulesScreen';
import CashPositionScreen from '../screens/CashPositionScreen';
import CategoriesScreen from '../screens/CategoriesScreen';
import CountriesScreen from '../screens/CountriesScreen';
import CreatePaymentScreen from '../screens/CreatePaymentScreen';
import CurrenciesScreen from '../screens/CurrenciesScreen';
import EntityScreen from '../screens/EntityScreen';
import HolidayCalendarScreen from '../screens/HolidayCalendarScreen';
import HomeScreen from '../screens/HomeScreen';
import LoginScreen from '../screens/LoginScreen';
import ModalScreen from '../screens/ModalScreen';
import NotFoundScreen from '../screens/NotFoundScreen';
import PaymentMethodsScreen from '../screens/PaymentMethodsScreen';
import PaymentTemplatesScreen from '../screens/PaymentTemplatesScreen';
import PaymentsReleaseScreen from '../screens/PaymentsReleaseScreen';
import PaymentsScreen from '../screens/PaymentsScreen';
import SFTPConfigurationScreen from '../screens/SFTPConfigurationScreen';
import StatementImportScreen from '../screens/StatementImportScreen';
import TempUtilsScreen from '../screens/TempUtilsScreen';
import UserGroupsScreen from '../screens/UserGroupsScreen';
import UsersScreen from '../screens/UsersScreen';
import { RootStackParamList } from '../types';
import HeaderBar from './HeaderBar';
import LinkingConfiguration from './LinkingConfiguration';
import NavMenu from './NavMenu';
import PendingApprovalsScreen from '../screens/PendingApprovalsScreen';
import JobScreen from '../screens/JobScreen';
import ReportingScreen from '../screens/ReportingScreen';
import ReportingDetailsScreen from '../screens/ReportingDetailsScreen';
import LogsScreen from '../screens/LogsScreen';
import ImportDataScreen from '../screens/ImportDataScreen';

export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {
  const { profileData } = useSelector((store: StaticDataStore) => store);

  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}
    >
      <RootNavigator />
      {profileData?.uid && <NavMenu />}

      <SmartMenu />
    </NavigationContainer>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
  return (
    <Stack.Navigator
      screenOptions={{
        header: (props) => {
          return <HeaderBar title={props.options?.title} />;
        },
      }}
    >
      <Stack.Screen
        name="LoginScreen"
        component={LoginScreen}
        options={{
          title: 'Cash Xact',
        }}
      />
      <Stack.Screen
        name="LoginScreenRedirect"
        component={LoginScreen}
        options={{
          title: 'Cash Xact',
        }}
      />
      <Stack.Screen
        name="HomeScreen"
        component={HomeScreen}
        options={{
          title: undefined,
        }}
      />

      <Stack.Screen
        name="StatementImportScreen"
        component={StatementImportScreen}
        options={{
          title: 'Import Bank Statements',
        }}
      />
      <Stack.Screen
        name="ImportDataScreen"
        component={ImportDataScreen}
        options={{
          title: 'Import Data',
        }}
      />

      <Stack.Screen
        name="BankAccountScreen"
        component={BankAccountScreen}
        options={{
          title: 'Bank Accounts',
        }}
      />

      <Stack.Screen
        name="BankStatementScreen"
        component={BankStatementScreen}
        options={{
          title: 'Bank Statements',
        }}
      />
      <Stack.Screen
        name="CashPositionScreen"
        component={CashPositionScreen}
        options={{
          title: 'Cash Position',
        }}
      />
      <Stack.Screen
        name="CategoriesScreen"
        component={CategoriesScreen}
        options={{
          title: 'Transaction Categories',
        }}
      />
      <Stack.Screen
        name="AccountGroupsScreen"
        component={AccountGroupsScreen}
        options={{
          title: 'Account Groups',
        }}
      />
      <Stack.Screen
        name="BankTransactionRulesScreen"
        component={BankTransactionRulesScreen}
        options={{
          title: 'Bank Transaction Rules',
        }}
      />
      <Stack.Screen
        name="ApplyBankTransactionRulesScreen"
        component={ApplyBankTransactionRulesScreen}
        options={{
          title: 'Apply Bank Transaction Rules',
        }}
      />
      <Stack.Screen
        name="TempUtilsScreen"
        component={TempUtilsScreen}
        options={{
          title: 'Utils',
        }}
      />
      <Stack.Screen
        name="CurrenciesScreen"
        component={CurrenciesScreen}
        options={{
          title: 'Currencies',
        }}
      />
      <Stack.Screen
        name="CountriesScreen"
        component={CountriesScreen}
        options={{
          title: 'Countries',
        }}
      />
      <Stack.Screen
        name="HolidayCalendarScreen"
        component={HolidayCalendarScreen}
        options={{
          title: 'Holidays',
        }}
      />
      <Stack.Screen
        name="BankScreen"
        component={BankScreen}
        options={{
          title: 'Banks',
        }}
      />
      <Stack.Screen
        name="EntityScreen"
        component={EntityScreen}
        options={{
          title: 'Entities',
        }}
      />
      <Stack.Screen
        name="UsersScreen"
        component={UsersScreen}
        options={{
          title: 'Users',
        }}
      />
      <Stack.Screen
        name="UserGroupsScreen"
        component={UserGroupsScreen}
        options={{
          title: 'User Groups',
        }}
      />
      <Stack.Screen
        name="ApprovalWorkflowScreen"
        component={ApprovalWorkflowScreen}
        options={{
          title: 'Approval Workflow',
        }}
      />

      <Stack.Screen
        name="PaymentsScreen"
        component={PaymentsScreen}
        options={{
          title: 'Payments',
        }}
      />
      <Stack.Screen
        name="PaymentTemplatesScreen"
        component={PaymentTemplatesScreen}
        options={{
          title: 'Payment Templates',
        }}
      />
      <Stack.Screen
        name="CreatePaymentScreen"
        component={CreatePaymentScreen}
        options={({ route }) => ({
          title: route.params.isTemplate ? 'Payment Template' : 'Payment',
        })}
      />
      <Stack.Screen
        name="PaymentMethodsScreen"
        component={PaymentMethodsScreen}
        options={{
          title: 'Payment Methods',
        }}
      />
      <Stack.Screen
        name="LogsScreen"
        component={LogsScreen}
        options={{
          title: 'Audit Logs',
        }}
      />

      <Stack.Screen
        name="PaymentsReleaseScreen"
        component={PaymentsReleaseScreen}
        options={{
          title: 'Payment Release',
        }}
      />
      <Stack.Screen
        name="SFTPConfigurationScreen"
        component={SFTPConfigurationScreen}
        options={{
          title: 'SFTP Configuration',
        }}
      />
      <Stack.Screen
        name="PendingApprovalsScreen"
        component={PendingApprovalsScreen}
        options={{
          title: 'Pending Approvals',
        }}
      />
      <Stack.Screen
        name="JobScreen"
        component={JobScreen}
        options={{
          title: 'Jobs',
        }}
      />
      <Stack.Screen
        name="ReportingScreen"
        component={ReportingScreen}
        options={{
          title: 'Reporting',
        }}
      />
      <Stack.Screen
        name="ReportingDetailsScreen"
        component={ReportingDetailsScreen}
        options={{
          title: 'Reporting',
        }}
      />

      <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Oops!' }} />
      <Stack.Group screenOptions={{ presentation: 'modal' }}>
        <Stack.Screen name="Modal" component={ModalScreen} />
      </Stack.Group>
    </Stack.Navigator>
  );
}
