import React, { FC } from 'react';

import { useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';

import { StaticDataStore, Status } from '../../commonTypes';
import CardBadge from '../CardBadge';

const BankAccountsWidget: FC = () => {
  const { staticData } = useSelector((store: StaticDataStore) => store);
  const newAccounts = staticData.bankAccounts?.filter(
    (account) => account.status === Status.NEW || account.status === undefined,
  )?.length;

  const navigation = useNavigation();

  return (
    <CardBadge
      label="Bank Accounts"
      value={
        staticData.bankAccounts
          ?.filter((acc) => acc.status !== Status.DEACTIVATED)
          ?.length.toString() || '0'
      }
      additionalInfo={newAccounts ? `${newAccounts} New accounts` : undefined}
      onPress={() => {
        navigation.navigate('BankAccountScreen');
      }}
    />
  );
};

export default BankAccountsWidget;
