/* eslint-disable react-native/no-inline-styles */
import { Card, useToast } from '@chakra-ui/react';
import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import omit from 'lodash/omit';
import { Box, StatusBar } from 'native-base';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Animated, Dimensions, TouchableOpacity } from 'react-native';
import { TabView } from 'react-native-tab-view';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { FontAwesome5 } from '@expo/vector-icons';
import { differenceBy, isEmpty, union } from 'lodash';
import { AppContext } from '../../App';
import { UPDATE_USER_GROUPS } from '../../actions/ActionConstatnts';
import {
  ApprovalWorkflowSource,
  InputMode,
  LogType,
  StaticDataStore,
  Status,
  User,
  UserGroup,
} from '../../commonTypes';
import Colors from '../../constants/Colors';
import AppStyles from '../../constants/Styles';
import { _getChangedFields, _textFieldIsInvalid } from '../../utils/helper';
import ActivityHistory from '../ActivityHistory';
import ReviewAndApproveModal from '../ApprovalWorkflow/ReviewAndApproveModal';
import ButtonGroup from '../ButtonGroup';
import CCheckBox from '../CCheckBox';
import { CFlatList } from '../CFlatList';
import CInput from '../CInput';
import { Text, View } from '../Themed';
import AccountGroupSelectionList from './AccountGroupSelectionList';
import { AllPermissions, UserGroupTabs } from './Constants';

export type UserGroupDetailsProps = {
  userGroup: UserGroup;
  setIsVisible?: Dispatch<SetStateAction<boolean>>;
  onUserGroupUpdated?: (updatedUser: UserGroup) => any;
  mode?: InputMode;
  onNext?: () => void;
  onItemActioned?: () => void;
};

export default function UserGroupDetails({
  userGroup,
  setIsVisible,
  onUserGroupUpdated,
  mode = InputMode.CREATE,
  onNext,
  onItemActioned,
}: UserGroupDetailsProps) {
  const [selectedUserGroup, setSelectedUserGroup] = useState(userGroup);
  const { profileData, staticData } = useSelector((store: StaticDataStore) => store);
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [storeUsers, setStoreUsers] = useState<User[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [allPerissions] = useState(AllPermissions);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [canApprove, setCanApprove] = useState(false);
  const isWorkflowEnabled =
    staticData?.orgInfo?.Workflow?.[ApprovalWorkflowSource.USER_GROUPS]?.status ||
    staticData?.orgInfo?.Workflow?.[ApprovalWorkflowSource.SECURITY]?.status;
  const isReadOnly = [InputMode.VIEW, InputMode.APPROVE].includes(mode);
  const canEdit = staticData.accessibleScreens?.UserGroupsScreen?.edit || false;

  const { db } = useContext(AppContext);
  const toast = useToast();
  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedUserGroup(userGroup);
  }, [userGroup]);

  useEffect(() => {
    const _users: User[] = [];
    const _userGroupUsers: User[] = [];
    const q = query(
      collection(db, 'Organizations', profileData.orgId, 'Users'),
      orderBy('firstName'),
    );
    getDocs(q).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        _users.push(doc.data() as User);
        _userGroupUsers.push({
          ...doc.data(),
          selected: doc.data().userGroups?.includes(selectedUserGroup?.id || ''),
        });
      });
      setStoreUsers(_users);
      setAllUsers(_userGroupUsers);
      setIsLoading(false);
    });
  }, [selectedUserGroup?.id]);

  const updateUsersOfGroup = useCallback(async () => {
    const selectedUsersForUpdate = allUsers.filter((user) => user.selected);
    const promises = differenceBy(
      selectedUsersForUpdate,
      storeUsers.filter((user) => user.userGroups?.includes(selectedUserGroup.id || '')),
      'uid',
    ).map(async (user) => {
      if (user.uid && profileData.orgId) {
        await updateDoc(
          doc(db, 'Organizations', profileData.orgId, 'Users', user.uid),
          isWorkflowEnabled
            ? {
                updateRequest: {
                  userGroups: union(
                    storeUsers.find((_user) => _user.uid === user.uid)?.userGroups,
                    [selectedUserGroup.id],
                  ),
                },
              }
            : {
                userGroups: arrayUnion(selectedUserGroup.id),
              },
        );
      }
    });

    const promisesRemove = differenceBy(
      storeUsers.filter((user) => user.userGroups?.includes(selectedUserGroup.id || '')),
      selectedUsersForUpdate,
      'uid',
    ).map(async (user) => {
      if (user.uid && profileData.orgId) {
        await updateDoc(
          doc(db, 'Organizations', profileData.orgId, 'Users', user.uid),
          isWorkflowEnabled
            ? {
                updateRequest: {
                  userGroups: storeUsers
                    .find((_user) => _user.uid === user.uid)
                    ?.userGroups.filter((_ug) => _ug !== selectedUserGroup.id),
                },
              }
            : {
                userGroups: arrayRemove(selectedUserGroup.id),
              },
        );
      }
    });
    await Promise.all([...promises, ...promisesRemove])
      .then(() => {
        console.warn('Resolved Promises', [...promises, ...promisesRemove].length);
      })
      .catch(() => {
        setIsSaving(false);
        console.warn('Something Went Wrong!');
      });
    if ([...promises, ...promisesRemove].length > 0) {
      toast({
        title: 'Users Updated',
        description: isWorkflowEnabled
          ? 'User addition/removal from the group has been requested and is pending approval'
          : 'User added/removed from the group successfully!',
        status: isWorkflowEnabled ? 'warning' : 'success',
        duration: 5000,
        isClosable: true,
      });
    }
    setIsSaving(false);
    setIsVisible?.(false);
  }, [allUsers, selectedUserGroup, profileData.orgId, db]);

  const onSave = useCallback(async () => {
    if (isSaving || !profileData?.orgId) {
      return;
    }
    console.warn('saving');
    setIsSaving(true);
    if (
      _textFieldIsInvalid(selectedUserGroup?.name) ||
      _textFieldIsInvalid(selectedUserGroup?.description)
    ) {
      setIsSaving(false);
      return toast({
        title: 'Please enter all madatory fields',
        description: 'Please enter a valid name for the account group.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    let userGroupDoc = {
      ...omit(selectedUserGroup, ['id']),
      status: Status.ACTIVE,
    };

    if (mode === InputMode.CREATE) {
      const docSnap = await getDocs(
        query(
          collection(db, 'Organizations', profileData?.orgId, 'UserGroups'),
          where('name', '==', selectedUserGroup.name),
        ),
      );

      if (docSnap.docs.length > 0) {
        setIsSaving(false);
        return toast({
          title: 'Group name already exists',
          description: 'Please enter a different name.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
      if (isWorkflowEnabled) {
        userGroupDoc = {
          ...userGroupDoc,
          status: Status.PENDING_APPROVAL,
        };
      }
      await setDoc(
        doc(db, 'Organizations', profileData.orgId, 'UserGroups', selectedUserGroup.name),
        {
          ...userGroupDoc,
          createdBy: profileData?.uid,
          createdDtTm: serverTimestamp(),
        } as UserGroup,
      );
      updateUsersOfGroup();
      onUserGroupUpdated?.({ ...userGroupDoc, id: userGroupDoc.name } as UserGroup);
      const _userGroups = staticData.userGroups || [];
      _userGroups.push({
        ...userGroupDoc,
        createdBy: profileData?.uid,
        createdDtTm: Date.now(),
      });
      dispatch({
        payload: _userGroups,
        type: UPDATE_USER_GROUPS,
      });
      toast({
        title: 'User group created',
        description: isWorkflowEnabled
          ? 'User Group creation request has been submitted and is pending approval'
          : 'User Group Created Successfully',
        status: isWorkflowEnabled ? 'warning' : 'success',
        duration: 5000,
        isClosable: true,
      });
    } else {
      // Update
      const changedFields = _getChangedFields(userGroup, selectedUserGroup);
      if (isEmpty(changedFields)) {
        updateUsersOfGroup();
        return;
      }
      const updateRequest = {
        ...changedFields,
        requestedBy: profileData.uid,
        requestId: uuidv4(),
        lastUpdatedAt: serverTimestamp(),
      };
      if (isWorkflowEnabled) {
        userGroupDoc = {
          ...userGroup,
          updateRequest: updateRequest,
          lastUpdatedBy: profileData.uid,
        };
      }
      await updateDoc(
        doc(db, 'Organizations', profileData.orgId, 'UserGroups', selectedUserGroup.name),
        isWorkflowEnabled
          ? { updateRequest: updateRequest }
          : {
              ...omit(userGroupDoc, ['createdBy', 'createdDtTm']),
            },
      );
      console.warn('userGroup Updated');
      updateUsersOfGroup();
      if (isWorkflowEnabled) {
        userGroupDoc = { ...userGroup, updateRequest };
      }
      onUserGroupUpdated?.({ ...userGroupDoc, id: userGroupDoc.name } as UserGroup);
      const _userGroups = staticData.userGroups || [];
      _userGroups[_userGroups.findIndex((eUserGroup) => eUserGroup.name === userGroupDoc.name)] = {
        ...userGroupDoc,
        id: userGroupDoc.name,
      };
      // console.warn(_userGroups);
      dispatch({
        payload: _userGroups,
        type: UPDATE_USER_GROUPS,
      });
      toast({
        title: isWorkflowEnabled
          ? 'User group update requested. Pending approval'
          : 'User group Updated',
        status: isWorkflowEnabled ? 'warning' : 'success',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [
    isSaving,
    profileData.orgId,
    profileData?.uid,
    selectedUserGroup,
    mode,
    toast,
    db,
    setIsVisible,
    onUserGroupUpdated,
    staticData.userGroups,
    dispatch,
    updateUsersOfGroup,
    isWorkflowEnabled,
  ]);

  const onTextChange = useCallback(
    (field: keyof UserGroup, value: string) => {
      setSelectedUserGroup((existingValue) => {
        return { ...existingValue, [field]: value } as UserGroup;
      });
    },
    [setSelectedUserGroup],
  );

  const onUpdatePermissionAccess = useCallback((permission: string, access: string) => {
    setSelectedUserGroup((currentValue) => {
      const updatedGroup = {
        ...currentValue,
        permissions: {
          ...currentValue?.permissions,
          [permission]: {
            ...currentValue?.permissions?.[permission],
            [access]: !currentValue?.permissions?.[permission]?.[access],
          },
        },
      };
      if (
        access !== 'view' &&
        (updatedGroup?.permissions?.[permission]?.create ||
          updatedGroup?.permissions?.[permission]?.delete ||
          updatedGroup?.permissions?.[permission]?.edit ||
          updatedGroup?.permissions?.[permission]?.approve)
      ) {
        updatedGroup.permissions[permission].view = true;
      }
      if (!updatedGroup?.permissions?.[permission]?.view) {
        updatedGroup.permissions[permission].create = false;
        updatedGroup.permissions[permission].edit = false;
        updatedGroup.permissions[permission].delete = false;
        updatedGroup.permissions[permission].approve = false;
      }
      if (access === 'create' && updatedGroup?.permissions?.[permission]?.create) {
        updatedGroup.permissions[permission].edit = true;
      }
      if (!updatedGroup?.permissions?.[permission]?.edit) {
        updatedGroup.permissions[permission].create = false;
      }

      return updatedGroup;
    });
  }, []);

  const onSelectAllAccesses = useCallback((permission: string) => {
    setSelectedUserGroup((currentValue: any) => {
      let value = true;
      if (
        currentValue?.permissions?.[permission]?.create &&
        currentValue?.permissions?.[permission]?.delete &&
        currentValue?.permissions?.[permission]?.edit &&
        currentValue?.permissions?.[permission]?.approve
      ) {
        value = false;
      }
      return {
        ...currentValue,
        permissions: {
          ...currentValue?.permissions,
          [permission]: {
            view: value,
            create: value,
            delete: value,
            edit: value,
            approve: value,
          },
        },
      };
    });
  }, []);

  const renderTab = useCallback(
    (tabName: UserGroupTabs) => {
      return (
        <>
          <CFlatList
            isLoading={false}
            keyField="name"
            data={allPerissions.filter((item) => item.tab === tabName)}
            renderItem={({ item, index }) => (
              <View
                style={[AppStyles.flexRow, AppStyles.marginTop]}
                pointerEvents={isReadOnly ? 'none' : 'auto'}
              >
                <TouchableOpacity
                  style={{ flex: 2 }}
                  onPress={() => onSelectAllAccesses(item.name)}
                >
                  <Text>{item.display}</Text>
                </TouchableOpacity>
                <View style={AppStyles.flex1}>
                  <CCheckBox
                    style={AppStyles.flex1}
                    label="View"
                    value={selectedUserGroup?.permissions?.[item.name]?.view}
                    onChange={() => onUpdatePermissionAccess(item.name, 'view')}
                  />
                </View>
                <View style={AppStyles.flex1}>
                  <CCheckBox
                    style={AppStyles.flex1}
                    label="Create"
                    value={selectedUserGroup?.permissions?.[item.name]?.create}
                    onChange={() => onUpdatePermissionAccess(item.name, 'create')}
                  />
                </View>
                <View style={AppStyles.flex1}>
                  <CCheckBox
                    style={AppStyles.flex1}
                    label="Edit"
                    value={selectedUserGroup?.permissions?.[item.name]?.edit}
                    onChange={() => onUpdatePermissionAccess(item.name, 'edit')}
                  />
                </View>
                <View style={AppStyles.flex1}>
                  <CCheckBox
                    style={AppStyles.flex1}
                    label="Delete"
                    value={selectedUserGroup?.permissions?.[item.name]?.delete}
                    onChange={() => onUpdatePermissionAccess(item.name, 'delete')}
                  />
                </View>
                {/* <View style={AppStyles.flex1}>
                  {!item.exclude?.includes('approve') && (
                    <CCheckBox
                      style={AppStyles.flex1}
                      label="Approve"
                      value={selectedUserGroup?.permissions?.[item.name]?.approve}
                      onChange={() => onUpdatePermissionAccess(item.name, 'approve')}
                    />
                  )}
                </View> */}
              </View>
            )}
          />
        </>
      );
    },
    [
      allPerissions,
      isReadOnly,
      onSelectAllAccesses,
      onUpdatePermissionAccess,
      selectedUserGroup?.permissions,
    ],
  );

  const renderAccountGroup = useCallback(() => {
    return (
      <AccountGroupSelectionList
        record={selectedUserGroup}
        setRecord={setSelectedUserGroup}
        isReadOnly={isReadOnly}
      />
    );
  }, [isReadOnly, selectedUserGroup]);

  const renderUsers = useCallback(() => {
    return (
      <CFlatList
        style={{ width: 400 }}
        emptyMessage="No more users to add"
        keyField="uid"
        data={allUsers}
        isLoading={isLoading}
        loaderLines={1}
        renderItem={({ item, index }: { item: User; index: number }) => (
          <TouchableOpacity
            style={{ marginTop: 5, maxWidth: 400 }}
            onPress={() => {
              setAllUsers((currentValue) =>
                currentValue.map((val, ind) =>
                  ind === index ? Object.assign(val, { selected: !val.selected }) : val,
                ),
              );
            }}
          >
            <Card key={item.uid}>
              <View style={[AppStyles.flexRowCenterSpaceBetween, AppStyles.padding]}>
                <Text>{item.firstName}</Text>
                <View style={AppStyles.flexRowCenter}>
                  <Text style={{ marginRight: 5 }}>{item.lastName}</Text>
                  {item.selected ? (
                    <FontAwesome5 name={'check'} size={12} color={Colors.primary} />
                  ) : (
                    <View style={{ width: 12 }} />
                  )}
                </View>
              </View>
            </Card>
          </TouchableOpacity>
        )}
      />
    );
  }, [allUsers, isLoading]);

  const initialLayout = {
    width: Dimensions.get('window').width,
  };
  const renderScene = ({ route, jumpTo }) => {
    switch (route.key) {
      case UserGroupTabs.ACCOUNT_GROUP:
        return renderAccountGroup();
      case UserGroupTabs.USERS:
        return renderUsers();
      default:
        return renderTab(route.key);
    }
  };

  const [index, setIndex] = React.useState(0);
  const [routes, setRoutes] = React.useState([
    {
      key: UserGroupTabs.USERS,
      title: 'Users',
    },
    {
      key: UserGroupTabs.STATIC_DATA,
      title: 'Static Data',
    },
    {
      key: UserGroupTabs.TRANSACTION,
      title: 'Transaction',
    },
    {
      key: UserGroupTabs.PROCESSING,
      title: 'Processing',
    },
    {
      key: UserGroupTabs.CONFIGURATION,
      title: 'Configuration',
    },
    {
      key: UserGroupTabs.ACCOUNT_GROUP,
      title: 'Account Group Mapping',
    },
  ]);

  useEffect(() => {
    if (selectedUserGroup?.name === 'Admin') {
      setRoutes([
        {
          key: UserGroupTabs.USERS,
          title: 'Users',
        },
      ]);
    }
    if (mode === InputMode.APPROVE) {
      setRoutes((cv) => cv.filter((r) => r.key !== UserGroupTabs.USERS));
    }
  }, [selectedUserGroup?.name]);

  const renderTabBar = (props: any) => {
    return (
      <Box pointerEvents="auto" flexDirection="row">
        {props.navigationState.routes.map((route, i) => {
          const color = index === i ? '#000' : '#1f2937';
          const borderColor = index === i ? 'cyan.500' : 'coolGray.200';
          return (
            <Box
              key={route.key}
              borderBottomWidth="3"
              borderColor={borderColor}
              flex={1}
              alignItems="center"
              p="0"
            >
              <TouchableOpacity
                onPress={() => {
                  console.log(i);
                  setIndex(i);
                }}
                style={{
                  alignSelf: 'stretch',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: 10,
                }}
              >
                <Animated.Text
                  style={{
                    color,
                  }}
                >
                  {route.title}
                </Animated.Text>
              </TouchableOpacity>
            </Box>
          );
        })}
      </Box>
    );
  };

  if (!userGroup) {
    return null;
  }

  return (
    <>
      {isWorkflowEnabled && (
        <ReviewAndApproveModal
          onClose={() => {
            setShowApprovalModal(false);
          }}
          pendingApprovalItem={selectedUserGroup}
          source={ApprovalWorkflowSource.USER_GROUPS}
          id={selectedUserGroup?.name}
          onApprovalDataLoaded={(approvalData) => {
            setCanApprove(approvalData.canApprove);
          }}
          onApprovalOrRejectonCompleted={(approved) => {
            onUserGroupUpdated?.(
              omit(
                {
                  ...selectedUserGroup,
                  status: approved
                    ? Status.ACTIVE
                    : selectedUserGroup?.updateRequest
                    ? selectedUserGroup.status
                    : Status.DEACTIVATED,
                },
                ['updateRequest'],
              ),
            );
            setIsVisible?.(false);
          }}
          onSomeActionDone={onItemActioned}
          show={showApprovalModal}
        />
      )}
      <View pointerEvents={canEdit && !isReadOnly ? 'auto' : 'none'}>
        <View style={AppStyles.flexRowCenter}>
          <View style={AppStyles.flex1}>
            <CInput
              tooltip={
                selectedUserGroup.name === 'Admin'
                  ? 'Admin group cannot be edited. You can only add or remove users from this group'
                  : undefined
              }
              label="Group Name"
              fieldKey="name"
              fieldValue={selectedUserGroup.name}
              onTextChange={onTextChange}
              isRequired
              disabled={mode === InputMode.EDIT || isReadOnly}
            />
          </View>
          <View style={AppStyles.flex1}>
            <CInput
              label="Description"
              fieldKey="description"
              fieldValue={selectedUserGroup.description}
              onTextChange={onTextChange}
              isRequired
              width={500}
              disabled={isReadOnly || selectedUserGroup?.name === 'Admin'}
            />
          </View>
        </View>
        <TabView
          navigationState={{
            index,
            routes,
          }}
          renderScene={renderScene}
          renderTabBar={renderTabBar}
          onIndexChange={setIndex}
          initialLayout={initialLayout}
          style={{
            marginTop: StatusBar.currentHeight,
          }}
        />
      </View>
      {mode !== InputMode.CREATE && (
        <ActivityHistory recordKey={selectedUserGroup.name} recordType={LogType.UserGroup} />
      )}
      <ButtonGroup
        mode={mode}
        onSave={onSave}
        isSaving={isSaving}
        canApprove={canApprove}
        setShowApprovalModal={setShowApprovalModal}
        setIsVisible={setIsVisible}
        onNext={onNext}
      />
    </>
  );
}
