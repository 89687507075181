/* eslint-disable react/jsx-max-depth */
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { MaterialCommunityIcons } from '@expo/vector-icons';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ScrollView, Switch, useToast } from 'native-base';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@chakra-ui/react';
import { AppContext } from '../App';
import { UPDATE_ORG_INFO } from '../actions/ActionConstatnts';
import {
  ApprovalWorkflow,
  ApprovalWorkflowSource,
  ApprovalWorkflowStep,
  Operator,
  StaticDataStore,
  Status,
} from '../commonTypes';
import DefaultStepItem from '../components/ApprovalWorkflow/DefaultStepItem';
import PaymentStepItem from '../components/ApprovalWorkflow/PaymentStepItem';
import { CFlatList } from '../components/CFlatList';
import StatusTag from '../components/StatusTag';
import { CTAFilled, Card, Text, ToastAlert, View } from '../components/Themed';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import AppStyles from '../constants/Styles';
import { RootTabScreenProps } from '../types';

export default function ApprovalWorkflowScreen({
  navigation,
}: RootTabScreenProps<'ApprovalWorkflowScreen'>) {
  const [isSaving, setIsSaving] = useState(false);
  const dispatch = useDispatch();
  const [selectedWolkflow, setSelectedWorkflow] = useState<ApprovalWorkflow>();
  const [workflowList, setWorkflowList] = useState<ApprovalWorkflow[]>([
    {
      source: ApprovalWorkflowSource.STATIC_DATA,
      status: false,
      icon: 'database-check',
      description:
        'Applies to Bank Accounts, Banks & Entities. Individual setup will override this rule',
    } as ApprovalWorkflow,
    {
      source: ApprovalWorkflowSource.BANK_ACCOUNTS,
      status: false,
      icon: 'bank-plus',
      parent: ApprovalWorkflowSource.STATIC_DATA,
    } as ApprovalWorkflow,
    {
      source: ApprovalWorkflowSource.BANKS,
      status: false,
      icon: 'bank',
      parent: ApprovalWorkflowSource.STATIC_DATA,
    } as ApprovalWorkflow,
    {
      source: ApprovalWorkflowSource.ENTITIES,
      status: false,
      icon: 'home-city',
      parent: ApprovalWorkflowSource.STATIC_DATA,
    } as ApprovalWorkflow,
    {
      source: ApprovalWorkflowSource.PAYMENTS,
      status: false,
      icon: 'bank-transfer-out',
      description: 'Applies to Payment Templates too',
    } as ApprovalWorkflow,
    {
      source: ApprovalWorkflowSource.SECURITY,
      status: false,
      icon: 'security',
      description:
        'Applies to Users, User Groups, Account Groups. Individual setup will override this rule',
    } as ApprovalWorkflow,
    {
      source: ApprovalWorkflowSource.USERS,
      status: false,
      icon: 'account',
      parent: ApprovalWorkflowSource.SECURITY,
    } as ApprovalWorkflow,
    {
      source: ApprovalWorkflowSource.USER_GROUPS,
      status: false,
      icon: 'account-group',
      parent: ApprovalWorkflowSource.SECURITY,
    } as ApprovalWorkflow,
    {
      source: ApprovalWorkflowSource.ACCOUNT_GROUPS,
      status: false,
      icon: 'hexagon-multiple-outline',
      parent: ApprovalWorkflowSource.SECURITY,
    } as ApprovalWorkflow,

    {
      source: ApprovalWorkflowSource.CONFIGURATION,
      status: false,
      icon: 'cogs',
      description: 'Applies to all items under configuration menu',
    } as ApprovalWorkflow,
  ]);

  const { db } = useContext(AppContext);
  const toast = useToast();

  const { profileData, staticData } = useSelector((store: StaticDataStore) => store);

  const fetchOrgInfo = useCallback(async () => {
    await getDoc(doc(db, 'Organizations', profileData.orgId)).then((orgDoc) => {
      if (orgDoc.exists()) {
        dispatch({
          payload: orgDoc.data(),
          type: UPDATE_ORG_INFO,
        });
      }
    });
  }, [db, dispatch, profileData.orgId]);

  useEffect(() => {
    if (staticData?.orgInfo?.Workflow) {
      setWorkflowList((existingValue) => {
        return existingValue.map((workflow) => {
          const existingWorkflow = staticData?.orgInfo?.Workflow?.[workflow.source];
          if (existingWorkflow) {
            return existingWorkflow;
          }
          return workflow;
        });
      });
    }
  }, [staticData?.orgInfo?.Workflow]);

  const onSave = useCallback(async () => {
    console.warn(selectedWolkflow);
    let errorExists = false;
    setIsSaving(true);
    if (
      !selectedWolkflow?.source ||
      (selectedWolkflow.status && (selectedWolkflow?.steps?.length || 0) < 1)
    ) {
      setIsSaving(false);
      errorExists = true;
      return toast.show({
        render: ({ id }) => {
          return (
            <ToastAlert
              id={id}
              title="Invalid Workflow"
              description="Please select a valid workflow"
              variant="solid"
              status="error"
            />
          );
        },
      });
    }
    selectedWolkflow.steps?.forEach((step, index) => {
      step.criteria.forEach((criteria, criteriaIndex) => {
        if ((index > 0 && !criteria.values?.length) || !criteria.field || !criteria.operator) {
          setIsSaving(false);
          errorExists = true;
          return toast.show({
            render: ({ id }) => {
              return (
                <ToastAlert
                  id={id}
                  title="Invalid Level Criteria"
                  description={`Please select a valid criteria for Rule ${index} - Criteria ${
                    criteriaIndex + 1
                  }`}
                  variant="solid"
                  status="error"
                />
              );
            },
          });
        }
      });
      if (!step.approverUserGroups?.length) {
        setIsSaving(false);
        errorExists = true;
        return toast.show({
          render: ({ id }) => {
            return (
              <ToastAlert
                id={id}
                title="Invalid Level Approvers"
                description={`Please select a valid set of approver user groups for ${
                  index ? `Level ${index}` : 'Fallback Rule'
                }`}
                variant="solid"
                status="error"
              />
            );
          },
        });
      }
    });
    if (errorExists) {
      return;
    }

    await updateDoc(doc(db, 'Organizations', profileData.orgId), {
      [`Workflow.${selectedWolkflow.source}`]: selectedWolkflow,
    })
      .catch((e) => {
        console.warn(e);
      })
      .then(async () => {
        await fetchOrgInfo();
      });
    setIsSaving(false);
    setSelectedWorkflow(undefined);
    const _workflowList = workflowList.map((workflow) => {
      if (workflow.source === selectedWolkflow.source) {
        return selectedWolkflow;
      }
      return workflow;
    });

    setWorkflowList(_workflowList);
    return toast.show({
      render: ({ id }) => {
        return (
          <ToastAlert
            id={id}
            title="Workflow Updated"
            description={`Your changes have been saved`}
            variant="solid"
            status="success"
          />
        );
      },
    });
  }, [db, dispatch, profileData.orgId, selectedWolkflow, staticData?.orgInfo, toast, workflowList]);

  const renderStepItem = useCallback(
    (step: ApprovalWorkflowStep, index: number) => {
      switch (selectedWolkflow?.source) {
        case ApprovalWorkflowSource.PAYMENTS:
          return (
            <PaymentStepItem
              step={step}
              index={index}
              setSelectedWorkflow={setSelectedWorkflow}
              key={index.toString()}
            />
          );
        default:
          return (
            <DefaultStepItem
              step={step}
              index={index}
              setSelectedWorkflow={setSelectedWorkflow}
              key={index.toString()}
            />
          );
      }
    },
    [selectedWolkflow?.source],
  );

  const getStepCriteria = useCallback(() => {
    const criteria = [{ field: 'accountGroups', values: ['All'], operator: Operator.IN }];
    if (selectedWolkflow?.source === ApprovalWorkflowSource.PAYMENTS) {
      criteria[0].field = 'fromAccountDetails.accountGroups';
      criteria.push({
        field: 'paymentAmount',
        values: ['0', '999999999999999'],
        operator: Operator.BETWEEN,
      });
      criteria.push({ field: 'template', values: [false], operator: Operator.EXISTS });
    }
    return criteria;
  }, [selectedWolkflow?.source]);

  return (
    <View
      style={[AppStyles.container, AppStyles.paddingTop, { height: Layout.window.height - 100 }]}
    >
      {selectedWolkflow ? (
        <View style={AppStyles.marginHorizontal}>
          <View style={AppStyles.flexRowCenterSpaceBetween}>
            <View style={AppStyles.flexRowCenter}>
              <Text style={AppStyles.textTitle}>{selectedWolkflow.source}</Text>
              <Switch
                onTrackColor={Colors.excel_green}
                offTrackColor={Colors.red}
                value={selectedWolkflow.status}
                style={[AppStyles.marginLeft]}
                onValueChange={(value) => {
                  setSelectedWorkflow((currentValue) => {
                    console.warn(value);
                    return {
                      ...currentValue,
                      status: value,
                    };
                  });
                }}
              />
            </View>
            <View style={AppStyles.flexRowCenter}>
              <Button
                onClick={async () => {
                  setSelectedWorkflow(undefined);
                  await fetchOrgInfo();
                }}
                isDisabled={isSaving}
                mr={2}
                variant={'outline'}
              >
                Cancel
              </Button>
              <Button onClick={onSave} isLoading={isSaving} colorScheme="blue">
                Save Changes
              </Button>
            </View>
          </View>
          <ScrollView
            contentContainerStyle={{ height: Layout.window.height - 100, paddingVertical: 10 }}
          >
            {selectedWolkflow.steps?.map((step, index) => {
              if (index === 0) {
                return null;
              }
              return renderStepItem(step, index);
            })}
            <CTAFilled
              label="Add Step"
              style={AppStyles.marginVertical}
              icon={'plus'}
              buttonColor={Colors.darkOrange}
              onPress={() => {
                const criteria = getStepCriteria();
                setSelectedWorkflow((currentValue) => {
                  const steps = currentValue.steps || [];
                  steps.push({
                    criteria: criteria,
                    level: steps.length,
                    allOrAny: 'Any',
                    approverUserGroups: [],
                  });
                  return { ...currentValue, steps: steps };
                });
              }}
            />
            {selectedWolkflow.steps?.[0] && renderStepItem(selectedWolkflow.steps?.[0], 0)}
            <View style={{ height: 20 }} />
          </ScrollView>
        </View>
      ) : (
        <View style={AppStyles.flex1}>
          <CFlatList
            numColumns={5}
            data={workflowList}
            renderItem={({ item }) => (
              <TouchableOpacity
                onPress={() => {
                  if (item.steps?.length) {
                    setSelectedWorkflow(item);
                  } else {
                    const criteria = getStepCriteria();
                    setSelectedWorkflow({
                      ...item,
                      steps: [
                        {
                          criteria: criteria,
                          level: 0,
                          allOrAny: 'Any',
                          approverUserGroups: [],
                        },
                      ],
                    });
                  }
                }}
              >
                <Card
                  // eslint-disable-next-line react-native/no-inline-styles
                  style={{
                    width: Layout.window.width / 6,
                    height: Layout.window.width / 6,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginVertical: 5,
                  }}
                >
                  <View style={AppStyles.flexCenter}>
                    <MaterialCommunityIcons name={item.icon} size={80} color={Colors.secondary} />
                    <Text style={AppStyles.textTitle}>{item.source}</Text>
                    <Text
                      style={[AppStyles.textSubTitle, AppStyles.marginTop, { textAlign: 'center' }]}
                    >
                      {item.description}
                    </Text>
                  </View>
                </Card>
                {item.parent &&
                (item.status === Status.DEACTIVATED || !item.status) &&
                workflowList?.find((wf) => wf.source === item.parent)?.status ? (
                  <StatusTag label={'Inherited'} isReadOnly style={styles.statusTag} />
                ) : (
                  <StatusTag
                    label={item.status ? Status.ACTIVE : Status.DEACTIVATED}
                    isReadOnly
                    style={styles.statusTag}
                  />
                )}
              </TouchableOpacity>
            )}
            isLoading={false}
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  statusTag: { position: 'absolute', right: 20, top: 20 },
});
