/* eslint-disable prettier/prettier */
import { StyleSheet } from 'react-native';

import Colors from './Colors';
import Constants from './Constants';
import Layout from './Layout';

const AppStyles = StyleSheet.create({
    flex1: { flex: 1 },
    shrink: { flexShrink: 1 },
    flexGrow: { flexGrow: 1 },
    flexRow: { flexDirection: 'row' },
    flexSpaceBetween: { justifyContent: 'space-between' },
    flexRowCenter: { flexDirection: 'row', alignItems: 'center' },
    alignCenter: { alignItems: 'center' },
    alignFlexEnd: { alignItems: 'flex-end' },
    defaultWidthSmall: { width: 300 },
    flexRowCenterSpaceBetween: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    flexRowCenterSpaceAround: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    rowReverse: { flexDirection: 'row-reverse' },
    container: {
        flex: 1,
        backgroundColor: Colors.white,
        paddingBottom: 50,
        marginLeft: 60,
    },
    marginHorizontal: { marginHorizontal: 10 },
    marginVertical: { marginVertical: 10 },
    marginLeft: { marginLeft: 10 },
    marginLeft25: { marginLeft: 25 },
    marginRight: { marginRight: 10 },
    marginRight25: { marginRight: 25 },
    marginTop: { marginTop: 10 },
    margin: { margin: 10 },
    paddingTop: { paddingTop: 10 },
    marginTop25: { marginTop: 25 },
    padding: { padding: 10 },
    paddingRight: { paddingRight: 10 },
    paddingLeft: { paddingLeft: 10 },
    padding20: { padding: 20 },
    paddingBottom: { paddingBottom: 10 },
    marginBottom: { marginBottom: 10 },
    flexCenter: { flex: 1, alignItems: 'center', justifyContent: 'center' },
    width60: {
        flex: 1,
        width: Layout.window.width * 0.6,
    },
    width60Center: {
        flex: 1,
        width: Layout.window.width * 0.6,
        alignItems: 'center',
        justifyContent: 'center',
    },
    textTitle: {
        fontSize: Constants.TextSize.max,
        color: Colors.primary,
    },
    textRowTitle: {
        fontSize: Constants.TextSize.xxl,
        color: Colors.primary,
    },
    textFormSectionHeader: {
        marginTop: 15,
        fontSize: Constants.TextSize.xxl,
        color: Colors.primary,
    },
    textSubTitle: {
        fontSize: Constants.TextSize.xl,
        color: Colors.grey,
    },
    textTiny: {
        fontSize: Constants.TextSize.tiny,
        color: Colors.grey,
    },
    textAlert: {
        fontSize: Constants.TextSize.small,
        color: Colors.darkOrange,
    },
    textInputLabel: {
        fontSize: Constants.TextSize.large,
        color: Colors.midGrey,
        fontWeight: "500",
    },
    textError: {
        fontSize: Constants.TextSize.xl,
        color: Colors.black,
        marginTop: 10,
    },
    textPrimary: {
        fontSize: Constants.TextSize.regular,
        color: Colors.primary,
    },
    textSecondary: {
        fontSize: Constants.TextSize.regular,
        color: Colors.secondary,
        fontWeight: 'bold',
    },
    shadowContainer: {
        borderRadius: 10,
        margin: 10,
        alignSelf: 'flex-end',
        shadowColor: Colors.secondary,
        shadowRadius: 5,
        shadowOpacity: 0.5,
        shadowOffset: { width: -2, height: 4 },
        elevation: 5,
        alignItems: 'center',
        justifyContent: 'center',
    },
    borderRadius: {
        borderRadius: 10,
    },
    borderRadius5: {
        borderRadius: 5,
    },
    floatingBottonRight: {
        position: 'absolute',
        bottom: 20,
        right: 20,
    },
    card: {
        marginBottom: 10,
        marginHorizontal: 20,
        flex: 1,
        padding: 0,
      },
});

export default AppStyles;
