import React, { FC, useCallback } from 'react';

import { useSelector } from 'react-redux';

import { Select } from '@chakra-ui/react';
import { debounce } from 'lodash';
import { StaticDataStore } from '../comonTypes';
import CInput from './CInput';

export type AmountInputProps = {
  title?: string;
  onCurrencyChange: (values: string) => void;
  onAmountChange?: (value: number) => void;
  currencyValue?: string;
  amountValue?: number;
  isReadOnly?: boolean;
  isRequired?: boolean;
  errors?: any;
  fieldKey?: string;
  setErrors?: (value: any) => void;
};
const AmountInput: FC<AmountInputProps> = ({
  onCurrencyChange,
  currencyValue,
  onAmountChange,
  amountValue,
  isRequired,
  isReadOnly = false,
  title,
  errors = {},
  setErrors,
  fieldKey,
}) => {
  const { staticData } = useSelector((store: StaticDataStore) => store);

  const formatAmount = useCallback((value: string) => {
    return onAmountChange?.(
      parseFloat(
        parseFloat(value.replaceAll(',', '')).toLocaleString('en-US') +
          (value.substring(value.length - 1) === '.' ? '.' : ''),
      ),
    );
  }, []);

  const delayedQuery = useCallback(
    debounce((rawValue: string) => {
      formatAmount(
        rawValue
          .toLocaleLowerCase()
          .replace('t', '000')
          .replace('k', '000')
          .replace('m', '000000')
          .replace('b', '000000000'),
      );
    }, 100),
    [],
  );

  return (
    <CInput
      label="Amount"
      fieldKey={fieldKey || 'amount'}
      fieldValue={amountValue?.toString()}
      isRequired={isRequired}
      isReadOnly={isReadOnly}
      errors={errors}
      setErrors={setErrors}
      onTextChange={(field, value) => {
        console.warn(/[0-9,.]$/.test(value));
        if (value === '') {
          return onAmountChange?.(value);
        }
        if (/[0-9,.]$/.test(value)) {
          return formatAmount?.(value);
        }
        return delayedQuery(value);
      }}
      leftElementProps={{ width: '6em' }}
      inputProps={{ pl: '6.5em' }}
      leftElement={
        <Select
          variant={'filled'}
          // bg="darkgrey"
          // borderColor={'darkgrey'}
          isReadOnly={isReadOnly}
          isDisabled={isReadOnly}
          onChange={(e) => {
            onCurrencyChange?.(e.target.value);
          }}
          value={currencyValue}
          name="Currency"
          placeholder="CCY"
        >
          {staticData.currencies?.map((ccy) => {
            return (
              <option key={ccy.iso.toString()} value={ccy.iso.toString()}>
                {ccy.iso}
              </option>
            );
          })}
        </Select>
      }
    />
  );
};

export default AmountInput;
