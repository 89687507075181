/* eslint-disable react-native/no-inline-styles */
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { Ionicons, MaterialIcons } from '@expo/vector-icons';
import debounce from 'lodash/debounce';
import { Icon, Input, Popover, useDisclose } from 'native-base';
import { InterfacePopoverProps } from 'native-base/lib/typescript/components/composites/Popover/types';
import { FlatList, StyleProp, TouchableOpacity, ViewStyle } from 'react-native';

import { Card, Text, View } from './Themed';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import AppStyles from '../constants/Styles';
import { _addAlpha, _searchList } from '../utils/helper';

export type PopOverFilterItemProps<T> = {
  title: string;
  data: T[];
  onSelect: Dispatch<SetStateAction<T | undefined>>;
  selectedValue?: T;
  label?: string;
  style?: StyleProp<ViewStyle>;
};
export function PopOverFilterItem({
  data,
  title,
  onSelect,
  selectedValue,
  label = 'label',
  style,
}: PopOverFilterItemProps<any>) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState<(typeof data)[]>();

  const onSearchOrFilter = useCallback(
    (searchValue: string) => {
      if (searchValue && searchValue !== '') {
        setFilteredData(_searchList(data, searchValue));
      } else {
        setFilteredData(undefined);
      }
    },
    [data],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedQuery = useCallback(
    debounce((q) => onSearchOrFilter(q), 500),
    [onSearchOrFilter],
  );
  const onChange = (value: string) => {
    setSearchText(value);
    delayedQuery(value);
    console.warn('onChange');
  };

  const renderTrigger = useCallback(
    (triggerProps: InterfacePopoverProps) => {
      return (
        <TouchableOpacity
          style={[
            {
              marginTop: 20,
              flexDirection: 'row',
              borderColor: Colors.grey,
              borderWidth: 1,
              borderRadius: 10,
              padding: 5,
            },
            style,
            {
              borderColor: selectedValue ? _addAlpha(Colors.primary, 0.1) : Colors.light_grey,
              backgroundColor: selectedValue ? _addAlpha(Colors.primary, 0.1) : undefined,
            },
          ]}
          {...triggerProps}
          onPress={() => setIsOpen(true)}
        >
          {selectedValue ? (
            <Text style={{ color: Colors.grey }}>
              {title + ': '}
              <Text style={AppStyles.textSecondary}>{selectedValue[label]}</Text>{' '}
            </Text>
          ) : (
            <Text>{title}</Text>
          )}
          {selectedValue ? (
            <TouchableOpacity onPress={() => onSelect(null)}>
              <Icon as={<MaterialIcons name="close" />} size={5} ml="2" />
            </TouchableOpacity>
          ) : (
            <Icon as={<MaterialIcons name="arrow-drop-down" />} size={5} ml="2" />
          )}
        </TouchableOpacity>
      );
    },
    [label, onSelect, selectedValue, style, title],
  );

  return (
    <Popover isOpen={isOpen} trigger={renderTrigger} onClose={() => setIsOpen(!isOpen)} key={title}>
      <Popover.Content w="l">
        <Popover.Arrow />
        <Popover.Header>
          {data?.length > 0 ? (
            <Input
              InputLeftElement={<Icon as={<MaterialIcons name="search" />} size={5} ml="2" />}
              InputRightElement={
                searchText ? (
                  <Icon
                    as={<Ionicons name="close-circle" />}
                    size={5}
                    ml="4"
                    color="muted.400"
                    style={{ marginRight: 10 }}
                    onPress={() => onChange('')}
                  />
                ) : undefined
              }
              value={searchText}
              size="md"
              variant="rounded"
              placeholder={'Search here'}
              w="l"
              onChangeText={onChange}
            />
          ) : (
            <Text>No option available</Text>
          )}
        </Popover.Header>
        <Popover.Body>
          <FlatList
            style={{ maxHeight: Layout.window.height / 2 }}
            data={filteredData || data}
            renderItem={({ item }) => {
              return (
                <TouchableOpacity
                  onPress={() => {
                    onSelect(item);
                    setIsOpen(false);
                  }}
                >
                  <Card style={{ marginHorizontal: 0 }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                      <Text>{item.accountNumber}</Text>
                      <Text>{item.currency}</Text>
                    </View>
                    <Text style={AppStyles.textSubTitle}>{item.accountName}</Text>
                  </Card>
                </TouchableOpacity>
              );
            }}
          />
        </Popover.Body>
      </Popover.Content>
    </Popover>
  );
}
