/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useContext, useEffect, useState } from 'react';

import {
  collection,
  doc,
  DocumentData,
  getDocs,
  limit,
  orderBy,
  query,
  QueryDocumentSnapshot,
  startAfter,
  updateDoc,
  where,
} from 'firebase/firestore';
import isEmpty from 'lodash/isEmpty';
import { FormControl, Input, Skeleton, useToast, VStack } from 'native-base';
import { FlatList, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';

import { AppContext } from '../App';
import { BankAccount, StatementTransaction, StaticDataStore } from '../commonTypes';
import { FilterBar } from '../components/FilterBar';
import { FlyOut } from '../components/FlyOut';
import { PopOverFilterItem } from '../components/PopOverFilterItem';
import { StatementBalanceWidget } from '../components/StatementBalanceWidget';
import { StatementTransactionItem } from '../components/StatementTransactionItem';
import { AmountText } from '../components/StyledText';
import { Text, View } from '../components/Themed';
import BankStatementWidget from '../components/Widgets/BankStatementWidget';
import Colors from '../constants/Colors';
import Constants from '../constants/Constants';
import Layout from '../constants/Layout';
import AppStyles from '../constants/Styles';
import { RootTabScreenProps } from '../types';
import { _filterToWhereClause, _isValidDate, _searchList } from '../utils/helper';

export default function BankStatementScreen({
  navigation,
}: RootTabScreenProps<'BankStatementScreen'>) {
  const { profileData, staticData } = useSelector((store: StaticDataStore) => store);

  const [bankStatements, setbankStatements] = useState<StatementTransaction[]>([]);
  const [bankBalance, setBankBalance] = useState<StatementTransaction>();
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState({});
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot<DocumentData> | null>();
  const [selectedBankStatement, setSelectedBankStatement] = useState<StatementTransaction>();
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [open, setOpen] = useState(false);
  const [bankAccount, setBankAccount] = useState<BankAccount>();
  // /{ id: '000570303ZAR' }

  const [filteredbankStatements, setFilteredbankStatements] = useState<
    StatementTransaction[] | undefined
  >();
  const { db } = useContext(AppContext);
  const toast = useToast();

  const fetchBankStatements = useCallback(
    async (account: BankAccount, filterValues: any) => {
      console.log('Fetching bank statements');
      console.warn(filterValues.valueDate, _isValidDate(filterValues.valueDate));

      const constraints = _filterToWhereClause(filterValues);
      const q = query(
        collection(db, 'Organizations', profileData.orgId, 'BankStatement'),
        where('accountId', '==', account?.id),
        where('isBalance', '==', false),
        ...constraints,
        limit(10),
      );

      const querySnapshot = await getDocs(q);
      const _bankStatements: StatementTransaction[] = [];
      querySnapshot.forEach((doc) => {
        _bankStatements.push({ ...doc.data(), id: doc.id } as StatementTransaction);
      });
      setbankStatements(_bankStatements);
      if (querySnapshot.empty) {
        setLastVisible(null);
      } else {
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      }

      setIsLoading(false);
    },
    [db, profileData?.orgId],
  );

  const loadMoreBankStatements = useCallback(
    async (account?: BankAccount, filterValues?: any) => {
      if (account && lastVisible && !isLoadingMore) {
        console.log('Fetching more statements');
        setIsLoadingMore(true);
        const constraints = _filterToWhereClause(filterValues);
        const q = query(
          collection(db, 'Organizations', profileData.orgId, 'BankStatement'),
          where('accountId', '==', account?.id),
          where('isBalance', '==', false),
          ...constraints,
          startAfter(lastVisible),
          limit(10),
        );
        const querySnapshot = await getDocs(q);
        const _bankStatements: StatementTransaction[] = [];
        querySnapshot.forEach((doc) => {
          _bankStatements.push({ ...doc.data(), id: doc.id } as StatementTransaction);
        });
        if (querySnapshot.empty) {
          setLastVisible(null);
        } else {
          setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        }
        setbankStatements((prevValue) => [...prevValue, ..._bankStatements]);
        setIsLoadingMore(false);
      }
    },
    [db, isLoadingMore, lastVisible, profileData.orgId],
  );

  const fetchBankBalance = useCallback(
    async (account: BankAccount) => {
      console.log('Fetching bank statements');
      const q = query(
        collection(db, 'Organizations', profileData.orgId, 'BankStatement'),
        where('accountId', '==', account?.id),
        where('isBalance', '==', true),
        orderBy('valueDate', 'desc'),
        limit(1),
      );
      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        setBankBalance(undefined);
      } else {
        setBankBalance({
          ...querySnapshot.docs[0].data(),
          id: querySnapshot.docs[0].id,
        } as StatementTransaction);
      }
    },
    [db, profileData?.orgId],
  );

  useEffect(() => {
    if (bankAccount) {
      setIsLoading(true);
      setLastVisible(null);
      fetchBankStatements(bankAccount, filter);
      fetchBankBalance(bankAccount);
    } else {
      setLastVisible(null);
      setBankBalance(undefined);
      setbankStatements([]);
    }
  }, [bankAccount, fetchBankBalance, fetchBankStatements, filter]);

  const ContentLoader = useCallback(() => {
    return (
      <VStack
        w="99%"
        height={100}
        marginTop={2}
        marginLeft={2}
        padding={5}
        borderWidth="1"
        space={8}
        overflow="hidden"
        rounded="md"
        _dark={{
          borderColor: 'coolGray.500',
        }}
        _light={{
          borderColor: 'coolGray.200',
        }}
      >
        <Skeleton.Text px="4" />
      </VStack>
    );
  }, []);
  const onSave = useCallback(async () => {
    console.warn(selectedIndex);
    if (selectedIndex !== undefined && selectedBankStatement?.id) {
      await updateDoc(
        doc(db, 'Organizations', profileData.orgId, 'BankStatements', selectedBankStatement.id),
        selectedBankStatement,
      ).catch((e) => {
        console.warn(e);
      });
      const _bankStatements = filteredbankStatements || bankStatements;
      _bankStatements[selectedIndex] = selectedBankStatement;
      if (filteredbankStatements) {
        setFilteredbankStatements(_bankStatements);
        const _AllAccounts = bankStatements;
        _AllAccounts[_AllAccounts.findIndex((bankAcc) => bankAcc.id === selectedBankStatement.id)] =
          selectedBankStatement;
        setbankStatements(_AllAccounts);
      } else {
        setbankStatements(_bankStatements);
      }
      setOpen(false);
    }

    toast.show({
      description: 'Account Saved',
    });
  }, [
    bankStatements,
    db,
    filteredbankStatements,
    profileData.orgId,
    selectedBankStatement,
    selectedIndex,
    toast,
  ]);

  const onSearch = useCallback(
    (searchValue: string) => {
      if (searchValue && searchValue !== '') {
        setFilteredbankStatements(_searchList(bankStatements, searchValue));
      } else {
        setFilteredbankStatements(undefined);
      }
    },
    [bankStatements],
  );

  const onTextChange = useCallback((field: keyof StatementTransaction, value: string) => {
    setSelectedBankStatement((existingValue) => {
      return { ...existingValue, [field]: value } as StatementTransaction;
    });
  }, []);

  return (
    <View style={AppStyles.container}>
      <FlyOut
        onSave={onSave}
        open={open}
        setOpen={(value) => setOpen(value)}
        title={selectedBankStatement?.accountNumber || 'Bank Account'}
      >
        {selectedBankStatement ? (
          <>
            <FormControl>
              <FormControl.Label>Account Number</FormControl.Label>
              <Input value={selectedBankStatement.accountNumber} />
            </FormControl>
            <FormControl>
              <FormControl.Label>Account Name</FormControl.Label>
              <Input
                value={selectedBankStatement.accountName}
                onChangeText={(value) => onTextChange('accountName', value)}
              />
            </FormControl>
            <FormControl>
              <FormControl.Label>Currency</FormControl.Label>
              <Input
                value={selectedBankStatement.currency}
                onChangeText={(value) => onTextChange('currency', value)}
              />
            </FormControl>
            <FormControl>
              <FormControl.Label>Bank Code</FormControl.Label>
              <Input
                value={selectedBankStatement.BIC}
                onChangeText={(value) => onTextChange('BIC', value)}
              />
            </FormControl>
            <FormControl>
              <FormControl.Label>Bank Name</FormControl.Label>
              <Input
                value={selectedBankStatement.Bank}
                onChangeText={(value) => onTextChange('Bank', value)}
              />
            </FormControl>
          </>
        ) : (
          <></>
        )}
      </FlyOut>
      <View
        style={{ flexDirection: 'row', marginLeft: 10, marginBottom: 10, alignItems: 'center' }}
      >
        <PopOverFilterItem
          data={staticData.bankAccounts || []}
          title={'Bank Acccount'}
          onSelect={setBankAccount}
          selectedValue={bankAccount}
          label={'accountNumber'}
          style={{ marginTop: 10 }}
        />

        <View style={[AppStyles.marginTop, AppStyles.flex1, AppStyles.flexRowCenterSpaceBetween]}>
          {(bankStatements && bankStatements.length > 0) || !isEmpty(filter) ? (
            <FilterBar
              onSearch={onSearch}
              searchPlaceholder={'Search loaded transactions'}
              filterParams={[
                // {
                //   title: 'Sort by',
                //   options: [
                //     { label: 'Amount', value: 'amount' },
                //     { label: 'Dr / Cr', value: 'CdtDbtInd' },
                //   ],
                //   type: 'sort',
                //   field: 'orderBy',
                // },
                {
                  title: 'Cr/Dr',
                  options: [
                    { label: 'Credits Only', value: 'C' },
                    { label: 'Debits Only', value: 'D' },
                  ],
                  type: 'select',
                  field: 'CdtDbtInd',
                },
                {
                  title: 'Value Date',
                  type: 'DateRange',
                  field: 'valueDate',
                },
              ]}
              filter={filter}
              setFilter={setFilter}
            />
          ) : (
            <View />
          )}
          <BankStatementWidget />
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row',
          height: Layout.window.height - 160,
          justifyContent: 'space-between',
        }}
      >
        {!bankAccount && (
          <View style={AppStyles.width60Center}>
            <Text style={AppStyles.textRowTitle}>Please select a Bank account</Text>
          </View>
        )}
        {bankAccount && !isLoading && bankStatements.length === 0 && (
          <View
            style={{
              flex: 1,
              width: Layout.window.width * 0.6,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Text style={AppStyles.textTitle}>No transactions found</Text>
          </View>
        )}
        {(isLoading || bankStatements.length > 0) && (
          <View style={AppStyles.width60}>
            <FlatList
              data={
                isLoading ? [{} as StatementTransaction] : filteredbankStatements || bankStatements
              }
              keyExtractor={(item) => item.id || ''}
              renderItem={({ item, index }) => {
                if (isLoading) {
                  return ContentLoader();
                }
                return (
                  <TouchableOpacity
                    onPress={() => {
                      // console.warn(item);
                      // setSelectedBankStatement(item);
                      // setSelectedIndex(index);
                      // setOpen(true);
                    }}
                  >
                    <StatementTransactionItem transactionItem={item} />
                  </TouchableOpacity>
                );
              }}
              ListFooterComponent={() => {
                if (isLoadingMore) {
                  return ContentLoader();
                }
                return null;
              }}
              onEndReached={() => loadMoreBankStatements(bankAccount, filter)}
              refreshing={isLoadingMore || isLoading}
            />
          </View>
        )}
        <View style={{ width: Layout.window.width * 0.3 }}>
          {bankBalance && (
            <>
              {bankAccount && (
                <View style={{ marginLeft: 10, marginBottom: 20 }}>
                  <AmountText
                    amount={bankAccount?.balance || 0}
                    currency={bankAccount.currency}
                    size={Constants.TextSize.max}
                    color={Colors.primary}
                  />

                  <Text style={[AppStyles.textSubTitle]}>System Balance</Text>
                </View>
              )}
              <StatementBalanceWidget transactionItem={bankBalance} />
            </>
          )}
        </View>
      </View>
    </View>
  );
}
