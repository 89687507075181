/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { getApp } from 'firebase/app';
import {
  AuthErrorCodes,
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  sendEmailVerification,
  signInWithEmailAndPassword,
  User,
} from 'firebase/auth';
import {
  collection,
  doc,
  Firestore,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import uniq from 'lodash/uniq';
import moment from 'moment';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { useDispatch } from 'react-redux';

import {
  SHOW_SMART_MENU,
  UPDATE_ACCOUNT_GROUPS,
  UPDATE_BANK_ACCOUNTS,
  UPDATE_CATEGORIES,
  UPDATE_COUNTRIES,
  UPDATE_CURRENCIES,
  UPDATE_CURRENCY_RATES,
  UPDATE_ENTITIES,
  UPDATE_ORG_INFO,
  UPDATE_OUR_BANKS,
  UPDATE_PERMISSIONS,
  UPDATE_PROFILE,
  UPDATE_USER_GROUPS,
  UPDATE_USERS,
} from '../actions/ActionConstatnts';
import { AppContext } from '../App';
import {
  AccountGroup,
  Bank,
  BankAccount,
  CurrencyRate,
  Entity,
  OrgInfo,
  Status,
  TransactionCategory,
  UserGroup,
} from '../commonTypes';
import { Button, Card, Text, View } from '../components/Themed';
import Colors from '../constants/Colors';
import Constants from '../constants/Constants';
import Layout from '../constants/Layout';
import { usePreviousBusinessDay } from '../hooks/usePreviousBusinessDay';
import { RootTabScreenProps } from '../types';
import { _getMergedPermissions } from '../utils/helper';
import CInput from '../components/CInput';
import { ActivityIndicator } from 'react-native';
import {
  Avatar,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import AppStyles from '../constants/Styles';
import { CFlatList } from '../components/CFlatList';

export default function LoginScreen({ navigation, route }: RootTabScreenProps<'LoginScreen'>) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [organizationId, setOrganizationId] = useState<string>();
  const [userGroupsOfTheUser, setUserGroupsOfTheUser] = useState<string[]>();
  const [accountGroupsOfTheUser, setAccountGroupsOfTheUser] = useState<string[]>();
  const [allAccountGroups, setAllAccountGroups] = useState<AccountGroup[]>();
  const [reEnterdPassword, setReEnterdPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [signUp, setSignUp] = useState(false);
  const [isOrgSelectionVisible, setIsOrgSelectionVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [staticDataLoaded, setStaticDataLoaded] = useState(false);
  const [accountsLoaded, setAccountsLoaded] = useState(false);
  const [isProcessingRequest, setIsProcessingRequest] = useState(false);
  const [emailVerificationSent, setEmailVerificationSent] = useState(false);
  const [linkedOrganizations, setLinkedOrganizations] = useState<Partial<OrgInfo>[]>();
  const [userUid, setUserUid] = useState<string>();
  const app = getApp();
  const auth = getAuth(app);
  const dispatch = useDispatch();

  const { defaultDb, db, setDb } = useContext(AppContext);
  usePreviousBusinessDay();

  useEffect(() => {
    const listener = window.addEventListener('keydown', function (e) {
      if (e.ctrlKey && e.code === 'KeyM') {
        console.warn('keydown', 'Ctrl + M');
        dispatch({
          payload: true,
          type: SHOW_SMART_MENU,
        });
      }
      if (e.key === 'Escape') {
        dispatch({
          payload: false,
          type: SHOW_SMART_MENU,
        });
      }
    });
    return () => {
      window.removeEventListener('keydown', listener);
    };
  }, [dispatch]);

  useEffect(() => {
    if (
      !organizationId ||
      !userGroupsOfTheUser ||
      userGroupsOfTheUser.length === 0 ||
      ((!accountGroupsOfTheUser || accountGroupsOfTheUser.length === 0) &&
        !userGroupsOfTheUser.includes('Admin'))
    ) {
      return;
    }

    let q = query(collection(db, 'Organizations', organizationId, 'BankAccounts'));

    if (!userGroupsOfTheUser.includes('Admin')) {
      console.warn('xxx', accountGroupsOfTheUser);
      q = query(q, where('accountGroups', 'array-contains-any', accountGroupsOfTheUser));
    }

    const unsubscribe = onSnapshot(q, (bankAcc) => {
      const _bankAccounts: BankAccount[] = [];
      console.warn('Bank Accounts - ', bankAcc.docs.length);
      bankAcc.docs.forEach((bankAccount) => {
        _bankAccounts.push({ ...bankAccount.data(), id: bankAccount.id } as BankAccount);
      });
      setAccountsLoaded(true);
      dispatch({
        payload: _bankAccounts,
        type: UPDATE_BANK_ACCOUNTS,
      });
    });

    return () => unsubscribe();
  }, [accountGroupsOfTheUser, db, dispatch, organizationId, userGroupsOfTheUser]);

  useEffect(() => {
    if (!allAccountGroups || !userGroupsOfTheUser || !accountGroupsOfTheUser) {
      return;
    }
    if (userGroupsOfTheUser?.includes('Admin')) {
      dispatch({
        payload: allAccountGroups,
        type: UPDATE_ACCOUNT_GROUPS,
      });
    } else {
      console.warn('accountGroupsOfTheUser', accountGroupsOfTheUser);
      dispatch({
        payload: allAccountGroups.filter((ag) => accountGroupsOfTheUser?.includes(ag.id)),
        type: UPDATE_ACCOUNT_GROUPS,
      });
    }
  }, [accountGroupsOfTheUser, allAccountGroups, dispatch, userGroupsOfTheUser]);

  useEffect(() => {
    if (staticDataLoaded && accountsLoaded) {
      // navigation.navigate(route.params?.redirect || 'HomeScreen');
      navigation.navigate(route.params?.redirect || 'ImportDataScreen', {
        isTemplate: false,
      });
    }
  }, [staticDataLoaded, accountsLoaded, navigation, route.params?.redirect]);

  const fetchOrganizationInfo = useCallback(
    async (orgId: string, userUid: string) => {
      const promises = [];
      let orgExists = false;

      const clientDb = getFirestore(orgId);
      setDb(clientDb);
      console.log('orgId', orgId);
      const userDoc = await getDoc(doc(clientDb, 'Organizations', orgId, 'Users', userUid));
      console.log(userDoc.id, ' => ', userDoc.data());
      // const orgId = userDoc.ref.parent.parent.id;

      setOrganizationId(orgId);
      const userGroups = userDoc?.data()?.userGroups;
      setUserGroupsOfTheUser(userGroups);
      dispatch({
        payload: { uid: userUid, orgId: orgId, ...userDoc.data() },
        type: UPDATE_PROFILE,
      });

      //Fetch Organization Info
      promises.push(
        await getDoc(doc(clientDb, 'Organizations', orgId)).then((orgDoc) => {
          orgExists = orgDoc.exists();
          if (orgExists) {
            console.warn(orgDoc.data());
            dispatch({
              payload: orgDoc.data(),
              type: UPDATE_ORG_INFO,
            });
            dispatch({
              payload: orgDoc.data()?.Countries,
              type: UPDATE_COUNTRIES,
            });
            dispatch({
              payload: orgDoc.data()?.Currencies,
              type: UPDATE_CURRENCIES,
            });
            setIsProcessingRequest(false);
          }
        }),
      );
      // Fetch Banks
      promises.push(
        await getDocs(
          query(
            collection(clientDb, 'Organizations', orgId, 'Banks'),
            where('relationship', 'in', ['Ours', 'Both']),
            where('status', '==', Status.ACTIVE),
            orderBy('name'),
          ),
        ).then((banks) => {
          const _banks: Bank[] = [];
          console.warn(banks.docs.length);
          banks.docs.forEach((bank) => {
            _banks.push(bank.data() as Bank);
          });
          dispatch({
            payload: _banks,
            type: UPDATE_OUR_BANKS,
          });
        }),
      );
      // Fetch User Groups
      if (userGroups && userGroups.length > 0) {
        let q = query(collection(clientDb, 'Organizations', orgId, 'UserGroups'), orderBy('name'));
        if (!userGroups.includes('Admin')) {
          q = query(q, where('name', 'in', userGroups));
        }
        promises.push(
          await getDocs(q).then((userGroupsColection) => {
            const _userGroups: UserGroup[] = [];
            const _accountGroups: string[] = [];
            userGroupsColection.docs.forEach((userGroup) => {
              _userGroups.push({
                ...userGroup.data(),
                id: userGroup.id,
              } as UserGroup);
              _accountGroups.push(...(userGroup.data().accountGroups || []));
            });
            const mergedPermissions = _getMergedPermissions(_userGroups);
            console.warn('mergedPermissions', mergedPermissions);
            dispatch({
              payload: mergedPermissions,
              type: UPDATE_PERMISSIONS,
            });

            setAccountGroupsOfTheUser(uniq(_accountGroups));
            dispatch({
              payload: _userGroups,
              type: UPDATE_USER_GROUPS,
            });
          }),
        );
      }
      // Fetch Account Groups
      promises.push(
        await getDocs(
          query(collection(clientDb, 'Organizations', orgId, 'AccountGroups'), orderBy('name')),
        ).then((accountGroups) => {
          const _accountGroups: AccountGroup[] = [];
          accountGroups.docs.forEach((accountGroup) => {
            _accountGroups.push({ ...accountGroup.data(), id: accountGroup.id } as AccountGroup);
          });
          setAllAccountGroups(_accountGroups);
          console.warn('Account Groups - ', _accountGroups);
          // dispatch({
          //   payload: _accountGroups,
          //   type: UPDATE_ACCOUNT_GROUPS,
          // });
        }),
      );

      // Fetch Entities
      promises.push(
        await getDocs(
          query(
            collection(clientDb, 'Organizations', orgId, 'Entities'),
            where('status', '==', Status.ACTIVE),
            orderBy('name'),
          ),
        ).then((entities) => {
          const _entities: Entity[] = [];
          console.warn(entities.docs.length);
          entities.docs.forEach((entity) => {
            _entities.push(entity.data() as Entity);
          });
          dispatch({
            payload: _entities,
            type: UPDATE_ENTITIES,
          });
        }),
      );

      // Fetch Categories
      promises.push(
        await getDocs(
          query(collection(clientDb, 'Organizations', orgId, 'Categories'), orderBy('name')),
        ).then((categories) => {
          const _categories: TransactionCategory[] = [];
          categories.forEach((category) => {
            _categories.push({ ...category.data(), id: category.id } as TransactionCategory);
          });

          dispatch({
            payload: _categories,
            type: UPDATE_CATEGORIES,
          });
        }),
      );

      // Fetch Currency Rates
      promises.push(
        await getDoc(doc(defaultDb, 'DefaultConfig', 'CurrencyRates')).then((ratesDoc) => {
          if (ratesDoc?.data()?.rates) {
            const _currencyRates: any = {};
            const _currencyRatesArray: CurrencyRate[] = [];
            Object.entries(ratesDoc?.data()?.rates).map(([currencyCode, rate]) => {
              _currencyRates[currencyCode] = rate;

              _currencyRatesArray.push({
                from: 'USD', // TODO: Replace with Business base currency
                to: currencyCode,
                rate: Number(rate),
              });
            });
            _currencyRates.date = moment(ratesDoc?.data()?.date, 'YYYYMMDD').toDate();
            dispatch({
              payload: _currencyRates,
              type: UPDATE_CURRENCY_RATES,
            });
          }
        }),
      );

      const functions = getFunctions();
      // connectFunctionsEmulator(functions, 'localhost', 5001);
      const getOrgUsers = httpsCallable(functions, 'getOrgUsers');
      promises.push(
        getOrgUsers({
          orgId: orgId,
        }).then((result) => {
          // Read result of the Cloud Function.
          /** @type {any} */
          dispatch({
            payload: result.data,
            type: UPDATE_USERS,
          });
        }),
      );

      Promise.all(promises).then(() => {
        if (orgExists) {
          setStaticDataLoaded(true);
        } else {
          setIsProcessingRequest(false);
          alert('Organization configuration invalid. Please contact support');
        }
      });
    },
    [defaultDb, dispatch],
  );

  const onUserSignedIn = useCallback(
    async (user: User) => {
      console.warn(user.uid, defaultDb, dispatch);
      // TODO: Enable below for UI development only
      // navigation.navigate('TempUtilsScreen');
      // return;
      setUserUid(user.uid);
      if (user.emailVerified) {
        // const OrgUsers = query(collectionGroup(db, 'Users'), where('uid', '==', user.uid));
        const Organizations = query(
          collection(defaultDb, 'OrgUsers'),
          where('Users', 'array-contains', user.uid),
        );
        const querySnapshot1 = await getDocs(Organizations);
        if (querySnapshot1.docs.length > 0) {
          if (querySnapshot1.docs.length > 1) {
            setIsOrgSelectionVisible(true);
            setLinkedOrganizations(
              querySnapshot1.docs.map((doc) => {
                return { Name: doc.data().Name, id: doc.id };
              }),
            );
            // alert('User is associated with multiple organizations. Please contact support');
            return;
          }
          const orgId = querySnapshot1.docs[0].id;
          fetchOrganizationInfo(orgId, user.uid);
        } else {
          setIsProcessingRequest(false);
          alert('User not found. Please contact support');
        }
      } else {
        sendEmailVerification(user).then(() => {
          setEmailVerificationSent(true);
        });
        setIsProcessingRequest(false);
      }
    },
    [defaultDb, dispatch, fetchOrganizationInfo],
  );

  useEffect(() => {
    return onAuthStateChanged(auth, (user) => {
      if (user) {
        onUserSignedIn(user);
      } else {
        // User is signed out
        // ...
        setIsLoading(false);
      }
    });
  }, [auth, onUserSignedIn]);

  const onSignUpPressed = useCallback(() => {
    if (password !== reEnterdPassword) {
      setErrorMessage('The passwords do not match');
      setIsProcessingRequest(false);
      return;
    }
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        onUserSignedIn(user);
      })
      .catch((error) => {
        const errorCode = error.code;
        switch (errorCode) {
          case AuthErrorCodes.WEAK_PASSWORD:
            setErrorMessage('Weak Password. We recommend you to use a stronger password');
            break;
          case AuthErrorCodes.EMAIL_EXISTS:
            setErrorMessage(
              'This email already exists. Reset your password if you have forgotten it',
            );
            break;

          case AuthErrorCodes.INVALID_PASSWORD:
            setErrorMessage('Incorrect credentials. Please check your email and password');
            break;
          default:
            setErrorMessage('Something went wrong!');
        }
        setIsProcessingRequest(false);
      });
  }, [auth, email, password, reEnterdPassword, onUserSignedIn]);

  const onLoginPressed = useCallback(() => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        onUserSignedIn(user);
        setIsProcessingRequest(false);
      })
      .catch((error) => {
        const errorCode = error.code;
        switch (errorCode) {
          case AuthErrorCodes.USER_DELETED:
            setErrorMessage(
              'User Not found. Please validate if the email is correct. Sign-up if you are a new user',
            );
            break;
          case AuthErrorCodes.INVALID_EMAIL:
            setErrorMessage('Invalid Email. Please check the email id');
            break;

          case AuthErrorCodes.INVALID_PASSWORD:
            setErrorMessage('Incorrect credentials. Please check your email and password');
            break;
          default:
            setErrorMessage('Something went wrong!');
        }
        setIsProcessingRequest(false);
        // ..
      });
  }, [auth, email, password, onUserSignedIn]);

  const handleLoginOrSignup = useCallback(() => {
    setIsProcessingRequest(true);
    if (signUp) {
      onSignUpPressed();
    } else {
      onLoginPressed();
    }
  }, [onLoginPressed, onSignUpPressed, signUp]);

  const renderLoginInput = useCallback(() => {
    if (isLoading) {
      return <ActivityIndicator />;
    }
    return (
      <>
        <CInput
          label="Email"
          fieldKey="email"
          fieldValue={email}
          onTextChange={(field, value) => setEmail(value)}
          onFocus={() => setErrorMessage('')}
        />

        <CInput
          label="Password"
          isSecured={true}
          fieldValue={password}
          fieldKey="password"
          onTextChange={(field, value) => setPassword(value)}
          onFocus={() => setErrorMessage('')}
          onSubmitEditing={() => {
            if (signUp) {
              return;
            }
            handleLoginOrSignup();
          }}
        />
        {signUp && (
          <CInput
            label="Re-enter Password"
            isSecured={true}
            fieldValue={reEnterdPassword}
            fieldKey="reEnterdPassword"
            onTextChange={(field, value) => setReEnterdPassword(value)}
            onFocus={() => setErrorMessage('')}
            onSubmitEditing={() => handleLoginOrSignup()}
          />
        )}
        <Button
          mt={5}
          onPress={handleLoginOrSignup}
          loading={isProcessingRequest}
          disabled={!email || !password || (signUp && !reEnterdPassword)}
          label={signUp ? 'Create Account' : 'Login'}
        />
        <Text
          style={{
            width: Layout.window.width / 2,
            textAlign: 'center',
            color: Colors.red,
            marginVertical: 10,
            fontSize: Constants.TextSize.xl,
          }}
        >
          {errorMessage || ' '}
        </Text>
        {/* <TouchableOpacity onPress={() => setSignUp((v) => !v)}>
          <Text style={{ color: Colors.secondary, textDecorationLine: 'underline' }}>
            {signUp ? 'Existing user? Sign-in here' : 'New user? Sign-up here'}
          </Text>
        </TouchableOpacity>
        <Text style={[AppStyles.textTitle, { marginTop: 20 }]}>Or</Text>
        <Button
          onPress={() => {
            navigation.navigate('StatementImportScreen');
          }}
          label={'Continue as Guest'}
        /> */}
      </>
    );
  }, [
    isLoading,
    email,
    password,
    signUp,
    reEnterdPassword,
    handleLoginOrSignup,
    isProcessingRequest,
    errorMessage,
  ]);
  return (
    <View style={styles.container}>
      <Modal
        closeOnEsc={false}
        closeOnOverlayClick={false}
        isOpen={isOrgSelectionVisible}
        onClose={() => {
          setIsOrgSelectionVisible(false);
        }}
      >
        <ModalOverlay bg="none" backdropFilter="auto" backdropInvert="30%" backdropBlur="2px" />
        <ModalContent maxWidth="40%">
          <ModalHeader>{`Select Organization to continue`}</ModalHeader>
          <ModalBody>
            <Text style={AppStyles.textRowTitle}></Text>

            <View style={AppStyles.padding}>
              <CFlatList
                isLoading={false}
                data={linkedOrganizations || []}
                renderItem={({ item }: { item: OrgInfo }) => {
                  return (
                    <TouchableOpacity
                      onPress={() => {
                        if (userUid) {
                          fetchOrganizationInfo(item.id, userUid);
                          setIsOrgSelectionVisible(false);
                        }
                      }}
                    >
                      <Card>
                        <View style={AppStyles.flexRowCenter}>
                          <Avatar size="sm" name={item.Name} mr={2} />
                          <Text style={[AppStyles.textTitle, { color: Colors.midGrey }]}>
                            {item.Name}
                          </Text>
                        </View>
                      </Card>
                    </TouchableOpacity>
                  );
                }}
              />
            </View>
          </ModalBody>
        </ModalContent>
      </Modal>
      {!emailVerificationSent && renderLoginInput()}
      {emailVerificationSent && (
        <>
          <Text
            style={{
              color: Colors.secondary,
              fontSize: Constants.TextSize.xl,
              width: Layout.window.width / 2,
              textAlign: 'center',
            }}
          >
            We have sent you an email to verify your account. Please follow the link on the email to
            complete your email verification
          </Text>
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
