import React, { FC } from 'react';

import { Card } from '@chakra-ui/react';
import { FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import moment from 'moment';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { Payment, StaticDataStore } from '../../commonTypes';
import Colors from '../../constants/Colors';
import AppStyles from '../../constants/Styles';
import IconText from '../IconText';
import StatusTag from '../StatusTag';
import { AmountText } from '../StyledText';
import { Text, View } from '../Themed';
import { useSelector } from 'react-redux';

export type PaymentListItemProps = {
  payment: Payment;
  isTemplate?: boolean;
  isDisabled?: boolean;
};
const PaymentListItem: FC<PaymentListItemProps> = ({ payment, isTemplate, isDisabled }) => {
  const navigation = useNavigation();

  const { staticData } = useSelector((store: StaticDataStore) => store);

  return (
    <TouchableOpacity
      disabled={isDisabled}
      onPress={() => {
        console.warn(payment);
        navigation.navigate('CreatePaymentScreen', {
          payment: {
            ...payment,
            valueDate: isTemplate ? undefined : payment.valueDate?.toDate(),
          },
          isTemplate,
        });
      }}
    >
      <Card style={AppStyles.card} mr={2}>
        <View style={[AppStyles.flexRowCenter, { minHeight: 100 }]}>
          <View
            style={[
              AppStyles.flex1,
              {
                // backgroundColor: '#D8807D',
                backgroundColor: Colors.secondary,
                alignSelf: 'stretch',
                borderTopRightRadius: 25,
                borderBottomRightRadius: 25,
                shadowColor: Colors.secondary,
                shadowRadius: 5,
                shadowOpacity: 0.5,
                shadowOffset: { width: 2, height: 0 },
                elevation: 5,
                padding: 10,
              },
            ]}
          >
            <Text style={[AppStyles.textSubTitle, { color: Colors.white }]}>
              {payment?.fromAccountDetails?.shortCode}
            </Text>
            <IconText
              icon={<FontAwesome name="hashtag" size={16} color={Colors.white} />}
              value={payment?.fromAccountDetails?.accountNumber}
              showEmpty={true}
              textColor={Colors.white}
            />
            <IconText
              value={payment?.fromAccountDetails?.accountName}
              showEmpty={true}
              textColor={Colors.white}
            />

            <IconText
              icon={<FontAwesome name="bank" size={16} color={Colors.white} />}
              value={payment?.fromAccountDetails?.bank}
              showEmpty={true}
              textColor={Colors.white}
              marginTop={10}
            />
          </View>
          <View
            style={[
              AppStyles.padding,
              { alignSelf: 'stretch', flex: 2, paddingHorizontal: 25 },
              AppStyles.flexRowCenterSpaceBetween,
            ]}
          >
            <View style={{ alignSelf: 'stretch' }}>
              {isTemplate ? (
                <>
                  <IconText
                    icon={<Text style={AppStyles.textSubTitle}>Template Name</Text>}
                    value={payment?.template}
                    showEmpty={true}
                    textColor={Colors.black}
                  />
                  <IconText
                    icon={<Text style={AppStyles.textSubTitle}>Default Date</Text>}
                    value={payment?.defaultDate}
                    showEmpty={false}
                    textColor={Colors.black}
                  />
                </>
              ) : (
                <>
                  <IconText
                    icon={<Text style={AppStyles.textSubTitle}>Payment #</Text>}
                    value={payment?.sequenceNumber?.toString()}
                    showEmpty={true}
                    textColor={Colors.black}
                  />
                  <IconText
                    icon={<Text style={AppStyles.textSubTitle}>Value Date</Text>}
                    value={moment(
                      typeof payment?.valueDate === 'string'
                        ? payment?.valueDate
                        : payment?.valueDate?.toDate(),
                    ).format('DD MMM YYYY')}
                    showEmpty={true}
                    textColor={Colors.black}
                  />
                </>
              )}

              <IconText
                icon={<Text style={AppStyles.textSubTitle}>Category</Text>}
                value={
                  staticData?.categories?.find((category) => payment.category === category.id)?.name
                }
                showEmpty={false}
                textColor={Colors.black}
                marginTop={10}
              />
              <IconText
                icon={<Text style={AppStyles.textSubTitle}>Bank Ref</Text>}
                value={payment.bankReference}
                showEmpty={false}
                textColor={Colors.black}
                marginTop={10}
              />
            </View>
            <View style={{ alignSelf: 'stretch', alignItems: 'flex-end' }}>
              <StatusTag label={payment.status} />
              {payment.updateRequest && (
                // <PendingApprovalTag onPress={() => setSelectedItemForApproval(item)} />
                <StatusTag label={'Pending Updates'} isReadOnly />
              )}
              <AmountText
                amount={payment.paymentAmount}
                currency={payment.paymentCurrency}
                currencyColor={Colors.primary}
                shorten="B"
                hideZeroAmount={!!isTemplate}
              />
              <IconText
                icon={<Text style={AppStyles.textSubTitle}>Pay Method</Text>}
                value={payment.paymentMethod}
                showEmpty={false}
                textColor={Colors.black}
                marginTop={10}
              />
            </View>
          </View>
          <View
            style={[
              AppStyles.flex1,
              {
                padding: 10,
                alignItems: 'flex-end',
              },
            ]}
          >
            <View style={styles.triangle} />
            <View style={[AppStyles.flexRowCenterSpaceBetween, { alignSelf: 'stretch' }]}>
              <IconText value={payment?.type} showEmpty={true} />
              <View style={{ alignItems: 'flex-end' }}>
                <Text style={[AppStyles.textSubTitle]}>{payment?.toAccountDetails?.shortCode}</Text>
                <IconText
                  icon={<FontAwesome name="hashtag" size={16} />}
                  value={payment?.toAccountNumber}
                  showEmpty={true}
                />
              </View>
            </View>

            <IconText value={payment?.toName} showEmpty={true} textAlignment="right" />
            <IconText
              icon={<FontAwesome name="bank" size={16} />}
              value={payment?.toBankName}
              showEmpty={true}
              marginTop={10}
            />
            <IconText value={payment?.toBankBIC} showEmpty={true} marginTop={0} />
          </View>
        </View>
      </Card>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  triangle: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderTopWidth: 20,
    borderRightWidth: 0,
    borderBottomWidth: 20,
    borderLeftWidth: 15,
    borderTopColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: 'transparent',
    borderLeftColor: Colors.primary,
    position: 'absolute',
    left: 0,
    top: 35,
  },
});

export default PaymentListItem;
