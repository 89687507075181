import React, { FC, useMemo } from 'react';

import { Chart, registerables } from 'chart.js';
import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumby';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

import Layout from '../../constants/Layout';
import { StaticDataStore } from '../../commonTypes';
import { _getFormattedCurrency } from '../../utils/helper';
import { Text, View } from '../Themed';
import Colors from '../../constants/Colors';

Chart.register(...registerables);

const BalanceByCurrencyWidget: FC = () => {
  const { staticData } = useSelector((store: StaticDataStore) => store);

  const actualPieData = useMemo(() => {
    const currencyGroup = groupBy(staticData.bankAccounts, 'currency');

    console.warn(Object.entries(currencyGroup));
    const formattedData = Object.entries(currencyGroup)
      .sort()
      .map((currencyData) => {
        return {
          currency: currencyData?.[0],
          balance: sumBy(currencyData?.[1], 'balance'),
          count: currencyData?.[1]?.length,
        };
      });
    // .sort(([, a], [, b]) => b - a)
    // .slice(0, 6)
    // .map((e, index) => ({ name: e[0], population: e[1], color: Colors.chartColors[index] }));

    return formattedData;
  }, [staticData.bankAccounts]);

  const chartConfig = {
    backgroundGradientFrom: '#fff',
    backgroundGradientTo: '#fff',
    barPercentage: 0.7,
    height: 5000,
    fillShadowGradient: `rgba(1, 122, 205, 1)`,
    fillShadowGradientOpacity: 1,
    decimalPlaces: 0, // optional, defaults to 2dp
    color: (opacity = 1) => `#4169E1`,
    labelColor: (opacity = 1) => `rgba(0, 0, 0, 1)`,

    style: {
      borderRadius: 16,
      fontFamily: 'Bogle-Regular',
    },
    propsForBackgroundLines: {
      strokeWidth: 1,
      stroke: '#e3e3e3',
      strokeDasharray: '0',
    },
    propsForLabels: {
      fontFamily: 'Bogle-Regular',
    },
  };

  return (
    <View style={{ height: Layout.window.height / 3, alignItems: 'center' }}>
      <Text>Balance by currency</Text>
      <Bar
        options={{
          plugins: { legend: { display: false } },
          scales: {
            y: {
              ticks: {
                // Create scientific notation labels
                callback: function (value, index, values) {
                  return _getFormattedCurrency(value as number) + ' K';
                },
              },
            },
            x: {
              grid: {
                display: false,
              },
            },
          },
        }}
        width={Layout.window.width}
        title="Balance by currency"
        data={{
          labels: [...actualPieData.map((e) => e.currency)],
          datasets: [
            {
              data: [...actualPieData.map((e) => e.balance / 1000)],
              backgroundColor: Colors.primary,
            },
          ],
        }}
      />
    </View>
  );
};

export default BalanceByCurrencyWidget;
