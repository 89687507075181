/* eslint-disable prettier/prettier */
import { UPDATE_PROFILE } from '../actions/ActionConstatnts';
const initialState = {
    uid: null,
    name: null,
    orgId: null,
};
const profileReducer = (state = initialState, action: { type?: any; payload: any }) => {
    switch (action.type) {
        case UPDATE_PROFILE:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return {
                ...state,
            };
    }
};
export default profileReducer;
