import React, { FC } from 'react';

import { useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';

import { StaticDataStore } from '../../commonTypes';
import CardBadge from '../CardBadge';

const BanksWidget: FC = () => {
  const { staticData } = useSelector((store: StaticDataStore) => store);
  const navigation = useNavigation();

  return (
    <CardBadge
      label="Banks"
      value={staticData.ourBanks?.length.toString() || '0'}
      onPress={() => navigation.navigate('BankScreen')}
    />
  );
};

export default BanksWidget;
