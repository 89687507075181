import React, { FC, useCallback } from 'react';

import { TouchableOpacity, ViewStyle } from 'react-native';

import { Badge } from '@chakra-ui/react';
import { ActiveStatus, PaymentStatus, Status } from '../commonTypes';
import { View } from './Themed';

type StatusTag = {
  label: string;
  color: string;
};

export type StatusTagProps = {
  label?: string;
  isReadOnly?: boolean;
  statuses?: StatusTag[];
  style?: ViewStyle;
  hideActive?: boolean;
  onPendingApprovalPress?: () => void;
  size?: '0.6em' | '0.3em';
};
const StatusTag: FC<StatusTagProps> = ({
  isReadOnly,
  statuses = [
    { label: Status.ACTIVE, color: 'green' },
    { label: Status.DEACTIVATED, color: 'red' },
    { label: ActiveStatus.Deativated, color: 'red' },
    { label: Status.NEW, color: 'yellow' },
    { label: Status.PENDING, color: 'orange' },
    { label: Status.PENDING_APPROVAL, color: 'orange' },
    { label: 'Pending Updates', color: 'orange' },
    { label: PaymentStatus.APPROVED, color: 'green' },
    { label: PaymentStatus.RELEASED, color: 'yellow' },
    { label: PaymentStatus.SENT, color: 'teal' },
    { label: PaymentStatus.REJECTED, color: 'red' },
    { label: PaymentStatus.CANCELLED, color: 'red' },
    { label: PaymentStatus.RECEIVED, color: 'cyan' },
    { label: PaymentStatus.COMPLETED, color: 'blue' },
  ],
  label = 'New',
  style,
  hideActive,
  size = '0.6em',
  onPendingApprovalPress,
}) => {
  const getStatusTagFromLabel = useCallback(
    (statusLabel: string) => {
      return (
        statuses?.find((status) => status.label === statusLabel) || {
          label: statusLabel,
          color: 'yellow',
        }
      );
    },
    [statuses],
  );

  const renderTrigger = useCallback(() => {
    const value = getStatusTagFromLabel(label);
    return (
      <View
        style={[
          {
            alignItems: 'flex-end',
            justifyContent: 'center',
          },
          style,
        ]}
      >
        <Badge
          colorScheme={value?.color}
          variant="solid"
          style={{ alignSelf: 'flex-end' }}
          fontSize={size}
        >
          {value?.label || 'New'}
        </Badge>
      </View>
    );
  }, [getStatusTagFromLabel, isReadOnly, label, style]);

  if (hideActive && label === Status.ACTIVE) {
    return <View />;
  }

  if (isReadOnly) {
    return renderTrigger();
  }
  if (label === Status.PENDING_APPROVAL) {
    return <TouchableOpacity onPress={onPendingApprovalPress}>{renderTrigger()}</TouchableOpacity>;
  }

  return renderTrigger();
};

export default StatusTag;
