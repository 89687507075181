import { SHOW_SMART_MENU, UPDATE_HIGH_PRIO_NOTIF_COUNT, UPDATE_NOTIF_COUNT } from '../actions/ActionConstatnts';

/* eslint-disable prettier/prettier */
const initialState = {
    smartMenu: false,
    notificationCount: 0,
    highPrioNotificationCount: 0
};
const userInterfaceReducer = (state = initialState, action: { type?: any; payload: any }) => {
    switch (action.type) {
        case SHOW_SMART_MENU:
            return {
                ...state,
                smartMenu: action.payload,
            };
        case UPDATE_NOTIF_COUNT:
            return {
                ...state,
                notificationCount: action.payload,
            };
        case UPDATE_HIGH_PRIO_NOTIF_COUNT:
            return {
                ...state,
                highPrioNotificationCount: action.payload,
            };
        default:
            return {
                ...state,
            };
    }
};
export default userInterfaceReducer;
