import React, { FC, useMemo } from 'react';

import { Text, View } from './Themed';
import Colors from '../constants/Colors';
import Constants from '../constants/Constants';
import AppStyles from '../constants/Styles';

export type FieldDisplayProps = {
  label?: string;
  value?: string;
  values?: string[];
  showEmpty?: boolean;
  textColor?: string;
};
const FieldDisplay: FC<FieldDisplayProps> = ({ label, value, values, showEmpty, textColor }) => {
  const valuesAsText = useMemo(() => {
    if (!values) {
      return '';
    }
    return values.join(', ');
  }, [values]);

  if (!value && !values && !showEmpty) {
    return <View />;
  }
  return (
    <View style={AppStyles.marginTop}>
      <Text style={{ color: Colors.grey }}>{label}</Text>
      <Text style={{ fontSize: Constants.TextSize.xl, color: textColor || Colors.black }}>
        {value || valuesAsText}
      </Text>
    </View>
  );
};

export default FieldDisplay;
