import { useSelector } from 'react-redux';
import { CReport, StaticDataStore } from '../commonTypes';
import { Dispatch, SetStateAction, useCallback, useContext } from 'react';
import { _filterToWhereClause } from '../utils/helper';
import {
  collection,
  doc,
  getDocs,
  limit,
  query,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import { AppContext } from '../App';
import { flatten, omit, pick, set } from 'lodash';
import useUsers from './useUsers';
import { Pivot, Report } from '@webdatarocks/webdatarocks/webdatarocks';
import moment from 'moment';
import { DateFormat } from '../constants/Constants';

export default function useReporting() {
  const { staticData, profileData } = useSelector((store: StaticDataStore) => store);
  const { db } = useContext(AppContext);
  const { _getUsersFromUid } = useUsers();
  const DataSources = [
    {
      value: 'bankAccounts',
      label: 'Bank Accounts',
      type: 'state',
      omitFields: ['updateRequest', 'Bank'],
      fieldFormats: {
        balance: { type: 'number', textAlign: 'right', thousandsSeparator: ',' },
        bankCode: { type: 'string', caption: 'Bank Code - Override' },
        createdDtTm: { type: 'string', cType: 'dttm' },
        accountNumber: { type: 'string' },
        createdBy: { type: 'string', cType: 'user' },
        lastUpdatedBy: { type: 'string', cType: 'user' },
        accountGroups: { type: 'string', cType: 'accGroups' },
      },
    },
    {
      value: 'BankStatement',
      label: 'Bank Statement',
      type: 'db',
      omitFields: ['valueDateString'],
      fieldFormats: {
        amount: { type: 'number', textAlign: 'right', thousandsSeparator: ',' },
        createdDtTm: { type: 'string', cType: 'dttm', caption: 'Created On' },
        bookingDate: { type: 'string', cType: 'date' },
        valueDate: { type: 'string', cType: 'date' },
        accountNumber: { type: 'string' },
        Refs: { type: 'string', cType: 'object' },
        RltdDts: { type: 'string', cType: 'object' },
        RltdAgts: { type: 'string', cType: 'object' },
        RltdPties: { type: 'string', cType: 'object' },
        createdBy: { type: 'string', cType: 'user' },
        category: { type: 'string', cType: 'lookup', lookup: 'categories' },
      },
      filterParams: [
        {
          title: 'Account',
          options: [],
          type: 'AccountSelect',
          field: 'accountId',
          isMandatory: true,
        },
        {
          title: 'Value Date',
          type: 'DateRange',
          field: 'valueDate',
          isMandatory: true,
        },
        {
          title: 'Is Balance',
          type: 'FixedValue',
          field: 'isBalance',
          isMandatory: true,
          hide: true,
          selectedValue: false,
        },
        {
          title: 'Cr/Dr',
          options: [
            { label: 'Credits Only', value: 'C' },
            { label: 'Debits Only', value: 'D' },
          ],
          type: 'select',
          field: 'CdtDbtInd',
        },
      ],
    },
    {
      value: 'ourBanks',
      label: 'Banks',
      type: 'state',
      fieldFormats: {
        createdDtTm: { type: 'string', cType: 'dttm' },
        createdBy: { type: 'string', cType: 'user' },
        lastUpdatedBy: { type: 'string', cType: 'user' },
        address: { type: 'string', cType: 'object' },
      },
    },
    {
      value: 'Users',
      label: 'Users',
      type: 'db',
      fieldFormats: {
        createdDtTm: { type: 'string', cType: 'dttm', caption: 'Created On' },
        createdBy: { type: 'string', cType: 'user' },
      },
    },
    {
      value: 'Payments',
      label: 'Payments',
      type: 'db',
      fieldFormats: {
        createdAt: { type: 'string', cType: 'dttm', caption: 'Created On' },
        createdBy: { type: 'string', cType: 'user' },
        valueDate: { type: 'string', cType: 'date' },
        fromEntityDetails: {
          type: 'string',
          cType: 'object',
          expand: true,
          pickFields: ['name', 'shortName', 'code'],
        },
        fromBankDetails: {
          type: 'string',
          cType: 'object',
          expand: true,
          pickFields: ['name', 'BIC', 'externalName'],
        },
        fromAccountDetails: {
          type: 'string',
          cType: 'object',
          expand: true,
          pickFields: ['id', 'accountName', 'accountNumber', 'shortCode', 'currency'],
        },
        toAddress: {
          type: 'string',
          cType: 'object',
          expand: true,
          pickFields: ['addressLine1', 'addressLine2', 'city', 'state', 'country', 'zipCode'],
        },
        toBankAddress: {
          type: 'string',
          cType: 'object',
          expand: true,
          pickFields: ['addressLine1', 'addressLine2', 'city', 'state', 'country', 'zipCode'],
        },
        category: { type: 'string', cType: 'lookup', lookup: 'categories' },
      },
    },
    { value: 'Jobs', label: 'Jobs', type: 'db' },
    { value: 'userGroups', label: 'User Groups', type: 'state' },
    { value: 'accountGroups', label: 'Account Groups', type: 'state' },
  ];

  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  const fetchData = useCallback(
    async (
      reportDetails: CReport,
      dataLimit: number | undefined,
      setIsLoading: Dispatch<SetStateAction<boolean>>,
      reportRef?: any,
    ) => {
      console.log('Fetching data ', reportDetails.dataSource);
      if (!profileData?.orgId) {
        return;
      }
      setIsLoading(true);
      const _data: any[] = [];
      const dataSourceDefinition = DataSources.find(
        (_ds) => _ds.value === reportDetails.dataSource,
      );
      let isMandatoryFiltersMissing = false;
      dataSourceDefinition?.filterParams?.forEach((filterParam) => {
        if (reportDetails.filters?.[filterParam.field] === undefined && filterParam.isMandatory) {
          isMandatoryFiltersMissing = true;
        }
      });
      if (!isMandatoryFiltersMissing) {
        let querySnapshot: any[] = [];
        if (dataSourceDefinition?.type === 'db') {
          const constraints = _filterToWhereClause(reportDetails.filters);
          // console.warn('constraints', constraints);
          let q = query(
            collection(db, 'Organizations', profileData.orgId, reportDetails.dataSource),
            ...constraints,
          );

          if (dataLimit) {
            q = query(q, limit(dataLimit));
          }
          querySnapshot = await getDocs(q);
          console.warn('Fetching from DB ', querySnapshot.docs?.length);
        } else {
          querySnapshot = staticData?.[reportDetails.dataSource];
          console.warn('Fetching from State ', staticData?.[reportDetails.dataSource]?.length);
        }

        let count = 0;
        querySnapshot.forEach((doc) => {
          let record: any = omit(
            dataSourceDefinition?.type === 'db' ? { ...doc.data(), key: doc.id } : doc,
            dataSourceDefinition?.omitFields || [],
          );

          if (count === 0) {
            count++;
            if (dataSourceDefinition?.fieldFormats) {
              let _fieldFormats: any = {};
              Object.keys(record).forEach((key) => {
                const result = key.replace(/([A-Z])/g, ' $1');
                const caption = result.charAt(0).toUpperCase() + result.slice(1);
                _fieldFormats[key] = { type: 'string', caption };
              });
              Object.entries(dataSourceDefinition.fieldFormats).forEach(([key, value]) => {
                if (value.cType === 'object' && value.expand) {
                  // console.warn('Expanding ', key, value.pickFields, record[key]);
                  Object.entries(pick(record[key], value.pickFields)).forEach(
                    ([subKey, subValue]) => {
                      _fieldFormats[`${key}${subKey}`] = {
                        type: 'string',
                        caption: `${key.replace(/([A-Z])/g, ' $1')} - ${subKey}`,
                      };
                    },
                  );
                  _fieldFormats = omit(_fieldFormats, [key]);
                } else {
                  _fieldFormats[key] = { ...(_fieldFormats[key] || {}), ...value };
                }
              });
              _data.push(_fieldFormats);
            }
          }
          Object.entries(dataSourceDefinition?.fieldFormats || {}).forEach(
            ([key, value]: [string, any]) => {
              switch (value.cType) {
                case 'date':
                  record[key] = record[key]
                    ? moment(record[key]?.toDate()).format(DateFormat.DATE_ONLY)
                    : '';
                  break;
                case 'dttm':
                  record[key] = record[key]?.toDate();
                  break;
                case 'user':
                  record[key] = record[key] ? _getUsersFromUid(record[key]) : '';
                  break;
                case 'accGroups':
                  record[key] = record[key]
                    ? JSON.stringify(
                        record[key].map(
                          (value: string) =>
                            staticData.accountGroups?.find((ag) => ag.id === value)?.name,
                        ),
                      ) || value
                    : '';
                  break;
                case 'object':
                  if (value.expand) {
                    // console.warn(
                    //   'Expanding data ',
                    //   key,
                    //   record[key],
                    //   value.pickFields,
                    //   Object.keys(pick(record[key], value.pickFields)).reduce(
                    //     (a, c) => ((a[`${key}${c}`] = record[key][c]), a),
                    //     {},
                    //   ),
                    // );
                    record = {
                      ...record,
                      ...Object.keys(pick(record[key], value.pickFields)).reduce(
                        (a, c) => ((a[`${key}${c}`] = record[key][c]), a),
                        {},
                      ),
                    };
                    // console.warn('Expanded record ', record);
                  } else {
                    record[key] = JSON.stringify(record[key]);
                  }
                  break;
                case 'lookup':
                  record[key] = record[key]
                    ? staticData?.[value.lookup]?.find((cat) => cat.id === record[key])?.name ||
                      'Unmapped'
                    : '';
              }
            },
          );
          _data.push(record);
        });
      }
      // console.warn('reportDetails?.reportConfig ', reportDetails?.reportConfig);
      const report: Report = reportDetails?.reportConfig
        ? { ...reportDetails.reportConfig, dataSource: { data: _data } }
        : {
            dataSource: { data: _data },
            cdatasource: reportDetails.dataSource,
            options: {
              grid: {
                title: `${staticData.orgInfo?.Name} - ${
                  reportDetails?.name || dataSourceDefinition?.label || reportDetails.dataSource
                } Report`,
                showHeaders: false,
                type: 'flat',
              },
            },
          };
      if (!reportRef?.current?.webdatarocks) {
        await sleep(500);
        // Ref is not ready especially on the ReportingRunModal while reading from State since the data is ready even before the WebDataRocks component is ready
      }
      if (reportDetails?.id) {
        updateDoc(doc(db, 'Organizations', profileData.orgId, 'Reports', reportDetails.id), {
          lastRun: serverTimestamp(),
        });
      }
      if (reportRef) {
        console.warn('Setting report ', report, reportRef?.current?.webdatarocks);
        (reportRef?.current?.webdatarocks as Pivot)?.setReport(report);

        setIsLoading(false);
      } else {
        setIsLoading(false);
        return _data;
      }
    },
    [db, profileData.orgId, DataSources, staticData],
  );

  const formatFilterValues = useCallback(
    (filters: any, dataSource: string) => {
      const _filters = filters || {};
      DataSources.find((_ds) => _ds.value === dataSource)?.filterParams?.forEach((filterParam) => {
        if (filterParam.type === 'DateRange' && _filters[filterParam.field]) {
          try {
            _filters[filterParam.field] = _filters[filterParam.field]?.toDate();
          } catch (e) {
            console.error('Error converting to date ', e);
          }
        }
      });
      return _filters;
    },
    [DataSources],
  );

  return { DataSources, fetchData, formatFilterValues };
}
