/* eslint-disable react-native/no-inline-styles */
import React from 'react';

import { LinearGradient } from 'expo-linear-gradient';

import { AmountText } from './StyledText';
import { Text } from './Themed';
import Colors from '../constants/Colors';
import Constants from '../constants/Constants';
import { BalanceRecord } from '../commonTypes';
import { _getCodeDescr } from '../utils/helper';

export type StatementBalanceItemProps = {
  balance: BalanceRecord;
  currency: string;
  balKey?: string;
};
export function StatementBalanceItem({ balance, currency, balKey }: StatementBalanceItemProps) {
  return (
    <LinearGradient
      // Background Linear Gradient
      colors={[Colors.secondary, Colors.primary]}
      key={balKey}
      start={[0.0, 0.0]}
      end={[1.0, 1.0]}
      // locations={[0.0, 0.2]}
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'flex-start',
        borderRadius: 10,
        margin: 10,
        padding: 10,
        elevation: 5,
        shadowColor: '#171717',
        shadowOpacity: 0.3,
        shadowRadius: 10,
      }}
    >
      <AmountText
        amount={balance.amount}
        currency={currency}
        size={Constants.TextSize.max}
        color={Colors.white}
      />
      <Text style={{ color: Colors.white }}>{_getCodeDescr({ code: balance.code })}</Text>
    </LinearGradient>
  );
}
