import React, { FC } from 'react';

import { useSelector } from 'react-redux';

import { Select } from 'chakra-react-select';
import { FormControl } from 'native-base';
import { StaticDataStore, UserGroup } from '../../commonTypes';

export type UserGroupInputProps = {
  onSelect: (values: UserGroup[]) => void;
  value?: string;
  values?: string[];
  isRequired?: boolean;
  isMultiSelect?: boolean;
  isReadOnly?: boolean;
};
const UserGroupInput: FC<UserGroupInputProps> = ({
  onSelect,
  value,
  isRequired,
  isMultiSelect,
  values,
  isReadOnly,
}) => {
  const { staticData } = useSelector((store: StaticDataStore) => store);

  return (
    <FormControl isRequired={isRequired} width={300}>
      <FormControl.Label>{'User Groups'}</FormControl.Label>
      <Select
        isReadOnly={isReadOnly}
        isMulti={isMultiSelect}
        onChange={(selected) => {
          isMultiSelect ? onSelect(selected?.map((val) => val.value)) : onSelect([selected?.value]);
        }}
        closeMenuOnSelect={!isMultiSelect}
        value={
          isMultiSelect
            ? values?.map((val) => {
                return { value: val, label: val };
              })
            : value
            ? [{ value, label: value }]
            : []
        }
        name="User Groups"
        options={staticData.userGroups?.map((ccy) => {
          return { value: ccy.name, label: ccy.name };
        })}
        placeholder="Select User Groups"
        size="sm"
        menuPosition="fixed"
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
        }}
        chakraStyles={{
          menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
          container: (provided) => ({
            ...provided,
            backgroundColor: 'white',
            borderRadius: 5,
          }),
        }}
        menuPortalTarget={document.body}
      />
    </FormControl>
  );
};

export default UserGroupInput;
