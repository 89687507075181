import React, { FC } from 'react';

import { FormControl, FormLabel } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { DateName } from '../commonTypes';

export type DateNameInputProps = {
  title?: string;
  onSelect: (value?: string) => void;
  value?: string;
  options?: { value: string; label: string }[];
  isReadOnly?: boolean;
  isRequired?: boolean;
};
const DateNameInput: FC<DateNameInputProps> = ({
  onSelect,
  value,
  isRequired,
  isReadOnly = false,
  title,
  options,
}) => {
  return (
    <FormControl isRequired={isRequired} width={300}>
      <FormLabel>{title || 'Default Date'}</FormLabel>
      <Select
        isReadOnly={isReadOnly}
        onChange={(selected) => {
          onSelect(selected?.value);
        }}
        closeMenuOnSelect={true}
        value={value ? [{ value, label: value }] : []}
        name="DefaultDate"
        options={
          options || [
            { value: DateName.TODAY, label: DateName.TODAY },
            { value: DateName.NEXT_BUSINESS_DAY, label: DateName.NEXT_BUSINESS_DAY },
          ]
        }
        placeholder="Select Default Date"
        size="sm"
        menuPosition="fixed"
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
        }}
        chakraStyles={{
          menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
        }}
        menuPortalTarget={document.body}
      />
    </FormControl>
  );
};

export default DateNameInput;
