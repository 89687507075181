import React from 'react';

import { Button, Modal } from 'native-base';

import Layout from '../constants/Layout';

export type FlyOutProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  title: string;
  children: React.ReactElement;
  onSave?: () => void;
  hideSaveButton?: boolean;
  hideButtons?: boolean;
  isSaving?: boolean;
};
export function FlyOut({
  open,
  setOpen,
  title,
  onSave,
  children,
  hideSaveButton,
  hideButtons,
  isSaving,
}: FlyOutProps) {
  return (
    <Modal isOpen={open} onClose={() => setOpen(false)} animationPreset="slide">
      <Modal.Content
        maxWidth="500"
        minHeight={Layout.window.height}
        style={{
          marginLeft: 'auto',
          marginRight: 0,
        }}
      >
        <Modal.CloseButton />
        <Modal.Header>{title}</Modal.Header>
        <Modal.Body style={{ margin: 0, height: Layout.window.height - 65 }}>{children}</Modal.Body>
        {hideButtons ? null : (
          <Modal.Footer>
            <Button.Group space={2}>
              <Button
                variant="ghost"
                colorScheme="blueGray"
                onPress={() => {
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
              {hideSaveButton ? (
                <></>
              ) : (
                <Button isLoading={isSaving} onPress={onSave}>
                  Save
                </Button>
              )}
            </Button.Group>
          </Modal.Footer>
        )}
      </Modal.Content>
    </Modal>
  );
}
