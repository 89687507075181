import React, { useCallback, useContext, useEffect, useState } from 'react';

import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';

import { AppContext } from '../App';
import { Entity, InputMode, StaticDataStore } from '../commonTypes';
import EntityDetails from '../components/BankAccounts/EntityDetails';
import Button from '../components/Button';
import { CFlatList } from '../components/CFlatList';
import { CModal } from '../components/CModal';
import { FilterBar } from '../components/FilterBar';
import StatusTag from '../components/StatusTag';
import { Card, Text, View } from '../components/Themed';
import Colors from '../constants/Colors';
import Constants from '../constants/Constants';
import AppStyles from '../constants/Styles';
import { RootTabScreenProps } from '../types';
import { _searchList } from '../utils/helper';

export default function EntityScreen({ navigation }: RootTabScreenProps<'EntityScreen'>) {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedEntity, setSelectedEntity] = useState<Entity>();
  const [entities, setEntities] = useState<Entity[]>();
  const [inputMode, setInputMode] = useState<InputMode>(InputMode.CREATE);
  const [filteredEntities, setFilteredEntities] = useState<Entity[]>();
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [isDetailVisible, setIsDetailVisible] = useState(true);

  const { db } = useContext(AppContext);
  const { profileData, staticData } = useSelector((store: StaticDataStore) => store);
  const canEdit = staticData.accessibleScreens?.EntityScreen?.edit || false;
  const fetchEntities = useCallback(async () => {
    console.log('Fetching Entities');
    setIsLoading(true);
    if (!profileData?.orgId) {
      return;
    }
    const q = query(
      collection(db, 'Organizations', profileData?.orgId, 'Entities'),
      orderBy('name'),
    );
    const querySnapshot = await getDocs(q);
    const _entities: Entity[] = [];
    querySnapshot.forEach((doc) => {
      _entities.push(doc.data() as Entity);
    });
    console.warn(_entities);
    setEntities(_entities);
    setIsLoading(false);
  }, [db, profileData?.orgId]);

  useEffect(() => {
    fetchEntities();
  }, [fetchEntities]);

  const onCreateNewEntity = useCallback(() => {
    setSelectedEntity({
      name: '',
    } as Entity);
    setInputMode(InputMode.CREATE);
    setIsDetailVisible(true);
  }, []);

  const onEntityUpdated = useCallback(
    (entitity: Entity) => {
      if (inputMode === InputMode.CREATE) {
        setEntities((existingValue) => {
          return [...(existingValue || []), entitity];
        });
      } else if (selectedIndex !== undefined) {
        //Updating existing account
        const _entities = filteredEntities || entities;
        if (!_entities) {
          return;
        }
        _entities[selectedIndex] = entitity;
        if (filteredEntities) {
          setFilteredEntities(_entities);
          const _AllEntities = entities;
          if (!_AllEntities) {
            return;
          }
          _AllEntities[_AllEntities.findIndex((eEntity) => eEntity.name === entitity.name)] =
            entitity;
          setEntities(_AllEntities);
        } else {
          setEntities(_entities);
        }
      }
    },
    [entities, filteredEntities, inputMode, selectedIndex],
  );

  const onSearchOrFilter = useCallback(
    (searchValue: string) => {
      if (searchValue && searchValue !== '') {
        setFilteredEntities(_searchList(entities, searchValue));
      } else {
        setFilteredEntities(undefined);
      }
    },
    [entities],
  );

  return (
    <View style={AppStyles.container}>
      {selectedEntity && (
        <CModal
          open={isDetailVisible}
          setOpen={(value) => setIsDetailVisible(value)}
          title={selectedEntity?.name || 'Entity'}
          hideButtons
        >
          <EntityDetails
            setIsVisible={setIsDetailVisible}
            entity={selectedEntity}
            onEntityUpdated={onEntityUpdated}
            mode={canEdit ? inputMode || InputMode.EDIT : InputMode.VIEW}
          />
        </CModal>
      )}
      <View style={[AppStyles.flexRowCenterSpaceBetween, AppStyles.marginTop]}>
        {staticData.currencies && staticData.currencies.length > 0 ? (
          <FilterBar onSearch={onSearchOrFilter} searchPlaceholder={'Search Name or Code'} />
        ) : (
          <View />
        )}
        <View />
        <View style={AppStyles.flexRowCenter}>
          <Button
            label="Create Entity"
            variant="Create"
            onPress={onCreateNewEntity}
            hidden={!staticData.accessibleScreens?.EntityScreen?.create}
          />
        </View>
      </View>
      <View style={AppStyles.width60}>
        <CFlatList
          emptyMessage={filteredEntities === undefined ? 'No Entities yet' : 'No results found'}
          emptyCTA={'Create Entity'}
          emptyAction={onCreateNewEntity}
          data={filteredEntities || entities}
          isLoading={isLoading}
          renderItem={({ item, index }: { item: Entity; index: number }) => (
            <TouchableOpacity
              onPress={() => {
                setSelectedEntity(item);
                setSelectedIndex(index);
                setInputMode(InputMode.EDIT);
                setIsDetailVisible(true);
              }}
            >
              <Card style={styles.entitityCard}>
                <View style={AppStyles.flexRowCenter}>
                  <View style={{ flex: 2 }}>
                    <Text style={AppStyles.textRowTitle}>{item.name}</Text>
                    <Text style={AppStyles.textSubTitle}>{item.code}</Text>
                  </View>

                  {item.shortName ? (
                    <View style={AppStyles.flex1}>
                      <Text style={{ color: Colors.grey }}>Short Name</Text>
                      <Text style={{ fontSize: Constants.TextSize.xl }}>{item.shortName}</Text>
                    </View>
                  ) : (
                    <View />
                  )}
                  <View style={AppStyles.flex1}>
                    <StatusTag label={item.status} />
                    {item.updateRequest && <StatusTag label={'Pending Updates'} isReadOnly />}
                  </View>
                </View>
              </Card>
            </TouchableOpacity>
          )}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  entitityCard: {
    marginBottom: 10,
    marginHorizontal: 10,
    borderWidth: 2,
  },
});
