/* eslint-disable react/jsx-max-depth */
import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';

import { FontAwesome5 } from '@expo/vector-icons';
import { Radio } from 'native-base';
import { StyleSheet, TouchableOpacity } from 'react-native';

import { ApprovalWorkflow, ApprovalWorkflowStep } from '../../commonTypes';
import Colors from '../../constants/Colors';
import Constants from '../../constants/Constants';
import AppStyles from '../../constants/Styles';
import { Card, Text, View } from '../Themed';
import UserGroupInput from '../Users/UserGroupInput';
import StepCriteria from './StepCriteria';

export type StepItemContainerProps = {
  step: ApprovalWorkflowStep;
  index: number;
  setSelectedWorkflow: Dispatch<SetStateAction<ApprovalWorkflow | undefined>>;
  fieldProperties?: any;
};
const StepItemContainer: FC<StepItemContainerProps> = ({
  step,
  index,
  setSelectedWorkflow,
  fieldProperties,
}) => {
  const isFallback = index === 0;

  return (
    <Card style={{ marginBottom: 20 }}>
      <View style={AppStyles.flexRow}>
        <View style={[{ flex: 2 }]}>
          <View style={AppStyles.flexRowCenterSpaceBetween}>
            <Text style={AppStyles.textRowTitle}>
              {isFallback ? 'Fallback Rule' : `Rule ${index}`}
            </Text>
            {isFallback ? (
              <View />
            ) : (
              <TouchableOpacity
                style={[AppStyles.flexRowCenter, AppStyles.marginRight, AppStyles.padding]}
                onPress={() => {
                  setSelectedWorkflow((currentValue) => {
                    const steps = currentValue.steps || [];
                    steps.splice(index, 1);
                    return { ...currentValue, steps: steps };
                  });
                }}
              >
                <FontAwesome5 name="trash" size={12} color={Colors.red} />
                <Text style={{ fontSize: 12, marginLeft: 5, color: Colors.red }}>Delete rule</Text>
              </TouchableOpacity>
            )}
          </View>
          <Text
            style={[
              AppStyles.textSubTitle,
              AppStyles.marginTop,
              { color: Colors.black, fontWeight: 'bold' },
            ]}
          >
            Approvers
          </Text>
          <View style={[AppStyles.marginTop]}>
            <Radio.Group
              style={AppStyles.marginRight}
              name="myRadioGroup"
              value={step.allOrAny}
              onChange={(nextValue) => {
                setSelectedWorkflow((currentValue) => {
                  const steps = currentValue.steps || [];
                  steps[index].allOrAny = nextValue;
                  return { ...currentValue, steps: steps };
                });
              }}
            >
              <Radio value="Any" my="0.5">
                Any Users of
              </Radio>
              <Radio value="All" my="0.5">
                All Users of
              </Radio>
            </Radio.Group>

            <View style={[AppStyles.marginLeft25, AppStyles.marginTop]}>
              <UserGroupInput
                onSelect={(values) => {
                  setSelectedWorkflow((currentValue) => {
                    const steps = currentValue.steps || [];
                    steps[index].approverUserGroups = values.map((value) => value) || [];
                    return { ...currentValue, steps: steps };
                  });
                }}
                values={step.approverUserGroups}
                isMultiSelect
              />
            </View>
          </View>
        </View>
        <StepCriteria
          fieldProperties={fieldProperties}
          step={step}
          index={index}
          setSelectedWorkflow={setSelectedWorkflow}
        />
      </View>
    </Card>
  );
};

const styles = StyleSheet.create({
  criteriaTextContainer: { backgroundColor: Colors.secondary, borderRadius: 5 },
  criteriaText: {
    textAlign: 'center',
    fontSize: Constants.TextSize.large,
    color: Colors.white,
  },
});

export default StepItemContainer;
