/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { collection, getDocs, query } from 'firebase/firestore';

import { StyleSheet, TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';
import { AppContext } from '../App';
import { InputMode, StaticDataStore, Status, User } from '../commonTypes';
import { CFlatList } from '../components/CFlatList';
import { CModal } from '../components/CModal';
import FieldDisplay from '../components/FieldDisplay';
import { FilterBar } from '../components/FilterBar';
import StatusTag from '../components/StatusTag';
import { Card, SmallCta, Text, View } from '../components/Themed';
import UserDetails from '../components/Users/UserDetails';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import AppStyles from '../constants/Styles';
import { RootTabScreenProps } from '../types';
import { _searchList } from '../utils/helper';

export default function UsersScreen({ navigation }: RootTabScreenProps<'UsersScreen'>) {
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [inputMode, setInputMode] = useState(InputMode.EDIT);
  const [selectedUser, setSelectedUser] = useState<User>({
    firstName: '',
    lastName: '',
    email: '',
  } as User);
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [isDetailVisible, setIsDetailVisible] = useState(false);

  const [filteredUsers, setFilteredUsers] = useState<User[] | undefined>();
  const { db } = useContext(AppContext);
  const toast = useToast();
  const dispatch = useDispatch();

  const { profileData, staticData } = useSelector((store: StaticDataStore) => store);
  const canEdit = staticData.accessibleScreens?.UsersScreen?.edit || false;

  const fetchUsers = useCallback(async () => {
    if (!profileData?.orgId) {
      return;
    }
    console.log('Fetching users');
    const q = query(collection(db, 'Organizations', profileData.orgId, 'Users'));
    const querySnapshot = await getDocs(q);
    const _users: User[] = [];

    querySnapshot.forEach((doc) => {
      _users.push(doc.data() as User);
    });
    setUsers(_users);
    // dispatch({
    //   payload: _users,
    //   type: UPDATE_BANK_ACCOUNTS,
    // });
    setIsLoading(false);
  }, [db, profileData.orgId]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const onSearchOrFilter = useCallback(
    (searchValue: string) => {
      console.warn('searchValue', searchValue);
      if (searchValue && searchValue !== '') {
        setFilteredUsers(_searchList(users, searchValue));
      } else {
        setFilteredUsers(undefined);
      }
    },
    [users],
  );

  const onUserCreatedOrUpdated = useCallback(
    (updatedUser: User) => {
      if (selectedIndex !== undefined && updatedUser?.uid) {
        //Updating existing account
        const _users = filteredUsers || users;
        _users[selectedIndex] = updatedUser;
        if (filteredUsers) {
          setFilteredUsers(_users);
          const _AllUsers = users;
          _AllUsers[_AllUsers.findIndex((user) => user.uid === updatedUser.uid)] = updatedUser;
          setUsers(_AllUsers);
        } else {
          setUsers(_users);
        }
      } else if (updatedUser) {
        //Create new account
        setUsers((currentValue) => [
          {
            ...updatedUser,
          },
          ...currentValue,
        ]);
        if (filteredUsers) {
          setFilteredUsers((currentValue) => [
            {
              ...updatedUser,
            },
            ...(currentValue || []),
          ]);
        }
      } else {
        setIsDetailVisible(false);
        return toast({
          title: 'Something went worong',
          description: 'Please try again later',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    },
    [users, filteredUsers, selectedIndex, toast],
  );

  const onCreateNewUser = useCallback(() => {
    setSelectedUser({
      firstName: '',
      lastName: '',
      email: '',
    } as User);
    setSelectedIndex(undefined);
    setInputMode(InputMode.CREATE);
    setIsDetailVisible(true);
  }, []);

  return (
    <View style={AppStyles.container}>
      {selectedUser && (
        <CModal
          open={isDetailVisible}
          setOpen={(value) => setIsDetailVisible(value)}
          title={selectedUser?.firstName || 'User'}
          hideButtons
        >
          <UserDetails
            setIsVisible={setIsDetailVisible}
            user={selectedUser}
            onUserUpdated={onUserCreatedOrUpdated}
            mode={canEdit ? inputMode || InputMode.EDIT : InputMode.VIEW}
          />
        </CModal>
      )}
      <View style={AppStyles.flexRowCenterSpaceBetween}>
        {users && users.length > 0 ? (
          <FilterBar onSearch={onSearchOrFilter} searchPlaceholder={'Search by Name or email'} />
        ) : (
          <View />
        )}
        <SmallCta
          label="Create New User"
          buttonColor={Colors.green}
          icon={'plus-square'}
          onPress={onCreateNewUser}
        />
      </View>

      <View
        style={{
          flexDirection: 'row',
          height: Layout.window.height - 150,
          justifyContent: 'space-between',
        }}
      >
        <View style={{ width: Layout.window.width * 0.6 }}>
          <CFlatList
            keyField="uid"
            emptyMessage={filteredUsers === undefined ? 'No Users yet' : 'No results found'}
            data={filteredUsers || users}
            isLoading={isLoading}
            renderItem={({ item, index }: { item: User; index: number }) => (
              <TouchableOpacity
                onPress={() => {
                  setSelectedUser(item);
                  setSelectedIndex(index);
                  setInputMode(InputMode.EDIT);
                  setIsDetailVisible(true);
                }}
              >
                <Card style={styles.userCard}>
                  <View
                    style={[
                      AppStyles.flexRow,
                      {
                        width: Layout.window.width * 0.6 - 20,
                      },
                    ]}
                  >
                    <View style={{ flex: 2 }}>
                      <View style={[AppStyles.flexRow, AppStyles.alignFlexEnd]}>
                        <Text style={AppStyles.textRowTitle}>{item.firstName + ' '}</Text>
                        <Text style={AppStyles.textSubTitle}>{item.lastName}</Text>
                      </View>
                      <Text style={AppStyles.textSubTitle}>{item.email}</Text>
                    </View>
                    <View style={AppStyles.flex1}>
                      <FieldDisplay label={'User Groups'} values={item.userGroups} />
                    </View>

                    <View style={[AppStyles.flex1, { paddingRight: 15 }]}>
                      <StatusTag label={item.status || Status.ACTIVE} isReadOnly />
                      {item.updateRequest && <StatusTag label={'Pending Updates'} isReadOnly />}
                    </View>
                  </View>
                </Card>
              </TouchableOpacity>
            )}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  userCard: {
    marginBottom: 10,
    marginHorizontal: 10,
    borderWidth: 2,
    flex: 1,
  },
});
