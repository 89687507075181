/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useContext, useState } from 'react';

import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { FontAwesome5 } from '@expo/vector-icons';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { useToast } from 'native-base';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { AppContext } from '../App';
import { UPDATE_COUNTRIES } from '../actions/ActionConstatnts';
import { Country, EnabeldDisabled, StaticDataStore } from '../commonTypes';
import Button from '../components/Button';
import { CFlatList } from '../components/CFlatList';
import CInput from '../components/CInput';
import DaysOfWeekPicker from '../components/DaysOfWeekPicker';
import FieldDisplay from '../components/FieldDisplay';
import { FilterBar } from '../components/FilterBar';
import { Card, Text, View } from '../components/Themed';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import AppStyles from '../constants/Styles';
import { RootTabScreenProps } from '../types';
import { _getDaysFromCodes, _searchList, _sort, _textFieldIsInvalid } from '../utils/helper';

export default function CountriesScreen({ navigation }: RootTabScreenProps<'CountriesScreen'>) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isImportCountries, setIsImportCountries] = useState(false);
  const [countriesToImport, setCountriesToImport] = useState<Country[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<Country>();
  const [selectedIndex, setSelectedIndex] = useState<number>();

  const [filteredCountries, setFilteredCountries] = useState<Country[] | undefined>();
  const { db, defaultDb } = useContext(AppContext);
  const toast = useToast();
  const dispatch = useDispatch();
  const { profileData, staticData } = useSelector((store: StaticDataStore) => store);

  const onSearchOrFilter = useCallback(
    (searchValue: string) => {
      if (searchValue && searchValue !== '') {
        setFilteredCountries(_searchList(staticData.countries, searchValue));
      } else {
        setFilteredCountries(undefined);
      }
    },
    [staticData.countries],
  );

  const onSave = useCallback(async () => {
    if (!profileData?.orgId) {
      return;
    }
    if (_textFieldIsInvalid(selectedCountry?.name)) {
      return toast.show({
        title: 'Invalid Country Name',
        variant: 'top-accent',
        description: 'Please enter a valid name',
      });
    }
    if (_textFieldIsInvalid(selectedCountry?.code)) {
      return toast.show({
        title: 'Invalid Country Code',
        variant: 'top-accent',
        description: 'Please enter a valid Code',
      });
    }

    if (selectedIndex !== undefined && selectedCountry?.code) {
      setIsSaving(true);
      // Update existing country
      if (!staticData.countries) {
        setIsSaving(false);
        return;
      }
      const index = staticData.countries?.findIndex(
        (country) => country.code === selectedCountry?.code,
      );
      const updatedCountryList = staticData.countries;
      if (index < 0 || !updatedCountryList) {
        setIsSaving(false);
        return;
      }
      updatedCountryList[index] = selectedCountry;

      await setDoc(
        doc(db, 'Organizations', profileData.orgId),
        {
          Countries: updatedCountryList,
        },
        { merge: true },
      ).then(() => {
        setIsSaving(false);
        dispatch({
          type: UPDATE_COUNTRIES,
          payload: updatedCountryList,
        });
      });
    } else if (staticData.countries?.find((country) => country.code === selectedCountry?.code)) {
      setIsSaving(false);
      return toast.show({
        title: 'Country code already exists',
        variant: 'top-accent',
        description: 'Please enter a different Code or update the existing currency',
      });
    } else {
      // Create new currency
      const updatedCountryList = _sort([...(staticData.countries || []), selectedCountry], 'code');

      await setDoc(
        doc(db, 'Organizations', profileData.orgId),
        {
          Countries: updatedCountryList,
        },
        { merge: true },
      ).then(() => {
        setIsSaving(false);
        dispatch({
          type: UPDATE_COUNTRIES,
          payload: updatedCountryList,
        });
      });
    }
    setIsSaving(false);
    onClose();

    toast.show({
      description: `Country ${selectedCountry?.code ? 'Updated' : 'Created'}`,
    });
  }, [
    profileData?.orgId,
    selectedCountry,
    selectedIndex,
    staticData.countries,
    toast,
    db,
    dispatch,
  ]);

  const onTextChange = useCallback((field: keyof Country, value: string) => {
    setSelectedCountry((existingValue) => {
      return { ...existingValue, [field]: value } as Country;
    });
  }, []);

  const fetchDefaultConfigCountries = useCallback(() => {
    setIsLoading(true);
    getDoc(doc(defaultDb, 'DefaultConfig', 'Countries')).then((countriesDoc) => {
      if (countriesDoc.exists()) {
        setCountriesToImport(
          countriesDoc
            .data()
            ?.Countries.filter(
              (country: any) =>
                !staticData.countries?.find(
                  (existingCountry) => existingCountry.code === country.code,
                ),
            ),
        );
        console.warn(countriesDoc.data()?.Countries);
      }
    });
    setIsLoading(false);
  }, [defaultDb, staticData.countries]);

  const importSelectedCountries = useCallback(async () => {
    if (!profileData?.orgId) {
      return;
    }
    setIsLoading(true);
    const _newCountries = countriesToImport
      .filter((country) => country.isSelected)
      .map((country: Country) => {
        return {
          code: country.code,
          name: country.name,
          nonBusinessDays: country.nonBusinessDays,
          state: EnabeldDisabled.ENABLED,
        };
      });

    const updatedCountryList = _sort([...(staticData.countries || []), ..._newCountries], 'code');

    await setDoc(
      doc(db, 'Organizations', profileData.orgId),
      {
        Countries: updatedCountryList,
      },
      { merge: true },
    ).then(() => {
      dispatch({
        type: UPDATE_COUNTRIES,
        payload: updatedCountryList,
      });
    });
    console.warn(updatedCountryList);

    setIsLoading(false);
    setIsImportCountries(false);
    onClose();
    toast.show({
      description: `Countries Imported`,
    });
  }, [countriesToImport, db, dispatch, profileData?.orgId, staticData.countries, toast]);

  const renderFlyOut = useCallback(() => {
    if (isImportCountries) {
      return (
        <View style={[AppStyles.flex1, { height: Layout.window.height - 150 }]}>
          <CFlatList
            isLoading={isLoading}
            data={countriesToImport}
            keyField="code"
            renderItem={({ item }: { item: Country }) => {
              return (
                <TouchableOpacity
                  key={item.code}
                  onPress={() => {
                    setCountriesToImport((currentValue) => {
                      return currentValue?.map((value) => {
                        if (value.code === item.code) {
                          return { ...value, isSelected: !value.isSelected };
                        }
                        return value;
                      });
                    });
                  }}
                >
                  <Card>
                    <View style={AppStyles.flexRowCenterSpaceBetween}>
                      <View style={AppStyles.flexRowCenter}>
                        <FontAwesome5
                          name="check"
                          size={15}
                          color={item.isSelected ? Colors.primary : Colors.grey}
                          style={AppStyles.marginRight}
                        />
                        <Text>{item.code}</Text>
                      </View>
                      <Text>{item.name}</Text>
                    </View>
                  </Card>
                </TouchableOpacity>
              );
            }}
          />
        </View>
      );
    }
    if (selectedCountry) {
      return (
        <>
          <CInput
            label="Country Code"
            fieldValue={selectedCountry.code}
            fieldKey={'code'}
            disabled={selectedIndex !== undefined}
            onTextChange={onTextChange}
            isRequired
          />
          <CInput
            label="Country Name"
            fieldValue={selectedCountry.name}
            fieldKey={'name'}
            onTextChange={onTextChange}
            isRequired
          />
          <DaysOfWeekPicker
            values={selectedCountry.nonBusinessDays}
            onSelect={(days) => {
              setSelectedCountry((currentValue) => {
                return {
                  ...currentValue,
                  nonBusinessDays: days,
                };
              });
            }}
            title="Non-Business Days"
          />
        </>
      );
    }
    return <></>;
  }, [
    countriesToImport,
    importSelectedCountries,
    isImportCountries,
    isLoading,
    onTextChange,
    selectedCountry,
    selectedIndex,
  ]);

  return (
    <View style={AppStyles.container}>
      <Drawer isOpen={isOpen} placement="right" size={'sm'} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{`Country`}</DrawerHeader>

          <DrawerBody>{renderFlyOut()}</DrawerBody>

          {isImportCountries ? (
            <DrawerFooter>
              <Button
                disabled={!(countriesToImport?.filter((country) => country.isSelected).length > 0)}
                label="Import Currencies"
                onPress={importSelectedCountries}
                isLoading={isLoading}
              />
            </DrawerFooter>
          ) : (
            <DrawerFooter>
              <Button label="Cancel" variant="Cancel" onPress={onClose} isDisabled={isSaving} />
              <Button label="Save" onPress={onSave} isLoading={isSaving} />
            </DrawerFooter>
          )}
        </DrawerContent>
      </Drawer>
      <View style={AppStyles.flexRowCenterSpaceBetween}>
        {staticData.countries && staticData.countries.length > 0 ? (
          <FilterBar onSearch={onSearchOrFilter} searchPlaceholder={'Search Name or Code'} />
        ) : (
          <View />
        )}
        <View style={AppStyles.flexRowCenter}>
          <Button
            label="Import Countries"
            variant="Create"
            onPress={() => {
              fetchDefaultConfigCountries();
              setIsImportCountries(true);
              onOpen();
            }}
          />
          {/* <MoreMenu>
            <Menu.Item
              onPress={() => {
                fetchDefaultConfigCountries();
                setIsImportCountries(true);
                onOpen();
              }}
            >
              Import Countries
            </Menu.Item>
          </MoreMenu> */}
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row',
          height: Layout.window.height - 150,
          justifyContent: 'space-between',
        }}
      >
        <View style={AppStyles.width60}>
          <CFlatList
            style={AppStyles.width60}
            emptyMessage={filteredCountries === undefined ? 'No Countries yet' : 'No results found'}
            keyField="code"
            data={_sort(filteredCountries || staticData.countries, 'code') as Country[]}
            isLoading={isLoading}
            renderItem={({ item, index }: { item: Country; index: number }) => (
              <TouchableOpacity
                onPress={() => {
                  setIsImportCountries(false);
                  setSelectedCountry(item);
                  setSelectedIndex(index);
                  onOpen();
                }}
                key={item.code}
              >
                <Card style={styles.countryCard}>
                  <View style={AppStyles.flexRowCenterSpaceBetween}>
                    <Text style={AppStyles.textRowTitle}>{item.code}</Text>
                    <Text style={AppStyles.textSubTitle}>{item.name}</Text>
                    <FieldDisplay
                      label="Non-Business Days"
                      value={_getDaysFromCodes(item.nonBusinessDays)}
                    />
                  </View>
                </Card>
              </TouchableOpacity>
            )}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  countryCard: {
    marginBottom: 10,
    marginHorizontal: 10,
    borderWidth: 2,
  },
});
