/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useContext, useState } from 'react';

import { StyleSheet, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';

import {
  DocumentData,
  QueryDocumentSnapshot,
  collection,
  getDocs,
  orderBy,
  query,
} from 'firebase/firestore';
import { CReport, InputMode, StaticDataStore } from '../commonTypes';
import { CFlatList } from '../components/CFlatList';
import { FilterBar } from '../components/FilterBar';
import { Text, View } from '../components/Themed';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import AppStyles from '../constants/Styles';
import { RootTabScreenProps } from '../types';
import { _filterToWhereClause, _searchList } from '../utils/helper';

import { LockIcon, SettingsIcon } from '@chakra-ui/icons';
import { Card, Stack, useToast } from '@chakra-ui/react';
import { useFocusEffect } from '@react-navigation/native';
import { isEmpty } from 'lodash';
import { AppContext } from '../App';
import Button from '../components/Button';
import IconText from '../components/IconText';
import ReportRunModal from '../components/Reports/ReportRunModal';
import Constants from '../constants/Constants';

export default function ReportingScreen({ navigation }: RootTabScreenProps<'ReportingScreen'>) {
  const [reports, setReports] = useState<CReport[]>([]);
  const [selectedReport, setSelectedReport] = useState<CReport>();
  const [isLoading, setIsLoading] = useState(true);
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [filteredReprots, setFilteredReports] = useState<CReport[] | undefined>();
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [filter, setFilter] = useState({});
  const [mode, setMode] = useState(InputMode.EDIT);
  const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot<DocumentData> | null>();
  const toast = useToast();
  const { profileData, staticData } = useSelector((store: StaticDataStore) => store);

  const canEdit = staticData.accessibleScreens?.ReportingScreen?.edit || false;

  const { db } = useContext(AppContext);

  const fetchReports = useCallback(
    async (filterValues: any) => {
      console.log('Fetching Reports');
      if (!profileData?.orgId) {
        return;
      }
      setIsLoading(true);
      setLastVisible(null);
      const constraints = _filterToWhereClause(filterValues);
      const q = query(
        collection(db, 'Organizations', profileData?.orgId, 'Reports'),
        ...constraints,
        orderBy('name'),
      );
      const querySnapshot = await getDocs(q);
      const _reports: CReport[] = [];
      querySnapshot.forEach((doc) => {
        _reports.push({ ...doc.data(), id: doc.id } as CReport);
      });

      setReports(_reports);
      if (querySnapshot.empty) {
        setLastVisible(null);
      } else {
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      }
      setIsLoading(false);
    },
    [db, profileData?.orgId, staticData?.bankAccounts],
  );

  // const loadMoreJobs = useCallback(
  //   async (filterValues: any) => {
  //     if (lastVisible) {
  //       setIsLoadingMore(true);
  //       const constraints = _filterToWhereClause(filterValues);
  //       const q = query(
  //         collection(db, 'Organizations', profileData?.orgId, 'Jobs'),
  //         ...constraints,
  //         orderBy('lastRun', 'desc'),
  //       );
  //       const querySnapshot = await getDocs(q);
  //       const _reports: Job[] = [];
  //       querySnapshot.forEach((doc) => {
  //         _reports.push({ ...doc.data(), id: doc.id } as Job);
  //       });
  //       if (querySnapshot.empty) {
  //         setLastVisible(null);
  //       } else {
  //         setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
  //       }
  //       setJobs([...jobs, ..._reports]);
  //       setIsLoadingMore(false);
  //     }
  //   },
  //   [db, lastVisible, jobs, profileData?.orgId],
  // );

  useFocusEffect(
    useCallback(() => {
      setIsLoading(true);
      fetchReports(filter);
    }, [fetchReports, filter]),
  );

  const onSearchOrFilter = useCallback(
    (searchValue: string) => {
      if (searchValue && searchValue !== '') {
        setFilteredReports(_searchList(reports, searchValue));
      } else {
        setFilteredReports(undefined);
      }
    },
    [reports],
  );

  const onCreateReport = useCallback(() => {
    setMode(InputMode.CREATE);
    navigation.navigate('ReportingDetailsScreen', { report: undefined });
  }, [profileData.uid, staticData?.categories]);

  return (
    <View style={AppStyles.container}>
      <ReportRunModal report={selectedReport} setReport={setSelectedReport} />
      <View style={[AppStyles.flexRowCenterSpaceBetween, AppStyles.marginTop]}>
        {(reports && reports.length > 0) || !isEmpty(filter) ? (
          <FilterBar
            onSearch={onSearchOrFilter}
            searchPlaceholder={'Search Reports'}
            filter={filter}
            setFilter={setFilter}
          />
        ) : (
          <View />
        )}
        {staticData.accessibleScreens?.ReportingScreen?.create ? (
          <Button label="Create Report" variant="Create" onPress={onCreateReport} />
        ) : (
          <View />
        )}
      </View>

      <View
        style={{
          flexDirection: 'row',
          height: Layout.window.height - 150,
          justifyContent: 'space-between',
        }}
      >
        <View style={{ flex: 2 }}>
          <CFlatList
            emptyMessage={!isEmpty(filter) ? 'No results found' : 'No Reports yet'}
            emptyCTA={'Create Report'}
            emptyAction={onCreateReport}
            // subAction={
            //   <Text style={AppStyles.textSubTitle}>
            //     Importing bank statements will automatically create bank accounts if they do not
            //     exists
            //   </Text>
            // }
            data={filteredReprots || reports}
            isLoading={isLoading}
            isLoadingMore={isLoadingMore}
            // loadMore={() => loadMoreJobs(filter)}
            renderItem={({ item }: { item: CReport }) => (
              <TouchableOpacity
                onPress={() => {
                  setSelectedReport(item);
                  setIsDetailVisible(true);
                  // setMode(InputMode.EDIT);
                  // navigation.navigate('ReportingDetailsScreen', { report: item });
                }}
              >
                <Card style={AppStyles.card} mr={2} mt={1}>
                  <View style={[AppStyles.flexRowCenter, AppStyles.padding]}>
                    <View style={{ flex: 2 }}>
                      <Text style={AppStyles.textRowTitle}>{item.name}</Text>
                      <Text style={AppStyles.textSubTitle}>{item.description}</Text>
                    </View>
                    <View>
                      <Stack direction="row" spacing={4} justifyContent={'flex-end'} mb={2}>
                        {item?.isLocked && <LockIcon color={Colors.darkOrange} />}
                        <TouchableOpacity
                          onPress={() => {
                            setMode(InputMode.EDIT);
                            navigation.navigate('ReportingDetailsScreen', { report: item });
                          }}
                        >
                          <SettingsIcon color={Colors.primary} />
                        </TouchableOpacity>
                      </Stack>
                      <IconText
                        fontSize={Constants.TextSize.tiny}
                        icon={
                          <View style={AppStyles.flexRowCenter}>
                            <Text style={[AppStyles.textTiny]}>Last run</Text>
                          </View>
                        }
                        value={item?.lastRun ? item?.lastRun.toDate().toDateString() : 'Never'}
                        showEmpty={true}
                        textColor={Colors.black}
                      />
                    </View>
                  </View>
                </Card>
              </TouchableOpacity>
            )}
          />
        </View>
        <View style={{ flex: 3 }}></View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  bankAccountCard: {
    marginBottom: 10,
    marginHorizontal: 10,
    borderWidth: 2,
    flex: 1,
    borderRadius: 5,
    padding: 0,
  },
  triangle: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderTopWidth: 20,
    borderRightWidth: 0,
    borderBottomWidth: 20,
    borderLeftWidth: 15,
    borderTopColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: 'transparent',
    borderLeftColor: Colors.primary,
    position: 'absolute',
    left: 0,
    top: 35,
  },
});
