import React, { FC } from 'react';

import { useSelector } from 'react-redux';

import { Select } from 'chakra-react-select';
import { StaticDataStore } from '../commonTypes';
import { FormControl, FormLabel } from '@chakra-ui/react';

export type PaymentMethodInputProps = {
  title?: string;
  onSelect: (values: string[]) => void;
  value?: string;
  options?: string[];
  isReadOnly?: boolean;
  isRequired?: boolean;
  values?: string[];
  isMultiSelect?: boolean;
};
const PaymentMethodInput: FC<PaymentMethodInputProps> = ({
  onSelect,
  value,
  isRequired,
  isReadOnly = false,
  values,
  isMultiSelect,
  title,
  options,
}) => {
  const { staticData } = useSelector((store: StaticDataStore) => store);
  return (
    <FormControl isRequired={isRequired} width={300}>
      <FormLabel>{title || 'Payment Method'}</FormLabel>
      <Select
        isReadOnly={isReadOnly}
        isMulti={isMultiSelect}
        onChange={(selected) => {
          isMultiSelect ? onSelect(selected?.map((val) => val.value)) : onSelect([selected?.value]);
        }}
        closeMenuOnSelect={!isMultiSelect}
        value={
          isMultiSelect
            ? values?.map((val) => {
                return { value: val, label: val };
              })
            : value
            ? [{ value, label: value }]
            : []
        }
        name="PaymentMethod"
        options={
          Object.keys(staticData?.orgInfo?.PaymentMethods || {})
            ?.map((method) => {
              return { value: method, label: method };
            })
            .filter((method) => !options || options?.includes(method.value)) || []
        }
        placeholder="Select Payment Method"
        size="sm"
        menuPosition="fixed"
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
        }}
        chakraStyles={{
          menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
        }}
        menuPortalTarget={document.body}
      />
    </FormControl>
  );
};

export default PaymentMethodInput;
