import React, { FC, useMemo, useState } from 'react';

import moment from 'moment';
import { useSelector } from 'react-redux';

import Colors from '../../constants/Colors';
import Layout from '../../constants/Layout';
import AppStyles from '../../constants/Styles';
import { BankAccount, StaticDataStore, Status } from '../../commonTypes';
import CardBadge from '../CardBadge';
import { CFlatList } from '../CFlatList';
import { CModal } from '../CModal';
import { Text, View } from '../Themed';

const BankStatementWidget: FC = () => {
  const { staticData } = useSelector((store: StaticDataStore) => store);
  const [open, setOpen] = useState(false);
  const importedStatements = useMemo(
    () =>
      staticData.bankAccounts
        ?.filter((acc) => acc.status !== Status.DEACTIVATED)
        .map((account) =>
          account.id &&
          account.lastStatementValueDate &&
          moment(account.lastStatementValueDate?.toDate()).isSame(
            staticData.bankAccountPreviousBusinessDays?.[account.id],
            'day',
          )
            ? { ...account, isImported: true }
            : { ...account, isImported: false },
        ),
    [staticData.bankAccountPreviousBusinessDays, staticData.bankAccounts],
  );

  return (
    <>
      <CModal
        title="Prior Day bank statement import status"
        open={open}
        setOpen={setOpen}
        hideSaveButton
      >
        <CFlatList
          data={
            importedStatements
              ? [
                  ...importedStatements.filter((acc) => acc.isImported),
                  ...importedStatements.filter((acc) => !acc.isImported),
                ]
              : []
          }
          keyExtractor={(item: BankAccount) => item.id || item.accountNumber}
          renderItem={({ item }: { item: BankAccount }) => (
            <View
              style={[
                AppStyles.flexRowCenterSpaceBetween,
                AppStyles.marginTop,
                { width: Layout.window.width / 2 },
              ]}
            >
              <View>
                <Text style={AppStyles.textRowTitle}>{item.id}</Text>
                <Text style={AppStyles.textSubTitle}>{item.accountName}</Text>
              </View>
              <View style={AppStyles.flexRowCenter}>
                <Text>
                  {item.lastStatementValueDate
                    ? moment(item.lastStatementValueDate?.toDate()).format('DD MMM YYYY')
                    : 'No statements yet'}
                </Text>
                <View
                  style={{
                    width: 10,
                    height: 10,
                    borderRadius: 10,
                    marginLeft: 10,
                    backgroundColor: item.isImported ? Colors.green : Colors.red,
                  }}
                />
              </View>
            </View>
          )}
        />
      </CModal>
      <CardBadge
        label="Prior Day Statements"
        value={importedStatements?.filter((acc) => acc.isImported)?.length.toString() || '0'}
        onPress={() => setOpen(true)}
        additionalInfo={
          (importedStatements?.filter((acc) => !acc.isImported)?.length.toString() || '0') +
          ' not available yet'
        }
      />
    </>
  );
};

export default BankStatementWidget;
