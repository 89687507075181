/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { FontAwesome5 } from '@expo/vector-icons';
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  serverTimestamp,
  updateDoc,
  writeBatch,
  WriteBatch,
} from 'firebase/firestore';
import debounce from 'lodash/debounce';
import differenceBy from 'lodash/differenceBy';
import { FormControl, Input } from 'native-base';

import { useToast } from '@chakra-ui/react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../App';
import {
  AccountGroup,
  ApprovalWorkflowSource,
  BankAccount,
  InputMode,
  StaticDataStore,
  Status,
} from '../commonTypes';
import Button from '../components/Button';
import { CFlatList } from '../components/CFlatList';
import { FilterBar } from '../components/FilterBar';
import { FlyOut } from '../components/FlyOut';
import { Card, CTA, Text, View } from '../components/Themed';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import AppStyles from '../constants/Styles';
import { RootTabScreenProps } from '../types';
import { _getChangedFields, _searchList, _textFieldIsInvalid } from '../utils/helper';
import { isEmpty, omit } from 'lodash';
import StatusTag from '../components/StatusTag';
import { CModal } from '../components/CModal';
import AccountGroupDetails from '../components/BankAccounts/AccountGroupDetails';

export default function AccountGroupsScreen({
  navigation,
}: RootTabScreenProps<'AccountGroupsScreen'>) {
  const { profileData, staticData } = useSelector((store: StaticDataStore) => store);
  const canEdit = staticData.accessibleScreens?.UserGroupsScreen?.edit || false;
  const [accountGroups, setAccountGroups] = useState<AccountGroup[]>(
    staticData.accountGroups || [],
  );

  const [allAccounts, setAllAccounts] = useState<BankAccount[]>([]);
  const [storeAccounts, setStoreAccounts] = useState<BankAccount[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isAccountsLoading, setIsAccountsLoading] = useState(false);
  const [selectedAccountGroup, setSelectedAccountGroup] = useState<AccountGroup>();
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [editEnabled, setEditEnabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [inputMode, setInputMode] = useState(InputMode.EDIT);
  const [isDetailVisible, setIsDetailVisible] = useState(false);

  const [filteredAccountGroups, setFilteredAccountGroups] = useState<AccountGroup[] | undefined>();
  const { db } = useContext(AppContext);
  const toast = useToast();

  const isWorkflowEnabled =
    staticData?.orgInfo?.Workflow?.[ApprovalWorkflowSource.BANK_ACCOUNTS]?.status ||
    staticData?.orgInfo?.Workflow?.[ApprovalWorkflowSource.STATIC_DATA]?.status;

  const groupAccounts = useMemo(() => {
    return (
      storeAccounts?.filter(
        (acc) => selectedAccountGroup?.id && acc.accountGroups?.includes(selectedAccountGroup?.id),
      ) || []
    );
  }, [selectedAccountGroup?.id, storeAccounts]);

  const fetchAllAccounts = useCallback(() => {
    console.log('Fetching All Accounts');

    const _accounts: BankAccount[] = storeAccounts || [];

    setAllAccounts(
      _accounts.map((account) => {
        return account.accountGroups?.includes(selectedAccountGroup?.id || '')
          ? { ...account, selected: true }
          : account;
      }),
    );

    setIsAccountsLoading(false);
  }, [selectedAccountGroup?.id, storeAccounts]);

  const delayedStoreAccountUpdate = useCallback(
    debounce((_bankAccounts) => {
      setStoreAccounts(_bankAccounts);
      setIsAccountsLoading(false);
    }, 3000),
    [],
  );

  useEffect(() => {
    if (staticData?.bankAccounts) {
      setIsAccountsLoading(true);
      delayedStoreAccountUpdate(staticData?.bankAccounts);
    }
  }, [delayedStoreAccountUpdate, staticData?.bankAccounts]);

  const onSearchOrFilter = useCallback(
    (searchValue: string) => {
      if (searchValue && searchValue !== '') {
        setFilteredAccountGroups(_searchList(accountGroups, searchValue));
      } else {
        setFilteredAccountGroups(undefined);
      }
    },
    [accountGroups],
  );

  const onSave = useCallback(async () => {
    if (_textFieldIsInvalid(selectedAccountGroup?.name)) {
      return toast({
        title: 'Invalid group name',
        description: 'Please enter a valid name for the account group.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    setIsSaving(true);
    let accountGroupDoc = {
      ...selectedAccountGroup,
    };
    if (selectedIndex !== undefined && selectedAccountGroup?.id) {
      // Update
      const accountGroup = accountGroups[selectedIndex];
      const changedFields = _getChangedFields(accountGroup, selectedAccountGroup);
      if (isEmpty(changedFields)) {
        return;
      }
      const updateRequest = {
        ...changedFields,
        requestedBy: profileData.uid,
        requestId: uuidv4(),
        lastUpdatedAt: serverTimestamp(),
      };
      if (isWorkflowEnabled) {
        accountGroupDoc = {
          ...accountGroup,
          updateRequest: updateRequest,
          lastUpdatedBy: profileData.uid,
        };
      }
      await updateDoc(
        doc(db, 'Organizations', profileData.orgId, 'AccountGroups', selectedAccountGroup.id),
        isWorkflowEnabled
          ? { updateRequest: updateRequest }
          : {
              ...omit(accountGroupDoc, ['createdBy', 'createdDtTm']),
            },
      ).catch((e) => {
        toast({
          title: 'Something went wrong while updating the Account group',
          description: 'This could be a permission issue',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
      const selectedccountsForUpd = allAccounts.filter((account) => account.selected);

      const batchArray: WriteBatch[] = [];
      batchArray.push(writeBatch(db));
      let operationCounter = 0;
      let batchIndex = 0;
      differenceBy(selectedccountsForUpd, groupAccounts, 'id').map((account) => {
        console.warn(account.accountNumber);

        if (account.id && !account.accountGroups?.includes(selectedAccountGroup.id)) {
          const updateRequest = {
            accountGroups: [...(account.accountGroups || []), selectedAccountGroup.id],
            requestedBy: profileData.uid,
            requestId: uuidv4(),
            lastUpdatedAt: serverTimestamp(),
          };
          batchArray[batchIndex].update(
            doc(db, 'Organizations', profileData.orgId, 'BankAccounts', account.id),
            isWorkflowEnabled
              ? { updateRequest: updateRequest }
              : {
                  accountGroups: arrayUnion(selectedAccountGroup.id),
                  lastUpdatedBy: profileData.uid,
                },
          );
          operationCounter++;
          // await updateDoc(doc(db, 'Organizations', profileData.orgId, 'BankAccounts', account.id), {
          //   accountGroups: arrayUnion(selectedAccountGroup.id),
          // });
          if (operationCounter === 499) {
            batchArray.push(writeBatch(db));
            batchIndex++;
            operationCounter = 0;
          }
        }
      });

      differenceBy(groupAccounts, selectedccountsForUpd, 'id').map((account) => {
        if (account.id && account.accountGroups?.includes(selectedAccountGroup.id)) {
          const updateRequest = {
            accountGroups: account.accountGroups?.map((ag) => ag !== selectedAccountGroup.id) || [],
            requestedBy: profileData.uid,
            requestId: uuidv4(),
            lastUpdatedAt: serverTimestamp(),
          };
          batchArray[batchIndex].update(
            doc(db, 'Organizations', profileData.orgId, 'BankAccounts', account.id),
            isWorkflowEnabled
              ? { updateRequest: updateRequest }
              : {
                  accountGroups: arrayRemove(selectedAccountGroup.id),
                  lastUpdatedBy: profileData.uid,
                },
          );
          operationCounter++;
          // await updateDoc(doc(db, 'Organizations', profileData.orgId, 'BankAccounts', account.id), {
          //   accountGroups: arrayRemove(selectedAccountGroup.id),
          // });
          if (operationCounter === 499) {
            batchArray.push(writeBatch(db));
            batchIndex++;
            operationCounter = 0;
          }
        }
      });
      const promises = batchArray.map(
        async (batch) =>
          // eslint-disable-next-line no-return-await
          await batch.commit().catch((e) => {
            // console.log(e);
            //errorsExist = true;
            console.warn('error encountered');
            console.warn(e);
            toast({
              title: 'Unable to update the bank accounts related to this group',
              description: 'This could be a permission issue',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
          }),
      );
      await Promise.all(promises)
        .then(() => {
          console.warn('Completed Updating Accounts');
        })
        .catch((error) => {
          console.warn('Something went wrong');
          console.warn(error);
        });

      // setAccounts(selectedccountsForUpd);
      const _accountGroups = filteredAccountGroups || accountGroups;
      _accountGroups[selectedIndex] = accountGroupDoc;
      if (filteredAccountGroups) {
        setFilteredAccountGroups(_accountGroups);
        const _AllAccounts = accountGroups;
        _AllAccounts[_AllAccounts.findIndex((bankAcc) => bankAcc.id === selectedAccountGroup.id)] =
          accountGroupDoc;
        setAccountGroups(_AllAccounts);
      } else {
        setAccountGroups(_accountGroups);
      }
      setOpen(false);
    } else if (selectedAccountGroup) {
      if (isWorkflowEnabled) {
        accountGroupDoc = {
          ...accountGroupDoc,
          status: Status.PENDING_APPROVAL,
        };
      }
      const newAccountGroup = await addDoc(
        collection(db, 'Organizations', profileData.orgId, 'AccountGroups'),
        {
          ...accountGroupDoc,
          createdBy: profileData.uid,
          createdDtTm: serverTimestamp(),
        } as AccountGroup,
      );
      setAccountGroups((currentValue) => [
        { ...selectedAccountGroup, id: newAccountGroup.id },
        ...(currentValue || []),
      ]);
      if (filteredAccountGroups) {
        setFilteredAccountGroups((currentValue) => [
          { ...selectedAccountGroup, id: newAccountGroup.id },
          ...(currentValue || []),
        ]);
      }
      setSelectedAccountGroup((currentValue: any) => {
        return { ...currentValue, id: newAccountGroup.id };
      });
      setSelectedIndex(0);
      setEditEnabled(true);
      setOpen(false);
    }

    toast({
      title: `Account Group ${selectedAccountGroup?.id ? 'Updated' : 'Created'}`,
      description: isWorkflowEnabled
        ? 'Accounts added or removed are pending for approval'
        : 'Accounts related to the group have been updated successfully',
      status: isWorkflowEnabled ? 'warning' : 'success',
      duration: 5000,
      isClosable: true,
    });

    setIsSaving(false);
    setEditEnabled(false);
  }, [
    accountGroups,
    groupAccounts,
    allAccounts,
    db,
    filteredAccountGroups,
    profileData.orgId,
    profileData.uid,
    selectedAccountGroup,
    selectedIndex,
    toast,
  ]);

  const onTextChange = useCallback((field: keyof AccountGroup, value: string) => {
    setSelectedAccountGroup((existingValue) => {
      return { ...existingValue, [field]: value } as AccountGroup;
    });
  }, []);

  const onAccountGroupCreatedOrUpdated = useCallback(
    (updatedAccountGroup: AccountGroup) => {
      if (selectedIndex !== undefined && updatedAccountGroup?.name) {
        //Updating existing account
        const _accountGroups = filteredAccountGroups || accountGroups;
        _accountGroups[selectedIndex] = updatedAccountGroup;
        if (filteredAccountGroups) {
          setFilteredAccountGroups(_accountGroups);
          const _AllUserGroups = _accountGroups;
          _AllUserGroups[
            _AllUserGroups.findIndex((accountGroup) => accountGroup.id === updatedAccountGroup.id)
          ] = updatedAccountGroup;
          setAccountGroups(_AllUserGroups);
        } else {
          setAccountGroups(_accountGroups);
        }
      } else if (updatedAccountGroup) {
        //Create new account
        setAccountGroups((currentValue) => [
          {
            ...updatedAccountGroup,
          },
          ...currentValue,
        ]);
        if (filteredAccountGroups) {
          setFilteredAccountGroups((currentValue) => [
            {
              ...updatedAccountGroup,
            },
            ...(currentValue || []),
          ]);
        }
      } else {
        setIsDetailVisible(false);
        return toast({
          title: 'Something went worong',
          description: 'Please try again later',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    },
    [accountGroups, filteredAccountGroups, selectedIndex, toast],
  );

  return (
    <View style={AppStyles.container}>
      <FlyOut
        onSave={onSave}
        open={open}
        setOpen={(value) => setOpen(value)}
        title={selectedAccountGroup?.name || 'New Account Group'}
      >
        {selectedAccountGroup ? (
          <>
            <FormControl>
              <FormControl.Label>Account Group Name</FormControl.Label>
              <Input
                value={selectedAccountGroup.name}
                onChangeText={(value) => onTextChange('name', value)}
              />
            </FormControl>
            <FormControl>
              <FormControl.Label>Account Group Description</FormControl.Label>
              <Input
                value={selectedAccountGroup.description}
                onChangeText={(value) => onTextChange('description', value)}
              />
            </FormControl>
          </>
        ) : (
          <></>
        )}
      </FlyOut>
      {selectedAccountGroup && (
        <CModal
          open={isDetailVisible}
          setOpen={(value) => setIsDetailVisible(value)}
          title={selectedAccountGroup?.name || 'Account Group'}
          hideButtons
        >
          <AccountGroupDetails
            setIsVisible={setIsDetailVisible}
            accountGroup={selectedAccountGroup}
            onAccountGroupUpdated={onAccountGroupCreatedOrUpdated}
            mode={canEdit ? inputMode || InputMode.EDIT : InputMode.VIEW}
          />
        </CModal>
      )}
      <View style={[AppStyles.flexRowCenterSpaceBetween, AppStyles.marginTop]}>
        {accountGroups && accountGroups.length > 0 ? (
          <FilterBar
            onSearch={onSearchOrFilter}
            searchPlaceholder={'Search by Name or Description'}
          />
        ) : (
          <View />
        )}

        <Button
          label="Create Account Group"
          variant="Create"
          onPress={() => {
            setSelectedAccountGroup({ name: '', description: '' } as AccountGroup);
            setSelectedIndex(undefined);
            setOpen(true);
          }}
          hidden={!staticData.accessibleScreens?.AccountGroupsScreen?.create}
        />
      </View>

      <View
        style={{
          flexDirection: 'row',
          height: Layout.window.height - 150,
        }}
      >
        <View style={{ width: Layout.window.width * 0.3 }}>
          <CFlatList
            data={filteredAccountGroups || accountGroups}
            isLoading={isLoading}
            emptyMessage={
              filteredAccountGroups === undefined
                ? 'No Account groups yet or \n You may not have access to them'
                : 'No results found'
            }
            subAction={
              <Text style={[AppStyles.textSubTitle, { textAlign: 'center' }]}>
                Group accounts for summarized cash positioning and transaction rules
              </Text>
            }
            renderItem={({ item, index }) => (
              <TouchableOpacity
                onPress={() => {
                  setSelectedAccountGroup(item);
                  setSelectedIndex(index);
                }}
                key={item.id}
              >
                <Card
                  style={[
                    styles.bankAccountCard,
                    selectedAccountGroup?.id === item.id && { borderColor: Colors.primary },
                  ]}
                >
                  <View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                      <Text style={AppStyles.textRowTitle}>{item.name}</Text>
                      <View style={[AppStyles.flex1]}>
                        <StatusTag label={item.status || Status.ACTIVE} isReadOnly />
                        {item.updateRequest && <StatusTag label={'Pending Updates'} isReadOnly />}
                      </View>
                    </View>
                    <Text style={AppStyles.textSubTitle}>{item.description}</Text>
                  </View>
                </Card>
              </TouchableOpacity>
            )}
          />
        </View>
        <View style={{ width: Layout.window.width * (editEnabled ? 0.6 : 0.3), marginRight: 10 }}>
          {selectedAccountGroup && selectedIndex !== undefined && (
            <View
              style={{
                flexDirection: 'row',
                borderWidth: 2,
                borderColor: Colors.primary,
                height: Layout.window.height * 0.8,
                borderRadius: 10,
              }}
            >
              <View style={{ flex: 1, padding: 10, justifyContent: 'space-between' }}>
                <View style={{ flex: 1 }}>
                  <View style={AppStyles.flexRowCenterSpaceBetween}>
                    <Text style={[AppStyles.textRowTitle, { marginBottom: 10 }]}>
                      {selectedAccountGroup.name}
                    </Text>
                    {!editEnabled && canEdit && (
                      <CTA
                        label="Edit"
                        icon="pencil"
                        buttonColor={Colors.orange}
                        onPress={() => {
                          // fetchAllAccounts();
                          // setEditEnabled(true);
                          if (selectedAccountGroup.status === Status.ACTIVE) {
                            setInputMode(InputMode.EDIT);
                            setIsDetailVisible(true);
                          } else {
                            toast({
                              title: 'Account Group is not editable',
                              description: 'Can be edited only when the status is Active',
                              status: 'warning',
                              duration: 5000,
                              isClosable: true,
                            });
                          }
                        }}
                      />
                    )}
                  </View>
                  {editEnabled && (
                    <>
                      <FormControl>
                        <FormControl.Label>Account Group Name</FormControl.Label>
                        <Input
                          value={selectedAccountGroup.name}
                          onChangeText={(value) => onTextChange('name', value)}
                        />
                      </FormControl>
                      <FormControl>
                        <FormControl.Label>Account Group Description</FormControl.Label>
                        <Input
                          value={selectedAccountGroup.description}
                          onChangeText={(value) => onTextChange('description', value)}
                        />
                      </FormControl>
                    </>
                  )}
                  {!editEnabled && (
                    <CFlatList
                      emptyMessage="No Accounts in this group yet "
                      data={groupAccounts}
                      isLoading={isAccountsLoading}
                      renderItem={({ item, index }) => (
                        <Card key={item.id}>
                          <View style={AppStyles.flexRowCenterSpaceBetween}>
                            <Text>{item.accountNumber}</Text>
                            <Text>{item.currency}</Text>
                          </View>
                          <Text style={{ color: Colors.light_grey }}>{item.accountName}</Text>
                        </Card>
                      )}
                    />
                  )}
                </View>
                {editEnabled && (
                  <View style={AppStyles.flexRowCenterSpaceAround}>
                    <Button
                      label="Cancel"
                      variant={'Cancel'}
                      onPress={() => setEditEnabled(false)}
                      isLoading={isSaving}
                    />
                    <Button label="Save Changes" onPress={onSave} isLoading={isSaving} />
                  </View>
                )}
              </View>
              {editEnabled && (
                <View
                  style={{
                    flex: 1,
                    borderRightWidth: 2,
                    borderRightColor: Colors.light_grey,
                    padding: 10,
                  }}
                >
                  <Text style={[AppStyles.textSubTitle, { marginBottom: 10 }]}>
                    Select accounts to add to the group
                  </Text>
                  <CFlatList
                    emptyMessage="No more accounts to add"
                    data={allAccounts}
                    isLoading={isAccountsLoading}
                    renderItem={({ item, index }) => (
                      <TouchableOpacity
                        style={{ marginTop: 5 }}
                        onPress={() => {
                          setAllAccounts((currentValue) =>
                            currentValue.map((val, ind) =>
                              ind === index ? Object.assign(val, { selected: !val.selected }) : val,
                            ),
                          );
                        }}
                      >
                        <Card key={item.id}>
                          <View style={AppStyles.flexRowCenterSpaceBetween}>
                            <Text>{item.accountNumber}</Text>
                            <View style={AppStyles.flexRowCenter}>
                              <Text style={{ marginRight: 5 }}>{item.currency}</Text>
                              {item.selected ? (
                                <FontAwesome5 name={'check'} size={12} color={Colors.primary} />
                              ) : (
                                <View style={{ width: 12 }} />
                              )}
                            </View>
                          </View>
                          <Text style={{ color: Colors.light_grey }}>{item.accountName}</Text>
                        </Card>
                      </TouchableOpacity>
                    )}
                  />
                </View>
              )}
            </View>
          )}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  bankAccountCard: {
    marginBottom: 10,
    marginHorizontal: 10,
    borderWidth: 2,
  },
});
