import React, { FC, useState } from 'react';

import { FontAwesome5 } from '@expo/vector-icons';
import { TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';

import Colors from '../../constants/Colors';
import AppStyles from '../../constants/Styles';
import { AccountGroup, StaticDataStore } from '../../commonTypes';
import { CFlatList } from '../CFlatList';
import { Card, Text, View } from '../Themed';

export type AccountGroupSelectionListProps<T> = {
  record: T;
  setRecord: (record: T) => void;
  isReadOnly?: boolean;
};
const AccountGroupSelectionList: FC<AccountGroupSelectionListProps<any>> = ({
  record,
  setRecord,
  isReadOnly,
}) => {
  const { staticData } = useSelector((store: StaticDataStore) => store);
  const [allAccountGroups] = useState<AccountGroup[]>(staticData.accountGroups || []);

  return (
    <CFlatList
      style={{ width: '50%' }}
      emptyMessage="No more account groups to add"
      data={allAccountGroups.map((ag) => {
        return { ...ag, selected: record?.accountGroups?.includes(ag.id) };
      })}
      isLoading={false}
      renderItem={({ item }: { item: AccountGroup; index: number }) => (
        <TouchableOpacity
          disabled={isReadOnly}
          style={{ marginTop: 5 }}
          onPress={() => {
            setRecord((existingValue: any) => {
              const existingAccountGroups = existingValue?.accountGroups || [];
              const newAccountGroups = item.selected
                ? existingAccountGroups.filter((x: string) => x !== item.id)
                : [...existingAccountGroups, item.id];
              return { ...existingValue, accountGroups: newAccountGroups };
            });
          }}
        >
          <Card key={item.id}>
            <View style={AppStyles.flexRowCenterSpaceBetween}>
              <Text>{item.name}</Text>

              {item.selected ? (
                <FontAwesome5 name={'check'} size={12} color={Colors.primary} />
              ) : (
                <View style={{ width: 12 }} />
              )}
            </View>
          </Card>
        </TouchableOpacity>
      )}
    />
  );
};

export default AccountGroupSelectionList;
