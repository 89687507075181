import React, { FC } from 'react';

import { Select } from 'chakra-react-select';
import { FormControl, FormLabel, SpaceProps } from '@chakra-ui/react';
import CToolTip from './CToolTip';

export type CDropdownProps = {
  options: { value: string; label: string }[];
  value?: string;
  placeholder: string;
  name: string;
  onChange?: (value?: string) => void;
  isRequired?: boolean;
  title?: string;
  width?: number;
  ml?: SpaceProps['ml'];
  tooltip?: string;
  isLockIcon?: boolean;
  disabled?: boolean;
};
const CDropdown: FC<CDropdownProps> = ({
  value,
  options,
  placeholder,
  name,
  onChange,
  isRequired,
  title,
  width,
  ml,
  tooltip,
  isLockIcon,
  disabled,
}) => {
  return (
    <FormControl ml={ml} isRequired={isRequired} width={width || 300}>
      {title && (
        <FormLabel>
          {title}
          {tooltip && <CToolTip label={tooltip} isLockIcon={isLockIcon} />}
        </FormLabel>
      )}
      <Select
        isMulti={false}
        onChange={(selected) => {
          onChange?.(selected?.value);
        }}
        isDisabled={disabled}
        closeMenuOnSelect={true}
        value={{ value, label: options?.find((option) => option.value === value)?.label }}
        name={name}
        options={options}
        placeholder={placeholder}
        size="sm"
        menuPosition="fixed"
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
        }}
        chakraStyles={{
          menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
        }}
        menuPortalTarget={document.body}
      />
    </FormControl>
  );
};

export default CDropdown;
