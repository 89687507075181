import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import * as WebDataRocksReact from '@webdatarocks/react-webdatarocks';
import { CReport } from '../../commonTypes';
import useReporting from '../../hooks/useReporting';
import { CModal } from '../CModal';
import { FilterBar } from '../FilterBar';
import { View } from '../Themed';
import Layout from '../../constants/Layout';

export type ReportRunModalProps = {
  report?: CReport;
  setReport: Dispatch<SetStateAction<CReport | undefined>>;
};

export default function ReportRunModal({ report, setReport }: ReportRunModalProps) {
  const [dataLimit, setDataLimit] = useState<number | undefined>(10);
  const [reportDetails, setReportDetails] = useState<CReport | undefined>(report);
  const { fetchData, formatFilterValues } = useReporting();
  const [isLoading, setIsLoading] = useState(false);
  const reportRef = useRef<any>();

  useEffect(() => {
    if (report) {
      const _filters = formatFilterValues(report?.filters, report?.dataSource);
      setReportDetails({ ...(report || {}), filters: _filters } as CReport);
    }
  }, [report]);

  useEffect(() => {
    console.warn('reportRef.current?.webdatarocks', reportRef.current?.webdatarocks);

    if (reportDetails?.dataSource) {
      fetchData(reportDetails, dataLimit, setIsLoading, reportRef);
    }
  }, [reportDetails, dataLimit]);

  function customizeToolbar(toolbar) {
    // Get all tabs from the toolbar
    const tabs = toolbar.getTabs();
    toolbar.getTabs = function () {
      // Delete the first tab
      delete tabs[0];
      delete tabs[1];
      delete tabs[2];
      delete tabs[7];
      tabs[3].rightGroup = true;
      return tabs;
    };
  }

  return (
    <CModal
      open={!!reportDetails}
      setOpen={(value) => {
        if (!value) {
          setReport(undefined);
        }
      }}
      title={reportDetails?.name || 'Report'}
      hideButtons
      onClose={() => {
        setReportDetails(undefined);
      }}
      height={Layout.window.height * 0.9}
    >
      <View>
        {reportDetails?.filterParams && (
          <FilterBar
            hideSearch
            filterParams={reportDetails?.filterParams}
            onSearch={() => null}
            filter={reportDetails?.filters}
            updateFilter={(value) =>
              setReportDetails((currentValue) => ({ ...currentValue, filters: value }))
            }
          />
        )}

        <WebDataRocksReact.Pivot
          key={reportDetails?.dataSource}
          ref={reportRef}
          beforetoolbarcreated={customizeToolbar}
          toolbar={true}
          width="100%"
        />
      </View>
    </CModal>
  );
}
