/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useContext, useEffect, useState } from 'react';

import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import { FormControl, Input } from 'native-base';
import { ScrollView, StyleSheet, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';
import { AppContext } from '../App';
import {
  AccountGroup,
  BankTransactionRule,
  StaticDataStore,
  Status,
  TransactionCategory,
} from '../commonTypes';
import Button from '../components/Button';
import { CAlertDialog } from '../components/CAlertDialog';
import { CFlatList } from '../components/CFlatList';
import { FilterBar } from '../components/FilterBar';
import { MultiSelectInput } from '../components/MultiSelectInput';
import { MultiTextInput } from '../components/MultiTextInput';
import StatusTag from '../components/StatusTag';
import { Card, Text, View } from '../components/Themed';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import AppStyles from '../constants/Styles';
import { RootTabScreenProps } from '../types';
import { _searchList } from '../utils/helper';

export default function BankTransactionRulesScreen({
  navigation,
}: RootTabScreenProps<'BankTransactionRulesScreen'>) {
  const [bankTransactionRules, setBankTransactionRules] = useState<BankTransactionRule[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedBankTransactionRule, setSelectedBankTransactionRule] =
    useState<BankTransactionRule>();
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [editEnabled, setEditEnabled] = useState(false);
  const [alertContent, setAlertContent] = useState({
    title: '',
    content: '',
    actionText: '',
    colorScheme: '',
    onAction: () => null,
  });

  const [filteredBankTransactionRules, setFilteredBankTransactionRules] = useState<
    BankTransactionRule[] | undefined
  >();
  const { db } = useContext(AppContext);
  const toast = useToast();
  const { profileData, staticData } = useSelector((store: StaticDataStore) => store);
  const [isDeactivateAlertOpen, setIsDeactivateAlertOpen] = useState(false);

  const fetchBankTransactionRules = useCallback(async () => {
    console.log('Fetching Bank Transaction Rules');
    const q = query(collection(db, 'Organizations', profileData.orgId, 'BankTransactionRules'));
    const querySnapshot = await getDocs(q);
    const _bankTransactionRules: BankTransactionRule[] = [];
    querySnapshot.forEach((doc) => {
      _bankTransactionRules.push({ ...doc.data(), id: doc.id } as BankTransactionRule);
    });
    setBankTransactionRules(_bankTransactionRules);
    // setSelectedBankTransactionRule(_bankTransactionRules[0]);
    // setSelectedIndex(0);

    setIsLoading(false);
  }, [db, profileData.orgId]);

  useEffect(() => {
    fetchBankTransactionRules();
  }, [fetchBankTransactionRules]);

  const onSearchOrFilter = useCallback(
    (searchValue: string) => {
      if (searchValue && searchValue !== '') {
        setFilteredBankTransactionRules(_searchList(bankTransactionRules, searchValue));
      } else {
        setFilteredBankTransactionRules(undefined);
      }
    },
    [bankTransactionRules],
  );

  const onSave = useCallback(async () => {
    if (!selectedBankTransactionRule?.name) {
      return toast({
        title: 'Invalid rule name',
        description: 'Please enter a valid name',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    if (!selectedBankTransactionRule?.category) {
      return toast({
        title: 'Invalid Rule Category',
        description: 'Please select a valid category to assign the matching transactions to',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }

    if (!selectedBankTransactionRule?.code || selectedBankTransactionRule?.code.length === 0) {
      return toast({
        title: 'Enter a Transaction code',
        description: 'Atleast one transaction code is required for a rule',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    if (
      selectedBankTransactionRule?.priority &&
      isNaN(parseFloat(selectedBankTransactionRule?.priority?.toString()))
    ) {
      return toast({
        title: 'Invalid rule priority',
        description: 'Please enter a valid number for priority',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    setIsSaving(true);
    const ruleDocument = {
      name: selectedBankTransactionRule.name,
      description: selectedBankTransactionRule.description,
      priority: parseFloat(selectedBankTransactionRule.priority?.toString()) || 0,
      category: selectedBankTransactionRule.category.id,
      accountGroups:
        selectedBankTransactionRule.accountGroups &&
        selectedBankTransactionRule.accountGroups?.length
          ? selectedBankTransactionRule.accountGroups?.map((ag) => ag.id)
          : ['*'],
      accounts:
        selectedBankTransactionRule.accounts && selectedBankTransactionRule.accounts?.length
          ? selectedBankTransactionRule.accounts?.map((ac) => ac.id)
          : ['*'],
      keyWords:
        selectedBankTransactionRule.keyWords && selectedBankTransactionRule.keyWords?.length
          ? selectedBankTransactionRule.keyWords
          : ['*'],
      code: selectedBankTransactionRule.code,
      status: 'Active',
    } as BankTransactionRule;
    console.warn('ruleDocument', ruleDocument);
    if (selectedIndex !== undefined && selectedBankTransactionRule?.id) {
      await updateDoc(
        doc(
          db,
          'Organizations',
          profileData.orgId,
          'BankTransactionRules',
          selectedBankTransactionRule.id,
        ),
        ruleDocument,
      ).catch((e) => {
        console.warn(e);
      });

      const _bankTransactionRules = filteredBankTransactionRules || bankTransactionRules;
      _bankTransactionRules[selectedIndex] = selectedBankTransactionRule;
      if (filteredBankTransactionRules) {
        setFilteredBankTransactionRules(_bankTransactionRules);
        const _AllRules = bankTransactionRules;
        _AllRules[_AllRules.findIndex((bankAcc) => bankAcc.id === selectedBankTransactionRule.id)] =
          selectedBankTransactionRule;
        setBankTransactionRules(_AllRules);
      } else {
        setBankTransactionRules(_bankTransactionRules);
      }
    } else {
      const newRule = await addDoc(
        collection(db, 'Organizations', profileData.orgId, 'BankTransactionRules'),
        {
          ...ruleDocument,
          createdBy: profileData.uid,
          createdDtTm: serverTimestamp(),
        } as BankTransactionRule,
      );
      setBankTransactionRules((currentValue) => [
        { ...ruleDocument, id: newRule.id },
        ...currentValue,
      ]);
    }

    toast({
      title: `Rule ${selectedBankTransactionRule?.id ? 'Updated' : 'Created'}`,
      status: 'success',
      duration: 5000,
      isClosable: true,
    });

    setIsSaving(false);
    setEditEnabled(false);
  }, [
    bankTransactionRules,
    db,
    filteredBankTransactionRules,
    profileData.orgId,
    profileData.uid,
    selectedBankTransactionRule,
    selectedIndex,
    toast,
  ]);

  const onTextChange = useCallback((field: keyof BankTransactionRule, value: string) => {
    setSelectedBankTransactionRule((existingValue) => {
      return { ...existingValue, [field]: value } as BankTransactionRule;
    });
  }, []);

  const deactivateActivateRule = useCallback(
    async (status: string) => {
      setIsSaving(true);
      if (selectedBankTransactionRule?.id) {
        await updateDoc(
          doc(
            db,
            'Organizations',
            profileData.orgId,
            'BankTransactionRules',
            selectedBankTransactionRule.id,
          ),
          { status: status },
        ).catch((e) => {
          console.warn(e);
        });
        const _bankTransactionRules = filteredBankTransactionRules || bankTransactionRules;
        _bankTransactionRules[selectedIndex] = { ...selectedBankTransactionRule, status: status };
        if (filteredBankTransactionRules) {
          setFilteredBankTransactionRules(_bankTransactionRules);
          const _AllRules = bankTransactionRules;
          _AllRules[
            _AllRules.findIndex((bankAcc) => bankAcc.id === selectedBankTransactionRule.id)
          ] = selectedBankTransactionRule;
          setBankTransactionRules(_AllRules);
        } else {
          setBankTransactionRules(_bankTransactionRules);
        }
        setSelectedBankTransactionRule((currentValue) => {
          return { ...currentValue, status: status };
        });
        setIsSaving(false);
        setEditEnabled(false);
      }
    },
    [
      bankTransactionRules,
      db,
      filteredBankTransactionRules,
      profileData.orgId,
      selectedBankTransactionRule,
      selectedIndex,
    ],
  );

  return (
    <View style={AppStyles.container}>
      <CAlertDialog
        open={isDeactivateAlertOpen}
        setOpen={setIsDeactivateAlertOpen}
        title={alertContent.title}
        content={alertContent.content}
        actionText={alertContent.actionText}
        colorScheme={alertContent.colorScheme}
        onAction={alertContent.onAction}
      />
      <View style={[AppStyles.flexRowCenterSpaceBetween, AppStyles.marginTop]}>
        {bankTransactionRules && bankTransactionRules.length > 0 ? (
          <FilterBar
            onSearch={onSearchOrFilter}
            searchPlaceholder={'Search by Name or Description'}
          />
        ) : (
          <View />
        )}

        <Button
          label="Create Transaction Rule"
          variant="Create"
          onPress={() => {
            setSelectedBankTransactionRule({
              name: '',
              description: '',
              priority: 1,
              keyWords: [],
              code: [],
              accountGroups: [],
              accounts: [],
              status: 'Active',
            } as BankTransactionRule);
            setSelectedIndex(undefined);
            setEditEnabled(true);
          }}
        />
      </View>

      <View
        style={{
          flexDirection: 'row',
          height: Layout.window.height - 150,
        }}
      >
        <View style={{ width: Layout.window.width * 0.3 }}>
          <CFlatList
            emptyMessage={
              filteredBankTransactionRules === undefined ? 'No rules yet' : 'No results found'
            }
            data={filteredBankTransactionRules || bankTransactionRules}
            isLoading={isLoading}
            renderItem={({ item, index }: { item: BankTransactionRule; index: number }) => (
              <TouchableOpacity
                onPress={() => {
                  setSelectedBankTransactionRule({
                    ...item,
                    category: staticData.categories?.find((c) => c.id === item.category),
                    accounts: item.accounts.includes('*')
                      ? []
                      : item.accounts.map((account: string) =>
                          staticData.bankAccounts?.find((acc) => acc.id === account),
                        ),
                    accountGroups: item.accountGroups.includes('*')
                      ? []
                      : item.accountGroups.map((accGroup: string) =>
                          staticData.accountGroups?.find((acc) => acc.id === accGroup),
                        ),
                  });
                  setSelectedIndex(index);
                  setEditEnabled(false);
                }}
                key={item.id}
              >
                <Card
                  style={[
                    styles.bankAccountCard,
                    selectedBankTransactionRule?.id === item.id && { borderColor: Colors.primary },
                  ]}
                >
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View>
                      <Text style={AppStyles.textRowTitle}>{item.name}</Text>
                      <Text style={AppStyles.textSubTitle}>{item.description}</Text>
                    </View>
                    <StatusTag isReadOnly label={item.status} hideActive />
                  </View>
                </Card>
              </TouchableOpacity>
            )}
          />
        </View>
        {selectedBankTransactionRule && (
          <View style={AppStyles.width60}>
            <View style={[AppStyles.flexRowCenterSpaceBetween, { marginHorizontal: 10 }]}>
              <Text style={[AppStyles.textRowTitle, { marginBottom: 10 }]}>
                {selectedBankTransactionRule.name || 'Transaction Rule'}
              </Text>
            </View>
            <ScrollView
              style={{
                maxHeight: Layout.window.height - 50,
                marginRight: 10,
                borderWidth: 2,
                borderColor: Colors.primary,
                borderRadius: 10,
              }}
            >
              <View style={{ flex: 1, padding: 10, paddingBottom: 100 }}>
                <>
                  <FormControl>
                    <FormControl.Label>Rule Name</FormControl.Label>
                    <Input
                      value={selectedBankTransactionRule.name}
                      onChangeText={(value) => onTextChange('name', value)}
                      isReadOnly={!editEnabled}
                    />
                  </FormControl>
                  <FormControl>
                    <FormControl.Label>Rule Description</FormControl.Label>
                    <Input
                      value={selectedBankTransactionRule.description}
                      onChangeText={(value) => onTextChange('description', value)}
                      isReadOnly={!editEnabled}
                    />
                  </FormControl>
                  <FormControl>
                    <FormControl.Label>Rule Priority</FormControl.Label>
                    <Input
                      value={selectedBankTransactionRule.priority?.toString()}
                      onChangeText={(value) => onTextChange('priority', value)}
                      isReadOnly={!editEnabled}
                    />
                  </FormControl>
                  <MultiSelectInput
                    data={staticData.bankAccounts}
                    title={'Bank Acccount'}
                    onSelect={(values) => {
                      setSelectedBankTransactionRule((currentValue) => {
                        return { ...currentValue, accounts: values };
                      });
                    }}
                    selectedValues={selectedBankTransactionRule.accounts}
                    label={'accountNumber'}
                    isReadOnly={!editEnabled}
                  />
                  <MultiSelectInput
                    data={staticData.accountGroups}
                    title={'Account Groups'}
                    onSelect={(values) => {
                      setSelectedBankTransactionRule((currentValue) => {
                        return { ...currentValue, accountGroups: values };
                      });
                    }}
                    selectedValues={selectedBankTransactionRule.accountGroups}
                    label={'name'}
                    renderItem={(item: AccountGroup) => <Text>{item?.name}</Text>}
                    isReadOnly={!editEnabled}
                  />
                  <MultiTextInput
                    title={'Transaction Codes'}
                    selectedValues={selectedBankTransactionRule.code}
                    onSelect={(values) =>
                      setSelectedBankTransactionRule((currentVlaue) => {
                        return { ...currentVlaue, code: values };
                      })
                    }
                    isReadOnly={!editEnabled}
                  />
                  <MultiTextInput
                    title={'Match Text'}
                    selectedValues={selectedBankTransactionRule.keyWords}
                    onSelect={(values) =>
                      setSelectedBankTransactionRule((currentVlaue) => {
                        return { ...currentVlaue, keyWords: values };
                      })
                    }
                    isReadOnly={!editEnabled}
                  />
                  <MultiSelectInput
                    isSingleSelect
                    data={staticData.categories}
                    label={'name'}
                    title={'Category to assign to'}
                    onSelect={(values) => {
                      setSelectedBankTransactionRule((currentValue) => {
                        return { ...currentValue, category: values?.[0] || null };
                      });
                    }}
                    selectedValues={
                      selectedBankTransactionRule.category
                        ? [selectedBankTransactionRule.category]
                        : []
                    }
                    renderItem={(item: TransactionCategory) => <Text>{item?.name}</Text>}
                    isReadOnly={!editEnabled}
                  />
                </>
              </View>
            </ScrollView>

            <View style={[AppStyles.flexRowCenter, AppStyles.floatingBottonRight]}>
              <Button
                onPress={() => {
                  setEditEnabled(false);
                }}
                isDisabled={isLoading}
                hidden={!editEnabled}
                variant="Cancel"
                label="Cancel"
              />
              <Button
                label="Activate"
                onPress={() => {
                  setAlertContent({
                    title: 'Activate Rule',
                    content:
                      'This rule will be applied to matching transactions going forward. Existing transactions will remain unchanged until the rules are re-applied',
                    actionText: 'Activate',
                    colorScheme: 'lime',
                    onAction: () => deactivateActivateRule('Active'),
                  });
                  setIsDeactivateAlertOpen(true);
                }}
                hidden={!editEnabled || selectedBankTransactionRule?.status === Status.ACTIVE}
                colorScheme="green"
                mr={2}
                isLoading={isSaving}
              />
              <Button
                label="Deactivate"
                onPress={() => {
                  {
                    setAlertContent({
                      title: 'Deactivate Rule',
                      content:
                        'This rule will not be applied to any transaction going forward. All existing transactions will remain unchanged',
                      actionText: 'Deactivate',
                      colorScheme: 'danger',
                      onAction: () => deactivateActivateRule('Deactivated'),
                    });
                    setIsDeactivateAlertOpen(true);
                  }
                }}
                hidden={
                  !editEnabled ||
                  selectedBankTransactionRule?.status !== Status.ACTIVE ||
                  !selectedBankTransactionRule?.id
                }
                colorScheme="red"
                isLoading={isSaving}
              />
              <Button
                label="Edit"
                variant={'Edit'}
                onPress={() => {
                  setEditEnabled(true);
                }}
                hidden={editEnabled}
              />
              <Button
                hidden={!editEnabled}
                onPress={onSave}
                isLoading={isSaving}
                label={selectedBankTransactionRule?.id ? 'Save Changes' : 'Create Rule'}
              />
            </View>
          </View>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  bankAccountCard: {
    marginBottom: 10,
    marginHorizontal: 10,
    borderWidth: 2,
  },
});
