/* eslint-disable react-native/no-inline-styles */

import React, { Dispatch, SetStateAction } from 'react';

import omit from 'lodash/omit';
import { useSelector } from 'react-redux';

import { CFlatList } from './CFlatList';
import { CModal } from './CModal';
import { Text, View } from './Themed';
import Layout from '../constants/Layout';
import AppStyles from '../constants/Styles';
import { StaticDataStore } from '../comonTypes';

export type CurrencyRateSummaryModalProps = {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
};

export default function CurrencyRateSummaryModal({
  isVisible,
  setIsVisible,
}: CurrencyRateSummaryModalProps) {
  const { staticData } = useSelector((store: StaticDataStore) => store);

  return (
    <CModal
      hideSaveButton
      open={isVisible}
      setOpen={(value) => setIsVisible(value)}
      title={'Exchange Rates'}
    >
      <CFlatList
        isLoading={false}
        data={Object.keys(omit(staticData.currencyRates, 'date'))}
        style={{ maxHeight: Layout.window.height * 0.5, marginTop: 10 }}
        ListHeaderComponent={
          <View
            style={{
              flexDirection: 'row',
              marginTop: 5,
            }}
          >
            <Text style={[AppStyles.flex1, AppStyles.textRowTitle]}>From Currency</Text>
            <Text style={[AppStyles.flex1, AppStyles.textRowTitle]}>To Currency</Text>
            <Text style={[AppStyles.flex1, AppStyles.textRowTitle]}>Exchange Rate</Text>
          </View>
        }
        renderItem={({ item }) => {
          return (
            <View
              style={{
                flexDirection: 'row',
                marginTop: 5,
              }}
            >
              <Text style={AppStyles.flex1}>{'USD'}</Text>
              <Text style={AppStyles.flex1}>{item}</Text>
              <Text style={AppStyles.flex1}>{staticData.currencyRates?.[item]}</Text>
            </View>
          );
        }}
      />
    </CModal>
  );
}
