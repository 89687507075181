import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';

import { Entypo, FontAwesome5 } from '@expo/vector-icons';
import { Radio } from 'native-base';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { Card, Text, View } from './Themed';
import AppStyles from '../constants/Styles';
import Colors from '../constants/Colors';
import Constants from '../constants/Constants';

export type ExpandableViewProps = {
  isExpanded: boolean;
  additionalMessage?: string;
  setExpanded: Dispatch<SetStateAction<boolean>>;
  title: string;
  subTitle?: string;
  children: React.ReactElement;
  centerElement?: React.ReactElement | null;
  disableExpand?: boolean;
};
const ExpandableView: FC<ExpandableViewProps> = ({
  isExpanded,
  additionalMessage,
  setExpanded,
  title,
  subTitle,
  children,
  centerElement = null,
  disableExpand,
}) => {
  return (
    <Card style={[AppStyles.marginTop, { borderRadius: 5, zIndex: 10 }]}>
      <TouchableOpacity
        style={[AppStyles.padding, AppStyles.flexRowCenterSpaceBetween]}
        onPress={() => {
          setExpanded((currentValue) => !currentValue);
        }}
        disabled={disableExpand}
      >
        <Text style={AppStyles.textTitle}>
          {title}
          <Text style={[AppStyles.textSubTitle, AppStyles.marginLeft]}>{subTitle}</Text>
          <Text style={[AppStyles.textInputLabel, AppStyles.marginLeft]}>{additionalMessage}</Text>
        </Text>
        {centerElement}
        <FontAwesome5
          name={isExpanded ? 'angle-up' : 'angle-down'}
          size={25}
          color={disableExpand ? Colors.light_grey : Colors.primary}
        />
      </TouchableOpacity>
      <View style={[AppStyles.padding, { display: isExpanded ? 'flex' : 'none' }]}>{children}</View>
    </Card>
  );
};

const styles = StyleSheet.create({
  criteriaTextContainer: { backgroundColor: Colors.secondary, borderRadius: 5 },
  criteriaText: {
    textAlign: 'center',
    fontSize: Constants.TextSize.large,
    color: Colors.white,
  },
});

export default ExpandableView;
