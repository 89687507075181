/* eslint-disable prettier/prettier */
import { UPDATE_OUR_BANKS, UPDATE_BANK_ACCOUNTS, UPDATE_CATEGORIES, UPDATE_CURRENCIES, UPDATE_ORG_INFO, UPDATE_ENTITIES, UPDATE_COUNTRIES, UPDATE_USERS, UPDATE_USER_GROUPS, UPDATE_BANK_PREV_BUS_DAYS, UPDATE_CURRENCY_RATES, UPDATE_ACCOUNT_GROUPS, UPDATE_PERMISSIONS, UPDATE_ACCESSIBLE_SCREENS } from '../actions/ActionConstatnts';
import { staticData } from '../commonTypes';
const initialState = {
    bankAccounts: [],
    categories: [],
    orgInfo: undefined,
    currencies: [],
    ourBanks: [],
    entities: [],
    users: undefined,
    userGroups: [],
    permissions: {},
    accountGroups: [],
    bankAccountPreviousBusinessDays: undefined,
    currencyRates: undefined,
    isAdmin: false,
    accessibleScreens: [],
} as staticData;
const staticDataReducer = (state = initialState, action: { type?: any; payload: any }) => {
    switch (action.type) {
        case UPDATE_BANK_ACCOUNTS:
            return {
                ...state,
                bankAccounts: action.payload,
            };
        case UPDATE_CATEGORIES:
            return {
                ...state,
                categories: action.payload,
            };
        case UPDATE_ORG_INFO:
            return {
                ...state,
                orgInfo: action.payload, // Remove currencies here as it is not needed
                currencies: action.payload.Currencies,
                countries: action.payload.Countries,
            };

        case UPDATE_CURRENCIES:
            return {
                ...state,
                currencies: action.payload,
            };
        case UPDATE_CURRENCY_RATES:
            return {
                ...state,
                currencyRates: action.payload,
            };
        case UPDATE_COUNTRIES:
            return {
                ...state,
                countries: action.payload,
            };
        case UPDATE_OUR_BANKS:
            return {
                ...state,
                ourBanks: action.payload,
            };
        case UPDATE_USERS:
            return {
                ...state,
                users: action.payload,
            };
        case UPDATE_USER_GROUPS:
            return {
                ...state,
                userGroups: action.payload,
                isAdmin: action.payload?.filter((group: any) => group.name === 'Admin').length > 0,
            };
        case UPDATE_PERMISSIONS:
            return {
                ...state,
                permissions: action.payload,
            };
        case UPDATE_ACCOUNT_GROUPS:
            return {
                ...state,
                accountGroups: action.payload,
            };

        case UPDATE_ENTITIES:
            return {
                ...state,
                entities: action.payload,
            };
        case UPDATE_BANK_PREV_BUS_DAYS:
            return {
                ...state,
                bankAccountPreviousBusinessDays: action.payload,
            };
        case UPDATE_ACCESSIBLE_SCREENS:
            return {
                ...state,
                accessibleScreens: action.payload,
            };
        default:
            return {
                ...state,
            };
    }
};
export default staticDataReducer;
