import React, { FC } from 'react';

import { Radio } from 'native-base';
import { ViewStyle } from 'react-native';

import { Text, View } from './Themed';
import UserGroupInput from './Users/UserGroupInput';
import AppStyles from '../constants/Styles';
import { Visibility } from '../commonTypes';

export type AccessTypeProps = {
  label: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  style?: ViewStyle;
  value?: string;
  options: string[];
  userGroups?: string[]; //If the radio option is a User Group, then this will be the list of user groups
  onUserGroupChange?: (value: string[]) => void;
};
const AccessType: FC<AccessTypeProps> = ({
  label,
  onChange,
  style,
  value,
  options,
  userGroups,
  onUserGroupChange,
}) => {
  return (
    <View>
      <Text style={AppStyles.textSubTitle}>{label}</Text>
      <Radio.Group
        name={label}
        value={value}
        onChange={(nextValue) => {
          onChange(nextValue);
        }}
      >
        {options.map((option) => (
          <Radio key={option} value={option} my="1" size={'sm'}>
            {option === Visibility.UserGroup && option === value ? (
              <UserGroupInput
                values={userGroups}
                onSelect={(v) => onUserGroupChange?.(v.map((x) => x))}
                isMultiSelect={true}
              />
            ) : (
              option
            )}
          </Radio>
        ))}
      </Radio.Group>
    </View>
  );
};

export default AccessType;
