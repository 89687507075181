/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useContext, useState } from 'react';

import { useSelector } from 'react-redux';

import { FormControl, FormLabel, useToast } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { WriteBatch, doc, getDoc, serverTimestamp, writeBatch } from 'firebase/firestore';
import { isEmpty, isUndefined, omit, omitBy, pick, set } from 'lodash';
import { ReactSpreadsheetImport } from 'react-spreadsheet-import';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../App';
import {
  ApprovalWorkflowSource,
  DataType,
  DbCollections,
  LogType,
  StaticDataStore,
  Status,
} from '../commonTypes';
import { Button, View } from '../components/Themed';
import { DataTypes } from '../constants/Constants';
import AppStyles from '../constants/Styles';
import { RootTabScreenProps } from '../types';
import { _getChangedFields } from '../utils/helper';

export default function ImportDataScreen({ navigation }: RootTabScreenProps<'ImportDataScreen'>) {
  const [isLoading, setIsLoading] = useState(false);
  const [dataTypes, setDataTypes] = useState<DataType[]>(DataTypes);
  const [selectedDataType, setSelectedDataType] = useState<DataType | null>();
  const [isOpen, setIsOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const { db } = useContext(AppContext);
  const toast = useToast();
  const { profileData, staticData } = useSelector((store) => store as StaticDataStore);

  const processUpload = useCallback(
    async (data: any[]) => {
      setIsUploading(true);

      const batchArray: WriteBatch[] = [];
      batchArray.push(writeBatch(db));
      let operationCounter = 0;
      let batchIndex = 0;
      let collectionName = selectedDataType?.value;
      let skipped = 0;
      let created = 0;
      let updated = 0;
      let isWorkflowEnabled = true;
      switch (selectedDataType?.value) {
        case DbCollections.Entities:
          isWorkflowEnabled =
            staticData?.orgInfo?.Workflow?.[ApprovalWorkflowSource.ENTITIES]?.status ||
            staticData?.orgInfo?.Workflow?.[ApprovalWorkflowSource.STATIC_DATA]?.status;
          break;
        case DbCollections.Banks:
          isWorkflowEnabled =
            staticData?.orgInfo?.Workflow?.[ApprovalWorkflowSource.BANKS]?.status ||
            staticData?.orgInfo?.Workflow?.[ApprovalWorkflowSource.STATIC_DATA]?.status;
          break;
        case DbCollections.BankAccounts:
          isWorkflowEnabled =
            staticData?.orgInfo?.Workflow?.[ApprovalWorkflowSource.BANK_ACCOUNTS]?.status ||
            staticData?.orgInfo?.Workflow?.[ApprovalWorkflowSource.STATIC_DATA]?.status;
          break;
      }

      data?.map((row) => {
        const nestedFields = Object.keys(row).filter((field) => field.includes('.'));
        if (nestedFields && nestedFields.length > 0) {
          const nested = pick(row, nestedFields);
          row = omit(row, nestedFields || []);
          nestedFields?.map((field) => {
            if (nested[field] !== undefined) {
              row = set(row, field, nested[field]);
            }
          });
        }
        let key = row.name;

        if (selectedDataType?.value === DbCollections.BankAccounts) {
          (row.accountNumber?.toString() || '') + (row.currency || '');
        }
        if (row.existingData) {
          // Update existing data
          if (isWorkflowEnabled) {
            const changedFields = _getChangedFields(row.existingData, omit(row, ['existingData']));
            if (isEmpty(changedFields)) {
              skipped++;
              return;
            }
            const updateRequest = {
              ...changedFields,
              requestedBy: profileData.uid,
              requestId: uuidv4(),
              lastUpdatedAt: serverTimestamp(),
            };
            // console.warn('Update', collectionName, key, updateRequest);
            batchArray[batchIndex].update(
              doc(db, 'Organizations', profileData.orgId, collectionName || '', key),
              { updateRequest: updateRequest },
            );
            updated++;
          }
        } else {
          // Create new data
          // console.warn('Create', collectionName, key, {
          //   ...row,
          //   createdDtTm: serverTimestamp(),
          //   createdBy: profileData.uid,
          //   status: isWorkflowEnabled ? Status.PENDING_APPROVAL : Status.ACTIVE,
          // });
          batchArray[batchIndex].set(
            doc(db, 'Organizations', profileData.orgId, collectionName || '', key),
            omitBy(
              {
                ...row,
                createdDtTm: serverTimestamp(),
                createdBy: profileData.uid,
                status: isWorkflowEnabled ? Status.PENDING_APPROVAL : Status.ACTIVE,
              },
              isUndefined,
            ),
          );
          created++;
        }
        operationCounter++;
        if (operationCounter === 499) {
          batchArray.push(writeBatch(db));
          batchIndex++;
          operationCounter = 0;
        }
      });

      const promises = batchArray.map(
        async (batch) =>
          // eslint-disable-next-line no-return-await
          await batch.commit().catch((e) => {
            // console.log(e);
            //errorsExist = true;
            console.warn('error encountered');
            console.warn(e);
            setIsUploading(false);
            toast({
              title: 'Something went wrong',
              description: 'This could be a permission issue',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
          }),
      );
      await Promise.all(promises)
        .then(() => {
          setIsUploading(false);
          toast({
            title: 'Data Uploaded',
            description: `Created: ${created}\nUpdated: ${updated} ${
              skipped ? '\nSkipped: ' + skipped + ' since no fields were updated' : ''
            } `,
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        })
        .catch((error) => {
          setIsUploading(false);
          toast({
            title: 'Something went wrong',
            description: 'This could be a permission issue',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          console.warn(error);
        });
    },
    [selectedDataType, profileData?.uid, staticData?.orgInfo],
  );

  return (
    <View style={[AppStyles.container, AppStyles.padding20]}>
      {selectedDataType && (
        <ReactSpreadsheetImport
          key={selectedDataType.value}
          isOpen={isOpen}
          rowHook={async (data, addError) => {
            // Validation
            let existingData;
            let key = data?.name?.toString() || '';
            let errorField = 'name';

            switch (selectedDataType.value) {
              case DbCollections.Entities:
                existingData = staticData?.entities?.find((entity) => entity.name === key);
                break;
              case DbCollections.Banks:
                existingData = staticData?.ourBanks?.find((bank) => bank.name === key);
                break;

              case DbCollections.BankAccounts:
                key = (data?.accountNumber?.toString() || '') + (data?.currency || '');
                existingData = staticData?.bankAccounts?.find((acc) => acc.id === key);
                errorField = 'accountNumber';
                break;
            }

            if (existingData) {
              addError(errorField, {
                message:
                  'Already exists & will be updated' +
                  (existingData?.updateRequest
                    ? '. Also has a pending change request that will be overwritten'
                    : ''),
                level: 'warning',
              });
              return {
                ...data,
                existingData,
              };
            } else {
              await getDoc(
                doc(db, 'Organizations', profileData?.orgId, selectedDataType.value, key || ''),
              ).then((accDoc) => {
                if (accDoc.exists()) {
                  addError(errorField, {
                    message: 'Already exists and you do not seem to have the access to update it',
                    level: 'error',
                  });
                }
              });
            }

            // Transformation
            return data;
          }}
          onClose={() => setIsOpen(false)}
          onSubmit={(data) => processUpload(data.validData)}
          fields={selectedDataType?.fields}
          autoMapSelectValues
          isNavigationEnabled
          allowInvalidSubmit={false}
        />
      )}
      <FormControl isRequired={true} width={300}>
        <FormLabel>{'Data Type to Import'}</FormLabel>
        <Select
          isDisabled={isUploading}
          onChange={(selected) => {
            selected?.fields?.map((field, index) => {
              if (field.fieldType.type === 'select' && field.fieldType.optionType) {
                switch (field.fieldType.optionType) {
                  case LogType.Entity:
                    selected.fields[index].fieldType.options = staticData?.entities?.map(
                      (entity) => {
                        return { label: entity.name, value: entity.name };
                      },
                    );
                    break;
                  case LogType.Currency:
                    selected.fields[index].fieldType.options = staticData?.currencies?.map(
                      (currency) => {
                        return { label: currency.iso, value: currency.iso };
                      },
                    );
                    break;
                  case LogType.Bank:
                    selected.fields[index].fieldType.options = staticData?.ourBanks?.map((bank) => {
                      return { label: bank.name, value: bank.name };
                    });
                  case LogType.Country:
                    selected.fields[index].fieldType.options = staticData?.countries?.map(
                      (country) => {
                        return { label: country.code, value: country.code };
                      },
                    );
                }
              }
            });
            setSelectedDataType(selected);
          }}
          value={selectedDataType}
          name="Currency"
          options={dataTypes}
          placeholder="Select Data Type"
          size="sm"
          menuPosition="fixed"
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
          }}
          chakraStyles={{
            menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
          }}
          menuPortalTarget={document.body}
        />
      </FormControl>
      <Button
        label="Import Data"
        onPress={() => {
          setIsOpen(true);
        }}
        disabled={!selectedDataType}
        loading={isUploading}
        mt={5}
      />
    </View>
  );
}
