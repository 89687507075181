/* eslint-disable react-native/no-inline-styles */
import React, { FC, useContext, useEffect, useState } from 'react';

import {
  CloseButton,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Stack,
} from '@chakra-ui/react';
import { FontAwesome, MaterialIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';
import { AppContext } from '../App';
import { Notification, StaticDataStore } from '../commonTypes';
import { CFlatList } from '../components/CFlatList';
import { Card, Text } from '../components/Themed';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import AppStyles from '../constants/Styles';
import { _getTimeStampString } from '../utils/helper';

export type props = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};
const NotifMenu: FC<props> = ({ isOpen, onOpen, onClose }) => {
  const { db } = useContext(AppContext);
  const { profileData } = useSelector((store: StaticDataStore) => store);
  const [notifList, setNotifList] = useState<Notification[]>();
  const [isLoading, setIsLoading] = useState(true);
  const navigation = useNavigation();

  useEffect(() => {
    if (!isOpen) return;
    console.warn('Fetching Notif List');
    setIsLoading(true);
    getDocs(
      query(
        collection(db, 'Organizations', profileData?.orgId, 'Notifications'),
        where('user', '==', profileData?.uid),
        where('read', '==', false),
        where('hide', '==', false),
        orderBy('timestamp', 'desc'),
        limit(10),
      ),
    ).then((notifSnapshot) => {
      const _notifList: Notification[] = [];
      notifSnapshot.forEach((doc) => {
        _notifList.push({ ...doc.data(), id: doc.id } as Notification);
      });
      setNotifList(_notifList);
      setIsLoading(false);
    });
  }, [db, profileData?.orgId, profileData?.uid, isOpen]);
  return (
    <Drawer placement={'right'} onClose={onClose} isOpen={isOpen} size={'sm'}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader borderBottomWidth="1px">
          <FontAwesome
            name="bell"
            size={20}
            color={Colors.secondary}
            style={AppStyles.marginRight}
          />
          Notifications
        </DrawerHeader>
        <DrawerBody>
          <CFlatList
            style={{ flex: 1, maxHeight: Layout.window.height - 100 }}
            data={notifList}
            renderItem={({ item }: { item: Notification }) => (
              <TouchableOpacity
                disabled={!item.targetScreen}
                onPress={() => {
                  navigation.navigate(item.targetScreen, { id: item.key });
                  onClose();
                }}
              >
                <Card>
                  <Stack direction="row" justifyContent={'space-between'}>
                    <Stack direction={'row'}>
                      <Text style={AppStyles.textRowTitle}>{item.title}</Text>
                      {item.priority === 1 ? (
                        <MaterialIcons name="notification-important" size={20} color={Colors.red} />
                      ) : null}
                    </Stack>
                    <TouchableOpacity>
                      <CloseButton
                        zIndex={9999}
                        onClick={() => {
                          if (item.id && profileData?.orgId) {
                            deleteDoc(
                              doc(
                                db,
                                'Organizations',
                                profileData?.orgId,
                                'Notifications',
                                item.id,
                              ),
                            );
                            setNotifList(notifList?.filter((x) => x.id !== item.id));
                          }
                        }}
                      />
                    </TouchableOpacity>
                  </Stack>
                  <Text>{item.message}</Text>
                  <Text style={[AppStyles.textTiny, AppStyles.marginTop]}>
                    {_getTimeStampString(item.timestamp)}
                  </Text>
                </Card>
              </TouchableOpacity>
            )}
            emptyMessage="Nothing to show!"
            isLoading={isLoading}
          />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default NotifMenu;
