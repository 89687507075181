import React, { FC, useCallback, useContext, useState } from 'react';

import { Feather } from '@expo/vector-icons';
import {
  DocumentData,
  QueryDocumentSnapshot,
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
} from 'firebase/firestore';
import omit from 'lodash/omit';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useFocusEffect } from '@react-navigation/native';
import { AppContext } from '../../App';
import { Payment, PaymentStatus, PaymentType, StaticDataStore } from '../../commonTypes';
import Colors from '../../constants/Colors';
import AppStyles from '../../constants/Styles';
import { _filterToWhereClause, _getDateFromDateName } from '../../utils/helper';
import { CFlatList } from '../CFlatList';
import { Card, Text, View } from '../Themed';

export type PaymentTemplatePickerModalProps = {
  onClose: () => void;
  show?: boolean;
  onSelected?: (data: Payment) => void;
};
const PaymentTemplatePickerModal: FC<PaymentTemplatePickerModalProps> = ({
  onClose,
  show,
  onSelected,
}) => {
  const { staticData, profileData } = useSelector((store: StaticDataStore) => store);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [filter, setFilter] = useState({});
  const [paymentTemplates, setPaymentTemplates] = useState<Payment[]>([]);
  const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot<DocumentData> | null>();
  const { db } = useContext(AppContext);

  const fetchPaymentTemplates = useCallback(
    async (filterValues: any) => {
      console.log('Fetching Payment Templates');
      if (!profileData?.orgId) {
        return;
      }
      setIsLoading(true);
      setLastVisible(null);
      const constraints = _filterToWhereClause(filterValues);
      const q = query(
        collection(db, 'Organizations', profileData?.orgId, 'PaymentTemplates'),
        ...constraints,
        orderBy('template'),
        limit(5),
      );
      const querySnapshot = await getDocs(q);
      const _paymentTemplates: Payment[] = [];
      querySnapshot.forEach((doc) => {
        _paymentTemplates.push({ ...doc.data(), id: doc.id } as Payment);
      });
      console.warn(_paymentTemplates);
      setPaymentTemplates(_paymentTemplates);
      if (querySnapshot.empty) {
        setLastVisible(null);
      } else {
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      }
      setIsLoading(false);
    },
    [db, profileData?.orgId],
  );

  const loadMorePaymentTemplates = useCallback(
    async (filterValues: any) => {
      if (lastVisible) {
        setIsLoadingMore(true);
        const constraints = _filterToWhereClause(filterValues);
        const q = query(
          collection(db, 'Organizations', profileData?.orgId, 'PaymentTemplates'),
          ...constraints,
          orderBy('template'),
          startAfter(lastVisible),
          limit(5),
        );
        const querySnapshot = await getDocs(q);
        const _paymentTemplates: Payment[] = [];
        querySnapshot.forEach((doc) => {
          _paymentTemplates.push({ ...doc.data(), id: doc.id } as Payment);
        });
        if (querySnapshot.empty) {
          setLastVisible(null);
        } else {
          setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        }
        setPaymentTemplates([...paymentTemplates, ..._paymentTemplates]);
        setIsLoadingMore(false);
      }
    },
    [db, lastVisible, paymentTemplates, profileData?.orgId],
  );

  useFocusEffect(
    useCallback(() => {
      setIsLoading(true);
      fetchPaymentTemplates(filter);
    }, [fetchPaymentTemplates, filter]),
  );

  if (show) {
    return (
      <Modal isOpen={true} onClose={onClose}>
        <ModalOverlay bg="none" backdropFilter="auto" backdropInvert="30%" backdropBlur="2px" />
        <ModalContent maxWidth="60%">
          <ModalCloseButton />
          <ModalHeader>{'Select a Payment Template'}</ModalHeader>
          <ModalBody>
            <View style={AppStyles.padding}>
              <CFlatList
                data={paymentTemplates}
                renderItem={({ item }: { item: Payment }) => {
                  const fromAccountDetails =
                    staticData.bankAccounts?.find((x) => x.id === item.fromAccountDetails.id) ||
                    item.fromAccountDetails;

                  const toAccountDetails =
                    item.type === PaymentType.Internal
                      ? staticData.bankAccounts?.find((x) => x.id === item.toAccountDetails?.id) ||
                        item.toAccountDetails
                      : { accountNumber: item.toAccountNumber, accountName: item.toName };
                  return (
                    <TouchableOpacity
                      onPress={() => {
                        const fromAccountDetails =
                          staticData.bankAccounts?.find(
                            (x) => x.id === item.fromAccountDetails.id,
                          ) || item.fromAccountDetails;
                        const toAccountDetails =
                          item.type === PaymentType.Internal
                            ? staticData.bankAccounts?.find(
                                (x) => x.id === item.toAccountDetails?.id,
                              ) || item.toAccountDetails
                            : undefined;
                        const toBank =
                          item.type === PaymentType.Internal
                            ? staticData.ourBanks?.find(
                                (bank) => bank.name === toAccountDetails?.bank,
                              )
                            : undefined;
                        onSelected?.({
                          ...omit(item, [
                            'id',
                            'approvedBy',
                            'approvedAt',
                            'rejectedBy',
                            'createdAt',
                            'updateRequest',
                            'createdBy',
                            'createdDttm',
                          ]),
                          valueDate: _getDateFromDateName(item.defaultDate).toDate(),
                          fromAccountDetails,
                          fromAccountCurrency: fromAccountDetails?.currency || '',
                          fromBankDetails:
                            staticData.ourBanks?.find(
                              (bank) => bank.name === fromAccountDetails?.bank,
                            ) || item.fromBankDetails,
                          status: PaymentStatus.DRAFT,
                          id: undefined,
                          ...(item.type === PaymentType.Internal && {
                            toAccountDetails,
                            toAccountNumber: toAccountDetails?.accountNumber,
                            toName: toAccountDetails?.accountName,
                            toAddress: staticData.entities?.find(
                              (entity) => entity.name === toAccountDetails?.entity,
                            )?.address,
                            toBankName: toBank?.externalName,
                            toBankBIC: toBank?.BIC,
                            toBankAddress: toBank?.address,
                            toBankRouteCode: toBank?.sortCode,
                          }),
                        } as Payment);
                        onClose();
                      }}
                      style={AppStyles.marginBottom}
                    >
                      <Card>
                        <View style={AppStyles.flexRowCenterSpaceBetween}>
                          <View>
                            <Text style={AppStyles.textRowTitle}>{item.template}</Text>
                            <Text>{item.type}</Text>
                            <Text>{item.paymentMethod}</Text>
                          </View>
                          <View style={AppStyles.flexRowCenter}>
                            <View>
                              <Text>{fromAccountDetails?.accountNumber}</Text>
                              <Text>{fromAccountDetails.accountName}</Text>
                              <Text>{item.paymentCurrency}</Text>
                              <Text>{fromAccountDetails.bank}</Text>
                            </View>
                            <Feather
                              name="chevron-right"
                              size={24}
                              color={Colors.primary}
                              style={AppStyles.marginHorizontal}
                            />
                            <View>
                              <Text>{toAccountDetails?.accountNumber}</Text>
                              <Text>{toAccountDetails?.accountName}</Text>
                            </View>
                          </View>
                        </View>
                      </Card>
                    </TouchableOpacity>
                  );
                }}
                isLoading={isLoading}
                isLoadingMore={isLoadingMore}
              />
            </View>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }
  return null;
};

const styles = StyleSheet.create({
  verticalLine: {
    width: 100,
    alignItems: 'flex-end',
    paddingRight: 10,
    borderRightWidth: 3,
    borderRightColor: Colors.lightest_grey,
    paddingVertical: 10,
  },
  roundIndicator: {
    width: 10,
    height: 10,
    borderRadius: 10,
    marginRight: 5,
    marginLeft: -7,
  },
});

export default PaymentTemplatePickerModal;
