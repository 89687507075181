/* eslint-disable react-native/no-inline-styles */
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import { Ionicons, MaterialIcons } from '@expo/vector-icons';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import { Icon, Input } from 'native-base';
import { StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';

import moment from 'moment';
import AppStyles from '../constants/Styles';
import { StaticDataStore } from '../comonTypes';
import { DateInput } from './DateInput';
import { FilterContext } from './FilterContext';
import { FilterSelect } from './FilterSelect';
import { MultiTextInput } from './MultiTextInput';
import { PopOverFilterItem } from './PopOverFilterItem';
import { View } from './Themed';
import { Select } from 'chakra-react-select';
export type FilterParam = {
  title: string;
  options?: { label: string; value: string }[];
  type: string;
  selectedValue?: string;
  field: string;
  hide?: boolean;
  minWidth?: number;
};

export type FilterProps = {
  hideSearch?: boolean;
  searchPlaceholder?: string;
  filterParams?: FilterParam[];
  filter?: any;
  setFilter?: Dispatch<SetStateAction<object>>;
  updateFilter?: (filter: any) => void; // Incase if not dispatch - E.g. for Reporting
  onSearch: (searchValue: string) => void;
};
export function FilterBar({
  hideSearch,
  filterParams,
  onSearch,
  searchPlaceholder,
  filter,
  setFilter,
  updateFilter,
}: FilterProps) {
  const { staticData } = useSelector((store: StaticDataStore) => store);
  const [searchText, setSearchText] = useState('');

  const delayedQuery = useCallback(
    debounce((q) => onSearch(q), 500),
    [onSearch],
  );

  const onChange = (value: string) => {
    setSearchText(value);
    delayedQuery(value);
  };

  const renderFilterParams = useCallback(() => {
    return filterParams?.map((filterParam) => {
      if (filterParam.hide) {
        return null;
      }
      switch (filterParam.type) {
        case 'select':
          return (
            <FilterSelect
              key={filterParam.field}
              {...filterParam}
              selectedValue={filter[filterParam.field]}
              onSelect={(val: string | null) => {
                setFilter?.((currentValue: any) => {
                  if (val) {
                    return { ...currentValue, [filterParam.field]: val };
                  }
                  return omit(currentValue, [filterParam.field]);
                });
                if (val) {
                  updateFilter?.({ ...filter, [filterParam.field]: val });
                } else {
                  updateFilter?.(omit(filter, [filterParam.field]));
                }
              }}
            />
          );
        case 'AccountSelect':
          return (
            <PopOverFilterItem
              key={filterParam.field}
              data={staticData.bankAccounts || []}
              title={'Bank Acccount'}
              onSelect={(val) => {
                setFilter?.((currentValue: any) => {
                  if (val) {
                    return { ...currentValue, [filterParam.field]: val.id };
                  }
                  return omit(currentValue, [filterParam.field]);
                });
                if (val) {
                  updateFilter?.({ ...filter, [filterParam.field]: val.id });
                } else {
                  updateFilter?.(omit(filter, [filterParam.field]));
                }
              }}
              selectedValue={staticData.bankAccounts?.find(
                (acc) => acc.id === filter[filterParam.field],
              )}
              label={'accountNumber'}
              style={{ marginTop: 0 }}
            />
          );
        case 'MultiTextInput':
          return (
            <MultiTextInput
              key={filterParam.field}
              title={filterParam.title || 'Transaction Codes'}
              selectedValues={filter[filterParam.field]}
              maxValues={3}
              isFilterComponent
              onSelect={
                (val) => {
                  setFilter?.((currentValue: any) => {
                    if (val && val.length > 0) {
                      return { ...currentValue, [filterParam.field]: val };
                    }
                    return omit(currentValue, [filterParam.field]);
                  });
                  if (val && val.length > 0) {
                    updateFilter?.({ ...filter, [filterParam.field]: val });
                  } else {
                    updateFilter?.(omit(filter, [filterParam.field]));
                  }
                }
                // setSelectedBankTransactionRule((currentVlaue) => {
                //   return { ...currentVlaue, code: values };
                // })
              }
            />
          );
        case 'DateRange':
          // return (
          //   <View style={AppStyles.marginLeft}>
          //     <DatePicker slotProps={{ textField: { size: 'small' } }} />
          //   </View>
          // );
          // console.warn('valx', filter[filterParam.field]);

          return (
            <>
              <DateInput
                onSelect={(val) => {
                  setFilter?.((currentValue: any) => {
                    console.warn(val);
                    if (val) {
                      return { ...currentValue, [filterParam.field]: val.toDate() };
                    }
                    return omit(currentValue, [filterParam.field]);
                  });
                  if (val) {
                    updateFilter?.({ ...filter, [filterParam.field]: val.toDate() });
                  } else {
                    updateFilter?.(omit(filter, [filterParam.field]));
                  }
                }}
                value={filter[filterParam.field] ? moment(filter[filterParam.field]) : undefined}
                placeholder={filterParam.title}
                width={150}
                style={AppStyles.marginLeft25}
                isNotOnModal
              />
            </>
          );
        case 'MultiSelect':
          return (
            <View
              style={[AppStyles.marginLeft25, { minWidth: filterParam.minWidth, maxWidth: 450 }]}
            >
              <Select
                isMulti
                onChange={(val) => {
                  setFilter?.((currentValue: any) => {
                    if (val && val.length > 0) {
                      return { ...currentValue, [filterParam.field]: val.map((v) => v.value) };
                    }
                    return omit(currentValue, [filterParam.field]);
                  });
                }}
                value={
                  filter[filterParam.field]?.map((value) => {
                    return {
                      value,
                      label:
                        filterParam.options?.find((option) => option.value === value)?.label ||
                        value,
                    };
                  }) || []
                }
                name="Entity"
                options={filterParam.options || []}
                placeholder={filterParam.title}
                menuPosition="fixed"
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
                }}
                chakraStyles={{
                  menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
                }}
                menuPortalTarget={document.body}
              />
            </View>
          );
        default:
          return null;
      }
    });
  }, [filter, filterParams, setFilter, staticData.bankAccounts]);
  return (
    <FilterContext.Provider value={{ filterValue: undefined }}>
      <View style={styles.bar}>
        {!hideSearch && (
          <Input
            InputLeftElement={<Icon as={<MaterialIcons name="search" />} size={5} ml="2" />}
            InputRightElement={
              searchText ? (
                <Icon
                  as={<Ionicons name="close-circle" />}
                  size={5}
                  ml="4"
                  color="muted.400"
                  style={{ marginRight: 10 }}
                  onPress={() => onChange('')}
                />
              ) : undefined
            }
            value={searchText}
            size="md"
            variant="rounded"
            autoComplete="off"
            key={'Search'}
            placeholder={searchPlaceholder || 'Search here'}
            w="s"
            onChangeText={onChange}
          />
        )}
        {renderFilterParams()}
      </View>
    </FilterContext.Provider>
  );
}

const styles = StyleSheet.create({
  bar: {
    marginHorizontal: 10,
    marginVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
});
