/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useContext, useEffect, useState } from 'react';

import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  increment,
  limit,
  orderBy,
  query,
  where,
  writeBatch,
  WriteBatch,
} from 'firebase/firestore';
import pick from 'lodash/pick';
import moment, { Moment } from 'moment';
import { Checkbox, useToast } from 'native-base';
import { useSelector } from 'react-redux';

import { Button } from '@chakra-ui/react';
import { AppContext } from '../App';
import {
  BankAccount,
  BankTransactionRule,
  CashPositionSummary,
  StatementTransaction,
} from '../commonTypes';
import { DateInput } from '../components/DateInput';
import { MultiSelectInput } from '../components/MultiSelectInput';
import { Text, View } from '../components/Themed';
import Layout from '../constants/Layout';
import AppStyles from '../constants/Styles';

export default function ApplyBankTransactionRulesScreen() {
  const [isLoading, setIsLoading] = useState(false);
  const { db } = useContext(AppContext);
  const [isAccountsLoading, setIsAccountsLoading] = useState(true);
  const [bankTransactionRules, setBankTransactionRules] = useState<BankTransactionRule[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [accounts, setAccounts] = useState<BankAccount[]>([]);
  const [valueDate, setValueDate] = useState<Moment>();
  const [unmappedTransactionsOnly, setUnmappedTransactionsOnly] = useState(true);
  const [selectedAccounts, setSelectedAccounts] = useState<BankAccount[]>([]);
  const { profileData } = useSelector((store) => store);
  const toast = useToast();
  const fetchAccounts = useCallback(async () => {
    console.log('Fetching Accounts');
    setIsAccountsLoading(true);
    const q = query(collection(db, 'Organizations', profileData.orgId, 'BankAccounts'), limit(10));
    const querySnapshot = await getDocs(q);
    const _accounts: BankAccount[] = [];
    querySnapshot.forEach((doc) => {
      _accounts.push({ ...doc.data(), id: doc.id } as BankAccount);
    });
    setAccounts(_accounts);

    setIsAccountsLoading(false);
  }, [db, profileData.orgId]);

  const fetchBankTransactionRules = useCallback(async () => {
    console.log('Fetching Bank Transaction Rules');
    const q = query(
      collection(db, 'Organizations', profileData.orgId, 'BankTransactionRules'),
      where('status', '==', 'Active'),
      orderBy('priority', 'desc'),
    );
    const querySnapshot = await getDocs(q);
    const _bankTransactionRules: BankTransactionRule[] = [];
    querySnapshot.forEach((doc) => {
      _bankTransactionRules.push({ ...doc.data(), id: doc.id } as BankTransactionRule);
    });
    setBankTransactionRules(_bankTransactionRules);
    // setSelectedBankTransactionRule(_bankTransactionRules[0]);
    // setSelectedIndex(0);

    setIsLoading(false);
  }, [db, profileData.orgId]);

  useEffect(() => {
    fetchAccounts();
    fetchBankTransactionRules();
  }, [fetchAccounts, fetchBankTransactionRules]);

  const assignCategoryToTransactions = useCallback(
    (transactions: StatementTransaction[]) => {
      return transactions.map((trans) => {
        const matchedRules = bankTransactionRules.filter((rule) => {
          return (
            ((rule.accounts && rule.accounts.includes(trans.accountId)) ||
              !rule.accounts ||
              rule.accounts.length === 0) &&
            rule.code.includes(trans.code + (trans.familyCode || '') + (trans.subFamilyCode || ''))
          );
        });
        if (matchedRules?.length > 0) {
          return {
            ...trans,
            ruleId: matchedRules[0].id,
            category: matchedRules[0].category,
            originalCategory: trans.category,
          };
        }
        if (trans.category === undefined) {
          return { ...trans, category: 'Unmapped', originalCategory: undefined };
        }
        return { ...trans, originalCategory: trans.category };
      });
    },
    [bankTransactionRules],
  );

  const onDeleteStatementForDay = useCallback(async () => {
    const q = query(
      collection(db, 'Organizations', profileData.orgId, 'BankStatement'),
      // where('code', 'in', ['CHRG', '698']),
      // where('isBalance', '==', false),
      where('accountId', '==', 'NL82INGB0008237239EUR'),
      //where('stmtId', '==', '0459'),
      //...constraints,
      // where('category', 'not-in', ['Unmapped']),
      //limit(500),
    );
    const querySnapshot = await getDocs(q);
    console.warn(querySnapshot.docs.length);
    querySnapshot.forEach((doc) => {
      console.warn(doc.id);
      deleteDoc(doc.ref);
    });
  }, [db, profileData.orgId]);

  const onDeleteSummaryForDay = useCallback(async () => {
    const q = query(
      collection(db, 'Organizations', profileData.orgId, 'CashPositionSummary'),
      // where('code', 'in', ['CHRG', '698']),
      // where('isBalance', '==', false),
      where('date', '==', new Date('2023-06-19')),
      //where('stmtId', '==', '0459'),
      //...constraints,
      // where('category', 'not-in', ['Unmapped']),
      //limit(500),
    );
    const querySnapshot = await getDocs(q);
    console.warn(querySnapshot.docs.length);
    querySnapshot.forEach((doc) => {
      console.warn(doc.id);
      deleteDoc(doc.ref);
    });
  }, [db, profileData.orgId]);

  const onDelete = useCallback(async () => {
    const q = query(
      collection(db, 'Organizations', profileData.orgId, 'BankStatement'),
      // where('code', 'in', ['CHRG', '698']),
      // where('isBalance', '==', false),
      //where('accountId', '==', '0599132800EUR'),
      where('stmtId', '==', '0459'),
      //...constraints,
      // where('category', 'not-in', ['Unmapped']),
      limit(500),
    );
    const querySnapshot = await getDocs(q);
    console.warn(querySnapshot.docs.length);
    querySnapshot.forEach((doc) => {
      console.warn(doc.id);
      deleteDoc(doc.ref);
    });
  }, [db, profileData.orgId]);

  const onSum = useCallback(async () => {
    const q = query(
      collection(db, 'Organizations', profileData.orgId, 'BankStatement'),
      // where('code', 'in', ['CHRG', '698']),
      // where('isBalance', '==', false),
      // where('accountId', '==', '0599132800EUR'),
      where('category', '==', 'EwBkluBSUWzz2238p6mg'),
      where('currency', '==', 'DKK'),
      where('isBalance', '==', false),
      where('valueDate', '>=', moment('20230119', 'YYYYMMDD').toDate()),
      where('valueDate', '<=', moment('20230120', 'YYYYMMDD').toDate()),
      //...constraints,
      // where('category', 'not-in', ['Unmapped']),
      limit(5000),
    );
    const querySnapshot = await getDocs(q);
    let sum = 0;
    querySnapshot.forEach((doc) => {
      sum += doc.data().amount;
    });
    console.warn('count: ' + querySnapshot.docs.length);
    console.warn('sum: ' + sum);
  }, [db, profileData.orgId]);

  const onApplyRules = useCallback(async () => {
    // if (!valueDate || !selectedAccounts || selectedAccounts?.length === 0) {
    //   return toast.show({
    //     title: 'Enter all selection criteria',
    //     variant: 'top-accent',
    //     description: 'Please choose your selection criteria to apply the rules to',
    //   });
    // }
    setIsProcessing(true);
    const constraints = [];
    if (unmappedTransactionsOnly) {
      constraints.push(where('category', '==', ''));
    }
    const q = query(
      collection(db, 'Organizations', profileData.orgId, 'BankStatement'),
      // where('code', 'in', ['CHRG', '698']),
      where('isBalance', '==', false),
      where('accountId', '==', '0599132800EUR'),
      //...constraints,
      // where('category', 'not-in', ['Unmapped']),
      limit(5000),
    );

    const querySnapshot = await getDocs(q);
    const _bankStatements: StatementTransaction[] = [];
    querySnapshot.forEach((doc) => {
      _bankStatements.push({ ...doc.data(), id: doc.id } as StatementTransaction);
    });
    console.warn('Statemetns:' + _bankStatements.length);
    const updatedTransactions = assignCategoryToTransactions(_bankStatements);

    try {
      const batchArray: WriteBatch[] = [];
      batchArray.push(writeBatch(db));
      let operationCounter = 0;
      let batchIndex = 0;
      let summaryList: any = {};
      updatedTransactions.forEach((trans) => {
        if (trans.category !== trans.originalCategory) {
          batchArray[batchIndex].update(
            doc(db, 'Organizations', profileData.orgId, 'BankStatement', trans.id || ''),
            pick(trans, ['category', 'ruleId']),
          );
          operationCounter++;

          const key = `${moment(trans.valueDate?.toDate()).format('YYYYMMDD')}${trans.currency}${
            trans.category
          }`;
          if (!summaryList[key]) {
            summaryList[key] = {
              statementAmount: 0,
              statementCount: 0,
              date: trans.valueDate,
              currency: trans.currency,
              category: trans.category,
            } as CashPositionSummary;
            operationCounter++;
          }
          if (summaryList[key]) {
            summaryList[key].statementAmount += Math.abs(trans.amount);
            summaryList[key].statementCount++;
          }
          if (trans.originalCategory) {
            const originalKey = `${moment(trans.valueDate?.toDate()).format('YYYYMMDD')}${
              trans.currency
            }${trans.originalCategory}`;
            if (!summaryList[originalKey]) {
              summaryList[originalKey] = {
                statementAmount: 0,
                statementCount: 0,
                date: trans.valueDate,
                currency: trans.currency,
                category: trans.category,
              } as CashPositionSummary;
              operationCounter++;
            }
            if (summaryList[originalKey]) {
              summaryList[originalKey].statementAmount -= Math.abs(trans.amount);
              summaryList[originalKey].statementCount--;
            }
          }
          if (operationCounter === 499) {
            Object.entries(summaryList).map(([summaryKey, summaryValue]: [string, any]) => {
              console.warn(summaryValue);
              batchArray[batchIndex].set(
                doc(db, 'Organizations', profileData.orgId, 'CashPositionSummary', summaryKey),
                {
                  category: summaryValue.category,
                  date: summaryValue.date,
                  currency: summaryValue.currency,
                  statementAmount: increment(summaryValue.statementAmount),
                  statementCount: increment(summaryValue.statementCount),
                } as CashPositionSummary,
                { merge: true },
              );
            });
            batchArray.push(writeBatch(db));
            batchIndex++;
            operationCounter = 0;
            summaryList = {};
          }
        }
      });
      // console.warn('entries');
      // console.warn(operationCounter);
      // Unlike BankStatemetns, only the counter is increased in the loop. If the counter for last batch does not reach 499,
      // those last set of summary still needs to be updated.
      Object.entries(summaryList).map(([summaryKey, summaryValue]: [string, any]) => {
        console.warn(summaryValue);
        batchArray[batchIndex].set(
          doc(db, 'Organizations', profileData.orgId, 'CashPositionSummary', summaryKey),
          {
            category: summaryValue.category,
            date: summaryValue.date,
            currency: summaryValue.currency,
            statementAmount: increment(summaryValue.statementAmount),
            statementCount: increment(summaryValue.statementCount),
          } as CashPositionSummary,
          { merge: true },
        );
      });

      const promises = batchArray.map(
        async (batch) =>
          await batch.commit().catch((e) => {
            // console.log(e);
            errorsExist = true;
            console.warn('error encountered');
            console.warn(e);
          }),
      );
      await Promise.all(promises)
        .then(() => {
          console.warn('Completed');
        })
        .catch(() => {
          console.warn('Something went wrong');
        });
    } catch (error) {
      console.warn('Something went wrong');
    }
    setIsProcessing(false);
  }, [assignCategoryToTransactions, db, profileData.orgId, unmappedTransactionsOnly]);

  return (
    <View style={AppStyles.container}>
      <View style={{ width: Layout.window.width / 3, flex: 1, padding: 10 }}>
        <Text style={AppStyles.textRowTitle}>Selection Criteria</Text>
        <DateInput title={'Value Date'} onSelect={(value) => setValueDate(value)} isNotOnModal />
        <MultiSelectInput
          data={accounts}
          title={'Bank Acccount'}
          onSelect={(values) => {
            setSelectedAccounts(values);
          }}
          selectedValues={selectedAccounts}
          label={'accountNumber'}
        />

        <Checkbox
          style={{ marginTop: 10 }}
          isChecked={unmappedTransactionsOnly}
          value={'unmappedTransactionsOnly'}
          onChange={setUnmappedTransactionsOnly}
        >
          <Text style={{ marginTop: 10 }}>Unmapped transctions only</Text>
        </Checkbox>
        <Button mt={2} onClick={onDeleteSummaryForDay} isLoading={isProcessing} colorScheme="blue">
          {'Apply Rules'}
        </Button>
      </View>
    </View>
  );
}
