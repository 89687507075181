import { DataType, DbCollections, JobStatus, LogType } from '../commonTypes';
import { CXPermissions, MenuItems } from '../components/Users/Constants';

export default {
  BankCodes: {
    '010': { descr: 'Opening Ledger', type: 'NA' },
    '011': { descr: 'Average Opening Ledger MTD', type: 'NA' },
    '012': { descr: 'Average Opening Ledger YTD', type: 'NA' },
    '015': { descr: 'Closing Ledger', type: 'NA' },
    '020': { descr: 'Average Closing Ledger MTD', type: 'NA' },
    '021': { descr: 'Average Closing Ledger - Previous Month', type: 'NA' },
    '022': { descr: 'Aggregate Balance Adjustments', type: 'NA' },
    '024': { descr: 'Average Closing Ledger YTD - Previous Month', type: 'NA' },
    '025': { descr: 'Average Closing Ledger YTD', type: 'NA' },
    '030': { descr: 'Current Ledger', type: 'NA' },
    '037': { descr: 'ACH Net Position', type: 'NA' },
    '039': { descr: 'Opening Available + Total Same-Day ACH DTC Deposit', type: 'NA' },
    '040': { descr: 'Opening Available', type: 'NA' },
    '041': { descr: 'Average Opening Available MTD', type: 'NA' },
    '042': { descr: 'Average Opening Available YTD', type: 'NA' },
    '043': { descr: 'Average Available - Previous Month', type: 'NA' },
    '044': { descr: 'Disbursing Opening Available Balance', type: 'NA' },
    '045': { descr: 'Closing Available', type: 'NA' },
    '050': { descr: 'Average Closing Available MTD', type: 'NA' },
    '051': { descr: 'Average Closing Available - Last Month', type: 'NA' },
    '054': { descr: 'Average Closing Available YTD - Last Month', type: 'NA' },
    '055': { descr: 'Average Closing Available YTD', type: 'NA' },
    '056': { descr: 'Loan Balance', type: 'NA' },
    '057': { descr: 'Total Investment Position', type: 'NA' },
    '059': { descr: 'Current Available (CRS Supressed)', type: 'NA' },
    '060': { descr: 'Current Available', type: 'NA' },
    '061': { descr: 'Average Current Available MTD', type: 'NA' },
    '062': { descr: 'Average Current Available YTD', type: 'NA' },
    '063': { descr: 'Total Float', type: 'NA' },
    '065': { descr: 'Target Balance', type: 'NA' },
    '066': { descr: 'Adjusted Balance', type: 'NA' },
    '067': { descr: 'Adjusted Balance MTD', type: 'NA' },
    '068': { descr: 'Adjusted Balance YTD', type: 'NA' },
    '070': { descr: '0-Day Float', type: 'NA' },
    '072': { descr: '1-Day Float', type: 'NA' },
    '073': { descr: 'Float Adjustment', type: 'NA' },
    '074': { descr: '2 or More Days Float', type: 'NA' },
    '075': { descr: '3 or More Days Float', type: 'NA' },
    '076': { descr: 'Adjustment to Balances', type: 'NA' },
    '077': { descr: 'Average Adjustment to Balances MTD', type: 'NA' },
    '078': { descr: 'Average Adjustment to Balances YTD', type: 'NA' },
    '079': { descr: '4-Day Float', type: 'NA' },
    '080': { descr: '5-Day Float', type: 'NA' },
    '081': { descr: '6-Day Float', type: 'NA' },
    '082': { descr: 'Average 1-Day Float MTD', type: 'NA' },
    '083': { descr: 'Average 1-Day Float YTD', type: 'NA' },
    '084': { descr: 'Average 2-Day Float MTD', type: 'NA' },
    '085': { descr: 'Average 2-Day Float YTD', type: 'NA' },
    '086': { descr: 'Transfer Calculation', type: 'NA' },
    '100': { descr: 'Total Credits', type: 'NA' },
    '101': { descr: 'Total Credit Amount MTD', type: 'NA' },
    '102': { descr: 'Credits Not Detailed (0102)', type: 'NA' },
    '104': { descr: 'Credits Not Detailed (0104)', type: 'NA' },
    '105': { descr: 'Credits Not Detailed', type: 'NA' },
    '106': { descr: 'Deposits Subject to Float', type: 'NA' },
    '107': { descr: 'Total Adjustment Credits YTD', type: 'NA' },
    '108': { descr: 'Credit (Any Type)', type: 'CR' },
    '109': { descr: 'Current Day Total Lockbox Deposits', type: 'NA' },
    '110': { descr: 'Total Lockbox Deposits', type: 'NA' },
    '115': { descr: 'Lockbox Deposit', type: 'CR' },
    '116': { descr: 'Item in Lockbox Deposit', type: 'CR' },
    '118': { descr: 'Lockbox Adjustment Credit', type: 'CR' },
    '120': { descr: 'EDI* Transaction Credit', type: 'NA' },
    '121': { descr: 'EDI Transaction Credit', type: 'CR' },
    '122': { descr: 'EDIBANX Credit Received', type: 'CR' },
    '123': { descr: 'EDIBANX Credit Return', type: 'CR' },
    '130': { descr: 'Total Concentration Credits', type: 'NA' },
    '131': { descr: 'Total DTC Credits', type: 'NA' },
    '135': { descr: 'DTC Concentration Credit', type: 'CR' },
    '136': { descr: 'Item in DTC Deposit', type: 'CR' },
    '140': { descr: 'Total ACH Credits', type: 'NA' },
    '142': { descr: 'ACH Credit Received', type: 'CR' },
    '143': { descr: 'Item in ACH Deposit', type: 'CR' },
    '145': { descr: 'ACH Concentration Credit', type: 'CR' },
    '146': { descr: 'Total Bank Card Deposits', type: 'NA' },
    '147': { descr: 'Individual Bank Card Deposit', type: 'CR' },
    '150': { descr: 'Total Preauthorized Payment Credits', type: 'NA' },
    '155': { descr: 'Preauthorized Draft Credit', type: 'CR' },
    '156': { descr: 'Item in PAC Deposit', type: 'CR' },
    '160': { descr: 'Total ACH Disbursing Funding Credits', type: 'NA' },
    '162': { descr: 'Corporate Trade Payment Settlement', type: 'NA' },
    '163': { descr: 'Corporate Trade Payment Credits', type: 'NA' },
    '164': { descr: 'Corporate Trade Payment Credit', type: 'CR' },
    '165': { descr: 'Preauthorized ACH Credit', type: 'CR' },
    '166': { descr: 'ACH Settlement', type: 'CR' },
    '167': { descr: 'ACH Settlement Credits', type: 'NA' },
    '168': { descr: 'ACH Return Item or Adjustment Settlement', type: 'CR' },
    '169': { descr: 'Miscellaneous ACH Credit', type: 'CR' },
    '170': { descr: 'Total Other Check Deposits', type: 'NA' },
    '171': { descr: 'Individual Loan Deposit', type: 'CR' },
    '172': { descr: 'Deposit Correction', type: 'CR' },
    '173': { descr: 'Bank-Prepared Deposit', type: 'CR' },
    '174': { descr: 'Other Deposit', type: 'CR' },
    '175': { descr: 'Check Deposit Package', type: 'CR' },
    '176': { descr: 'Re-presented Check Deposit', type: 'CR' },
    '178': { descr: 'List Post Credits', type: 'NA' },
    '180': { descr: 'Total Loan Proceeds', type: 'NA' },
    '182': { descr: 'Total Bank-Prepared Deposits', type: 'NA' },
    '184': { descr: 'Draft Deposit', type: 'CR' },
    '185': { descr: 'Total Miscellaneous Deposits', type: 'NA' },
    '186': { descr: 'Total Cash Letter Credits', type: 'NA' },
    '187': { descr: 'Cash Letter Credit', type: 'CR' },
    '188': { descr: 'Total Cash Letter Adjustments', type: 'NA' },
    '189': { descr: 'Cash Letter Adjustment', type: 'CR' },
    '190': { descr: 'Total Incoming Money Transfers', type: 'NA' },
    '191': { descr: 'Individual Incoming Internal Money Transfer', type: 'CR' },
    '195': { descr: 'Incoming Money Transfer', type: 'CR' },
    '196': { descr: 'Money Transfer Adjustment', type: 'CR' },
    '198': { descr: 'Compensation', type: 'CR' },
    '200': { descr: 'Total Automatic Transfer Credits', type: 'NA' },
    '201': { descr: 'Individual Automatic Transfer Credit', type: 'CR' },
    '202': { descr: 'Bond Operations Credit', type: 'CR' },
    '205': { descr: 'Total Book Transfer Credits', type: 'NA' },
    '206': { descr: 'Book Transfer Credit', type: 'CR' },
    '207': { descr: 'Total International Money Transfer Credits', type: 'NA' },
    '208': { descr: 'Individual International Money Transfer Credit', type: 'CR' },
    '210': { descr: 'Total International Credits', type: 'NA' },
    '212': { descr: 'Foreign Letter of Credit', type: 'CR' },
    '213': { descr: 'Letter of Credit', type: 'CR' },
    '214': { descr: 'Foreign Exchange of Credit', type: 'CR' },
    '215': { descr: 'Total Letters of Credit', type: 'NA' },
    '216': { descr: 'Foreign Remittance Credit', type: 'CR' },
    '218': { descr: 'Foreign Collection Credit', type: 'CR' },
    '221': { descr: 'Foreign Check Purchase', type: 'CR' },
    '222': { descr: 'Foreign Checks Deposited', type: 'CR' },
    '224': { descr: 'Commission', type: 'CR' },
    '226': { descr: 'International Money Market Trading', type: 'CR' },
    '227': { descr: 'Standing Order', type: 'CR' },
    '229': { descr: 'Miscellaneous International Credit', type: 'CR' },
    '230': { descr: 'Total Security Credits', type: 'NA' },
    '231': { descr: 'Total Collection Credits', type: 'NA' },
    '232': { descr: 'Sale of Debt Security', type: 'CR' },
    '233': { descr: 'Securities Sold', type: 'CR' },
    '234': { descr: 'Sale of Equity Security', type: 'CR' },
    '235': { descr: 'Matured Reverse Repurchase Order', type: 'CR' },
    '236': { descr: 'Maturity of Debt Security', type: 'CR' },
    '237': { descr: 'Individual Collection Credit', type: 'CR' },
    '238': { descr: 'Collection of Dividends', type: 'CR' },
    '239': { descr: 'Total Bankers’ Acceptance Credits', type: 'NA' },
    '240': { descr: 'Coupon Collections - Banks', type: 'CR' },
    '241': { descr: 'Bankers’ Acceptances', type: 'CR' },
    '242': { descr: 'Collection of Interest Income', type: 'CR' },
    '243': { descr: 'Matured Fed Funds Purchased', type: 'CR' },
    '244': { descr: 'Interest/Matured Principal Payment', type: 'CR' },
    '245': { descr: 'Monthly Dividends', type: 'NA' },
    '246': { descr: 'Commercial Paper', type: 'CR' },
    '247': { descr: 'Capital Change', type: 'CR' },
    '248': { descr: 'Savings Bonds Sales Adjustment', type: 'CR' },
    '249': { descr: 'Miscellaneous Security Credit', type: 'CR' },
    '250': { descr: 'Total Checks Posted and Returned', type: 'NA' },
    '251': { descr: 'Total Debit Reversals', type: 'NA' },
    '252': { descr: 'Debit Reversal', type: 'CR' },
    '254': { descr: 'Posting Error Correction Credit', type: 'CR' },
    '255': { descr: 'Check Posted and Returned', type: 'CR' },
    '256': { descr: 'Total ACH Return Items', type: 'NA' },
    '257': { descr: 'Individual ACH Return Item', type: 'CR' },
    '258': { descr: 'ACH Reversal Credit', type: 'CR' },
    '260': { descr: 'Total Rejected Credits', type: 'NA' },
    '261': { descr: 'Individual Rejected Credit', type: 'CR' },
    '263': { descr: 'Overdraft', type: 'CR' },
    '266': { descr: 'Return Item', type: 'CR' },
    '268': { descr: 'Return Item Adjustment', type: 'CR' },
    '270': { descr: 'Total ZBA Credits', type: 'NA' },
    '271': { descr: 'Net Zero-Balance Amount', type: 'NA' },
    '274': { descr: 'Cumulative** ZBA or Disbursement Credits', type: 'CR' },
    '275': { descr: 'ZBA Credit', type: 'CR' },
    '276': { descr: 'ZBA Float Adjustment', type: 'CR' },
    '277': { descr: 'ZBA Credit Transfer', type: 'CR' },
    '278': { descr: 'ZBA Credit Adjustment', type: 'CR' },
    '280': { descr: 'Total Controlled Disbursing Credits', type: 'NA' },
    '281': { descr: 'Individual Controlled Disbursing Credit', type: 'CR' },
    '285': { descr: 'Total DTC Disbursing Credits', type: 'NA' },
    '286': { descr: 'Individual DTC Disbursing Credit', type: 'CR' },
    '294': { descr: 'Total ATM Credits', type: 'NA' },
    '295': { descr: 'ATM Credit', type: 'CR' },
    '301': { descr: 'Commercial Deposit', type: 'CR' },
    '302': { descr: 'Correspondent Bank Deposit', type: 'NA' },
    '303': { descr: 'Total Wire Transfers In - FF', type: 'NA' },
    '304': { descr: 'Total Wire Transfers In - CHF', type: 'NA' },
    '305': { descr: 'Total Fed Funds Sold', type: 'NA' },
    '306': { descr: 'Fed Funds Sold', type: 'CR' },
    '307': { descr: 'Total Trust Credits', type: 'NA' },
    '308': { descr: 'Trust Credit', type: 'CR' },
    '309': { descr: 'Total Value - Dated Funds', type: 'NA' },
    '310': { descr: 'Total Commercial Deposits', type: 'NA' },
    '315': { descr: 'Total International Credits - FF', type: 'NA' },
    '316': { descr: 'Total International Credits - CHF', type: 'NA' },
    '318': { descr: 'Total Foreign Check Purchased', type: 'NA' },
    '319': { descr: 'Late Deposit', type: 'NA' },
    '320': { descr: 'Total Securities Sold - FF', type: 'NA' },
    '321': { descr: 'Total Securities Sold - CHF', type: 'NA' },
    '324': { descr: 'Total Securities Matured - FF', type: 'NA' },
    '325': { descr: 'Total Securities Matured - CHF', type: 'NA' },
    '326': { descr: 'Total Securities Interest', type: 'NA' },
    '327': { descr: 'Total Securities Matured', type: 'NA' },
    '328': { descr: 'Total Securities Interest - FF', type: 'NA' },
    '329': { descr: 'Total Securities Interest - CHF', type: 'NA' },
    '330': { descr: 'Total Escrow Credits', type: 'NA' },
    '331': { descr: '', type: 'CR' },
    '332': { descr: 'Total Miscellaneous Securities Credits - FF', type: 'NA' },
    '336': { descr: 'Total Miscellaneous Securities Credits - CHF', type: 'NA' },
    '338': { descr: 'Total Securities Sold', type: 'NA' },
    '340': { descr: 'Total Broker Deposits', type: 'NA' },
    '341': { descr: 'Total Broker Deposits - FF', type: 'NA' },
    '342': { descr: 'Broker Deposit', type: 'CR' },
    '343': { descr: 'Total Broker Deposits - CHF', type: 'NA' },
    '344': { descr: 'Individual Back Value Credit', type: 'CR' },
    '345': { descr: 'Item in Brokers Deposit', type: 'CR' },
    '346': { descr: 'Sweep Interest Income', type: 'CR' },
    '347': { descr: 'Sweep Principal Sell', type: 'CR' },
    '348': { descr: 'Futures Credit', type: 'CR' },
    '349': { descr: 'Principal Payments Credit', type: 'CR' },
    '350': { descr: 'Investment Sold', type: 'NA' },
    '351': { descr: 'Individual Investment Sold', type: 'CR' },
    '352': { descr: 'Total Cash Center Credits', type: 'NA' },
    '353': { descr: 'Cash Center Credit', type: 'CR' },
    '354': { descr: 'Interest Credit', type: 'CR' },
    '355': { descr: 'Investment Interest', type: 'NA' },
    '356': { descr: 'Total Credit Adjustment', type: 'NA' },
    '357': { descr: 'Credit Adjustment', type: 'CR' },
    '358': { descr: 'YTD Adjustment Credit', type: 'CR' },
    '359': { descr: 'Interest Adjustment Credit', type: 'CR' },
    '360': { descr: 'Total Credits Less Wire Transfer and Returned Checks', type: 'NA' },
    '361': { descr: 'Grand Total Credits Less Grand Total Debits', type: 'NA' },
    '362': { descr: 'Correspondent Collection', type: 'CR' },
    '363': { descr: 'Correspondent Collection Adjustment', type: 'CR' },
    '364': { descr: 'Loan Participation', type: 'CR' },
    '366': { descr: 'Currency and Coin Deposited', type: 'CR' },
    '367': { descr: 'Food Stamp Letter', type: 'CR' },
    '368': { descr: 'Food Stamp Adjustment', type: 'CR' },
    '369': { descr: 'Clearing Settlement Credit', type: 'CR' },
    '370': { descr: 'Total Back Value Credits', type: 'NA' },
    '372': { descr: 'Back Value Adjustment', type: 'CR' },
    '373': { descr: 'Customer Payroll', type: 'CR' },
    '374': { descr: 'FRB Statement Recap', type: 'CR' },
    '376': { descr: 'Savings Bond Letter or Adjustment', type: 'CR' },
    '377': { descr: 'Treasury Tax and Loan Credit', type: 'CR' },
    '378': { descr: 'Transfer of Treasury Credit', type: 'CR' },
    '379': { descr: 'FRB Government Checks Cash Letter Credit', type: 'CR' },
    '381': { descr: 'FRB Government Check Adjustment', type: 'CR' },
    '382': { descr: 'FRB Postal Money Order Credit', type: 'CR' },
    '383': { descr: 'FRB Postal Money Order Adjustment', type: 'CR' },
    '384': { descr: 'FRB Cash Letter Auto Charge Credit', type: 'CR' },
    '385': { descr: 'Total Universal Credits', type: 'NA' },
    '386': { descr: 'FRB Cash Letter Auto Charge Adjustment', type: 'CR' },
    '387': { descr: 'FRB Fine-Sort Cash Letter Credit', type: 'CR' },
    '388': { descr: 'FRB Fine-Sort Adjustment', type: 'CR' },
    '389': { descr: 'Total Freight Payment Credits', type: 'NA' },
    '390': { descr: 'Total Miscellaneous Credits', type: 'NA' },
    '391': { descr: 'Universal Credit', type: 'CR' },
    '392': { descr: 'Freight Payment Credit', type: 'CR' },
    '393': { descr: 'Itemized Credit Over $10,000', type: 'CR' },
    '394': { descr: 'Cumulative** Credits', type: 'CR' },
    '395': { descr: 'Check Reversal', type: 'CR' },
    '397': { descr: 'Float Adjustment', type: 'CR' },
    '398': { descr: 'Miscellaneous Fee Refund', type: 'CR' },
    '399': { descr: 'Miscellaneous Credit', type: 'CR' },
    '400': { descr: 'Total Debits', type: 'NA' },
    '401': { descr: 'Total Debit Amount MTD', type: 'NA' },
    '402': { descr: 'Total Debit Number (0402)', type: 'NA' },
    '403': { descr: 'Today’s Total Debits', type: 'NA' },
    '404': { descr: 'Debits Available (0404)', type: 'NA' },
    '405': { descr: 'Total Debit Less Wire Transfers and Charge- Backs', type: 'NA' },
    '406': { descr: 'Debits not Detailed', type: 'NA' },
    '408': { descr: 'Float Adjustment', type: 'DB' },
    '409': { descr: 'Debit (Any Type)', type: 'DB' },
    '410': { descr: 'Total YTD Adjustment', type: 'NA' },
    '412': { descr: 'Total Debits (Excluding Returned Items)', type: 'NA' },
    '415': { descr: 'Lockbox Debit', type: 'DB' },
    '416': { descr: 'Total Lockbox Debits', type: 'NA' },
    '420': { descr: 'EDI Transaction Debits', type: 'NA' },
    '421': { descr: 'EDI Transaction Debit', type: 'DB' },
    '422': { descr: 'EDIBANX Settlement Debit', type: 'DB' },
    '423': { descr: 'EDIBANX Return Item Debit', type: 'DB' },
    '430': { descr: 'Total Payable-Through Drafts', type: 'NA' },
    '435': { descr: 'Payable-Through Draft', type: 'DB' },
    '445': { descr: 'ACH Concentration Debit', type: 'DB' },
    '446': { descr: 'Total ACH Disbursement Funding Debits', type: 'NA' },
    '447': { descr: 'ACH Disbursement Funding Debit', type: 'DB' },
    '450': { descr: 'Total ACH Debits', type: 'NA' },
    '451': { descr: 'ACH Debit Received', type: 'DB' },
    '452': { descr: 'Item in ACH Disbursement or Debit', type: 'DB' },
    '455': { descr: 'Preauthorized ACH Debit', type: 'DB' },
    '462': { descr: 'Account Holder Initiated ACH Debit', type: 'DB' },
    '463': { descr: 'Corporate Trade Payment Debits', type: 'NA' },
    '464': { descr: 'Corporate Trade Payment Debit', type: 'DB' },
    '465': { descr: 'Corporate Trade Payment Settlement', type: 'NA' },
    '466': { descr: 'ACH Settlement', type: 'DB' },
    '467': { descr: 'ACH Settlement Debits', type: 'NA' },
    '468': { descr: 'ACH Return Item or Adjustment Settlement', type: 'DB' },
    '469': { descr: 'Miscellaneous ACH Debit', type: 'DB' },
    '470': { descr: 'Total Check Paid', type: 'NA' },
    '471': { descr: 'Total Check Paid - Cumulative MTD', type: 'NA' },
    '472': { descr: 'Cumulative** Checks Paid', type: 'DB' },
    '474': { descr: 'Certified Check Debit', type: 'DB' },
    '475': { descr: 'Check Paid', type: 'DB' },
    '476': { descr: 'Federal Reserve Bank Letter Debit', type: 'DB' },
    '477': { descr: 'Bank Originated Debit', type: 'DB' },
    '478': { descr: 'List Post Debits', type: 'NA' },
    '479': { descr: 'List Post Debit', type: 'DB' },
    '480': { descr: 'Total Loan Payments', type: 'NA' },
    '481': { descr: 'Individual Loan Payment', type: 'DB' },
    '482': { descr: 'Total Bank-Originated Debits', type: 'NA' },
    '484': { descr: 'Draft', type: 'DB' },
    '485': { descr: 'DTC Debit', type: 'DB' },
    '486': { descr: 'Total Cash Letter Debits', type: 'NA' },
    '487': { descr: 'Cash Letter Debit', type: 'DB' },
    '489': { descr: 'Cash Letter Adjustment', type: 'DB' },
    '490': { descr: 'Total Outgoing Money Transfers', type: 'NA' },
    '491': { descr: 'Individual Outgoing Internal Money Transfer', type: 'DB' },
    '493': { descr: 'Customer Terminal Initiated Money Transfer', type: 'DB' },
    '495': { descr: 'Outgoing Money Transfer', type: 'DB' },
    '496': { descr: 'Money Transfer Adjustment', type: 'DB' },
    '498': { descr: 'Compensation', type: 'DB' },
    '500': { descr: 'Total Automatic Transfer Debits', type: 'NA' },
    '501': { descr: 'Individual Automatic Transfer Debit', type: 'DB' },
    '502': { descr: 'Bond Operations Debit', type: 'DB' },
    '505': { descr: 'Total Book Transfer Debits', type: 'NA' },
    '506': { descr: 'Book Transfer Debit', type: 'DB' },
    '507': { descr: 'Total International Money Transfer Debits', type: 'NA' },
    '508': { descr: 'Individual International Money Transfer Debits', type: 'DB' },
    '510': { descr: 'Total International Debits', type: 'NA' },
    '512': { descr: 'Letter of Credit Debit', type: 'DB' },
    '513': { descr: 'Letter of Credit', type: 'DB' },
    '514': { descr: 'Foreign Exchange Debit', type: 'DB' },
    '515': { descr: 'Total Letters of Credit', type: 'NA' },
    '516': { descr: 'Foreign Remittance Debit', type: 'DB' },
    '518': { descr: 'Foreign Collection Debit', type: 'DB' },
    '522': { descr: 'Foreign Checks Paid', type: 'DB' },
    '524': { descr: 'Commission', type: 'DB' },
    '526': { descr: 'International Money Market Trading', type: 'DB' },
    '527': { descr: 'Standing Order', type: 'DB' },
    '529': { descr: 'Miscellaneous International Debit', type: 'DB' },
    '530': { descr: 'Total Security Debits', type: 'NA' },
    '531': { descr: 'Securities Purchased', type: 'DB' },
    '532': { descr: 'Total Amount of Securities Purchased', type: 'NA' },
    '533': { descr: 'Security Collection Debit', type: 'DB' },
    '534': { descr: 'Total Miscellaneous Securities DB - FF', type: 'NA' },
    '535': { descr: 'Purchase of Equity Securities', type: 'DB' },
    '536': { descr: 'Total Miscellaneous Securities Debit - CHF', type: 'NA' },
    '537': { descr: 'Total Collection Debit', type: 'NA' },
    '538': { descr: 'Matured Repurchase Order', type: 'DB' },
    '539': { descr: 'Total Bankers’ Acceptances Debit', type: 'NA' },
    '540': { descr: 'Coupon Collection Debit', type: 'DB' },
    '541': { descr: 'Bankers’ Acceptances', type: 'DB' },
    '542': { descr: 'Purchase of Debt Securities', type: 'DB' },
    '543': { descr: 'Domestic Collection', type: 'DB' },
    '544': { descr: 'Interest/Matured Principal Payment', type: 'DB' },
    '546': { descr: 'Commercial paper', type: 'DB' },
    '547': { descr: 'Capital Change', type: 'DB' },
    '548': { descr: 'Savings Bonds Sales Adjustment', type: 'DB' },
    '549': { descr: 'Miscellaneous Security Debit', type: 'DB' },
    '550': { descr: 'Total Deposited Items Returned', type: 'NA' },
    '551': { descr: 'Total Credit Reversals', type: 'NA' },
    '552': { descr: 'Credit Reversal', type: 'DB' },
    '554': { descr: 'Posting Error Correction Debit', type: 'DB' },
    '555': { descr: 'Deposited Item Returned', type: 'DB' },
    '556': { descr: 'Total ACH Return Items', type: 'NA' },
    '557': { descr: 'Individual ACH Return Item', type: 'DB' },
    '558': { descr: 'ACH Reversal Debit', type: 'DB' },
    '560': { descr: 'Total Rejected Debits', type: 'NA' },
    '561': { descr: 'Individual Rejected Debit', type: 'DB' },
    '563': { descr: 'Overdraft', type: 'DB' },
    '564': { descr: 'Overdraft Fee', type: 'DB' },
    '566': { descr: 'Return Item', type: 'DB' },
    '567': { descr: 'Return Item Fee', type: 'DB' },
    '568': { descr: 'Return Item Adjustment', type: 'DB' },
    '570': { descr: 'Total ZBA Debits', type: 'NA' },
    '574': { descr: 'Cumulative ZBA Debits', type: 'DB' },
    '575': { descr: 'ZBA Debit', type: 'DB' },
    '577': { descr: 'ZBA Debit Transfer', type: 'DB' },
    '578': { descr: 'ZBA Debit Adjustment', type: 'DB' },
    '580': { descr: 'Total Controlled Disbursing Debits', type: 'NA' },
    '581': { descr: 'Individual Controlled Disbursing Debit', type: 'DB' },
    '583': { descr: 'Total Disbursing Checks Paid - Early Amount', type: 'NA' },
    '584': { descr: 'Total Disbursing Checks Paid - Later Amount', type: 'NA' },
    '585': { descr: 'Disbursing Funding Requirement', type: 'NA' },
    '586': { descr: 'FRB Presentment Estimate (Fed Estimate)', type: 'NA' },
    '587': { descr: 'Late Debits (After Notification)', type: 'NA' },
    '588': { descr: 'Total Disbursing Checks Paid-Last Amount', type: 'NA' },
    '590': { descr: 'Total DTC Debits', type: 'NA' },
    '594': { descr: 'Total ATM Debits', type: 'NA' },
    '595': { descr: 'ATM Debit', type: 'DB' },
    '596': { descr: 'Total APR Debits', type: 'NA' },
    '597': { descr: 'ARP Debit', type: 'DB' },
    '601': { descr: 'Estimated Total Disbursement', type: 'NA' },
    '602': { descr: 'Adjusted Total Disbursement', type: 'NA' },
    '610': { descr: 'Total Funds Required', type: 'NA' },
    '611': { descr: 'Total Wire Transfers Out- CHF', type: 'NA' },
    '612': { descr: 'Total Wire Transfers Out - FF', type: 'NA' },
    '613': { descr: 'Total International Debit - CHF', type: 'NA' },
    '614': { descr: 'Total International Debit - FF', type: 'NA' },
    '615': { descr: 'Total Federal Reserve Bank - Commercial Bank Debit', type: 'NA' },
    '616': { descr: 'Federal Reserve Bank - Commercial Bank Debit', type: 'DB' },
    '617': { descr: 'Total Securities Purchased - CHF', type: 'NA' },
    '618': { descr: 'Total Securities Purchased - FF', type: 'NA' },
    '621': { descr: 'Total Broker Debits - CHF', type: 'NA' },
    '622': { descr: 'Broker Debit', type: 'DB' },
    '623': { descr: 'Total Broker Debits - FF', type: 'NA' },
    '625': { descr: 'Total Broker Debits', type: 'NA' },
    '626': { descr: 'Total Fed Funds Purchased', type: 'NA' },
    '627': { descr: 'Fed Funds Purchased', type: 'DB' },
    '628': { descr: 'Total Cash Center Debits', type: 'NA' },
    '629': { descr: 'Cash Center Debit', type: 'DB' },
    '630': { descr: 'Total Debit Adjustments', type: 'NA' },
    '631': { descr: 'Debit Adjustment', type: 'DB' },
    '632': { descr: 'Total Trust Debits', type: 'NA' },
    '633': { descr: 'Trust Debit', type: 'DB' },
    '634': { descr: 'YTD Adjustment Debit', type: 'DB' },
    '640': { descr: 'Total Escrow Debits', type: 'NA' },
    '641': { descr: 'Individual Escrow Debit', type: 'DB' },
    '644': { descr: 'Individual Back Value Debit', type: 'DB' },
    '646': { descr: 'Transfer Calculation Debit', type: 'NA' },
    '650': { descr: 'Investments Purchased', type: 'NA' },
    '651': { descr: 'Individual Investment purchased', type: 'DB' },
    '654': { descr: 'Interest Debit', type: 'DB' },
    '655': { descr: 'Total Investment Interest Debits', type: 'NA' },
    '656': { descr: 'Sweep Principal Buy', type: 'DB' },
    '657': { descr: 'Futures Debit', type: 'DB' },
    '658': { descr: 'Principal Payments Debit', type: 'DB' },
    '659': { descr: 'Interest Adjustment Debit', type: 'DB' },
    '661': { descr: 'Account Analysis Fee', type: 'DB' },
    '662': { descr: 'Correspondent Collection Debit', type: 'DB' },
    '663': { descr: 'Correspondent Collection Adjustment', type: 'DB' },
    '664': { descr: 'Loan Participation', type: 'DB' },
    '665': { descr: 'Intercept Debits', type: 'NA' },
    '666': { descr: 'Currency and Coin Shipped', type: 'DB' },
    '667': { descr: 'Food Stamp Letter', type: 'DB' },
    '668': { descr: 'Food Stamp Adjustment', type: 'DB' },
    '669': { descr: 'Clearing Settlement Debit', type: 'DB' },
    '670': { descr: 'Total Back Value Debits', type: 'NA' },
    '672': { descr: 'Back Value Adjustment', type: 'DB' },
    '673': { descr: 'Customer Payroll', type: 'DB' },
    '674': { descr: 'FRB Statement Recap', type: 'DB' },
    '676': { descr: 'Savings Bond Letter or Adjustment', type: 'DB' },
    '677': { descr: 'Treasury Tax and Loan Debit', type: 'DB' },
    '678': { descr: 'Transfer of Treasury Debit', type: 'DB' },
    '679': { descr: 'FRB Government Checks Cash Letter Debit', type: 'DB' },
    '681': { descr: 'FRB Government Check Adjustment', type: 'DB' },
    '682': { descr: 'FRB Postal Money Order Debit', type: 'DB' },
    '683': { descr: 'FRB Postal Money Order Adjustment', type: 'DB' },
    '684': { descr: 'FRB Cash Letter Auto Charge Debit', type: 'DB' },
    '685': { descr: 'Total Universal Debits', type: 'NA' },
    '686': { descr: 'FRB Cash Letter Auto Charge Adjustment', type: 'DB' },
    '687': { descr: 'FRB Fine-Sort Cash Letter Debit', type: 'DB' },
    '688': { descr: 'FRB Fine-Sort Adjustment', type: 'DB' },
    '689': { descr: 'FRB Freight Payment Debits', type: 'NA' },
    '690': { descr: 'Total Miscellaneous Debits', type: 'NA' },
    '691': { descr: 'Universal Debit', type: 'DB' },
    '692': { descr: 'Freight Payment Debit', type: 'DB' },
    '693': { descr: 'Itemized Debit Over $10,000', type: 'DB' },
    '694': { descr: 'Deposit Reversal', type: 'DB' },
    '695': { descr: 'Deposit Correction Debit', type: 'DB' },
    '696': { descr: 'Regular Collection Debit', type: 'DB' },
    '697': { descr: 'Cumulative** Debits', type: 'DB' },
    '698': { descr: 'Miscellaneous Fees', type: 'DB' },
    '699': { descr: 'Miscellaneous Debit', type: 'DB' },
    '701': { descr: 'Principal Loan Balance', type: 'NA' },
    '703': { descr: 'Available Commitment Amount', type: 'NA' },
    '705': { descr: 'Payment Amount Due', type: 'NA' },
    '707': { descr: 'Principal Amount Past Due', type: 'NA' },
    '709': { descr: 'Interest Amount Past Due', type: 'NA' },
    '720': { descr: 'Total Loan Payment', type: 'NA' },
    '721': { descr: 'Amount Applied to Interest', type: 'CR' },
    '722': { descr: 'Amount Applied to Principal', type: 'CR' },
    '723': { descr: 'Amount Applied to Escrow', type: 'CR' },
    '724': { descr: 'Amount Applied to Late Charges', type: 'CR' },
    '725': { descr: 'Amount Applied to Buydown', type: 'CR' },
    '726': { descr: 'Amount Applied to Misc. Fees', type: 'CR' },
    '727': { descr: 'Amount Applied to Deferred Interest Detail', type: 'CR' },
    '728': { descr: 'Amount Applied to Service Charge', type: 'CR' },
    '760': { descr: 'Loan Disbursement', type: 'NA' },
    '890': { descr: 'Contains Non-monetary Information', type: 'NA' },
    '900': { descr: 'User Defined Status Code 900', type: 'NA' },
    '901': { descr: 'User Defined Status Code 901', type: 'NA' },
    '902': { descr: 'User Defined Status Code 902', type: 'NA' },
    '903': { descr: 'User Defined Status Code 903', type: 'NA' },
    '904': { descr: 'User Defined Status Code 904', type: 'NA' },
    '905': { descr: 'User Defined Status Code 905', type: 'NA' },
    '906': { descr: 'User Defined Status Code 906', type: 'NA' },
    '907': { descr: 'User Defined Status Code 907', type: 'NA' },
    '908': { descr: 'User Defined Status Code 908', type: 'NA' },
    '909': { descr: 'User Defined Status Code 909', type: 'NA' },
    '910': { descr: 'User Defined Status Code 910', type: 'NA' },
    '911': { descr: 'User Defined Status Code 911', type: 'NA' },
    '912': { descr: 'User Defined Status Code 912', type: 'NA' },
    '913': { descr: 'User Defined Status Code 913', type: 'NA' },
    '914': { descr: 'User Defined Status Code 914', type: 'NA' },
    '915': { descr: 'User Defined Status Code 915', type: 'NA' },
    '916': { descr: 'User Defined Status Code 916', type: 'NA' },
    '917': { descr: 'User Defined Status Code 917', type: 'NA' },
    '918': { descr: 'User Defined Status Code 918', type: 'NA' },
    '919': { descr: 'User Defined Status Code 919', type: 'NA' },
    OPBD: { descr: 'Opening Booked', type: 'NA' },
    CLBD: { descr: 'Closing Booked', type: 'NA' },
    CLAV: { descr: 'Closing Available', type: 'NA' },
    FWAV: { descr: 'Forward Available', type: 'NA' },
    PRCD: { descr: 'Previously Closed Booked', type: 'NA' },
    OPAV: { descr: 'Opening Available', type: 'NA' },
    '60F': { descr: 'Opening Balance', type: 'NA' },
    '64': { descr: 'Closing Balance', type: 'NA' },
    '62': { descr: 'Current Account balance', type: 'NA' },
    NCOM: { descr: 'Commisions', type: 'NA' },
    NDCR: { descr: 'Documentary credit', type: 'NA' },
    NDDT: { descr: 'Direct Debit Item', type: 'NA' },
    NDIV: { descr: 'Dividends-Warrants', type: 'NA' },
    NECK: { descr: 'Eurocheques', type: 'NA' },
    NEQA: { descr: 'Equivalent amount', type: 'NA' },
    NFEX: { descr: 'Foreign exchange', type: 'NA' },
    NINT: { descr: 'Interest', type: 'NA' },
    NLDP: { descr: 'Loan deposit', type: 'NA' },
    NMSC: { descr: 'Miscellaneous', type: 'NA' },
    NRTI: { descr: 'Returned item', type: 'NA' },
    NSEC: { descr: 'Securities', type: 'NA' },
    NSTO: { descr: 'Standing order', type: 'NA' },
    NTCK: { descr: 'Travellers cheques', type: 'NA' },
    NTRF: { descr: 'Transfer', type: 'NA' },
    FCHG: { descr: 'Charges and other expenses', type: 'NA' },
    FCHK: { descr: 'Cheques', type: 'NA' },
    NBNK: { descr: 'Securities Related Item - Bank fees', type: 'NA' },
    NBRF: { descr: 'Brokerage fee', type: 'NA' },
    NCAR: {
      descr:
        'Securities Related Item - Corporate Actions Related (Should only be used when no specific corporate action event code is available)',
      type: 'NA',
    },
    NCAS: { descr: 'Securities Related Item - Cash in Lieu', type: 'NA' },
    NCLR: { descr: 'Cash letters/Cheques remittance', type: 'NA' },
    NCMI: { descr: 'Cash management item - No detail', type: 'NA' },
    NCMN: { descr: 'Cash management item - Notional pooling', type: 'NA' },
    NCMP: { descr: 'Compensation claims', type: 'NA' },
    NCMS: { descr: 'Cash management item - Sweeping', type: 'NA' },
    NCMT: { descr: 'Cash management item -Topping', type: 'NA' },
    NCMZ: { descr: 'Cash management item - Zero balancing', type: 'NA' },
    NCPN: { descr: 'Securities Related Item - Coupon payments', type: 'NA' },

    NDIS: { descr: 'Securities Related Item - Gains disbursement', type: 'NA' },

    NEXT: { descr: 'Securities Related Item - External transfer for own account', type: 'NA' },

    NLBX: { descr: 'Lock box', type: 'NA' },
    NMAR: { descr: 'Securities Related Item - Margin payments/Receipts', type: 'NA' },
    NMAT: { descr: 'Securities Related Item - Maturity', type: 'NA' },
    NMGT: { descr: 'Securities Related Item - Management fees', type: 'NA' },

    NNWI: { descr: 'Securities Related Item - New issues distribution', type: 'NA' },
    NOPT: { descr: 'Securities Related Item - Options', type: 'NA' },
    NPCH: {
      descr: 'Securities Related Item - Purchase (including STIF and Time deposits)',
      type: 'NA',
    },
    NPOP: { descr: 'Securities Related Item - Pair-off proceeds', type: 'NA' },
    NPRN: { descr: 'Securities Related Item - Principal pay-down/pay-up', type: 'NA' },
    NREC: { descr: 'Securities Related Item - Tax reclaim', type: 'NA' },
    NRED: { descr: 'Securities Related Item - Redemption/Withdrawal', type: 'NA' },
    NRIG: { descr: 'Securities Related Item - Rights', type: 'NA' },

    NSAL: {
      descr: 'Securities Related Item - Sale (including STIF and Time deposits)',
      type: 'NA',
    },

    NSLE: { descr: 'Securities Related Item - Securities lending related', type: 'NA' },
    NSTP: { descr: 'Securities Related Item - Stamp duty', type: 'NA' },
    NSUB: { descr: 'Securities Related Item - Subscription', type: 'NA' },
    NSWP: { descr: 'Securities Related Item - SWAP payment', type: 'NA' },
    NTAX: { descr: 'Securities Related Item - Withholding tax payment', type: 'NA' },
    NTCM: { descr: 'Securities Related Item - Tripartite collateral management', type: 'NA' },
    NTRA: { descr: 'Securities Related Item - Internal transfer for own account', type: 'NA' },
    NTRN: { descr: 'Securities Related Item - Transaction fee', type: 'NA' },
    NUWC: { descr: 'Securities Related Item - Underwriting commission', type: 'NA' },
    NVDA: {
      descr:
        'Value date adjustment (used with an entry made to withdraw an incorrectly dated entry - it will be followed by the correct entry with the relevant code)',
      type: 'NA',
    },
    NWAR: { descr: 'Securities Related Item - Warrant', type: 'NA' },
    FINT: { descr: 'Interest', type: 'NA' },
    FRTI: { descr: 'Returned item', type: 'NA' },
    NBOE: { descr: 'Bill of exchange', type: 'NA' },
    NCHG: { descr: 'Charges and other expenses', type: 'NA' },
    NCHK: { descr: 'Cheques', type: 'NA' },
    NCOL: { descr: 'Collections', type: 'NA' },

    S100: { descr: 'SWIFT message 100', type: 'NA' },
    S101: { descr: 'SWIFT message 101', type: 'NA' },
    S103: { descr: 'SWIFT message 103', type: 'NA' },
    S190: { descr: 'SWIFT message 190', type: 'NA' },
    S191: { descr: 'SWIFT message 191', type: 'NA' },
    S200: { descr: 'SWIFT message 200', type: 'NA' },
    S201: { descr: 'SWIFT message 201', type: 'NA' },
    S202: { descr: 'SWIFT message 202', type: 'NA' },
    S203: { descr: 'SWIFT message 203', type: 'NA' },
    S205: { descr: 'SWIFT message 205', type: 'NA' },
    S300: { descr: 'SWIFT message 300', type: 'NA' },
    S320: { descr: 'SWIFT message 320', type: 'NA' },
    S400: { descr: 'SWIFT message 400', type: 'NA' },
    S554: { descr: 'SWIFT message 554', type: 'NA' },
    S556: { descr: 'SWIFT message 556', type: 'NA' },
    NODC: { descr: 'Overdraft Charge', type: 'NA' },
    ACMTMCOPCHRG: {
      descr: 'Account Management-Miscellaneous Credit Operations-Charges',
      type: 'NA',
    },
    ACMTMCOPCOMM: {
      descr: 'Account Management-Miscellaneous Credit Operations-Commission',
      type: 'NA',
    },
    ACMTMCOPINTR: {
      descr: 'Account Management-Miscellaneous Credit Operations-Interest',
      type: 'NA',
    },
    ACMTMCOPTAXE: { descr: 'Account Management-Miscellaneous Credit Operations-Taxes', type: 'NA' },
    ACMTMDOPCHRG: {
      descr: 'Account Management-Miscellaneous Debit Operations-Charges',
      type: 'NA',
    },
    ACMTMDOPCOMM: {
      descr: 'Account Management-Miscellaneous Debit Operations-Commission',
      type: 'NA',
    },
    ACMTMDOPINTR: {
      descr: 'Account Management-Miscellaneous Debit Operations-Interest',
      type: 'NA',
    },
    ACMTMDOPTAXE: { descr: 'Account Management-Miscellaneous Debit Operations-Taxes', type: 'NA' },
    ACMTNTAVNTAV: { descr: 'Account Management-Not Available-Not Available', type: 'NA' },
    CAMTACCBCAJT: { descr: 'Cash Management-Account Balancing-Credit Adjustment', type: 'NA' },
    CAMTACCBDAJT: { descr: 'Cash Management-Account Balancing-Debit Adjustment', type: 'NA' },
    CAMTACCBSWEP: { descr: 'Cash Management-Account Balancing-Sweeping', type: 'NA' },
    CAMTACCBTOPG: { descr: 'Cash Management-Account Balancing-Topping', type: 'NA' },
    CAMTACCBZABA: { descr: 'Cash Management-Account Balancing-Zero Balancing', type: 'NA' },
    CAMTACCBNTAV: { descr: 'Cash Management-Account Balancing-Not Available', type: 'NA' },
    CAMTNTAVNTAV: { descr: 'Cash Management-Not Available-Not Available', type: 'NA' },
    DERVNTAVNTAV: { descr: 'Derivatives-Not Available-Not Available', type: 'NA' },
    LDASCSLNCHRG: { descr: 'Loans, Deposits & Syndications - Consumer Loans- Charges', type: 'NA' },
    LDASCSLNCOMM: {
      descr: 'Loans, Deposits & Syndications- Consumer Loans - Commission',
      type: 'NA',
    },
    LDASCSLNDDWN: {
      descr: 'Loans, Deposits & Syndications - Consumer Loans - Drawdown',
      type: 'NA',
    },
    LDASCSLNINTR: {
      descr: 'Loans, Deposits & Syndications - Consumer Loans - Interest',
      type: 'NA',
    },
    LDASMCOPCHRG: {
      descr: 'Loans, Deposits & Syndications - Miscellaneous Credit Operations - Charges',
      type: 'NA',
    },
    LDASMCOPINTR: {
      descr: 'Loans, Deposits & Syndications - Miscellaneous Credit Operations - Interest',
      type: 'NA',
    },
    LDASMDOPCHRG: {
      descr: 'Loans, Deposits & Syndications - Miscellaneous Debit Operations - Charges',
      type: 'NA',
    },
    LDASMDOPINTR: {
      descr: 'Loans, Deposits & Syndications - Miscellaneous Debit Operations - Interest',
      type: 'NA',
    },
    LDASMDOPOTHR: {
      descr: 'Loans, Deposits & Syndications - Miscellaneous Debit Operations - Other',
      type: 'NA',
    },
    LDASMGLNCHRG: {
      descr: 'Loans, Deposits & Syndications - Mortgage Loans - Charges',
      type: 'NA',
    },
    LDASMGLNINTR: {
      descr: 'Loans, Deposits & Syndications - Mortgage Loans - Interest',
      type: 'NA',
    },
    LDASMGLNTAXE: { descr: 'Loans, Deposits & Syndications - Mortgage Loans - Taxes', type: 'NA' },
    LDASNTAVINTR: {
      descr: 'Loans, Deposits & Syndications - Not Available - Interest',
      type: 'NA',
    },
    LDASNTAVNTAV: {
      descr: 'Loans, Deposits & Syndications - Not Available - Not Available',
      type: 'NA',
    },
    NTAVNTAVNTAV: { descr: 'Not Available-Not Available-Not Available', type: 'NA' },
    PMNTACCBZABA: { descr: 'Payments-Account Balancing-Zero Balancing', type: 'NA' },
    PMNTCNTRCDPT: { descr: 'Payments-Counter Transactions-Cash Deposit', type: 'NA' },
    PMNTCNTRCWDL: { descr: 'Payments-Counter Transactions-Cash Withdrawal', type: 'NA' },
    PMNTCNTRCHRG: { descr: 'Payments-Counter Transactions-Charges', type: 'NA' },
    PMNTCNTRCCHQ: { descr: 'Payments-Counter Transactions-Cheque', type: 'NA' },
    PMNTCNTRCHKD: { descr: 'Payments-Counter Transactions-Cheque Deposit', type: 'NA' },
    PMNTCNTRFCDP: { descr: 'Payments-Counter Transactions-Foreign Currency Deposit', type: 'NA' },
    PMNTCNTRFCWD: {
      descr: 'Payments-Counter Transactions-Foreign Currency Withdrawal',
      type: 'NA',
    },
    PMNTCNTRMIXD: { descr: 'Payments-Counter Transactions-Mixed Deposit', type: 'NA' },
    PMNTCNTRNTAV: { descr: 'Payments-Counter Transactions-Not Available', type: 'NA' },
    PMNTCNTROTHR: { descr: 'Payments-Counter Transactions-Other', type: 'NA' },
    PMNTCCRDCDPT: { descr: 'Payments-Customer Card Transactions-Cash Deposit', type: 'NA' },
    PMNTCCRDCWDL: { descr: 'Payments-Customer Card Transactions-Cash Withdrawal', type: 'NA' },
    PMNTCCRDCHRG: { descr: 'Payments-Customer Card Transactions-Charges', type: 'NA' },
    PMNTCCRDDAJT: { descr: 'Payments-Customer Card Transactions-Credit Adjustments', type: 'NA' },
    PMNTCCRDPOSC: { descr: 'Payments-Customer Card Transactions-Credit Card Payment', type: 'NA' },
    PMNTCCRDXBCW: {
      descr: 'Payments-Customer Card Transactions-Cross-Border Cash Withdrawal',
      type: 'NA',
    },
    PMNTCCRDXBCP: {
      descr: 'Payments-Customer Card Transactions-Cross-Border Credit Card Payment',
      type: 'NA',
    },
    PMNTCCRDCAJT: { descr: 'Payments-Customer Card Transactions-Debit Adjustments', type: 'NA' },
    PMNTCCRDINTR: { descr: 'Payments-Customer Card Transactions-Interest', type: 'NA' },
    PMNTCCRDNTAV: { descr: 'Payments-Customer Card Transactions-Not Available', type: 'NA' },
    PMNTCCRDPOSP: {
      descr: 'Payments-Customer Card Transactions-Point-of-Sale (POS) Payment',
      type: 'NA',
    },
    PMNTCCRDPOSD: {
      descr: 'Payments-Customer Card Transactions-Point-of-Sale (POS) Payment - Debit Card',
      type: 'NA',
    },
    PMNTCCRDRIMB: { descr: 'Payments-Customer Card Transactions-Reimbursements', type: 'NA' },
    PMNTITAVITAV: {
      descr: 'Payments-Interim Available Balance-Interim Available Balance',
      type: 'NA',
    },
    PMNTICHQBCHQ: { descr: 'Payments-Issued Cheques-Bank Cheque', type: 'NA' },
    PMNTICHQCCHQ: { descr: 'Payments-Issued Cheques-Cheque', type: 'NA' },
    PMNTICHQXBCQ: { descr: 'Payments-Issued Cheques-Foreign Cheque', type: 'NA' },
    PMNTICHQNTAV: { descr: 'Payments-Issued Cheques-Not Available', type: 'NA' },
    PMNTICDTATXN: { descr: 'Payments-Issued Credit Transfers-ACH Transaction', type: 'NA' },
    PMNTICDTAUTT: { descr: 'Payments-Issued Credit Transfers-Automatic Transfer', type: 'NA' },
    PMNTICDTCHRG: { descr: 'Payments-Issued Credit Transfers-Charges', type: 'NA' },
    PMNTICDTVCOM: {
      descr: 'Payments-Issued Credit Transfers-Credit Transf. w/agreed commercial info',
      type: 'NA',
    },
    PMNTICDTXBCT: {
      descr: 'Payments-Issued Credit Transfers-Cross-Border Credit Transfer',
      type: 'NA',
    },
    PMNTICDTPMDD: { descr: 'Payments-Issued Credit Transfers-Direct Debit', type: 'NA' },
    PMNTICDTDMCT: {
      descr: 'Payments-Issued Credit Transfers-Domestic Credit Transfer',
      type: 'NA',
    },
    PMNTICDTNTAV: { descr: 'Payments-Issued Credit Transfers-Not Available', type: 'NA' },
    PMNTICDTOTHR: { descr: 'Payments-Issued Credit Transfers-Other', type: 'NA' },
    PMNTICDTSALA: { descr: 'Payments-Issued Credit Transfers-Payroll/Salary Payment', type: 'NA' },
    PMNTICDTTAXE: { descr: 'Payments-Issued Credit Transfers-Taxes', type: 'NA' },
    PMNTIDDTPMDD: { descr: 'Payments-Issued Direct Debits-Direct Debit', type: 'NA' },
    PMNTIDDTITAV: { descr: 'Payments-Issued Direct Debits-Interim Available Balance', type: 'NA' },
    PMNTIDDTNTAV: { descr: 'Payments-Issued Direct Debits-Not Available', type: 'NA' },
    PMNTIDDTUPDD: {
      descr: 'Payments-Issued Direct Debits-Reversal due to Return/Unpaid Direct Debit',
      type: 'NA',
    },
    PMNTIDDTBBDD: { descr: 'Payments-Issued Direct Debits-SEPA B2B Direct Debit', type: 'NA' },
    PMNTIDDTESDD: { descr: 'Payments-Issued Direct Debits-SEPA Core Direct Debit', type: 'NA' },
    PMNTLBOXLBDB: { descr: 'Payments-Lockbox Transactions-Debit', type: 'NA' },
    PMNTLBOXLBDP: { descr: 'Payments-Lockbox Transactions-Deposit', type: 'NA' },
    PMNTLBOXNTAV: { descr: 'Payments-Lockbox Transactions-Not Available', type: 'NA' },
    PMNTMCRDCHRG: { descr: 'Payments-Merchant Card Transactions-Charges', type: 'NA' },
    PMNTMCRDPOSP: {
      descr: 'Payments-Merchant Card Transactions-Point-of-Sale (POS) Payment',
      type: 'NA',
    },
    PMNTMCRDRIMB: { descr: 'Payments-Merchant Card Transactions-Reimbursements', type: 'NA' },
    PMNTMDOPCHRG: { descr: 'Payments-Miscellaneous Debit Operations-Charges', type: 'NA' },
    PMNTMDOPOTHR: { descr: 'Payments-Miscellaneous Debit Operations-Other', type: 'NA' },
    PMNTNTAVNTAV: { descr: 'Payments-Not Available-Not Available', type: 'NA' },
    PMNTRCHQNTAV: { descr: 'Payments-Received Cheques-Not Available', type: 'NA' },
    PMNTRCDTATXN: { descr: 'Payments-Received Credit Transfers-ACH Transaction', type: 'NA' },
    PMNTRCDTAUTT: { descr: 'Payments-Received Credit Transfers-Automatic Transfer', type: 'NA' },
    PMNTRCDTCHRG: { descr: 'Payments-Received Credit Transfers-Charges', type: 'NA' },
    PMNTRCDTVCOM: {
      descr:
        'Payments-Received Credit Transfers-Credit Transfer with agreed Commercial Information',
      type: 'NA',
    },
    PMNTRCDTXBCT: {
      descr: 'Payments-Received Credit Transfers-Cross-Border Credit Transfer',
      type: 'NA',
    },
    PMNTRCDTPMDD: { descr: 'Payments-Received Credit Transfers-Direct Debit', type: 'NA' },
    PMNTRCDTDMCT: {
      descr: 'Payments-Received Credit Transfers-Domestic Credit Transfer',
      type: 'NA',
    },
    PMNTRCDTNTAV: { descr: 'Payments-Received Credit Transfers-Not Available', type: 'NA' },
    PMNTRCDTSALA: {
      descr: 'Payments-Received Credit Transfers-Payroll/Salary Payment',
      type: 'NA',
    },
    PMNTRCDTRRTN: {
      descr:
        'Payments-Received Credit Transfers-Reversal due to Payment Return / reimbursement of a Credit Transfer',
      type: 'NA',
    },
    PMNTRDDTPMDD: { descr: 'Payments-Received Direct Debits-Direct Debit', type: 'NA' },
    PMNTRDDTITAV: {
      descr: 'Payments-Received Direct Debits-Interim Available Balance',
      type: 'NA',
    },
    PMNTRDDTNTAV: { descr: 'Payments-Received Direct Debits-Not Available', type: 'NA' },
    PMNTRDDTBBDD: { descr: 'Payments-Received Direct Debits-SEPA B2B Direct Debit', type: 'NA' },
    PMNTRDDTESDD: { descr: 'Payments-Received Direct Debits-SEPA Core Direct Debit', type: 'NA' },
    SECUCUSTCHRG: { descr: 'Securities-Custody-Charges', type: 'NA' },
    SECUCUSTINTR: { descr: 'Securities-Custody-Interest', type: 'NA' },
    SECUCUSTNTAV: { descr: 'Securities-Custody-Not Available', type: 'NA' },
    SECUMCOPOTHR: { descr: 'Securities-Miscellaneous Credit Operations - Other', type: 'NA' },
    SECUMDOPOTHR: { descr: 'Securities-Miscellaneous Debit Operations - Other', type: 'NA' },
    SECUNTAVNTAV: { descr: 'Securities-Not Available-Not Available', type: 'NA' },
    TRADGUARNTAV: { descr: 'Trade Services-Guarantees-Not Available', type: 'NA' },
    PMNTICDTESCT: { descr: 'Payments-Issued Credit Transfers-SEPA Credit Transfer', type: 'NA' },
    PMNTRCDTESCT: { descr: 'Payments-Received Credit Transfers-SEPA Credit Transfer', type: 'NA' },
  },
  TextSize: {
    tiny: 8,
    small: 10,
    regular: 12,
    large: 14,
    xl: 16,
    xxl: 18,
    xxxl: 20,
    max: 24,
  },
  daysOfWeek: [
    { id: 0, label: 'Sunday' },
    { id: 1, label: 'Monday' },
    { id: 2, label: 'Tuesday' },
    { id: 3, label: 'Wednesday' },
    { id: 4, label: 'Thursday' },
    { id: 5, label: 'Friday' },
    { id: 6, label: 'Saturday' },
  ],
  openingLedger: ['010', 'OPBD'],
  openingAvailable: ['040', 'OPAV'],
  closingAvailable: ['045', 'CLAV'],
  closingBalances: ['015', '045'],
  menu: [
    // Make sure to add to AllPermissions aswell
    {
      icon: 'usd',
      title: MenuItems.CASH_MANAGEMENT,
      subMenu: [
        {
          title: 'Bank Statements',
          target: 'BankStatementScreen',
          permission: CXPermissions.BANK_STATEMENT,
        },
        {
          title: 'Cash Position',
          target: 'CashPositionScreen',
          permission: CXPermissions.CASH_POSITION,
        },
        { title: 'Payments', target: 'PaymentsScreen', permission: CXPermissions.PAYMENT },
        {
          title: 'Payment Templates',
          target: 'PaymentTemplatesScreen',
          permission: CXPermissions.PAYMENT_TEMPLATE,
        },
      ],
      expanded: false,
    },
    {
      icon: 'th-list',
      title: MenuItems.STATIC_DATA,
      subMenu: [
        {
          title: 'Bank Account',
          target: 'BankAccountScreen',
          permission: CXPermissions.BANK_ACCOUNT,
        },
        { title: 'Banks', target: 'BankScreen', permission: CXPermissions.BANK },
        { title: 'Entities', target: 'EntityScreen', permission: CXPermissions.ENTITY },
      ],
      expanded: false,
    },
    {
      icon: 'cogs',
      title: MenuItems.CONFIGURATION,
      subMenu: [
        { title: 'Categories', target: 'CategoriesScreen', permission: CXPermissions.CATEGORIES },
        { title: 'Countries', target: 'CountriesScreen', permission: CXPermissions.COUNTRIES },
        { title: 'Currencies', target: 'CurrenciesScreen', permission: CXPermissions.CURRENCIES },
        {
          title: 'Holiday Calendar',
          target: 'HolidayCalendarScreen',
          permission: CXPermissions.HOLIDAYS,
        },
        {
          title: 'Bank Transaction Rules',
          target: 'BankTransactionRulesScreen',
          permission: CXPermissions.BANK_TRANSACTION_RULES,
        },
        {
          title: 'Payment Methods',
          target: 'PaymentMethodsScreen',
          permission: CXPermissions.PAYMENT_METHODS,
        },
        {
          title: 'SFTP Configuration',
          target: 'SFTPConfigurationScreen',
          permission: CXPermissions.SFTP_CONFIGURATION,
        },
      ],
      expanded: false,
    },
    {
      icon: 'shield',
      title: MenuItems.ACCESS_CONTROL,
      subMenu: [
        { title: 'Users', target: 'UsersScreen', permission: CXPermissions.USER },
        { title: 'User Groups', target: 'UserGroupsScreen', permission: CXPermissions.USER_GROUP },
        {
          title: 'Account Groups',
          target: 'AccountGroupsScreen',
          permission: CXPermissions.ACCOUNT_GROUP,
        },
        {
          title: 'Approval Workflow',
          target: 'ApprovalWorkflowScreen',
          permission: CXPermissions.APPROVAL_WORKFLOW,
        },
        {
          title: 'Audit Logs',
          target: 'LogsScreen',
          permission: CXPermissions.LOGS,
        },
      ],
      expanded: false,
    },
    {
      icon: 'cubes',
      title: MenuItems.PROCESSING,
      subMenu: [
        {
          title: 'Import Statements',
          target: 'StatementImportScreen',
          permission: CXPermissions.IMPORT_BANK_STATEMENTS,
        },
        {
          title: 'Import Data',
          target: 'ImportDataScreen',
          permission: CXPermissions.IMPORT_DATA,
        },
        {
          title: 'Apply Bank Transaction Rules',
          target: 'ApplyBankTransactionRulesScreen',
          permission: CXPermissions.APPLY_BANK_TRANSACTION_RULES,
        },
        {
          title: 'Payments',
          target: 'PaymentsReleaseScreen',
          permission: CXPermissions.PAYMENT_PROCESSING,
        },
        { title: 'Jobs', target: 'JobScreen', permission: CXPermissions.JOBS },
        { title: 'Reporting', target: 'ReportingScreen', permission: CXPermissions.REPORTING },
        {
          title: 'Pending Approvals',
          target: 'PendingApprovalsScreen',
          permission: CXPermissions.PENDING_APPROVAL,
        },
      ],
      expanded: false,
    },
  ],
};

export enum DateFormat {
  DEFAULT = 'DD MMM YYYY hh:mm A',
  DATE_ONLY = 'DD MMM YYYY',
}

export const JobStatusColors = {
  [JobStatus.Completed]: 'green',
  [JobStatus.Failed]: 'red',
  [JobStatus.Running]: 'orange',
  [JobStatus.Scheduled]: 'blue',
};

export const DataTypes = [
  {
    value: DbCollections.BankAccounts,
    label: 'Bank Accounts',
    fields: [
      {
        // Visible in table header and when matching columns.
        label: 'Account Name',
        // This is the key used for this field when we call onSubmit.
        key: 'accountName',
        // Allows for better automatic column matching. Optional.
        alternateMatches: ['account name', 'name'],
        // Used when editing and validating information.
        fieldType: {
          // There are 3 types - "input" / "checkbox" / "select".
          type: 'input',
        },
        // Used in the first step to provide an example of what data is expected in this field. Optional.
        example: 'ABC Corporation',
        // Can have multiple validations that are visible in Validation Step table.
        validations: [
          {
            // Can be "required" / "unique" / "regex"
            rule: 'required',
            errorMessage: 'Name is required',
            // There can be "info" / "warning" / "error" levels. Optional. Default "error".
            level: 'error',
          },
          {
            rule: 'regex',
            value: `^.{0,50}$`,
            errorMessage: 'Max length - 50 chars',
            level: 'error',
          },
          //   {
          //     rule: 'MaxLen',
          //     value: '50',
          //     errorMessage: 'Exceeds maximum length',
          //     level: 'error',
          //   },
        ],
      },
      {
        label: 'Account Number',
        key: 'accountNumber',
        alternateMatches: ['account number', 'number'],
        fieldType: {
          type: 'input',
        },
        example: '1122334455',
        validations: [
          {
            rule: 'required',
            errorMessage: 'Number is required',
            level: 'error',
          },
          {
            rule: 'regex',
            value: `^.{0,50}$`,
            errorMessage: 'Max length - 50 chars',
            level: 'error',
          },
        ],
      },
      {
        label: 'Short Code',
        key: 'shortCode',
        alternateMatches: ['short code', 'code'],
        fieldType: {
          type: 'input',
        },
        example: 'BOFAUS4455',
        validations: [
          {
            rule: 'regex',
            value: `^.{0,10}$`,
            errorMessage: 'Max length - 10 chars',
            level: 'error',
          },
        ],
      },
      {
        label: 'Entity',
        key: 'entity',
        alternateMatches: ['entity', 'bu', 'business unit'],
        fieldType: {
          type: 'select',
          options: [],
          optionType: LogType.Entity,
        },
        example: 'Subsidiary NL',
        validations: [
          {
            rule: 'required',
            errorMessage: 'Entity is required',
            level: 'error',
          },
        ],
      },
      {
        label: 'Currency',
        key: 'currency',
        alternateMatches: ['currency', 'curr', 'cur'],
        fieldType: {
          type: 'select',
          options: [],
          optionType: LogType.Currency,
        },
        example: 'USD',
        validations: [
          {
            rule: 'required',
            errorMessage: 'Currency is required',
            level: 'error',
          },
        ],
      },
      {
        label: 'Swift BIC - Override',
        key: 'BIC',
        alternateMatches: [
          'bic',
          'swift',
          'swift bic',
          'swift code',
          'bic code',
          'bank code',
          'Swift BIC',
        ],
        fieldType: {
          type: 'input',
        },
        example: 'CHASUS33XXX',
        validations: [
          {
            rule: 'regex',
            value: '^$|^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$',
            errorMessage: 'Invalid BIC code',
            level: 'warning',
          },
        ],
      },
      {
        label: 'IBAN',
        key: 'iban',
        alternateMatches: ['iban'],
        fieldType: {
          type: 'input',
        },
        example: 'NL20INGB0001234567',
        validations: [
          {
            rule: 'regex',
            value: '^$|^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,30}$',
            errorMessage: 'IBAN format looks incorrect',
            level: 'warning',
          },
        ],
      },
      {
        label: 'Bank',
        key: 'bank',
        alternateMatches: ['bank', 'bank code', 'bank id', 'bank name'],
        fieldType: {
          type: 'select',
          options: [],
          optionType: LogType.Bank,
        },
        example: 'BOFA',
        validations: [
          {
            rule: 'required',
            errorMessage: 'Bank is required',
            level: 'error',
          },
        ],
      },
    ],
  },

  {
    value: DbCollections.Banks,
    label: 'Banks',
    fields: [
      {
        label: 'Bank Name',

        key: 'name',

        alternateMatches: ['bank name', 'name'],
        fieldType: {
          type: 'input',
        },

        example: 'BOFA - US',

        validations: [
          {
            rule: 'required',
            errorMessage: 'Name is required',

            level: 'error',
          },
          {
            rule: 'regex',
            value: `^.{0,50}$`,
            errorMessage: 'Max length - 50 chars',
            level: 'error',
          },
        ],
      },
      {
        label: 'External Name',
        key: 'externalName',
        alternateMatches: ['external name', 'other name'],
        fieldType: {
          type: 'input',
        },
        example: 'Bank of America, N.A.',
        validations: [
          {
            rule: 'required',
            errorMessage: 'External name is required',
            level: 'error',
          },
          {
            rule: 'regex',
            value: `^.{0,50}$`,
            errorMessage: 'Max length - 50 chars',
            level: 'error',
          },
        ],
      },
      {
        label: 'Swift BIC',
        key: 'BIC',
        alternateMatches: [
          'bic',
          'swift',
          'swift bic',
          'swift code',
          'bic code',
          'bank code',
          'Swift BIC',
        ],
        fieldType: {
          type: 'input',
        },
        example: 'BOFAUS33XXX',
        validations: [
          {
            rule: 'regex',
            value: '^$|^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$',
            errorMessage: 'Invalid BIC code',
            level: 'warning',
          },
        ],
      },
      {
        label: 'Sort Code',
        key: 'sortCode',
        alternateMatches: ['sort code', 'local route code', 'route code'],
        fieldType: {
          type: 'input',
        },
        example: '121000358',
        validations: [
          {
            rule: 'regex',
            value: `^.{0,15}$`,
            errorMessage: 'Max length - 15 chars',
            level: 'error',
          },
        ],
      },
      {
        label: 'Relationship',
        key: 'relationship',
        alternateMatches: ['relation', 'relationship'],
        fieldType: {
          type: 'select',
          options: [
            { label: 'Ours', value: 'Ours' },
            { label: 'Third Party', value: 'Third Party' },
            { label: 'Both', value: 'Both' },
          ],
        },
        example: 'Ours',
        validations: [
          {
            rule: 'required',
            errorMessage: 'Relationship is required',
            level: 'error',
          },
        ],
      },
      {
        label: 'Address Line 1',
        key: 'address.addressLine1',
        alternateMatches: ['address line 1', 'address 1', 'building'],
        fieldType: {
          type: 'input',
        },
        example: '8001 VILLA PARK DRIVE',
        validations: [
          {
            rule: 'regex',
            value: `^.{0,35}$`,
            errorMessage: 'Max length - 35 chars',
            level: 'error',
          },
        ],
      },
      {
        label: 'Address Line 2',
        key: 'address.addressLine2',
        alternateMatches: ['address line 2', 'address 2', 'street name'],
        fieldType: {
          type: 'input',
        },
        example: 'St. Louis Street',
        validations: [
          {
            rule: 'regex',
            value: `^.{0,35}$`,
            errorMessage: 'Max length - 35 chars',
            level: 'error',
          },
        ],
      },
      {
        label: 'City',
        key: 'address.city',
        alternateMatches: ['city', 'town'],
        fieldType: {
          type: 'input',
        },
        example: 'HENRICO',
        validations: [
          {
            rule: 'regex',
            value: `^.{0,35}$`,
            errorMessage: 'Max length - 35 chars',
            level: 'error',
          },
        ],
      },
      {
        label: 'State',
        key: 'address.state',
        alternateMatches: ['state', 'province'],
        fieldType: {
          type: 'input',
        },
        example: 'VA',
        validations: [
          {
            rule: 'regex',
            value: `^.{0,35}$`,
            errorMessage: 'Max length - 35 chars',
            level: 'error',
          },
        ],
      },
      {
        label: 'Postal',
        key: 'address.postal',
        alternateMatches: ['postal', 'postal code', 'zip'],
        fieldType: {
          type: 'input',
        },
        example: '23228',
        validations: [
          {
            rule: 'regex',
            value: `^.{0,10}$`,
            errorMessage: 'Max length - 10 chars',
            level: 'error',
          },
        ],
      },
      {
        label: 'Country',
        key: 'address.country',
        alternateMatches: ['country', 'ctry'],
        fieldType: {
          type: 'select',
          options: [],
          optionType: LogType.Country,
        },
        example: 'US',
        validations: [],
      },
    ],
  },
  {
    value: DbCollections.Entities,
    label: 'Entities',
    fields: [
      {
        label: 'Entity Name',

        key: 'name',

        alternateMatches: ['entity name', 'name'],
        fieldType: {
          type: 'input',
        },

        example: 'Master Entiry',

        validations: [
          {
            rule: 'required',
            errorMessage: 'Name is required',

            level: 'error',
          },
          {
            rule: 'regex',
            value: `^.{0,50}$`,
            errorMessage: 'Max length - 50 chars',
            level: 'error',
          },
        ],
      },
      {
        label: 'Code',
        key: 'code',
        alternateMatches: ['entity code', 'entity code'],
        fieldType: {
          type: 'input',
        },
        example: 'US001',
        validations: [
          {
            rule: 'required',
            errorMessage: 'Entity code is required',
            level: 'error',
          },
          {
            rule: 'regex',
            value: `^.{0,10}$`,
            errorMessage: 'Max length - 10 chars',
            level: 'error',
          },
        ],
      },
      {
        label: 'Short Name',
        key: 'shortName',
        alternateMatches: ['short name', 'other name'],
        fieldType: {
          type: 'input',
        },
        example: 'MASTER',
        validations: [
          {
            rule: 'regex',
            value: `^.{0,10}$`,
            errorMessage: 'Max length - 10 chars',
            level: 'error',
          },
        ],
      },
      {
        label: 'Address Line 1',
        key: 'address.addressLine1',
        alternateMatches: ['address line 1', 'address 1', 'building'],
        fieldType: {
          type: 'input',
        },
        example: '8001 VILLA PARK DRIVE',
        validations: [
          {
            rule: 'regex',
            value: `^.{0,35}$`,
            errorMessage: 'Max length - 35 chars',
            level: 'error',
          },
        ],
      },
      {
        label: 'Address Line 2',
        key: 'address.addressLine2',
        alternateMatches: ['address line 2', 'address 2', 'street name'],
        fieldType: {
          type: 'input',
        },
        example: 'St. Louis Street',
        validations: [
          {
            rule: 'regex',
            value: `^.{0,35}$`,
            errorMessage: 'Max length - 35 chars',
            level: 'error',
          },
        ],
      },
      {
        label: 'City',
        key: 'address.city',
        alternateMatches: ['city', 'town'],
        fieldType: {
          type: 'input',
        },
        example: 'HENRICO',
        validations: [
          {
            rule: 'regex',
            value: `^.{0,35}$`,
            errorMessage: 'Max length - 35 chars',
            level: 'error',
          },
        ],
      },
      {
        label: 'State',
        key: 'address.state',
        alternateMatches: ['state', 'province'],
        fieldType: {
          type: 'input',
        },
        example: 'VA',
        validations: [
          {
            rule: 'regex',
            value: `^.{0,35}$`,
            errorMessage: 'Max length - 35 chars',
            level: 'error',
          },
        ],
      },
      {
        label: 'Postal',
        key: 'address.postal',
        alternateMatches: ['postal', 'postal code', 'zip'],
        fieldType: {
          type: 'input',
        },
        example: '23228',
        validations: [
          {
            rule: 'regex',
            value: `^.{0,10}$`,
            errorMessage: 'Max length - 10 chars',
            level: 'error',
          },
        ],
      },
      {
        label: 'Country',
        key: 'address.country',
        alternateMatches: ['country', 'ctry'],
        fieldType: {
          type: 'select',
          options: [],
          optionType: LogType.Country,
        },
        example: 'US',
        validations: [],
      },
    ],
  },
] as DataType[];
