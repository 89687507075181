import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { AppContext } from '../App';
import { UPDATE_BANK_PREV_BUS_DAYS } from '../actions/ActionConstatnts';
import { BankAccount, Holiday, StaticDataStore } from '../commonTypes';
export const usePreviousBusinessDay = () => {
  const [forDate] = useState<Date>(moment().toDate());
  const { staticData, profileData } = useSelector((store: StaticDataStore) => store);
  const [forAccounts] = useState<BankAccount[]>(staticData?.bankAccounts || []);
  const [holidayDates, setHolidayDates] = useState<Holiday[]>([]);
  const dispatch = useDispatch();
  const { db } = useContext(AppContext);

  const fetchHolidays = useCallback(async () => {
    if (!forDate || !profileData?.orgId) {
      return;
    }
    const q = query(
      collection(db, 'Organizations', profileData?.orgId, 'Holidays'),
      where('date', '>=', moment(forDate).subtract(7, 'days').toDate()),
      where('date', '<=', moment(forDate).add(1, 'days').toDate()),
      orderBy('date'),
    );
    const querySnapshot = await getDocs(q);
    const _holidays: Holiday[] = [];
    querySnapshot.forEach((holidayDoc) => {
      _holidays.push({
        ...holidayDoc.data(),
        id: holidayDoc.id,
        date: holidayDoc.data().date.toDate(),
      } as Holiday);
    });
    // console.warn('fetchHolidays', _holidays);
    setHolidayDates(_holidays);
  }, [db, forDate, profileData?.orgId]);

  useEffect(() => {
    fetchHolidays();
  }, [fetchHolidays]);

  const updatedAccounts = useMemo(() => {
    return forAccounts.map((account) => {
      let previousBusinessDay = moment(forDate).subtract(1, 'days').toDate();
      const accountEntityCountry = staticData.entities?.find(
        (entity) => entity.name === account.entity,
      )?.address?.country;
      const countryNonBusinessDays = staticData.countries?.find(
        (country) => country.code === accountEntityCountry,
      )?.nonBusinessDays || [0, 6];

      while (
        countryNonBusinessDays?.includes(moment(previousBusinessDay).day()) ||
        holidayDates.find(
          (holiday) =>
            ((accountEntityCountry && holiday.countries?.includes(accountEntityCountry)) ||
              holiday.currencies?.includes(account.currency) ||
              (!holiday.countries && !holiday.currencies)) &&
            moment(holiday.date).isSame(previousBusinessDay, 'day'),
        )
      ) {
        previousBusinessDay = moment(previousBusinessDay).subtract(1, 'days').toDate();
      }

      return { ...account, previousBusinessDay };
    });
  }, [forAccounts, forDate, holidayDates, staticData.countries, staticData.entities]);

  useEffect(() => {
    if (updatedAccounts) {
      dispatch({
        payload: updatedAccounts.reduce(
          (obj, item) => Object.assign(obj, { [item.id]: item.previousBusinessDay }),
          {},
        ),
        type: UPDATE_BANK_PREV_BUS_DAYS,
      });
    }
  }, [dispatch, updatedAccounts]);
  return;
};
