const tintColorLight = '#2f95dc';
const tintColorDark = '#fff';
const primary = '#1AA9D0';
const secondary = '#062E60';
const midBlue = '#145a92';

export default {
  primary: primary,
  secondary: secondary,
  midBlue,
  green: '#2E7D32',
  grey: 'grey',
  midGrey: '#666666',
  orange: 'orange',
  darkOrange: '#cc7000',
  light_grey: '#c0c0c0',
  lightest_grey: '#EAE9E8',
  excel_green: '#008000',
  red: '#D84315',
  white: 'white',
  black: 'black',
  transBlack: '#00000080',
  warning: '#c69035',
  info: '#89CFF0',
  light: {
    text: '#000',
    background: '#fff',
    tint: tintColorLight,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
    primary,
  },
  dark: {
    text: '#fff',
    background: '#000',
    tint: tintColorDark,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorDark,
    secondary,
  },
  chartColors: ['#075264', '#0A758F', '#0D98BA', '#10BBE5', '#32CBF1', '#5DD6F4', '#042F39'],
  categoryColors: [
    '#d73964',
    '#d23440',
    '#db643a',
    '#e88334',
    '#e2a71e',
    '#e25241',
    '#d0da59',
    '#4053ae',
    '#70b949',
    '#73564a',
    '#67ab5a',
    '#8f36aa',
    '#f6c244',
    '#52b9d0',
    '#4595ec',
    '#009688',
    '#5abeA7',
    '#59bccd',
    '#4a97e4',
    '#2d68cd',
    '#9946c7',
    '#d9639e',
    '#6d6f74',
    '#939287',
    '#868ea3',
  ],
};
