import React, { FC } from 'react';

import { useSelector } from 'react-redux';

import { Select } from 'chakra-react-select';

import { StaticDataStore } from '../commonTypes';
import { FormControl, FormLabel } from '@chakra-ui/react';
import CToolTip from './CToolTip';

export type CurrencyInputProps = {
  title?: string;
  onSelect: (values: string[]) => void;
  value?: string;
  isReadOnly?: boolean;
  isRequired?: boolean;
  values?: string[];
  isMultiSelect?: boolean;
};
const CurrencyInput: FC<CurrencyInputProps> = ({
  onSelect,
  value,
  isRequired,
  isReadOnly = false,
  values,
  isMultiSelect,
  title,
}) => {
  const { staticData } = useSelector((store: StaticDataStore) => store);
  return (
    <FormControl isRequired={isRequired} width={300}>
      <FormLabel>
        {title || 'Currency'}
        {value && !staticData.currencies?.find((currency) => currency.iso === value) && (
          <CToolTip
            isWarningIcon
            label="This currency is not setup. Please request the Admin to set this up to use it elsewhere"
          />
        )}
      </FormLabel>
      <Select
        isReadOnly={isReadOnly}
        isMulti={isMultiSelect}
        onChange={(selected) => {
          console.warn('selected', selected);
          isMultiSelect ? onSelect(selected?.map((val) => val.value)) : onSelect([selected?.value]);
        }}
        closeMenuOnSelect={!isMultiSelect}
        value={
          isMultiSelect
            ? values?.map((val) => {
                return { value: val, label: val };
              })
            : value
            ? [{ value, label: value }]
            : []
        }
        name="Currency"
        options={staticData.currencies?.map((ccy) => {
          return { value: ccy.iso, label: ccy.iso + ' - ' + ccy.name };
        })}
        placeholder="Select Currency"
        size="sm"
        menuPosition="fixed"
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
        }}
        chakraStyles={{
          menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
        }}
        menuPortalTarget={document.body}
      />
    </FormControl>
  );
};

export default CurrencyInput;
