/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useContext, useState } from 'react';

import { StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';

import {
  DocumentData,
  QueryDocumentSnapshot,
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
} from 'firebase/firestore';
import {
  ApprovalWorkflowSource,
  BankAccount,
  Payment,
  PaymentStatus,
  StaticDataStore,
} from '../commonTypes';
import ReviewAndApproveModal from '../components/ApprovalWorkflow/ReviewAndApproveModal';
import { CFlatList } from '../components/CFlatList';
import { FilterBar } from '../components/FilterBar';
import { View } from '../components/Themed';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import AppStyles from '../constants/Styles';
import { RootTabScreenProps } from '../types';
import { _filterToWhereClause, _searchList } from '../utils/helper';

import { useFocusEffect } from '@react-navigation/native';
import { isEmpty } from 'lodash';
import { AppContext } from '../App';
import Button from '../components/Button';
import PaymentListItem from '../components/Payments/PaymentListItem';
import { useToast } from '@chakra-ui/react';

export default function PaymentsScreen({ navigation }: RootTabScreenProps<'PaymentsScreen'>) {
  const [payments, setPayments] = useState<Payment[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedItemForApproval, setSelectedItemForApproval] = useState<BankAccount>();
  const [filteredPayments, setFilteredPayments] = useState<Payment[] | undefined>();
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [filter, setFilter] = useState({});
  const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot<DocumentData> | null>();
  const toast = useToast();
  const { profileData, staticData } = useSelector((store: StaticDataStore) => store);

  const canEdit = staticData.accessibleScreens?.PaymentsScreen?.edit || false;

  const { db } = useContext(AppContext);

  const fetchPayments = useCallback(
    async (filterValues: any) => {
      console.log('Fetching Payments - PaymentsScreen');
      if (!profileData?.orgId) {
        return;
      }
      setIsLoading(true);
      setLastVisible(null);
      const constraints = _filterToWhereClause(filterValues);
      const q = query(
        collection(db, 'Organizations', profileData?.orgId, 'Payments'),
        ...constraints,
        orderBy('valueDate', 'desc'),
        orderBy('sequenceNumber', 'desc'),
        limit(5),
      );
      const querySnapshot = await getDocs(q);
      const _payments: Payment[] = [];
      querySnapshot.forEach((doc) => {
        _payments.push({ ...doc.data(), id: doc.id } as Payment);
      });

      setPayments(
        _payments.filter(
          (
            p, // ToDo: Handle it from BE
          ) =>
            staticData?.bankAccounts?.some(
              (bankAccount) => bankAccount.id === p.fromAccountDetails?.id,
            ),
        ),
      );
      if (querySnapshot.empty) {
        setLastVisible(null);
      } else {
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      }
      setIsLoading(false);
    },
    [db, profileData?.orgId, staticData?.bankAccounts],
  );

  const loadMorePayments = useCallback(
    async (filterValues: any) => {
      if (lastVisible) {
        setIsLoadingMore(true);
        const constraints = _filterToWhereClause(filterValues);
        const q = query(
          collection(db, 'Organizations', profileData?.orgId, 'Payments'),
          ...constraints,
          orderBy('valueDate', 'desc'),
          orderBy('sequenceNumber', 'desc'),
          startAfter(lastVisible),
          limit(5),
        );
        const querySnapshot = await getDocs(q);
        const _payments: Payment[] = [];
        querySnapshot.forEach((doc) => {
          _payments.push({ ...doc.data(), id: doc.id } as Payment);
        });
        if (querySnapshot.empty) {
          setLastVisible(null);
        } else {
          setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        }
        setPayments([
          ...payments,
          ..._payments.filter(
            (
              p, // ToDo: Handle it from BE
            ) =>
              staticData?.bankAccounts?.some(
                (bankAccount) => bankAccount.id === p.fromAccountDetails?.id,
              ),
          ),
        ]);
        setIsLoadingMore(false);
      }
    },
    [db, lastVisible, payments, profileData?.orgId],
  );

  useFocusEffect(
    useCallback(() => {
      setIsLoading(true);
      fetchPayments(filter);
    }, [fetchPayments, filter]),
  );

  // useEffect(() => {
  //   fetchPayments();
  //   setIsLoading(false);
  // }, [fetchPayments]);

  const onSearchOrFilter = useCallback(
    (searchValue: string) => {
      if (searchValue && searchValue !== '') {
        setFilteredPayments(_searchList(payments, searchValue));
      } else {
        setFilteredPayments(undefined);
      }
    },
    [payments],
  );

  const onCreateNewPayment = useCallback(() => {
    if ((staticData?.categories?.length || 0) === 0) {
      return toast({
        title: 'No Categories found',
        description: 'You do not have access to any categories to create a payment.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }

    navigation.navigate('CreatePaymentScreen', {});
  }, [profileData.uid, staticData?.categories]);

  return (
    <View style={AppStyles.container}>
      <ReviewAndApproveModal
        onClose={() => setSelectedItemForApproval(undefined)}
        pendingApprovalItem={selectedItemForApproval}
        source={ApprovalWorkflowSource.PAYMENTS}
      />
      <View style={[AppStyles.flexRowCenterSpaceBetween, AppStyles.marginTop]}>
        {(payments && payments.length > 0) || !isEmpty(filter) ? (
          <FilterBar
            onSearch={onSearchOrFilter}
            searchPlaceholder={'Search Loaded Payments'}
            filter={filter}
            setFilter={setFilter}
            filterParams={[
              {
                title: 'Value Date',
                type: 'DateRange',
                field: 'valueDate',
              },
              {
                title: 'Status',
                type: 'MultiSelect',
                field: 'status',
                options: [
                  { label: PaymentStatus.PENDING_APPROVAL, value: PaymentStatus.PENDING_APPROVAL },
                  { label: PaymentStatus.APPROVED, value: PaymentStatus.APPROVED },
                  { label: PaymentStatus.RELEASED, value: PaymentStatus.RELEASED },
                  { label: PaymentStatus.SENT, value: PaymentStatus.SENT },
                  { label: PaymentStatus.RECEIVED, value: PaymentStatus.RECEIVED },
                  { label: PaymentStatus.COMPLETED, value: PaymentStatus.COMPLETED },
                  { label: PaymentStatus.REJECTED, value: PaymentStatus.REJECTED },
                ],
              },
            ]}
          />
        ) : (
          <View />
        )}
        {staticData.accessibleScreens?.PaymentsScreen?.create ? (
          <Button label="Create Payment" variant="Create" onPress={onCreateNewPayment} />
        ) : (
          <View />
        )}
      </View>

      <View
        style={{
          flexDirection: 'row',
          height: Layout.window.height - 150,
          justifyContent: 'space-between',
        }}
      >
        <View style={{ width: Layout.window.width - 60 }}>
          <CFlatList
            emptyMessage={!isEmpty(filter) ? 'No results found' : 'No Payments yet'}
            emptyCTA={'Create Payment'}
            emptyAction={onCreateNewPayment}
            // subAction={
            //   <Text style={AppStyles.textSubTitle}>
            //     Importing bank statements will automatically create bank accounts if they do not
            //     exists
            //   </Text>
            // }
            data={filteredPayments || payments}
            isLoading={isLoading}
            isLoadingMore={isLoadingMore}
            loadMore={() => loadMorePayments(filter)}
            renderItem={({ item }: { item: Payment }) => <PaymentListItem payment={item} />}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  bankAccountCard: {
    marginBottom: 10,
    marginHorizontal: 10,
    borderWidth: 2,
    flex: 1,
    borderRadius: 5,
    padding: 0,
  },
  triangle: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderTopWidth: 20,
    borderRightWidth: 0,
    borderBottomWidth: 20,
    borderLeftWidth: 15,
    borderTopColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: 'transparent',
    borderLeftColor: Colors.primary,
    position: 'absolute',
    left: 0,
    top: 35,
  },
});
