import React, { FC } from 'react';

import { TextStyle, TouchableOpacity } from 'react-native';

import { Card, Text, View } from './Themed';
import AppStyles from '../constants/Styles';

export type CardBadgeProps = {
  label: string;
  value: string;
  additionalInfo?: string;
  onPress?: () => void;
  additionalInfoStyle?: TextStyle;
};
const CardBadge: FC<CardBadgeProps> = ({
  label,
  value,
  additionalInfo,
  onPress,
  additionalInfoStyle,
}) => {
  return (
    <TouchableOpacity disabled={!onPress} onPress={onPress}>
      <Card style={[AppStyles.shadowContainer]}>
        <View style={AppStyles.alignCenter}>
          <Text style={AppStyles.textRowTitle}>{value}</Text>
          <Text>{label}</Text>
          {additionalInfo && (
            <Text style={[AppStyles.textAlert, additionalInfoStyle]}>{additionalInfo}</Text>
          )}
        </View>
      </Card>
    </TouchableOpacity>
  );
};

export default CardBadge;
