/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useContext, useState } from 'react';

import { useToast } from 'native-base';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';

import {
  DocumentData,
  QueryDocumentSnapshot,
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
} from 'firebase/firestore';
import {
  ApprovalWorkflowSource,
  BankAccount,
  Payment,
  StaticDataStore,
  Status,
} from '../commonTypes';
import ReviewAndApproveModal from '../components/ApprovalWorkflow/ReviewAndApproveModal';
import { CFlatList } from '../components/CFlatList';
import { FilterBar } from '../components/FilterBar';
import { View } from '../components/Themed';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import AppStyles from '../constants/Styles';
import { _filterToWhereClause, _searchList } from '../utils/helper';

import { FontAwesome } from '@expo/vector-icons';
import { useFocusEffect } from '@react-navigation/native';
import { isEmpty } from 'lodash';
import { AppContext } from '../App';
import Button from '../components/Button';
import PaymentListItem from '../components/Payments/PaymentListItem';
import { RootTabScreenProps } from '../types';

export default function PaymentTemplatesScreen({
  navigation,
}: RootTabScreenProps<'PaymentTemplatesScreen'>) {
  const [payments, setPayments] = useState<Payment[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedItemForApproval, setSelectedItemForApproval] = useState<BankAccount>();
  const [filteredPayments, setFilteredPayments] = useState<Payment[] | undefined>();
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [filter, setFilter] = useState({});
  const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot<DocumentData> | null>();
  const toast = useToast();
  const { profileData, staticData } = useSelector((store: StaticDataStore) => store);

  const canEdit = staticData.accessibleScreens?.PaymentTemplatesScreen?.edit || false;

  const { db } = useContext(AppContext);

  const fetchPaymentTemplates = useCallback(
    async (filterValues: any) => {
      console.log('Fetching Payment Tepaltes');
      if (!profileData?.orgId) {
        return;
      }
      setIsLoading(true);
      setLastVisible(null);
      const constraints = _filterToWhereClause(filterValues);
      const q = query(
        collection(db, 'Organizations', profileData?.orgId, 'PaymentTemplates'),
        ...constraints,
        orderBy('template'),
        limit(5),
      );
      const querySnapshot = await getDocs(q);
      const _payments: Payment[] = [];
      querySnapshot.forEach((doc) => {
        _payments.push({
          ...doc.data(),
          id: doc.id,
          fromAccountDetails:
            staticData?.bankAccounts?.find((b) => b.id === doc.data()?.fromAccountDetails?.id) ||
            doc.data()?.fromAccountDetails,
        } as Payment);
      });
      console.warn(_payments);
      setPayments(
        _payments.filter(
          (
            p, // ToDo: Handle it from BE
          ) =>
            staticData?.bankAccounts?.some(
              (bankAccount) => bankAccount.id === p.fromAccountDetails?.id,
            ),
        ),
      );
      if (querySnapshot.empty) {
        setLastVisible(null);
      } else {
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      }
      setIsLoading(false);
    },
    [db, profileData?.orgId],
  );

  const loadMorePaymentTemplates = useCallback(
    async (filterValues: any) => {
      if (lastVisible) {
        setIsLoadingMore(true);
        const constraints = _filterToWhereClause(filterValues);
        const q = query(
          collection(db, 'Organizations', profileData?.orgId, 'PaymentTemplates'),
          ...constraints,
          orderBy('template'),
          startAfter(lastVisible),
          limit(5),
        );
        const querySnapshot = await getDocs(q);
        const _payments: Payment[] = [];
        querySnapshot.forEach((doc) => {
          _payments.push({
            ...doc.data(),
            id: doc.id,
            fromAccountDetails:
              staticData?.bankAccounts?.find((b) => b.id === doc.data()?.fromAccountDetails?.id) ||
              doc.data()?.fromAccountDetails,
          } as Payment);
        });
        if (querySnapshot.empty) {
          setLastVisible(null);
        } else {
          setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        }
        setPayments([
          ...payments,
          ..._payments.filter(
            (
              p, // ToDo: Handle it from BE
            ) =>
              staticData?.bankAccounts?.some(
                (bankAccount) => bankAccount.id === p.fromAccountDetails?.id,
              ),
          ),
        ]);
        setIsLoadingMore(false);
      }
    },
    [db, lastVisible, payments, profileData?.orgId],
  );

  useFocusEffect(
    useCallback(() => {
      setIsLoading(true);
      fetchPaymentTemplates(filter);
    }, [fetchPaymentTemplates, filter]),
  );

  // useEffect(() => {
  //   fetchPayments();
  //   setIsLoading(false);
  // }, [fetchPayments]);

  const onSearchOrFilter = useCallback(
    (searchValue: string) => {
      if (searchValue && searchValue !== '') {
        setFilteredPayments(_searchList(payments, searchValue));
      } else {
        setFilteredPayments(undefined);
      }
    },
    [payments],
  );

  const onCreateNewPayment = useCallback(() => {
    navigation.navigate('CreatePaymentScreen', { isTemplate: true });
  }, [profileData.uid]);

  return (
    <View style={AppStyles.container}>
      <ReviewAndApproveModal
        onClose={() => setSelectedItemForApproval(undefined)}
        pendingApprovalItem={selectedItemForApproval}
        source={ApprovalWorkflowSource.PAYMENTS}
      />
      <View style={AppStyles.flexRowCenterSpaceBetween}>
        {(payments && payments.length > 0) || !isEmpty(filter) ? (
          <FilterBar
            onSearch={onSearchOrFilter}
            searchPlaceholder={'Search Loaded Templates'}
            filter={filter}
            setFilter={setFilter}
            filterParams={[
              {
                title: 'Status',
                type: 'MultiSelect',
                field: 'status',
                options: [
                  { label: Status.ACTIVE, value: Status.ACTIVE },
                  { label: Status.DEACTIVATED, value: Status.DEACTIVATED },
                  { label: Status.PENDING_APPROVAL, value: Status.PENDING_APPROVAL },
                ],
              },
            ]}
          />
        ) : (
          <View />
        )}
        <View style={AppStyles.flexRowCenter}>
          <TouchableOpacity
            style={[AppStyles.marginRight, AppStyles.padding]}
            onPress={() => {
              setIsLoading(true);
              fetchPaymentTemplates(filter);
            }}
          >
            <FontAwesome name="refresh" size={24} color={Colors.primary} />
          </TouchableOpacity>
          {staticData.accessibleScreens?.PaymentTemplatesScreen?.create ? (
            <Button label="Create Payment Template" variant="Create" onPress={onCreateNewPayment} />
          ) : (
            <View />
          )}
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row',
          height: Layout.window.height - 150,
          justifyContent: 'space-between',
        }}
      >
        <View style={{ width: Layout.window.width - 60 }}>
          <CFlatList
            emptyMessage={!isEmpty(filter) ? 'No results found' : 'No Templates yet'}
            emptyCTA={'Create Payment Template'}
            emptyAction={onCreateNewPayment}
            // subAction={
            //   <Text style={AppStyles.textSubTitle}>
            //     Importing bank statements will automatically create bank accounts if they do not
            //     exists
            //   </Text>
            // }
            data={filteredPayments || payments}
            isLoading={isLoading}
            isLoadingMore={isLoadingMore}
            loadMore={() => loadMorePaymentTemplates(filter)}
            renderItem={({ item }: { item: Payment }) => (
              <PaymentListItem payment={item} isTemplate />
            )}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  bankAccountCard: {
    marginBottom: 10,
    marginHorizontal: 10,
    borderWidth: 2,
    flex: 1,
    borderRadius: 5,
    padding: 0,
  },
  triangle: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderTopWidth: 20,
    borderRightWidth: 0,
    borderBottomWidth: 20,
    borderLeftWidth: 15,
    borderTopColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: 'transparent',
    borderLeftColor: Colors.primary,
    position: 'absolute',
    left: 0,
    top: 35,
  },
});
