import React, { useRef } from 'react';

import { AlertDialog, Button } from 'native-base';

export type AlertDialogProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  title: string;
  content: string | React.ReactElement;
  onAction: () => void;
  actionText?: string;
  colorScheme?: string;
  cancelText?: string;
};
export function CAlertDialog({
  open,
  setOpen,
  title,
  content,
  actionText = 'Continue',
  onAction,
  colorScheme = 'lime',
  cancelText = 'Cancel',
}: AlertDialogProps) {
  const onClose = () => setOpen(false);

  const cancelRef = useRef(null);
  return (
    <AlertDialog leastDestructiveRef={cancelRef} isOpen={open} onClose={onClose}>
      <AlertDialog.Content>
        <AlertDialog.CloseButton />
        <AlertDialog.Header>{title}</AlertDialog.Header>
        <AlertDialog.Body>{content}</AlertDialog.Body>
        <AlertDialog.Footer>
          <Button.Group space={2}>
            <Button variant="unstyled" colorScheme="coolGray" onPress={onClose} ref={cancelRef}>
              {cancelText}
            </Button>
            <Button
              colorScheme={colorScheme}
              onPress={() => {
                onClose();
                onAction();
              }}
            >
              {actionText}
            </Button>
          </Button.Group>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  );
}
