/* eslint-disable react-native/no-inline-styles */
import React from 'react';

import { Checkbox } from 'native-base';
import { ScrollView } from 'react-native';

import { StatementBalanceItem } from './StatementBalanceItem';
import { AmountText } from './StyledText';
import { Card, Text, View } from './Themed';
import Colors from '../constants/Colors';
import Constants from '../constants/Constants';
import AppStyles from '../constants/Styles';
import { StatementTransaction } from '../commonTypes';
import { _getCodeDescr, _getColorFromCrDrIndicator } from '../utils/helper';

export type AccountStatementTransactionItemProps = {
  transactionItem: StatementTransaction;
  useDateString?: boolean;
};
export function AccountStatementTransactionItem({
  transactionItem,
  useDateString = false,
}: AccountStatementTransactionItemProps) {
  if (transactionItem.isBalance) {
    return (
      <Card
        style={{
          marginBottom: 10,
          marginHorizontal: 10,
          borderColor: Colors.primary,
          borderWidth: 2,
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            backgroundColor: 'transparent',
            justifyContent: 'space-between',
          }}
        >
          <Text
            style={{
              fontSize: Constants.TextSize.max,
              color: Colors.primary,
            }}
          >
            {'Account Number: ' + transactionItem.accountNumber}
          </Text>
          {transactionItem.branchCode ? (
            <Text>{'Swift/Route Code: ' + transactionItem.branchCode}</Text>
          ) : null}
        </View>
        <ScrollView horizontal>
          {transactionItem.balances?.map((balance, index) => {
            return (
              <StatementBalanceItem
                balance={balance}
                currency={transactionItem.currency}
                key={index.toString()}
              />
            );
          })}
        </ScrollView>
      </Card>
    );
  }
  return (
    <Card>
      <View style={AppStyles.flexRow}>
        <Checkbox
          style={{ marginRight: 10 }}
          isChecked={transactionItem.isSelected}
          value={'unmappedTransactionsOnly'}
          isReadOnly={true}
          // onChange={setUnmappedTransactionsOnly}
        >
          <></>
        </Checkbox>
        <View style={{ flex: 1 }}>
          <View
            style={{
              flexDirection: 'row',
              flex: 1,
              backgroundColor: 'transparant',
              justifyContent: 'space-between',
            }}
          >
            <Text style={AppStyles.textSubTitle}>{transactionItem.accountNumber}</Text>
            <View style={AppStyles.alignFlexEnd}>
              <AmountText
                amount={transactionItem.amount}
                currency={transactionItem.currency}
                code={transactionItem.subFamilyCode || transactionItem.code}
                color={_getColorFromCrDrIndicator(transactionItem.CdtDbtInd)}
              />
              <Text style={{ flex: 1 }}>
                {_getCodeDescr({
                  code: transactionItem.code,
                  familyCode: transactionItem.familyCode,
                  subFamilyCode: transactionItem.subFamilyCode,
                })}
              </Text>
            </View>
          </View>

          <Text style={{ flex: 1, marginTop: 10 }}>{transactionItem.narrative}</Text>
        </View>
      </View>
    </Card>
  );
}
